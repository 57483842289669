/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface IDAMSupporting
 */
export interface IDAMSupporting {
  /**
   *
   * @type {string}
   * @memberof IDAMSupporting
   */
  itemNo?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMSupporting
   */
  itemType?: string;
}

export function IDAMSupportingFromJSON(json: any): IDAMSupporting {
  return IDAMSupportingFromJSONTyped(json, false);
}

export function IDAMSupportingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IDAMSupporting {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    itemNo: !exists(json, 'itemNo') ? undefined : json['itemNo'],
    itemType: !exists(json, 'itemType') ? undefined : json['itemType'],
  };
}

export function IDAMSupportingToJSON(value?: IDAMSupporting | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    itemNo: value.itemNo,
    itemType: value.itemType,
  };
}
