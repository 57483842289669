export const baseUrl = process.env.REACT_APP_BASE_URL;
export const env = process.env.REACT_APP_ENV;
export let resolvedEnv: 'dev' | 'prod' = 'dev';
console.log('env', env);

export const cacheLocation = 'localStorage' as const; // sessionStorage

let BYNDER_BASE_URL;
let BYNDER_API_BASE_URL;
let BYNDER_CLIENT_ID;
let BYNDER_CLIENT_SECRET;
let BYNDER_REDIRECT_URI;

let BYNDER_RELEASE_DATE_META_ID;
let BYNDER_DESCRIPTIVE_METADATA_META_ID;
let BYNDER_DESCRIPTIVE_METADATA_VALUES_META_ID;
let BYNDER_INTENDED_TIMING_META_ID;
let BYNDER_OBJECTIVE_META_ID;

let BYNDER_PRODUCT_COORDINATES_META_ID;

let BYNDER_CAMERA_SHOT_META_ID = '3B4DA76E-4577-40E3-A77666528CBD2C23';
let BYNDER_COMMON_SEASONAL_ACTIVITY_META_ID = '11660247-F229-4A92-AF66F55745331DD3';
let BYNDER_HAS_ANIMALS_META_ID = '59B94620-FBBF-49B6-B6AB237AC96DE1A5';
let BYNDER_HAS_PEOPLE_META_ID = 'E3D756FE-1CB2-4CE0-831999AED13E992A';
let BYNDER_LONG_TIME_PRIORITY_META_ID = 'B6A09DA4-9C51-43F2-9F884AFDEFE79BDB';
let BYNDER_MAIN_HF_ACTIVITY_PRIMARY_META_ID = '32D4E1E2-D9C7-4D09-82A9DE79D4F55600';
let BYNDER_ORIENTATION_META_ID = '20273AF9-0EE3-430B-990FF625AB10A914';
let BYNDER_PRICE_LEVEL_META_ID = '1CED2467-8656-447F-99B85EC51909899E';
let BYNDER_ROOM_TYPE_META_ID = 'B4670F57-177E-4B6F-A27375AD921BB372';
let BYNDER_SEASON_ICOM_META_ID = 'E6184003-948C-41B6-9A9763DED291F6E7';
let BYNDER_STYLE_GROUP_META_ID = '16B5137A-F664-49F9-9243410716894120';
let BYNDER_NEGOTIATED_USAGE_RIGHTS_ID = 'B7A4FBD3-C00D-424D-9E5F49BC559AE7F1';
let BYNDER_PRODUCED_FOR_ID = 'D408A2C1-E829-43E5-A53F717961E29889';
let BYNDER_CHANNEL_META_ID;
let BYNDER_ITEM_NO_META_ID;

let BYNDER_PRODUCT_NAME_META_ID;
let BYNDER_CROP_INFO_META_ID;

let BYNDER_CHANNEL_META_INSPIRATIONAL_FEED_OPTION_ID;
let BYNDER_CHANNEL_META_SHOPPABLE_APP_OPTION_ID;
let BYNDER_CONTENT_TYPE_META_ID;
let BYNDER_CONTENT_TYPE_META_SHOPPABLE_IMAGE_OPTION_ID;

if (env === 'production') {
  BYNDER_BASE_URL = 'https://ikea.getbynder.com/';
  BYNDER_API_BASE_URL = `${BYNDER_BASE_URL}api/`;
  BYNDER_CLIENT_ID = '03920f8e-f60b-4b0d-95a9-100c181cd03e';
  BYNDER_CLIENT_SECRET = '30b41ed5-c472-4f7e-8407-e3ba694d812e';
  BYNDER_REDIRECT_URI = 'https://ladan.ingka.com';
  BYNDER_DESCRIPTIVE_METADATA_META_ID = '';
  BYNDER_DESCRIPTIVE_METADATA_VALUES_META_ID = '';
  BYNDER_NEGOTIATED_USAGE_RIGHTS_ID = '99E5973B-B49A-43F0-9A82B884EFD30631';
  BYNDER_PRODUCED_FOR_ID = 'BA18BB80-AE2F-4B90-A5381385763FB0DC';

  BYNDER_RELEASE_DATE_META_ID = 'C3F64427-0385-4F81-A05790EC63D560A2';
  BYNDER_INTENDED_TIMING_META_ID = '56B66335-2A6E-4699-AB3351CEED075018';
  BYNDER_OBJECTIVE_META_ID = 'BD9C2565-96FB-4087-82E5E6F7D0A4769D';

  BYNDER_CAMERA_SHOT_META_ID = '80D02925-4008-40FF-8E94BB61FA789463';
  BYNDER_COMMON_SEASONAL_ACTIVITY_META_ID = '24A7994C-B9EC-41F5-84CFA601BD99BF61';
  BYNDER_HAS_ANIMALS_META_ID = 'B6DEEAD4-2B0B-4388-A3995DCBC1B73851';
  BYNDER_HAS_PEOPLE_META_ID = '81555B92-C497-4F74-A0D9C86C8F2AD4D9';
  BYNDER_LONG_TIME_PRIORITY_META_ID = 'F56D52A5-A1C9-4591-ABA3F23E6D2DE162';
  BYNDER_MAIN_HF_ACTIVITY_PRIMARY_META_ID = 'EF102415-5D56-4CAB-94B5156EB7202F8B';
  BYNDER_ORIENTATION_META_ID = '9229D461-D819-43C6-B4019BE74B35F67B';
  BYNDER_PRICE_LEVEL_META_ID = 'A31B7C2A-CFE6-42EF-97D473982F6193AA';
  BYNDER_ROOM_TYPE_META_ID = '13F88F53-CEA5-4DE8-A155AEEFD957FA27';
  BYNDER_SEASON_ICOM_META_ID = '7BD7A065-9A2E-4E6B-BC45B7519340E1E0';
  BYNDER_STYLE_GROUP_META_ID = 'F5601FF6-6E7B-4BAD-BF12EF0E80E7D636';

  BYNDER_ITEM_NO_META_ID = 'A351E785-930B-4837-A6F45FC47186C002';
  // BYNDER_PRODUCT_LIST_META_ID = '9AB3F6CA-891F-44F2-A566472C855427E4';
  BYNDER_PRODUCT_NAME_META_ID = 'DDBD1E21-FFA6-473A-A93DD33A0BEE7136';

  BYNDER_PRODUCT_COORDINATES_META_ID = 'E9BEB4D4-2072-4653-9E3E450B10C25957';

  BYNDER_CHANNEL_META_ID = '0F8B8EF6-DE76-4FB6-935CFAA410AC1BCA';
  BYNDER_CONTENT_TYPE_META_ID = '330594CD-7483-433E-96C7C83E1E4FD1E1';
  BYNDER_CONTENT_TYPE_META_SHOPPABLE_IMAGE_OPTION_ID = '66BA9D57-A6FA-4BFB-93CB1D24B21316E1';
  BYNDER_CROP_INFO_META_ID = '65831D24-4B1A-4BCD-8A02BF929D9EEED7';

  BYNDER_CHANNEL_META_INSPIRATIONAL_FEED_OPTION_ID = '49437F0B-A946-4541-9703E146C3A048E6';
  BYNDER_CHANNEL_META_SHOPPABLE_APP_OPTION_ID = '10A6FAD3-0B0D-437E-9A3ADC321A00B9C3';

  resolvedEnv = 'prod';
} else if (env === 'dev') {
  BYNDER_RELEASE_DATE_META_ID = '626D1215-AF04-449A-9FC8C881949C7AF2';
  BYNDER_BASE_URL = 'https://ikea-demo.getbynder.com/';
  BYNDER_API_BASE_URL = `${BYNDER_BASE_URL}api/`;
  BYNDER_CLIENT_ID = '4409a359-19c1-4da4-be81-7efe9e19b6ef';
  BYNDER_CLIENT_SECRET = '2f558bdb-01c2-4162-80e7-9c20fa2e7b42';
  BYNDER_REDIRECT_URI = 'https://dev.ladan.ingka.com';
  BYNDER_DESCRIPTIVE_METADATA_META_ID = '07AFFEF5-F58E-4FD8-84543E0B84AE2BDC';
  BYNDER_INTENDED_TIMING_META_ID = 'D684A807-03F1-45CC-8E142B8405EE4EAF';
  BYNDER_OBJECTIVE_META_ID = '522BBA50-CD58-482A-983D17C8F233D8C6';
  BYNDER_NEGOTIATED_USAGE_RIGHTS_ID = 'B7A4FBD3-C00D-424D-9E5F49BC559AE7F1';
  BYNDER_PRODUCED_FOR_ID = 'D408A2C1-E829-43E5-A53F717961E29889';

  BYNDER_CAMERA_SHOT_META_ID = '3B4DA76E-4577-40E3-A77666528CBD2C23';
  BYNDER_COMMON_SEASONAL_ACTIVITY_META_ID = '11660247-F229-4A92-AF66F55745331DD3';
  BYNDER_HAS_ANIMALS_META_ID = '59B94620-FBBF-49B6-B6AB237AC96DE1A5';
  BYNDER_HAS_PEOPLE_META_ID = 'E3D756FE-1CB2-4CE0-831999AED13E992A';
  BYNDER_LONG_TIME_PRIORITY_META_ID = 'B6A09DA4-9C51-43F2-9F884AFDEFE79BDB';
  BYNDER_MAIN_HF_ACTIVITY_PRIMARY_META_ID = '32D4E1E2-D9C7-4D09-82A9DE79D4F55600';
  BYNDER_ORIENTATION_META_ID = '20273AF9-0EE3-430B-990FF625AB10A914';
  BYNDER_PRICE_LEVEL_META_ID = '1CED2467-8656-447F-99B85EC51909899E';
  BYNDER_ROOM_TYPE_META_ID = 'B4670F57-177E-4B6F-A27375AD921BB372';
  BYNDER_SEASON_ICOM_META_ID = 'E6184003-948C-41B6-9A9763DED291F6E7';
  BYNDER_STYLE_GROUP_META_ID = '16B5137A-F664-49F9-9243410716894120';

  BYNDER_ITEM_NO_META_ID = '8C1E2DBA-08F7-4933-8A39C575213F2DBA';
  // BYNDER_PRODUCT_LIST_META_ID = '0EE55256-53C7-4969-B1142A319B159CDD';
  BYNDER_PRODUCT_NAME_META_ID = '03EA2432-AF77-49D0-AAA352C4D28C4EB1';

  BYNDER_PRODUCT_COORDINATES_META_ID = '293633FD-9710-4E7D-AFB0C33B9842E691';

  BYNDER_CONTENT_TYPE_META_ID = '87EEB5DD-D107-4E64-87FCABAD7967CAE2';
  BYNDER_CONTENT_TYPE_META_SHOPPABLE_IMAGE_OPTION_ID = '47AC59BF-2481-40EA-9458F0867235C7A1';
  BYNDER_CROP_INFO_META_ID = '9CD2F618-ADD7-4230-A52F6ACE15610746';

  BYNDER_CHANNEL_META_ID = '43652CD9-08A0-487F-99F868AB9A853C41';
  BYNDER_CHANNEL_META_INSPIRATIONAL_FEED_OPTION_ID = '33CB0F36-3C18-49AB-A769EF6F40C3B389';
  BYNDER_CHANNEL_META_SHOPPABLE_APP_OPTION_ID = '5E235FD3-783F-4D33-8BE79DD2ED0E262E';

  resolvedEnv = 'dev';
} else {
  resolvedEnv = 'dev';
  // local
  BYNDER_BASE_URL = 'https://ikea-demo.getbynder.com/';
  BYNDER_API_BASE_URL = `${BYNDER_BASE_URL}api/`;
  BYNDER_CLIENT_ID = '4409a359-19c1-4da4-be81-7efe9e19b6ef';
  BYNDER_CLIENT_SECRET = '2f558bdb-01c2-4162-80e7-9c20fa2e7b42';
  BYNDER_REDIRECT_URI = 'http://localhost:3000';

  // prod
  // BYNDER_BASE_URL = 'https://ikea.getbynder.com/';
  // BYNDER_API_BASE_URL = `${BYNDER_BASE_URL}api/`;
  // BYNDER_CLIENT_ID = '03920f8e-f60b-4b0d-95a9-100c181cd03e';
  // BYNDER_CLIENT_SECRET = '30b41ed5-c472-4f7e-8407-e3ba694d812e';
  // BYNDER_REDIRECT_URI = 'http://localhost:3000';

  // bynder meta dev
  BYNDER_RELEASE_DATE_META_ID = '626D1215-AF04-449A-9FC8C881949C7AF2';
  BYNDER_DESCRIPTIVE_METADATA_META_ID = '07AFFEF5-F58E-4FD8-84543E0B84AE2BDC';
  BYNDER_INTENDED_TIMING_META_ID = 'D684A807-03F1-45CC-8E142B8405EE4EAF';
  BYNDER_OBJECTIVE_META_ID = '522BBA50-CD58-482A-983D17C8F233D8C6';
  BYNDER_CAMERA_SHOT_META_ID = '3B4DA76E-4577-40E3-A77666528CBD2C23';
  BYNDER_COMMON_SEASONAL_ACTIVITY_META_ID = '11660247-F229-4A92-AF66F55745331DD3';
  BYNDER_HAS_ANIMALS_META_ID = '59B94620-FBBF-49B6-B6AB237AC96DE1A5';
  BYNDER_HAS_PEOPLE_META_ID = 'E3D756FE-1CB2-4CE0-831999AED13E992A';
  BYNDER_LONG_TIME_PRIORITY_META_ID = 'B6A09DA4-9C51-43F2-9F884AFDEFE79BDB';
  BYNDER_MAIN_HF_ACTIVITY_PRIMARY_META_ID = '32D4E1E2-D9C7-4D09-82A9DE79D4F55600';
  BYNDER_ORIENTATION_META_ID = '20273AF9-0EE3-430B-990FF625AB10A914';
  BYNDER_PRICE_LEVEL_META_ID = '1CED2467-8656-447F-99B85EC51909899E';
  BYNDER_ROOM_TYPE_META_ID = 'B4670F57-177E-4B6F-A27375AD921BB372';
  BYNDER_SEASON_ICOM_META_ID = 'E6184003-948C-41B6-9A9763DED291F6E7';
  BYNDER_STYLE_GROUP_META_ID = '16B5137A-F664-49F9-9243410716894120';
  BYNDER_PRODUCT_COORDINATES_META_ID = '293633FD-9710-4E7D-AFB0C33B9842E691';
  BYNDER_CHANNEL_META_ID = '43652CD9-08A0-487F-99F868AB9A853C41';
  BYNDER_CONTENT_TYPE_META_ID = '87EEB5DD-D107-4E64-87FCABAD7967CAE2';
  BYNDER_CHANNEL_META_INSPIRATIONAL_FEED_OPTION_ID = '33CB0F36-3C18-49AB-A769EF6F40C3B389';
  BYNDER_CHANNEL_META_SHOPPABLE_APP_OPTION_ID = '5E235FD3-783F-4D33-8BE79DD2ED0E262E';
  BYNDER_CONTENT_TYPE_META_SHOPPABLE_IMAGE_OPTION_ID = '47AC59BF-2481-40EA-9458F0867235C7A1';
  BYNDER_ITEM_NO_META_ID = '8C1E2DBA-08F7-4933-8A39C575213F2DBA';
  // BYNDER_PRODUCT_LIST_META_ID = '0EE55256-53C7-4969-B1142A319B159CDD';
  BYNDER_PRODUCT_NAME_META_ID = '03EA2432-AF77-49D0-AAA352C4D28C4EB1';
  BYNDER_CROP_INFO_META_ID = '9CD2F618-ADD7-4230-A52F6ACE15610746';
}

export interface IBynderConfig {
  BYNDER_BASE_URL: string;
  BYNDER_API_BASE_URL: string;
  BYNDER_CLIENT_ID: string;
  BYNDER_CLIENT_SECRET: string;
  BYNDER_REDIRECT_URI: string;
}

export const bynder: IBynderConfig = {
  BYNDER_BASE_URL,
  BYNDER_API_BASE_URL,
  BYNDER_CLIENT_ID,
  BYNDER_CLIENT_SECRET,
  BYNDER_REDIRECT_URI,
};

export const bynderMetaFieldIDs = {
  BYNDER_RELEASE_DATE_META_ID,
  BYNDER_DESCRIPTIVE_METADATA_META_ID,
  BYNDER_DESCRIPTIVE_METADATA_VALUES_META_ID,
  BYNDER_INTENDED_TIMING_META_ID,
  BYNDER_OBJECTIVE_META_ID,

  BYNDER_CAMERA_SHOT_META_ID,
  BYNDER_COMMON_SEASONAL_ACTIVITY_META_ID,
  BYNDER_HAS_ANIMALS_META_ID,
  BYNDER_HAS_PEOPLE_META_ID,
  BYNDER_LONG_TIME_PRIORITY_META_ID,
  BYNDER_MAIN_HF_ACTIVITY_PRIMARY_META_ID,
  BYNDER_ORIENTATION_META_ID,
  BYNDER_PRICE_LEVEL_META_ID,
  BYNDER_ROOM_TYPE_META_ID,
  BYNDER_SEASON_ICOM_META_ID,
  BYNDER_STYLE_GROUP_META_ID,
  BYNDER_NEGOTIATED_USAGE_RIGHTS_ID,
  BYNDER_PRODUCED_FOR_ID,

  BYNDER_PRODUCT_COORDINATES_META_ID,

  BYNDER_CHANNEL_META_ID,
  BYNDER_CONTENT_TYPE_META_ID,
  BYNDER_CONTENT_TYPE_META_SHOPPABLE_IMAGE_OPTION_ID,

  BYNDER_CHANNEL_META_INSPIRATIONAL_FEED_OPTION_ID,
  BYNDER_CHANNEL_META_SHOPPABLE_APP_OPTION_ID,

  BYNDER_ITEM_NO_META_ID,
  // BYNDER_PRODUCT_LIST_META_ID,
  BYNDER_PRODUCT_NAME_META_ID,
  BYNDER_CROP_INFO_META_ID,
};

// export const BASE_URL = 'https://ikea-demo.getbynder.com';
// export const API_BASE_URL = `${BASE_URL}/api/`;
// export const CLIENT_ID = '4409a359-19c1-4da4-be81-7efe9e19b6ef';
// export const CLIENT_SECRET = '2f558bdb-01c2-4162-80e7-9c20fa2e7b42';
// export const REDIRECT_URI = 'http://localhost:3000';
