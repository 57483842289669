/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IDAMAssetInfo,
  IDAMAssetInfoFromJSON,
  IDAMAssetInfoFromJSONTyped,
  IDAMAssetInfoToJSON,
  IDAMAssetType,
  IDAMAssetTypeFromJSON,
  IDAMAssetTypeFromJSONTyped,
  IDAMAssetTypeToJSON,
  IDAMFiles,
  IDAMFilesFromJSON,
  IDAMFilesFromJSONTyped,
  IDAMFilesToJSON,
  IDAMProducts,
  IDAMProductsFromJSON,
  IDAMProductsFromJSONTyped,
  IDAMProductsToJSON,
} from './';

/**
 *
 * @export
 * @interface IDAMData
 */
export interface IDAMData {
  /**
   *
   * @type {string}
   * @memberof IDAMData
   */
  approvedDate?: string;
  /**
   *
   * @type {IDAMAssetInfo}
   * @memberof IDAMData
   */
  assetInfo?: IDAMAssetInfo;
  /**
   *
   * @type {IDAMAssetType}
   * @memberof IDAMData
   */
  assetType?: IDAMAssetType;
  /**
   *
   * @type {IDAMFiles}
   * @memberof IDAMData
   */
  files?: IDAMFiles;
  /**
   *
   * @type {string}
   * @memberof IDAMData
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMData
   */
  modifiedDate?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMData
   */
  schemaVersion?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMData
   */
  status?: IDAMDataStatusEnum;
  /**
   *
   * @type {string}
   * @memberof IDAMData
   */
  copyRightText?: string;
  /**
   *
   * @type {IDAMProducts}
   * @memberof IDAMData
   */
  products?: IDAMProducts;
  /**
   *
   * @type {string}
   * @memberof IDAMData
   */
  expiryDate?: string;
}

/**
 * @export
 * @enum {string}
 */
export type IDAMDataStatusEnum = 'ACTIVE';

export function IDAMDataFromJSON(json: any): IDAMData {
  return IDAMDataFromJSONTyped(json, false);
}

export function IDAMDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDAMData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    approvedDate: !exists(json, 'approvedDate') ? undefined : json['approvedDate'],
    assetInfo: !exists(json, 'assetInfo') ? undefined : IDAMAssetInfoFromJSON(json['assetInfo']),
    assetType: !exists(json, 'assetType') ? undefined : IDAMAssetTypeFromJSON(json['assetType']),
    files: !exists(json, 'files') ? undefined : IDAMFilesFromJSON(json['files']),
    id: !exists(json, 'id') ? undefined : json['id'],
    modifiedDate: !exists(json, 'modifiedDate') ? undefined : json['modifiedDate'],
    name: !exists(json, 'name') ? undefined : json['name'],
    schemaVersion: !exists(json, 'schemaVersion') ? undefined : json['schemaVersion'],
    status: !exists(json, 'status') ? undefined : json['status'],
    copyRightText: !exists(json, 'copyRightText') ? undefined : json['copyRightText'],
    products: !exists(json, 'products') ? undefined : IDAMProductsFromJSON(json['products']),
    expiryDate: !exists(json, 'expiryDate') ? undefined : json['expiryDate'],
  };
}

export function IDAMDataToJSON(value?: IDAMData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    approvedDate: value.approvedDate,
    assetInfo: IDAMAssetInfoToJSON(value.assetInfo),
    assetType: IDAMAssetTypeToJSON(value.assetType),
    files: IDAMFilesToJSON(value.files),
    id: value.id,
    modifiedDate: value.modifiedDate,
    name: value.name,
    schemaVersion: value.schemaVersion,
    status: value.status,
    copyRightText: value.copyRightText,
    products: IDAMProductsToJSON(value.products),
    expiryDate: value.expiryDate,
  };
}
