/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IMCCImageAttributesApprovedAt,
  IMCCImageAttributesApprovedAtFromJSON,
  IMCCImageAttributesApprovedAtFromJSONTyped,
  IMCCImageAttributesApprovedAtToJSON,
  IMCCImageAttributesCameraShot,
  IMCCImageAttributesCameraShotFromJSON,
  IMCCImageAttributesCameraShotFromJSONTyped,
  IMCCImageAttributesCameraShotToJSON,
  IMCCImageAttributesCommonSeasonalActivity,
  IMCCImageAttributesCommonSeasonalActivityFromJSON,
  IMCCImageAttributesCommonSeasonalActivityFromJSONTyped,
  IMCCImageAttributesCommonSeasonalActivityToJSON,
  IMCCImageAttributesLaunch,
  IMCCImageAttributesLaunchFromJSON,
  IMCCImageAttributesLaunchFromJSONTyped,
  IMCCImageAttributesLaunchToJSON,
  IMCCImageAttributesMainHomeFurnishingActivityPrimary,
  IMCCImageAttributesMainHomeFurnishingActivityPrimaryFromJSON,
  IMCCImageAttributesMainHomeFurnishingActivityPrimaryFromJSONTyped,
  IMCCImageAttributesMainHomeFurnishingActivityPrimaryToJSON,
  IMCCImageAttributesMimeType,
  IMCCImageAttributesMimeTypeFromJSON,
  IMCCImageAttributesMimeTypeFromJSONTyped,
  IMCCImageAttributesMimeTypeToJSON,
  IMCCImageAttributesModifiedAt,
  IMCCImageAttributesModifiedAtFromJSON,
  IMCCImageAttributesModifiedAtFromJSONTyped,
  IMCCImageAttributesModifiedAtToJSON,
  IMCCImageAttributesOrientation,
  IMCCImageAttributesOrientationFromJSON,
  IMCCImageAttributesOrientationFromJSONTyped,
  IMCCImageAttributesOrientationToJSON,
  IMCCImageAttributesReleasedAt,
  IMCCImageAttributesReleasedAtFromJSON,
  IMCCImageAttributesReleasedAtFromJSONTyped,
  IMCCImageAttributesReleasedAtToJSON,
  IMCCImageAttributesRoomType,
  IMCCImageAttributesRoomTypeFromJSON,
  IMCCImageAttributesRoomTypeFromJSONTyped,
  IMCCImageAttributesRoomTypeToJSON,
  IMCCImageAttributesSeason,
  IMCCImageAttributesSeasonFromJSON,
  IMCCImageAttributesSeasonFromJSONTyped,
  IMCCImageAttributesSeasonToJSON,
  IMCCProductResponse,
  IMCCProductResponseFromJSON,
  IMCCProductResponseFromJSONTyped,
  IMCCProductResponseToJSON,
  IMCCProductionMethod,
  IMCCProductionMethodFromJSON,
  IMCCProductionMethodFromJSONTyped,
  IMCCProductionMethodToJSON,
} from './';

/**
 *
 * @export
 * @interface IMCCImageAttributes
 */
export interface IMCCImageAttributes {
  /**
   *
   * @type {IMCCImageAttributesOrientation}
   * @memberof IMCCImageAttributes
   */
  orientation: IMCCImageAttributesOrientation;
  /**
   *
   * @type {number}
   * @memberof IMCCImageAttributes
   */
  fileSize: number;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  imageUrl: string;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  productName: string;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  agency: string;
  /**
   *
   * @type {IMCCImageAttributesApprovedAt}
   * @memberof IMCCImageAttributes
   */
  approvedAt: IMCCImageAttributesApprovedAt | null;
  /**
   *
   * @type {number}
   * @memberof IMCCImageAttributes
   */
  dpi: number;
  /**
   *
   * @type {number}
   * @memberof IMCCImageAttributes
   */
  editionYear: number;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  fileExtension: string;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  fiscalYear: string;
  /**
   *
   * @type {boolean}
   * @memberof IMCCImageAttributes
   */
  hasAnimals: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IMCCImageAttributes
   */
  hasClippingPath: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IMCCImageAttributes
   */
  hasPeople: boolean;
  /**
   *
   * @type {number}
   * @memberof IMCCImageAttributes
   */
  heightPx: number;
  /**
   *
   * @type {IMCCProductResponse}
   * @memberof IMCCImageAttributes
   */
  mainProduct: IMCCProductResponse;
  /**
   *
   * @type {IMCCImageAttributesMimeType}
   * @memberof IMCCImageAttributes
   */
  mimeType: IMCCImageAttributesMimeType | null;
  /**
   *
   * @type {IMCCImageAttributesModifiedAt}
   * @memberof IMCCImageAttributes
   */
  modifiedAt: IMCCImageAttributesModifiedAt | null;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  name: string;
  /**
   *
   * @type {Array<IMCCProductResponse>}
   * @memberof IMCCImageAttributes
   */
  products: Array<IMCCProductResponse>;
  /**
   *
   * @type {IMCCImageAttributesReleasedAt}
   * @memberof IMCCImageAttributes
   */
  releasedAt: IMCCImageAttributesReleasedAt | null;
  /**
   *
   * @type {number}
   * @memberof IMCCImageAttributes
   */
  widthPx: number;
  /**
   *
   * @type {IMCCProductionMethod}
   * @memberof IMCCImageAttributes
   */
  productionMethod?: IMCCProductionMethod | null;
  /**
   *
   * @type {IMCCImageAttributesCameraShot}
   * @memberof IMCCImageAttributes
   */
  cameraShot?: IMCCImageAttributesCameraShot;
  /**
   *
   * @type {IMCCImageAttributesCommonSeasonalActivity}
   * @memberof IMCCImageAttributes
   */
  commonSeasonalActivity?: IMCCImageAttributesCommonSeasonalActivity;
  /**
   *
   * @type {IMCCImageAttributesLaunch}
   * @memberof IMCCImageAttributes
   */
  launch?: IMCCImageAttributesLaunch;
  /**
   *
   * @type {IMCCImageAttributesMainHomeFurnishingActivityPrimary}
   * @memberof IMCCImageAttributes
   */
  mainHomeFurnishingActivityPrimary?: IMCCImageAttributesMainHomeFurnishingActivityPrimary;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  priceLevel?: string;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  producedFor?: string;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  marketingObjective?: string;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  roomSize?: string;
  /**
   *
   * @type {IMCCImageAttributesRoomType}
   * @memberof IMCCImageAttributes
   */
  roomType?: IMCCImageAttributesRoomType;
  /**
   *
   * @type {IMCCImageAttributesSeason}
   * @memberof IMCCImageAttributes
   */
  season?: IMCCImageAttributesSeason;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  styleGroup?: string;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  area?: string;
  /**
   *
   * @type {string}
   * @memberof IMCCImageAttributes
   */
  section?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof IMCCImageAttributes
   */
  longTermPriority?: Array<string>;
}

export function IMCCImageAttributesFromJSON(json: any): IMCCImageAttributes {
  return IMCCImageAttributesFromJSONTyped(json, false);
}

export function IMCCImageAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCImageAttributes {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    orientation: IMCCImageAttributesOrientationFromJSON(json['orientation']),
    fileSize: json['fileSize'],
    imageUrl: json['imageUrl'],
    productName: json['productName'],
    agency: json['agency'],
    approvedAt: IMCCImageAttributesApprovedAtFromJSON(json['approvedAt']),
    dpi: json['dpi'],
    editionYear: json['editionYear'],
    fileExtension: json['fileExtension'],
    fiscalYear: json['fiscalYear'],
    hasAnimals: json['hasAnimals'],
    hasClippingPath: json['hasClippingPath'],
    hasPeople: json['hasPeople'],
    heightPx: json['heightPx'],
    mainProduct: IMCCProductResponseFromJSON(json['mainProduct']),
    mimeType: IMCCImageAttributesMimeTypeFromJSON(json['mimeType']),
    modifiedAt: IMCCImageAttributesModifiedAtFromJSON(json['modifiedAt']),
    name: json['name'],
    products: (json['products'] as Array<any>).map(IMCCProductResponseFromJSON),
    releasedAt: IMCCImageAttributesReleasedAtFromJSON(json['releasedAt']),
    widthPx: json['widthPx'],
    productionMethod: !exists(json, 'productionMethod')
      ? undefined
      : IMCCProductionMethodFromJSON(json['productionMethod']),
    cameraShot: !exists(json, 'cameraShot')
      ? undefined
      : IMCCImageAttributesCameraShotFromJSON(json['cameraShot']),
    commonSeasonalActivity: !exists(json, 'commonSeasonalActivity')
      ? undefined
      : IMCCImageAttributesCommonSeasonalActivityFromJSON(json['commonSeasonalActivity']),
    launch: !exists(json, 'launch') ? undefined : IMCCImageAttributesLaunchFromJSON(json['launch']),
    mainHomeFurnishingActivityPrimary: !exists(json, 'mainHomeFurnishingActivityPrimary')
      ? undefined
      : IMCCImageAttributesMainHomeFurnishingActivityPrimaryFromJSON(
          json['mainHomeFurnishingActivityPrimary']
        ),
    priceLevel: !exists(json, 'priceLevel') ? undefined : json['priceLevel'],
    producedFor: !exists(json, 'producedFor') ? undefined : json['producedFor'],
    marketingObjective: !exists(json, 'marketingObjective')
      ? undefined
      : json['marketingObjective'],
    roomSize: !exists(json, 'roomSize') ? undefined : json['roomSize'],
    roomType: !exists(json, 'roomType')
      ? undefined
      : IMCCImageAttributesRoomTypeFromJSON(json['roomType']),
    season: !exists(json, 'season') ? undefined : IMCCImageAttributesSeasonFromJSON(json['season']),
    styleGroup: !exists(json, 'styleGroup') ? undefined : json['styleGroup'],
    category: !exists(json, 'category') ? undefined : json['category'],
    area: !exists(json, 'area') ? undefined : json['area'],
    section: !exists(json, 'section') ? undefined : json['section'],
    longTermPriority: !exists(json, 'longTermPriority') ? undefined : json['longTermPriority'],
  };
}

export function IMCCImageAttributesToJSON(value?: IMCCImageAttributes | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    orientation: IMCCImageAttributesOrientationToJSON(value.orientation),
    fileSize: value.fileSize,
    imageUrl: value.imageUrl,
    productName: value.productName,
    agency: value.agency,
    approvedAt: IMCCImageAttributesApprovedAtToJSON(value.approvedAt),
    dpi: value.dpi,
    editionYear: value.editionYear,
    fileExtension: value.fileExtension,
    fiscalYear: value.fiscalYear,
    hasAnimals: value.hasAnimals,
    hasClippingPath: value.hasClippingPath,
    hasPeople: value.hasPeople,
    heightPx: value.heightPx,
    mainProduct: IMCCProductResponseToJSON(value.mainProduct),
    mimeType: IMCCImageAttributesMimeTypeToJSON(value.mimeType),
    modifiedAt: IMCCImageAttributesModifiedAtToJSON(value.modifiedAt),
    name: value.name,
    products: (value.products as Array<any>).map(IMCCProductResponseToJSON),
    releasedAt: IMCCImageAttributesReleasedAtToJSON(value.releasedAt),
    widthPx: value.widthPx,
    productionMethod: IMCCProductionMethodToJSON(value.productionMethod),
    cameraShot: IMCCImageAttributesCameraShotToJSON(value.cameraShot),
    commonSeasonalActivity: IMCCImageAttributesCommonSeasonalActivityToJSON(
      value.commonSeasonalActivity
    ),
    launch: IMCCImageAttributesLaunchToJSON(value.launch),
    mainHomeFurnishingActivityPrimary: IMCCImageAttributesMainHomeFurnishingActivityPrimaryToJSON(
      value.mainHomeFurnishingActivityPrimary
    ),
    priceLevel: value.priceLevel,
    producedFor: value.producedFor,
    marketingObjective: value.marketingObjective,
    roomSize: value.roomSize,
    roomType: IMCCImageAttributesRoomTypeToJSON(value.roomType),
    season: IMCCImageAttributesSeasonToJSON(value.season),
    styleGroup: value.styleGroup,
    category: value.category,
    area: value.area,
    section: value.section,
    longTermPriority: value.longTermPriority,
  };
}
