import { API_BASE_URL, REDIRECT_URI, Timestamped } from './bynderReactUtils';
import { controlledFetch, ICouldNotPerformRequest, IRequestAbortedError } from './common';
import { hasExactNumberKeyValue, hasNumberKey, hasStringKey, IError, IResult, ok } from './type';
import * as t from 'io-ts';
import { validateType, ValidationError } from '@ikea-ingka-dam/type-util';

export type GetToken = (forceRefresh?: boolean) => Promise<Timestamped<ITokenWithRefresh> | null>;

export interface IBynderCollectionsOptions {
  limit?: number;
  page?: number;
  orderBy?: string;
  ids?: string;
  count?: 0; // 1 | 0, 1 changes the output format
  keyword?: string;
  isPublic?: 1 | 0;
  minCount?: number;
}

export interface IBynderCollection {
  userId: string;
  dateModified: string;
  filename: string;
  dateCreated: string;
  collectionCount: number;
  id: string;
  name: string;
  cover: {
    thumbnail: string;
    thumbnails: string[];
    large: string;
  };
  user?: {
    id: string;
    name: string;
  };
  description: string;
  IsPublic: 1 | 0;
}

export interface IBynderUser {
  phoneNumber: string;
  profileId: string;
  lastLogin: Date;
  department: string;
  job: string;
  state: string;
  firstname: string;
  city: string;
  infix: string;
  username: string;
  email: string;
  persisted: boolean;
  legalEntity: string;
  county: string;
  postalCode: string;
  language: string;
  country: string;
  costCenter: string;
  lastname: string;
  cellphoneNumber: string;
  employeeNumber: string;
  departmentCode: string;
  gender: string;
  id: string;
  companyName: string;
  active: boolean;
  address: string;
  terms: string;
}

export function constructBynderAssetUrl(baseUrl: string, assetId: string): string {
  return `${baseUrl}/media/?mediaId=${assetId}`;
}

export async function exchangeCodeForToken(
  baseurl: string,
  clientId: string,
  clientSecret: string,
  code: string,
): Promise<ITokenResponse | ITokenErrorResponse | IRequestAbortedError | ICouldNotPerformRequest> {
  const res = await controlledFetch(`${baseurl}/v6/authentication/oauth2/token`, {
    method: 'POST',
    body: `grant_type=authorization_code&code=${code}&redirect_uri=${REDIRECT_URI}&client_id=${clientId}&client_secret=${clientSecret}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  if (res.isError) {
    return res;
  }

  const d: unknown = await res.result.json();

  if (!isValidToken(d) || !hasStringKey('refresh_token', d)) {
    return { isError: true, type: 'invalid-key', response: d };
  }

  return { isError: false, token: d };
}

export async function refreshToken(
  baseurl: string,
  clientId: string,
  clientSecret: string,
  refreshToken: string,
): Promise<
  ITokenRefreshResponse | ITokenErrorResponse | ICouldNotPerformRequest | IRequestAbortedError
> {
  const q = {
    method: 'POST',
    body: `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${clientId}&client_secret=${clientSecret}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  const res = await controlledFetch(`${baseurl}/v6/authentication/oauth2/token`, q);

  if (res.isError) {
    return res;
  }

  const d: unknown = await res.result.json();

  if (!isValidToken(d)) {
    return { isError: true, type: 'invalid-key', response: d };
  }

  return { isError: false, token: d };
}

export function getAuthorizationHeaderToken(clientId: string, clientSecret: string): string {
  const encodedCredentials = `${encodeURIComponent(clientId)}:${encodeURIComponent(clientSecret)}`;

  return Buffer.from(encodedCredentials).toString('base64');
}

export function isValidToken(token: unknown): token is IToken {
  return (
    hasStringKey('access_token', token) &&
    hasStringKey('token_type', token) &&
    hasNumberKey('expires_in', token) &&
    hasStringKey('scope', token)
  );
}

interface ITokenErrorResponse {
  isError: true;
  type: 'invalid-key';
  response: unknown;
}
export interface IToken {
  access_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
}

export interface ITokenWithRefresh extends IToken {
  refresh_token: string;
}

interface ITokenResponse {
  isError: false;
  token: ITokenWithRefresh;
}

interface ITokenRefreshResponse {
  isError: false;
  token: IToken;
}

export type INotLoggedInError = IError<'not-logged-in'>;

export async function getCollections(
  getToken: GetToken,
  options: IBynderCollectionsOptions = {},
  controller = new AbortController(),
): Promise<
  IResult<IBynderCollection[]> | INotLoggedInError | ICouldNotPerformRequest | IRequestAbortedError
> {
  const token = await getToken();

  if (token === null) {
    return { isError: true, type: 'not-logged-in' };
  }

  const u = new URL(`${API_BASE_URL}v4/collections/`);

  Object.entries(options).forEach(([k, v]: [string, string]) => {
    u.searchParams.append(k, v);
  });

  const res = await controlledFetch(u.toString(), {
    method: 'GET',
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });

  if (res.isError) {
    return res;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data: IBynderCollection[] = await res.result.json();

  return ok(data);
}

export async function getCollection(
  getToken: GetToken,
  id: string,
  controller = new AbortController(),
): Promise<
  IResult<IBynderCollection> | INotLoggedInError | ICouldNotPerformRequest | IRequestAbortedError
> {
  const token = await getToken();

  if (token === null) {
    return { isError: true, type: 'not-logged-in' };
  }

  const u = new URL(`${API_BASE_URL}v4/collections/${id}/`);

  const res = await controlledFetch(u.toString(), {
    method: 'GET',
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });

  if (res.isError) {
    return res;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data: IBynderCollection = await res.result.json();

  return ok(data);
}

const BynderApiAsset = t.intersection([
  t.type({
    id: t.string,
    name: t.string,
    description: t.string,
    copyright: t.string,
    brandId: t.string,
    archive: t.number,
    datePublished: t.string,
    extension: t.array(t.string),
  }),
  t.partial({
    width: t.number,
    height: t.number,
    tags: t.array(t.string),
    propertyOptions: t.array(t.string),
    property_product_coordinates: t.string,
    thumbnails: t.type({
      webimage: t.string,
    }),
  }),
]);

export type BynderApiAssetType = t.TypeOf<typeof BynderApiAsset>;

export type BynderGetAssetResponse =
  | IResult<BynderApiAssetType>
  | INotLoggedInError
  | ICouldNotPerformRequest
  | IRequestAbortedError
  | ValidationError;

export async function getAsset(
  getToken: GetToken,
  id: string,
  controller = new AbortController(),
): Promise<BynderGetAssetResponse> {
  const token = await getToken();

  if (token === null) {
    return { isError: true, type: 'not-logged-in' };
  }

  const u = new URL(`${API_BASE_URL}v4/media/${id}/media/?versions=1&stats=1`);

  const res = await controlledFetch(u.toString(), {
    method: 'GET',
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });

  if (res.isError) {
    return res;
  }

  const data: unknown = await res.result.json();

  return validateType(BynderApiAsset, data);
}

const BynderApiAssetDownload = t.intersection([
  t.type({
    s3_file: t.string,
  }),
  t.partial({
    disclaimer: t.string,
  }),
]);

export type BynderApiAssetDownloadType = t.TypeOf<typeof BynderApiAssetDownload>;

export async function getAssetDownloadUrl(
  getToken: GetToken,
  id: string,
  controller = new AbortController(),
): Promise<
  | IResult<BynderApiAssetDownloadType>
  | ICouldNotPerformRequest
  | IRequestAbortedError
  | INotLoggedInError
  | ValidationError
> {
  const token = await getToken();

  if (token === null) {
    return { isError: true, type: 'not-logged-in' };
  }

  const u = new URL(`${API_BASE_URL}v4/media/${id}/download`);

  const res = await controlledFetch(u.toString(), {
    method: 'GET',
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });

  if (res.isError) {
    return res;
  }

  const data: unknown = await res.result.json();

  return validateType(BynderApiAssetDownload, data);
}

const BynderApiKeywordSearchResponse = t.array(t.unknown);

export type BynderApiKeywordSearchResponseType = t.TypeOf<typeof BynderApiKeywordSearchResponse>;

export type BynderKeywordSearchResponse =
  | IResult<BynderApiKeywordSearchResponseType>
  | INotLoggedInError
  | ICouldNotPerformRequest
  | IRequestAbortedError
  | ValidationError;

export async function searchKeyword(
  getToken: GetToken,
  keyword: string,
  controller = new AbortController(),
): Promise<BynderKeywordSearchResponse> {
  const token = await getToken();

  if (token === null) {
    return { isError: true, type: 'not-logged-in' };
  }

  const u = new URL(`${API_BASE_URL}v4/media/`);
  u.searchParams.append('keyword', keyword);

  const res = await controlledFetch(u.toString(), {
    method: 'GET',
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });

  if (res.isError) {
    return res;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data = await res.result.json();

  return validateType(BynderApiKeywordSearchResponse, data);
}

export async function getCollectionAssets(
  getToken: GetToken,
  options: { id: string },
  controller = new AbortController(),
): Promise<IResult<string[]> | INotLoggedInError | ICouldNotPerformRequest | IRequestAbortedError> {
  const token = await getToken();
  if (token === null) {
    return { isError: true, type: 'not-logged-in' };
  }
  const u = new URL(`${API_BASE_URL}v4/collections/${options.id}/media/`);

  const res = await controlledFetch(u.toString(), {
    method: 'GET',
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });

  if (res.isError) {
    return res;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data: string[] = await res.result.json();

  return ok(data);
}

export async function getCurrentUser(
  getToken: GetToken,
  controller = new AbortController(),
): Promise<
  IResult<IBynderUser> | INotLoggedInError | ICouldNotPerformRequest | IRequestAbortedError
> {
  const token = await getToken();
  if (token === null) {
    return { isError: true, type: 'not-logged-in' };
  }
  const u = new URL(`${API_BASE_URL}v4/currentUser/`);

  const res = await controlledFetch(u.toString(), {
    method: 'GET',
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });

  if (res.isError) {
    return res;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data: IBynderUser = await res.result.json();

  return ok(data);
}

export interface IBynderCreateCollectionOptions {
  name: string;
  description?: string;
}

export interface IBynderCreateCollectionResponse {
  statuscode: 201;
  message: string;
}

export interface IUnexpectedResponseError extends IError<'unexpected-response'> {
  response: unknown;
}

export async function createCollection(
  getToken: GetToken,
  options: IBynderCreateCollectionOptions,
  controller = new AbortController(),
): Promise<
  | IResult<IBynderCreateCollectionResponse>
  | INotLoggedInError
  | IUnexpectedResponseError
  | ICouldNotPerformRequest
  | IRequestAbortedError
> {
  const token = await getToken();
  if (token === null) {
    return { isError: true, type: 'not-logged-in' };
  }
  const u = new URL(`${API_BASE_URL}v4/collections/`);

  const bodyData = new URLSearchParams();

  bodyData.append('name', options.name);

  if (options.description) {
    bodyData.append('description', options.description);
  }

  const res = await controlledFetch(u.toString(), {
    method: 'POST',
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${token.access_token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: bodyData,
  });

  if (res.isError) {
    return res;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data: unknown = await res.result.json();

  if (!hasStringKey('message', data) || !hasExactNumberKeyValue('statuscode', data, 201)) {
    return { isError: true, type: 'unexpected-response', response: data };
  }

  return ok(data);
}

export interface IBynderShareCollectionOptions {
  id: string;
  recipients: string[]; // user1@bynder.com,user2@bynder.com
  collectionOptions: 'view' | 'edit';
  loginRequired?: boolean;

  // 2014-08-12T08:07:24Z
  dateStart?: string;

  // 2014-08-12T08:07:24Z
  dateEnd?: string;

  // Wether the recipients should be notified using an e-mail.
  sendMail?: boolean;
  message?: string;
}

export interface IBynderShareCollectionResponse {
  statuscode: 201;
  message: string;
}

export async function shareCollection(
  getToken: GetToken,
  options: IBynderShareCollectionOptions,
  controller = new AbortController(),
): Promise<
  | IResult<IBynderShareCollectionResponse>
  | INotLoggedInError
  | IUnexpectedResponseError
  | ICouldNotPerformRequest
  | IRequestAbortedError
> {
  const token = await getToken();
  if (token === null) {
    return { isError: true, type: 'not-logged-in' };
  }
  const u = new URL(`${API_BASE_URL}v4/collections/${options.id}/share`);

  const bodyData = new URLSearchParams();

  bodyData.append('recipients', options.recipients.join(','));
  bodyData.append('collectionOptions', options.collectionOptions);
  if (options.dateStart) {
    bodyData.append('dateStart', options.dateStart);
  }
  if (options.dateEnd) {
    bodyData.append('dateEnd', options.dateEnd);
  }

  if (options.sendMail) {
    bodyData.append('sendMail', String(options.sendMail));
  }
  if (options.message) {
    bodyData.append('message', options.message);
  }

  const res = await controlledFetch(u.toString(), {
    method: 'POST',
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${token.access_token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: bodyData,
  });

  if (res.isError) {
    return res;
  }

  const data: unknown = await res.result.json();

  if (!hasStringKey('message', data) || !hasExactNumberKeyValue('statuscode', data, 201)) {
    return { isError: true, type: 'unexpected-response', response: data };
  }

  return ok(data);
}

export interface IBynderAddAssetsToCollectionResponse {
  message: string;
  statuscode: 202;
}

export async function addAssetsToCollection(
  getToken: GetToken,
  collectionId: string,
  assets: string[],
  controller = new AbortController(),
): Promise<
  | IResult<IBynderAddAssetsToCollectionResponse>
  | INotLoggedInError
  | IUnexpectedResponseError
  | ICouldNotPerformRequest
  | IRequestAbortedError
> {
  const token = await getToken();
  if (token === null) {
    return { isError: true, type: 'not-logged-in' };
  }
  const u = new URL(`${API_BASE_URL}v4/collections/${collectionId}/media/`);

  const bodyData = new URLSearchParams();

  bodyData.append('data', JSON.stringify(assets));

  const res = await controlledFetch(u.toString(), {
    method: 'POST',
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${token.access_token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: bodyData,
  });

  if (res.isError) {
    return res;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data: unknown = await res.result.json();

  if (!hasStringKey('message', data) || !hasExactNumberKeyValue('statuscode', data, 202)) {
    return { isError: true, type: 'unexpected-response', response: data };
  }

  return ok(data);
}

export interface IBynderRemoveAssetsFromCollectionResponse {
  message: string;
  statuscode: 204;
}

export async function removeAssetsFromCollection(
  getToken: GetToken,
  collectionId: string,
  assets: string[],
  controller = new AbortController(),
): Promise<
  | IResult<IBynderRemoveAssetsFromCollectionResponse>
  | INotLoggedInError
  | IUnexpectedResponseError
  | ICouldNotPerformRequest
  | IRequestAbortedError
> {
  const token = await getToken();
  if (token === null) {
    return { isError: true, type: 'not-logged-in' };
  }
  const u = new URL(`${API_BASE_URL}v4/collections/${collectionId}/media/`);

  u.searchParams.append('deleteIds', assets.join(','));

  const res = await controlledFetch(u.toString(), {
    method: 'DELETE',
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });

  if (res.isError) {
    return res;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data: unknown = await res.result.json();

  if (!hasStringKey('message', data) || !hasExactNumberKeyValue('statuscode', data, 204)) {
    return { isError: true, type: 'unexpected-response', response: data };
  }

  return ok(data);
}
