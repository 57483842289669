/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Matrix } from 'react-spreadsheet';

import React, { useState, useEffect, ReactNode } from 'react';
import { SectionWrap } from './SectionWrap';
import { Section } from './components/section/section';
import Button from '@ingka/button';
import {
  BynderMetaResult,
  CreateBynderAssetJobResult,
  EditJobItem,
  FSAssetDev,
  FSAssetProd,
  SearchFSResult,
} from './generated-backend-api';
import { Loadable } from './util/type';
import { BynderLoginButton } from './components/bynder-login-button';
import { useBynder } from './util/bynderReactUtils';
import { useToasts } from './util/toastProvider';

import {
  allBynderProps,
  bynderDescriptionProp,
  bynderIdProp,
  bynderLimitedDateProp,
  bynderLimitedProp,
  bynderNameProp,
  bynderTagsProp,
} from './components/bynder-default-meta-fields';
import { INGKAISOMarkets } from '@ikea-ingka-dam/usage-rights';
import { EditListLoadable } from './app-state';
import { JobList } from './components/job-list';
import { bynderMetaFieldIDs } from './config';
import Expander from '@ingka/expander';
import AssetEditHelp from './components/AssetEdit/AssetEditHelp/AssetEditHelp';
import useAssetEditHelpers from './hooks/useAssetEditHelpers';
import { getDefaults, storeTableData } from './helpers/assets-edit';

import AssetsTable from './components/AssetEdit/AssetsTable/AssetsTable';

import RemoveIcon from '@ingka/ssr-icon/paths/cross';
import Select, { Option } from '@ingka/select';

export type PartialBynderMeta = {
  id: string;
  label: string;
  options?: { displayLabel: string }[];
};

interface IAssetEditProps {
  bynderMeta: Loadable<BynderMetaResult>;
  bynderBaseUrl: string;
  editAssets: (items: EditJobItem[]) => Promise<CreateBynderAssetJobResult | false>;
  assets: Loadable<SearchFSResult, { ids: string[] }>;
  assetsNameExt: Loadable<SearchFSResult, { names: string[]; extensions: string[] }>;
  fetchAssets: (ids: string[]) => Promise<void>;
  searchFsByExtName: (names: string[], extensions: string[]) => Promise<void>;
  loadBynderMeta: () => void;
  loadEditList: (startBefore?: number | undefined) => Promise<unknown> | undefined;
  editList: EditListLoadable;
}

export const EXTENSION_FAKE_ID = 'fake_extension';

export const AssetEdit = (props: IAssetEditProps): JSX.Element => {
  const { bynderMeta, loadBynderMeta, editAssets, assets, assetsNameExt } = props;
  const defaults = getDefaults();
  const [data, setData] = useState<Matrix<{ value: string; className?: string }>>(defaults.data);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [headerSelection, setHeaderSelection] = useState<string[]>(defaults.headers);
  const [creationCell, setCreationCell] = useState<string[]>([]);
  const [faultyCountryCodesArray, setFaultyCountryCodesArray] = useState<string[]>([]);
  const bynderWrap = useBynder();
  const toastApi = useToasts();
  const {
    identifyingCol,
    isFaultyRow,
    checkIdOrNameExt,
    isCreationCell,
    syncDuplicateWarnings,
    syncFaultyNameExtWarnings,
    syncFaultyCountryCodesWarnings,
    faultyIDSet,
    duplicateNames,
    faultyExtNameSet,
  } = useAssetEditHelpers(
    assets,
    headerSelection,
    creationCell,
    assetsNameExt,
    faultyCountryCodesArray,
  );

  useEffect(() => {
    if (bynderMeta.status !== 'uninitialized') {
      return;
    }
    loadBynderMeta();
  }, [loadBynderMeta, bynderMeta.status]);

  if (bynderMeta.status !== 'loaded') {
    return <div>Loading bynder metadata</div>;
  }

  if (bynderMeta.data.type !== 'bynder-meta-response') {
    return (
      <div>
        Could not load bynder meta response: <pre>{JSON.stringify(bynderMeta.data)}</pre>
      </div>
    );
  }

  if (!bynderWrap.isLoggedIn) {
    return <BynderLoginButton />;
  }

  const defaultBynderMetaValues: PartialBynderMeta[] = [
    {
      id: 'empty',
      label: '',
      options: [],
    },
    ...allBynderProps,
  ];

  const bynderMetaList: PartialBynderMeta[] = [
    ...defaultBynderMetaValues,
    { label: 'Extension', id: EXTENSION_FAKE_ID },
    ...Object.values(bynderMeta.data.result),
  ];

  const onSelectColumnHeader = (headerIndex: number, bynderMetaId: string) => {
    if (headerIndex >= headerSelection.length) {
      console.error(
        `Got header index outside headers range. num headers: ${headerSelection.length} index: ${headerIndex}`,
      );
      return;
    }
    const partialMeta = bynderMetaList.find((q) => q.id === bynderMetaId);
    if (!partialMeta) {
      console.error(
        `Could not find id ${bynderMetaId} among values in bynderMetaList`,
        bynderMetaList,
      );
      return;
    }

    const clone = [...headerSelection];

    clone[headerIndex] = bynderMetaId;
    setHeaderSelection(clone);
  };

  const removeRowByindex = (index: number) => {
    if (checkTableLength(data.length)) {
      setData((prev) => {
        const newData = [...prev];
        newData.splice(index, 1);
        return newData;
      });
    }
  };

  const removeColumnByIndex = (index: number) => {
    if (checkTableLength(data[0].length)) {
      setHeaderSelection((h) => {
        h.splice(index, 1);
        return h;
      });

      setData((prev) => {
        return prev.map((row) => {
          const newRow = [...row];
          newRow.splice(index, 1);
          return newRow;
        });
      });
    }
  };

  const addColumn = () => {
    setHeaderSelection([...headerSelection, 'empty']);
    setData((prev) => {
      return prev.map((row) => [...row, { value: '' }]);
    });
  };

  const addRow = () => {
    setData([
      ...data,
      data[0].map((_) => ({
        value: '',
      })),
    ]);
  };

  const checkTableLength = (numCellsOrRows: number) => {
    if (numCellsOrRows !== 1) {
      return true;
    } else {
      toastApi.push({ isError: true, message: "You're not allowed to remove the entire table" });
      return false;
    }
  };

  const onSync = () => {
    // find id column and fetch by id
    // check if the user is creating a new value
    const validatedCountryCodes: string[] = [];

    const producedForIndex = headerSelection.findIndex(
      (q) => q === bynderMetaFieldIDs.BYNDER_PRODUCED_FOR_ID,
    );

    if (producedForIndex !== -1) {
      const uniqueFaultyCountryCodes: Set<string> = new Set();

      const producedForCells: string[] = data.map((q) =>
        String(q[producedForIndex]?.value).toLowerCase(),
      );

      for (let x = 0; x < producedForCells.length; x++) {
        const countryCodes: string[] = producedForCells[x].split(',');
        const trimedCountryCodes: string[] = countryCodes.map((element) => {
          return element.replace(/\s/g, '');
        });

        const correctCountryCodes: string[] = trimedCountryCodes.filter((c) =>
          INGKAISOMarkets.has(c),
        );
        validatedCountryCodes.push(correctCountryCodes.join(', '));
        const faultyCountryCodes: string[] = trimedCountryCodes.filter(
          (el) => !correctCountryCodes.includes(el),
        );
        faultyCountryCodes.forEach((item) => uniqueFaultyCountryCodes.add(item));
      }
      setFaultyCountryCodesArray(Array.from(uniqueFaultyCountryCodes));
      setData(
        data.map((row, rowIndex) => {
          return row.map((cell, coloumnIndex) => {
            if (coloumnIndex === producedForIndex) {
              return { value: validatedCountryCodes[rowIndex] };
            } else {
              return cell;
            }
          });
        }),
      );
    }

    const creationCellSet: Set<string> = new Set();
    if (props.bynderMeta.status !== 'loaded' || props.bynderMeta.data.isError === true) {
      return;
    }

    for (let i = 0; i < headerSelection.length; i++) {
      const coloumn: string[] = data.map((q) => String(q[i]?.value));

      const oneBynderMetaProperty = bynderMetaList.find((q) => q.id === headerSelection[i]);
      const displayLabelList = oneBynderMetaProperty?.options?.map((q) =>
        q.displayLabel.toLowerCase(),
      );

      if (typeof displayLabelList === 'undefined' || displayLabelList.length === 0) {
        continue;
      }
      for (let x = 0; x < coloumn.length; x++) {
        const oldValue = displayLabelList.includes(coloumn[x].toLowerCase());
        if (oldValue === false && validatedCountryCodes[x] !== coloumn[x]) {
          creationCellSet.add([i, coloumn[x]].join(','));
        }
      }
      setCreationCell(Array.from(creationCellSet));
    }

    if (identifyingCol.type === 'id') {
      const ids: string[] = data.map((q) => String(q[identifyingCol.idColumn]?.value));
      void props.fetchAssets(ids);
      setSaveEnabled(true);
    } else if (identifyingCol.type === 'name-ext') {
      const names: string[] = data.map((q) =>
        String(q[identifyingCol.nameCol]?.value).toLowerCase(),
      );
      const extensions: string[] = data.map((q) =>
        String(q[identifyingCol.extensionCol]?.value).toLowerCase(),
      );
      void props.searchFsByExtName(names, extensions);
      setSaveEnabled(true);
    } else {
      toastApi.push({
        isError: true,
        message: 'No Id nor names and extension columns found, could not search',
      });
      console.log('No identifier');
    }
  };
  const onSave = async () => {
    if (identifyingCol.type === 'no-identifier-found') {
      toastApi.push({
        isError: true,
        message: 'No Id nor names and extension columns found, could not search',
      });
      console.log('No identifier');
      return;
    }

    const rowToPayloadObject = (
      row: (
        | {
            value: string;
          }
        | undefined
      )[],
      id: string,
    ): EditJobItem => {
      const assetObject = {
        id: id,
        type: 'edit-job',
        bynderUploadMeta: {},
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any as EditJobItem;

      for (let coloumnIndex = 0; coloumnIndex < row.length; coloumnIndex++) {
        const headerIndex = headerSelection[coloumnIndex];
        const cellValue = row[coloumnIndex];

        if (typeof cellValue === 'undefined') {
          continue;
        }

        switch (headerIndex) {
          case bynderTagsProp.id:
            assetObject.tags = [cellValue.value];
            continue;
          case bynderDescriptionProp.id:
            assetObject.description = cellValue.value;
            continue;
          case bynderNameProp.id:
            assetObject.name = cellValue.value;
            continue;
          case EXTENSION_FAKE_ID:
            continue;
          case bynderIdProp.id:
            continue;
          case bynderLimitedProp.id:
            if (cellValue.value === 'true') {
              assetObject.limited = true;
            } else if (cellValue.value === 'false') {
              assetObject.limited = false;
            }
            continue;
          case bynderLimitedDateProp.id:
            assetObject.limitedDate = cellValue.value;
            continue;
        }
        if (assetObject.bynderUploadMeta !== undefined) {
          assetObject.bynderUploadMeta['metaproperty.' + headerIndex] = cellValue.value;
        }
      }
      return assetObject;
    };

    const edits = [];

    if (identifyingCol.type === 'name-ext') {
      if (assetsNameExt.status !== 'loaded' || assetsNameExt.data.isError) {
        console.warn("Expected name data to be loaded but wasn't!");
        return;
      }

      for (let i = 0; i < data.length; i++) {
        const nameData = String(data[i][identifyingCol.nameCol]?.value);
        const extensionData = String(data[i][identifyingCol.extensionCol]?.value);
        const r: Array<FSAssetDev | FSAssetProd> = assetsNameExt.data.results;
        const fsAsset = r.find(
          (e) =>
            e.nameLowerCase === nameData.toLowerCase() &&
            e.extension &&
            Object.keys(e.extension).find((k) => k === extensionData.toLowerCase()),
        );
        if (!fsAsset) {
          console.warn("The user didn't sync before saving or the assets weren't found!");
          return;
        }

        const j = rowToPayloadObject(data[i], fsAsset.id);
        edits.push(j);
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        const id = String(data[i][identifyingCol.idColumn]?.value);
        const j = rowToPayloadObject(data[i], id);
        edits.push(j);
      }
    }

    const res = await editAssets(edits);
    if (res === false) {
      toastApi.push({ isError: true, message: 'Not logged in!' });
      return;
    }

    if (res.type === 'create-bynder-job-response') {
      toastApi.push({ isError: false, message: 'Saved!' });
    } else {
      toastApi.push({ isError: true, message: JSON.stringify(res, null, 4) });
    }
  };

  let numCols = 0;
  // find max num of cols in data
  for (const row of data) {
    numCols = Math.max(row.length, numCols);
  }

  const columnsLabels: ReactNode[] = [];
  for (let i = 0; i < numCols; i++) {
    columnsLabels.push(
      <div
        css={css`
          position: relative;
          .btn {
            color: rgba(255, 0, 0, 0.5);
            position: absolute;
            top: 0;
            right: 0;
            transform: scale(0.8) translate(12px, -12px);
          }
        `}
      >
        <Button
          title="Remove column"
          iconOnly
          ssrIcon={RemoveIcon}
          small
          type="tertiary"
          onClick={() => removeColumnByIndex(i)}
        />
        <Select
          key={`col_${i}`}
          id="deliveryDropDown"
          label="Select an attribute"
          onChange={(ev: React.FormEvent<HTMLSelectElement>) => {
            onSelectColumnHeader(i, ev.currentTarget.value);
          }}
          value={headerSelection[i]}
        >
          {bynderMetaList
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((d) => (
              <Option key={d.id} value={d.id} name={`${d.label}`} />
            ))}
        </Select>
      </div>,
    );
  }
  const prefillSearch = (prefillType: 'id' | 'nameAndExtension') => {
    switch (prefillType) {
      case 'id':
        onSelectColumnHeader(0, 'fake_bynder_id');
        break;
      case 'nameAndExtension':
        if (columnsLabels.length < 2) addColumn();
        setHeaderSelection((prev) => {
          const newHeadersSelection = [...prev];
          newHeadersSelection[0] = bynderNameProp.id;
          newHeadersSelection[1] = EXTENSION_FAKE_ID;
          return newHeadersSelection;
        });
    }
  };

  return (
    <div>
      {syncDuplicateWarnings}
      {syncFaultyNameExtWarnings}
      {syncFaultyCountryCodesWarnings}
      <SectionWrap>
        <Section size="large">
          <h1>Asset edit</h1>
        </Section>
        <Section>
          <AssetEditHelp />
        </Section>
        <Section>
          <Button small onClick={() => prefillSearch('nameAndExtension')}>
            Search name and extension
          </Button>
          <Button
            css={css`
              margin-left: 1%;
            `}
            small
            onClick={() => prefillSearch('id')}
          >
            Search ID
          </Button>

          <AssetsTable
            columnsLabels={columnsLabels}
            isFaultyRow={isFaultyRow}
            removeRowByIndex={removeRowByindex}
            setData={setData}
            setSaveEnabled={setSaveEnabled}
            data={data}
            isCreationCell={isCreationCell}
            addColumn={addColumn}
            addRow={addRow}
          />
        </Section>
        <Section>
          <Button onClick={onSync} text={'Sync'} />
          <Button
            css={css`
              float: right;
            `}
            onClick={onSave}
            disabled={
              checkIdOrNameExt() ||
              faultyIDSet.size > 0 ||
              saveEnabled === false ||
              duplicateNames.length > 0 ||
              faultyExtNameSet.size > 0
            }
            text={'Save'}
          />
          <Button
            css={css`
              float: right;
              margin-right: 1%;
            `}
            onClick={() => {
              storeTableData(data, headerSelection);
            }}
            text={'Save current data as defaults.'}
          />
        </Section>

        <Expander labelClosed={'Show jobs list'} labelOpen={'Hide jobs list'}>
          <Section>
            <JobList
              bynderBaseUrl={props.bynderBaseUrl}
              bynderMeta={props.bynderMeta}
              fetchJobsList={props.loadEditList}
              list={props.editList}
              loadBynderMeta={props.loadBynderMeta}
            />
          </Section>
        </Expander>
      </SectionWrap>
    </div>
  );
};
