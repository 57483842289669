import React, { useEffect, useState } from 'react';
import { SectionWrap } from './SectionWrap';
import TextArea from '@ingka/text-area';
import Button from '@ingka/button';
import { Loadable } from './util/type';
import {
  CreatePackageBodyItem,
  FSAssetDev,
  FSAssetProd,
  IDAMAssetInterface,
  SearchFSResult,
  UploadJobItem,
} from './generated-backend-api';
import Loading, { LoadingBall } from '@ingka/loading';
import { ISelectableItemProps, SelectableList } from './SelectableList';
import Pill from '@ingka/pill';
import { useBynder } from './util/bynderReactUtils';
import {
  BynderBoardInput,
  ICollectionResult,
  IFailedCollectionResult,
} from './components/bynder-board-input/BynderBoardInput';
import { Section } from './components/section/section';
import style from './PHTools.module.scss';
import ArrowCloudOutIcon from '@ingka/ssr-icon/paths/arrow-cloud-out';
import ArrowCloudInIcon from '@ingka/ssr-icon/paths/arrow-cloud-in';
import CopyIcon from '@ingka/ssr-icon/paths/copy';
import SearchIcon from '@ingka/ssr-icon/paths/magnifying-glass';
import Tooltip from '@ingka/tooltip';
import { BynderLoginButton } from './components/bynder-login-button';
import { UploadJobItemWithThumbnail } from './util/common-types';
import Checkbox from '@ingka/checkbox';
import { IDAMAssetResultWrapper } from './app-state';
import Icon from '@ingka/icon';
import copy from '@ingka/ssr-icon/paths/copy';
import WarningIcon from '@ingka/ssr-icon/paths/warning-triangle';

interface IPHToolsProps {
  search: (phs: string[], includeSuffixResults: boolean) => Promise<void>;
  searchFSResults: Loadable<SearchFSResult, { phs: string[] }>;
  searchIDAMResults: Loadable<IDAMAssetResultWrapper, { phs: string[] }>;
  downloadPackage: (downloadables: CreatePackageBodyItem[]) => void;
  prepareForUpload: (jobs: UploadJobItemWithThumbnail[]) => void;
}

const localStorageSearchDataKey = 'tools-search-text';
export const PHTools = (props: React.PropsWithChildren<IPHToolsProps>): JSX.Element => {
  const [text, setText] = useState(() => {
    const s = localStorage.getItem(localStorageSearchDataKey);
    if (s === null) {
      return '';
    }

    return s;
  });
  const bynderWrap = useBynder();
  const [includeSuffixResults, setIncludeSuffixResults] = useState(false);
  const [includeArchived, setIncludeArchived] = useState(false);

  const handleSearchClick = () => {
    const r = text
      .split(/\r\n|\r|\n/)
      .map((q) => q.trim())
      .filter((q) => q.length > 0);

    const rows = [...new Set(r)];

    if (rows.length > 0) {
      localStorage.setItem(localStorageSearchDataKey, text);
      void props.search(rows, true);
    } else {
      console.log('no rows');
    }
  };

  const onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.currentTarget.value);
  };

  if (!bynderWrap.isLoggedIn) {
    return (
      <SectionWrap>
        <h1>PH-Tools</h1>
        <BynderLoginButton />
      </SectionWrap>
    );
  }

  return (
    <SectionWrap>
      <Section size="large">
        <h1>PH-Tools</h1>
      </Section>
      <Section>
        <BynderLoginButton />
      </Section>

      <Section>
        <TextArea
          label="Enter PH-numbers, newline separated."
          placeholder={`PH00001
PH00002
...`}
          onChange={onChangeText}
          value={text}
        />
      </Section>

      <Section>
        <Button ssrIcon={SearchIcon} title="search" onClick={handleSearchClick} text="Search" />
      </Section>

      <Section>
        <Checkbox
          id={'select_all'}
          label={<b>Include Suffix Results</b>}
          value=""
          checked={includeSuffixResults}
          onClick={() => setIncludeSuffixResults(!includeSuffixResults)}
        />
        <br />
        <Checkbox
          id={'select_archived'}
          label={<b>Include Archived</b>}
          value=""
          checked={includeArchived}
          onClick={() => setIncludeArchived(!includeArchived)}
        />
      </Section>
      <Section>
        <PHToolsSearchResult
          {...props}
          includeSuffixResults={includeSuffixResults}
          includeArchived={includeArchived}
        />
      </Section>
    </SectionWrap>
  );
};

type PHMapTypeKey = string;
type PHMapTypeValue = {
  dam?: (FSAssetDev | FSAssetProd)[];
  idam?: IDAMAssetInterface;
};

type PHMapType = Map<PHMapTypeKey, PHMapTypeValue>;

const constructPHMap = (
  dam: SearchFSResult,
  idam: IDAMAssetInterface[],
  phs: string[],
  includeSuffixResults: boolean,
): PHMapType => {
  const phMap: PHMapType = new Map<PHMapTypeKey, PHMapTypeValue>();

  for (const ph of phs) {
    phMap.set(ph, {});
  }

  if (!dam.isError) {
    for (const fsAsset of dam.results) {
      const e = phMap.get(fsAsset.name);

      if (e) {
        if (!Array.isArray(e.dam)) e.dam = [];

        e.dam.push(fsAsset);
      } else if (includeSuffixResults) {
        phMap.set(fsAsset.name, { dam: [fsAsset] });
      }
    }
  }

  for (const idamAsset of idam) {
    const e = phMap.get(idamAsset.data.name as string);
    if (e) {
      e.idam = idamAsset;
    } else if (includeSuffixResults) {
      phMap.set(idamAsset.data.name as string, { idam: idamAsset });
    }
  }

  return phMap;
};

interface PHToolsSearchResultProps {
  searchFSResults: Loadable<SearchFSResult, { phs: string[] }>;
  searchIDAMResults: Loadable<IDAMAssetResultWrapper, { phs: string[] }>;
  downloadPackage: (downloadables: CreatePackageBodyItem[]) => void;
  prepareForUpload: (jobs: UploadJobItem[]) => void;
  includeSuffixResults: boolean;
  includeArchived: boolean;
}

type ResultFilterTypes = 'idam' | 'dam' | 'only-idam' | 'all' | 'not-found';

const PHToolsSearchResult = (props: PHToolsSearchResultProps) => {
  const [selected, setSelected] = useState(new Set<string>());
  const [copied, setCopied] = useState(false);
  const [filterType, _setFilterType] = useState<ResultFilterTypes>('all');
  const [boardStatus, setBoardStatus] = useState<
    Loadable<ICollectionResult | IFailedCollectionResult>
  >({ status: 'uninitialized' });
  const {
    searchFSResults,
    searchIDAMResults,
    downloadPackage,
    includeSuffixResults,
    includeArchived,
  } = props;

  const isDamArchived = (assets: PHMapTypeValue['dam']) => !!assets?.some((a) => a.archive);

  useEffect(() => {
    setSelected(new Set());
  }, [searchIDAMResults]);

  if (searchFSResults.status === 'loading' || searchIDAMResults.status === 'loading') {
    return (
      <Loading text="Loading...">
        <LoadingBall />
      </Loading>
    );
  }

  if (searchFSResults.status === 'uninitialized' || searchIDAMResults.status === 'uninitialized') {
    return null;
  }

  if (
    searchFSResults.data.type !== 'fs-search-result-dev-response' &&
    searchFSResults.data.type !== 'fs-search-result-prod-response'
  ) {
    return (
      <div>
        Could not fetch search results from fs. Details:{' '}
        <pre>{JSON.stringify(searchFSResults, null, 4)}</pre>
      </div>
    );
  }

  if (searchIDAMResults.data.isError) {
    return (
      <div>
        Could not fetch search results from idam. Details:{' '}
        <pre>{JSON.stringify(searchIDAMResults, null, 4)}</pre>
      </div>
    );
  }

  const phMap = constructPHMap(
    searchFSResults.data,
    searchIDAMResults.data.result,
    searchIDAMResults.phs,
    includeSuffixResults,
  );

  const setFilterType = (type: ResultFilterTypes) => {
    if (type === filterType && filterType === 'all') {
      return;
    }

    if (type === filterType) {
      _setFilterType('all');
    } else {
      _setFilterType(type);
    }

    setSelected(new Set());
  };

  const toggleAsset = (id: string) => {
    if (selected.has(id)) {
      setSelected(new Set([...selected].filter((i) => i !== id)));
    } else {
      setSelected(new Set([...selected, id]));
    }
  };

  const onSelectAllToggle = () => {
    const allSelected = assets.every((a) => selected.has(a.id));

    if (allSelected) {
      setSelected(new Set());
    } else {
      const selectedList = [
        ...assets.filter((a) => !a.archived || includeArchived).map((a) => a.id),
      ];

      setSelected(new Set(selectedList));
    }
  };

  const onDownloadFiles = () => {
    if (searchIDAMResults.data.type !== 'idam-asset-result') {
      return;
    }

    const packages: CreatePackageBodyItem[] = searchIDAMResults.data.result
      .filter((h) => h.data.name && selected.has(h.data.name))
      .map((h) => {
        const originalImage = h.data.files?.renditions?.[0];
        const n = `${h.data.name as string}.${originalImage?.extension as string}`;

        return {
          id: String(h.data.id),
          name: n,
          url: originalImage?.renditionPrivateUrl as string,
        };
      });
    downloadPackage(packages);
  };

  const onUploadToBynder = () => {
    const uploadJobs: UploadJobItemWithThumbnail[] = [];

    for (let s of selected) {
      let a = phMap.get(s);

      if (!a) {
        phMap.forEach((value) => {
          if (typeof value.dam === 'undefined') {
            return;
          }

          if (value.dam.length > 0) {
            value.dam.forEach((asset) => {
              if (selected.has(asset.id) && !asset.archive) {
                a = { ...value, dam: [asset] };
                s = asset.name;
              }
            });
          }
        });
      }

      if (!a) {
        console.warn(`${s} not found in phmap`);
        continue;
      }

      const { idam } = a;

      if (!idam) {
        console.warn(`${s} did not have a idam item.`);
        continue;
      }

      const original =
        idam.data.files?.renditions &&
        idam.data.files.renditions.find((r) => r.type === 'ORIGINAL');

      const thumbnail =
        idam.data.files?.renditions &&
        idam.data.files.renditions.find((r) => r.type === 'THUMBNAIL');

      uploadJobs.push({
        type: 'upload-job',
        name: s,
        extension: original?.extension as string,
        url: original?.renditionPrivateUrl as string,
        fileSize: parseInt(original?.fileSize as string),
        thumbnailUrl: thumbnail?.renditionPrivateUrl as string,
      });
    }

    props.prepareForUpload(uploadJobs);
  };

  type Counts = {
    total: number;
    inDAM: number;
    inIdam: number;
    onlyIdam: number;
    notFound: number;
  };

  const counts: Counts = {
    total: 0,
    inDAM: 0,
    inIdam: 0,
    onlyIdam: 0,
    notFound: 0,
  };

  const createAsset = (
    ph: string,
    damAsset?: FSAssetDev | FSAssetProd,
    idamAsset?: IDAMAssetInterface,
    numberOfDamAssets?: number,
  ) => {
    const inDam = Boolean(damAsset);
    const inIdam = Boolean(idamAsset);

    if (!includeArchived && damAsset && isDamArchived([damAsset])) {
      return null;
    }

    counts.total += 1;

    if (inDam) {
      counts.inDAM += 1;
    }

    if (inIdam) {
      counts.inIdam += 1;
    }

    if (!inDam && inIdam) {
      counts.onlyIdam += 1;
    }

    if (!inIdam && !inDam) {
      counts.notFound += 1;
    }

    if (filterType === 'dam' && !inDam) {
      return null;
    }

    if (filterType === 'only-idam' && (inDam || !inIdam)) {
      return null;
    }

    if (filterType === 'not-found' && (inIdam || inDam)) {
      return null;
    }

    let inSelectedBoard;
    if (
      damAsset?.id &&
      boardStatus.status === 'loaded' &&
      boardStatus.data.type === 'collection-result' &&
      boardStatus.data.assets.includes(damAsset.id)
    ) {
      inSelectedBoard = <Pill small={true} label={'In board'} autoFocus={false} disabled={true} />;
    }

    const item = (
      <PHItem
        inSelectedBoard={inSelectedBoard}
        filterType={filterType}
        setFilterType={setFilterType}
        inIdam={inIdam}
        inDam={inDam}
        isArchived={!!damAsset?.archive}
        hasDuplicates={Boolean(numberOfDamAssets && numberOfDamAssets > 1)}
      />
    );

    return {
      id: damAsset?.id || ph,
      selected: damAsset ? selected.has(damAsset.id) : selected.has(ph),
      label: ph,
      name: ph,
      onChange: () => (damAsset ? toggleAsset(damAsset.id) : toggleAsset(ph)),
      item,
      image: {
        damUrl: damAsset?.thumbnails?.webimage,
        idamUrl: idamAsset?.data.files?.renditions?.[1]?.renditionPrivateUrl,
      },
      archived: !!damAsset?.archive,
    };
  };

  const allAssets: Array<ISelectableItemProps | null> = Array.from(phMap.keys())
    .map((ph) => {
      const e = phMap.get(ph);

      let item;
      if (!e) {
        counts.total += 1;
        item = <li key={ph}>Could not find ph {ph} in phMap!</li>;
        return {
          id: ph,
          selected: selected.has(ph),
          label: ph,
          name: ph,
          onChange: () => toggleAsset(ph),
          item,
          archived: false,
        };
      } else {
        if (e.dam && e.dam.length > 0) {
          return e.dam.map((damAsset) => {
            return createAsset(ph, damAsset, e.idam, e.dam?.length);
          });
        } else {
          return createAsset(ph, undefined, e.idam, e.dam?.length);
        }
      }
    })
    .flat();

  const assets = allAssets.filter((a): a is ISelectableItemProps => a !== null);

  const selectedAssets: Array<{ id: string; ph: string }> = [];

  phMap.forEach((v, k) => {
    if (!selected.has(k) && !v.dam?.some((a) => selected.has(a.id))) {
      return;
    }

    if (typeof v.dam === 'undefined') {
      return;
    }

    if (v.dam.length > 0) {
      v.dam.forEach((a) => {
        if (!selected.has(a.id)) return;

        selectedAssets.push({
          id: a.id,
          ph: k,
        });
      });
    }
  });

  const copyToClipboard = () => {
    const names = new Set<string>();
    for (const s of selected) {
      const a = phMap.get(s);

      if (a) {
        names.add(s);
      } else {
        phMap.forEach((value) => {
          if (typeof value.dam === 'undefined') {
            return;
          }

          value.dam.forEach((asset) => {
            if (selected.has(asset.id)) {
              names.add(asset.name);
            }
          });
        });
      }
    }

    void navigator.clipboard.writeText(Array.from(names).join('\n'));

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div>
      <Section size={'large'}>
        <h3>Filter</h3>
        <Pill
          small={true}
          label={`Only in IDAM (${counts.onlyIdam})`}
          selected={filterType === 'only-idam'}
          autoFocus={false}
          onClick={() => setFilterType('only-idam')}
          className={style['filter-list-item']}
        />
        <Pill
          small={true}
          label={`DAM (${counts.inDAM})`}
          selected={filterType === 'dam'}
          autoFocus={false}
          onClick={() => setFilterType('dam')}
          className={style['filter-list-item']}
        />

        <Pill
          small={true}
          label={`All (${counts.total})`}
          selected={filterType === 'all'}
          autoFocus={false}
          onClick={() => setFilterType('all')}
          className={style['filter-list-item']}
        />

        <Pill
          small={true}
          label={`Not found (${counts.notFound})`}
          selected={filterType === 'not-found'}
          autoFocus={false}
          onClick={() => setFilterType('not-found')}
          className={style['filter-list-item']}
        />
      </Section>

      <Section size={'large'}>
        <div className={style['tools-wrap']}>
          <Tooltip
            tooltipText={`Download selected IDAM items (${selected.size}).`}
            className={style['tools-button']}
          >
            <Button
              ssrIcon={ArrowCloudOutIcon}
              iconOnly={true}
              disabled={selected.size === 0}
              onClick={onDownloadFiles}
              type={'tertiary'}
            />
          </Tooltip>
          <Tooltip
            tooltipText={`Upload selected items to Bynder (${selected.size}).`}
            className={style['tools-button']}
          >
            <Button
              ssrIcon={ArrowCloudInIcon}
              disabled={selected.size === 0}
              onClick={onUploadToBynder}
              type={'tertiary'}
            >
              IDAM
            </Button>
          </Tooltip>
          <Tooltip
            tooltipText={
              copied
                ? `Copied ${selected.size} items to clipboard!`
                : `Copy selected to clipboard (${selected.size}).`
            }
            className={style['tools-button']}
          >
            <Button
              ssrIcon={CopyIcon}
              iconOnly={true}
              onClick={copyToClipboard}
              disabled={selected.size === 0}
              type={'tertiary'}
            />
          </Tooltip>

          <div
            style={{
              width: '1px',
              borderLeft: '1px solid #929292',
              marginRight: '50px',
              marginLeft: '30px',
            }}
          ></div>

          <BynderBoardInput
            selectedAssetIds={selectedAssets.map(({ id }) => id)}
            onBoardStatusChange={(boardStatus) => setBoardStatus(boardStatus)}
          />
        </div>
      </Section>

      <Section>
        <SelectableList
          items={assets}
          onSelectAll={onSelectAllToggle}
          localStorageKey={'PHTools_selectableList'}
        />
      </Section>
    </div>
  );
};

const PHItem = ({
  inSelectedBoard,
  filterType,
  setFilterType,
  inIdam,
  inDam,
  isArchived,
  hasDuplicates = false,
}: {
  inSelectedBoard?: JSX.Element;
  filterType: ResultFilterTypes;
  setFilterType: (type: ResultFilterTypes) => void;
  inIdam: boolean;
  inDam: boolean;
  isArchived: boolean;
  hasDuplicates?: boolean;
}) => (
  <div className={style['row-items-wrap']}>
    <div className={style['row-item']}>{inSelectedBoard}</div>
    {isArchived && (
      <div
        className={style['row-item']}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#FF000099',
          padding: 5,
          userSelect: 'none',
        }}
        title="Archived"
      >
        <Icon paths={WarningIcon} />
      </div>
    )}
    {hasDuplicates && (
      <div
        className={style['row-item']}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 5,
          userSelect: 'none',
        }}
        title="Has duplicates"
      >
        <Icon paths={copy} />
      </div>
    )}
    <div className={style['row-item']}>
      <Pill
        small={true}
        label={'IDAM'}
        selected={filterType === 'only-idam'}
        autoFocus={false}
        onClick={() => setFilterType('only-idam')}
        disabled={!inIdam}
        className={!inIdam ? style.hidden : ''}
      />
    </div>
    <div className={style['row-item']}>
      <Pill
        small={true}
        label={'DAM'}
        selected={filterType === 'dam'}
        autoFocus={false}
        onClick={() => setFilterType('dam')}
        disabled={!inDam}
        className={!inDam ? style.hidden : ''}
      />
    </div>
  </div>
);
