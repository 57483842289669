/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
  BynderAssetJobRetryResponse,
  CannotRetryNotMaxAttempts,
  CouldNotFetchFromFSError,
  CouldNotQueueUploadTask,
  CouldNotUpdateFSJob,
  NotFoundInFS,
  ValidationError,
  BynderAssetJobRetryResponseFromJSONTyped,
  BynderAssetJobRetryResponseToJSON,
  CannotRetryNotMaxAttemptsFromJSONTyped,
  CannotRetryNotMaxAttemptsToJSON,
  CouldNotFetchFromFSErrorFromJSONTyped,
  CouldNotFetchFromFSErrorToJSON,
  CouldNotQueueUploadTaskFromJSONTyped,
  CouldNotQueueUploadTaskToJSON,
  CouldNotUpdateFSJobFromJSONTyped,
  CouldNotUpdateFSJobToJSON,
  NotFoundInFSFromJSONTyped,
  NotFoundInFSToJSON,
  ValidationErrorFromJSONTyped,
  ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type BynderAssetJobRetry
 *
 * @export
 */
export type BynderAssetJobRetry =
  | BynderAssetJobRetryResponse
  | CannotRetryNotMaxAttempts
  | CouldNotFetchFromFSError
  | CouldNotQueueUploadTask
  | CouldNotUpdateFSJob
  | ValidationError
  | NotFoundInFS;

export function BynderAssetJobRetryFromJSON(json: any): BynderAssetJobRetry {
  return BynderAssetJobRetryFromJSONTyped(json, false);
}

export function BynderAssetJobRetryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BynderAssetJobRetry {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json['type']) {
    case 'bynder-asset-job-retry-response':
      return {
        ...BynderAssetJobRetryResponseFromJSONTyped(json, true),
        type: 'bynder-asset-job-retry-response',
      };
    case 'cannot-retry-not-max-attempts':
      return {
        ...CannotRetryNotMaxAttemptsFromJSONTyped(json, true),
        type: 'cannot-retry-not-max-attempts',
      };
    case 'could-not-fetch-from-fs':
      return {
        ...CouldNotFetchFromFSErrorFromJSONTyped(json, true),
        type: 'could-not-fetch-from-fs',
      };
    case 'could-not-queue-upload-task':
      return {
        ...CouldNotQueueUploadTaskFromJSONTyped(json, true),
        type: 'could-not-queue-upload-task',
      };
    case 'could-not-update-fs-job':
      return { ...CouldNotUpdateFSJobFromJSONTyped(json, true), type: 'could-not-update-fs-job' };
    case 'failed-to-validate':
      return { ...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate' };
    case 'not-found-in-fs':
      return { ...NotFoundInFSFromJSONTyped(json, true), type: 'not-found-in-fs' };
    default:
      throw new Error(`No variant of BynderAssetJobRetry exists with 'type=${json['type']}'`);
  }
}

export function BynderAssetJobRetryToJSON(value?: BynderAssetJobRetry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value['type']) {
    case 'bynder-asset-job-retry-response':
      return BynderAssetJobRetryResponseToJSON(value);
    case 'cannot-retry-not-max-attempts':
      return CannotRetryNotMaxAttemptsToJSON(value);
    case 'could-not-fetch-from-fs':
      return CouldNotFetchFromFSErrorToJSON(value);
    case 'could-not-queue-upload-task':
      return CouldNotQueueUploadTaskToJSON(value);
    case 'could-not-update-fs-job':
      return CouldNotUpdateFSJobToJSON(value);
    case 'failed-to-validate':
      return ValidationErrorToJSON(value);
    case 'not-found-in-fs':
      return NotFoundInFSToJSON(value);
    default:
      throw new Error(`No variant of BynderAssetJobRetry exists with 'type=${value['type']}'`);
  }
}
