/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  GetFeaturesResultRoomclassification,
  GetFeaturesResultRoomclassificationFromJSON,
  GetFeaturesResultRoomclassificationFromJSONTyped,
  GetFeaturesResultRoomclassificationToJSON,
  GetFeaturesResultStylegroup,
  GetFeaturesResultStylegroupFromJSON,
  GetFeaturesResultStylegroupFromJSONTyped,
  GetFeaturesResultStylegroupToJSON,
  ImageColorResult,
  ImageColorResultFromJSON,
  ImageColorResultFromJSONTyped,
  ImageColorResultToJSON,
  ProductColorResult,
  ProductColorResultFromJSON,
  ProductColorResultFromJSONTyped,
  ProductColorResultToJSON,
} from './';

/**
 *
 * @export
 * @interface GetFeaturesResult
 */
export interface GetFeaturesResult {
  /**
   *
   * @type {Array<number>}
   * @memberof GetFeaturesResult
   */
  extract?: Array<number>;
  /**
   *
   * @type {Array<ImageColorResult>}
   * @memberof GetFeaturesResult
   */
  imagecolor?: Array<ImageColorResult>;
  /**
   *
   * @type {Array<ProductColorResult>}
   * @memberof GetFeaturesResult
   */
  productcolor?: Array<ProductColorResult>;
  /**
   *
   * @type {Array<GetFeaturesResultRoomclassification>}
   * @memberof GetFeaturesResult
   */
  roomclassification?: Array<GetFeaturesResultRoomclassification>;
  /**
   *
   * @type {Array<GetFeaturesResultStylegroup>}
   * @memberof GetFeaturesResult
   */
  stylegroup?: Array<GetFeaturesResultStylegroup>;
}

export function GetFeaturesResultFromJSON(json: any): GetFeaturesResult {
  return GetFeaturesResultFromJSONTyped(json, false);
}

export function GetFeaturesResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetFeaturesResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    extract: !exists(json, 'extract') ? undefined : json['extract'],
    imagecolor: !exists(json, 'imagecolor')
      ? undefined
      : (json['imagecolor'] as Array<any>).map(ImageColorResultFromJSON),
    productcolor: !exists(json, 'productcolor')
      ? undefined
      : (json['productcolor'] as Array<any>).map(ProductColorResultFromJSON),
    roomclassification: !exists(json, 'roomclassification')
      ? undefined
      : (json['roomclassification'] as Array<any>).map(GetFeaturesResultRoomclassificationFromJSON),
    stylegroup: !exists(json, 'stylegroup')
      ? undefined
      : (json['stylegroup'] as Array<any>).map(GetFeaturesResultStylegroupFromJSON),
  };
}

export function GetFeaturesResultToJSON(value?: GetFeaturesResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    extract: value.extract,
    imagecolor:
      value.imagecolor === undefined
        ? undefined
        : (value.imagecolor as Array<any>).map(ImageColorResultToJSON),
    productcolor:
      value.productcolor === undefined
        ? undefined
        : (value.productcolor as Array<any>).map(ProductColorResultToJSON),
    roomclassification:
      value.roomclassification === undefined
        ? undefined
        : (value.roomclassification as Array<any>).map(GetFeaturesResultRoomclassificationToJSON),
    stylegroup:
      value.stylegroup === undefined
        ? undefined
        : (value.stylegroup as Array<any>).map(GetFeaturesResultStylegroupToJSON),
  };
}
