import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FrontendSearchState, Search } from '../components/Search/Search';
import { SearchExtApi2Result, SearchRequestBody } from '../generated-backend-api';
import useUrlSearchQuery from '../hooks/useUrlSearchQuery';
import { Loadable } from '../util/type';
import { searchRequestBodyToFrontendSearchState } from '../components/Search/helpers';

interface Props {
  search: (opts: SearchRequestBody) => Promise<void> | undefined;
  results: Loadable<
    SearchExtApi2Result,
    {
      options: SearchRequestBody;
    }
  >;
  usageRights: string[];
  requestingMarket: string | undefined;
}

const SearchPage = (props: Props) => {
  const { search } = useLocation();
  const [getQuery, setUrlQuery] = useUrlSearchQuery();

  const urlQuery = React.useMemo(getQuery, [search, getQuery]);

  const [searchState, setSearchState] = useState<FrontendSearchState>(
    searchRequestBodyToFrontendSearchState(
      urlQuery !== null
        ? urlQuery
        : {
            query: { name: { prefix: 'ph' } },
            usageRights: props.usageRights,
            requestingMarket: !props.requestingMarket ? '' : props.requestingMarket,
          },
    ),
  );

  return (
    <Search
      {...props}
      title="Search assets"
      searchState={searchState}
      setSearchState={setSearchState}
      urlQuery={urlQuery}
      setUrlQuery={setUrlQuery}
    />
  );
};
export default SearchPage;
