/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface FSSearchByExtNameRequestBody
 */
export interface FSSearchByExtNameRequestBody {
  /**
   *
   * @type {Array<string>}
   * @memberof FSSearchByExtNameRequestBody
   */
  phList: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof FSSearchByExtNameRequestBody
   */
  extensions: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof FSSearchByExtNameRequestBody
   */
  full?: boolean;
}

export function FSSearchByExtNameRequestBodyFromJSON(json: any): FSSearchByExtNameRequestBody {
  return FSSearchByExtNameRequestBodyFromJSONTyped(json, false);
}

export function FSSearchByExtNameRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FSSearchByExtNameRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    phList: json['phList'],
    extensions: json['extensions'],
    full: !exists(json, 'full') ? undefined : json['full'],
  };
}

export function FSSearchByExtNameRequestBodyToJSON(
  value?: FSSearchByExtNameRequestBody | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    phList: value.phList,
    extensions: value.extensions,
    full: value.full,
  };
}
