/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IDAMAssetInterface,
  IDAMAssetInterfaceFromJSON,
  IDAMAssetInterfaceFromJSONTyped,
  IDAMAssetInterfaceToJSON,
} from './';

/**
 *
 * @export
 * @interface IDAMAssetResponse
 */
export interface IDAMAssetResponse {
  /**
   *
   * @type {string}
   * @memberof IDAMAssetResponse
   */
  type: IDAMAssetResponseTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof IDAMAssetResponse
   */
  isError: IDAMAssetResponseIsErrorEnum;
  /**
   *
   * @type {IDAMAssetInterface}
   * @memberof IDAMAssetResponse
   */
  result: IDAMAssetInterface;
}

/**
 * @export
 * @enum {string}
 */
export type IDAMAssetResponseTypeEnum = 'idam-asset';

/**
 * @export
 * @enum {boolean}
 */
export type IDAMAssetResponseIsErrorEnum = false;

export function IDAMAssetResponseFromJSON(json: any): IDAMAssetResponse {
  return IDAMAssetResponseFromJSONTyped(json, false);
}

export function IDAMAssetResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IDAMAssetResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    isError: json['isError'],
    result: IDAMAssetInterfaceFromJSON(json['result']),
  };
}

export function IDAMAssetResponseToJSON(value?: IDAMAssetResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    isError: value.isError,
    result: IDAMAssetInterfaceToJSON(value.result),
  };
}
