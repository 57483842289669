/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
  CouldNotGenerateClipEmbedding,
  CouldNotPerformRequest,
  RequestAbortedError,
  UnexpectedResponseError,
  VIClipFeaturesResponse,
  ValidationError,
  CouldNotGenerateClipEmbeddingFromJSONTyped,
  CouldNotGenerateClipEmbeddingToJSON,
  CouldNotPerformRequestFromJSONTyped,
  CouldNotPerformRequestToJSON,
  RequestAbortedErrorFromJSONTyped,
  RequestAbortedErrorToJSON,
  UnexpectedResponseErrorFromJSONTyped,
  UnexpectedResponseErrorToJSON,
  VIClipFeaturesResponseFromJSONTyped,
  VIClipFeaturesResponseToJSON,
  ValidationErrorFromJSONTyped,
  ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type VIClipFeatures
 *
 * @export
 */
export type VIClipFeatures =
  | VIClipFeaturesResponse
  | CouldNotGenerateClipEmbedding
  | CouldNotPerformRequest
  | ValidationError
  | RequestAbortedError
  | UnexpectedResponseError;

export function VIClipFeaturesFromJSON(json: any): VIClipFeatures {
  return VIClipFeaturesFromJSONTyped(json, false);
}

export function VIClipFeaturesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VIClipFeatures {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json['type']) {
    case 'clip-features':
      return { ...VIClipFeaturesResponseFromJSONTyped(json, true), type: 'clip-features' };
    case 'could-not-generate-clip-embedding':
      return {
        ...CouldNotGenerateClipEmbeddingFromJSONTyped(json, true),
        type: 'could-not-generate-clip-embedding',
      };
    case 'could-not-perform-request':
      return {
        ...CouldNotPerformRequestFromJSONTyped(json, true),
        type: 'could-not-perform-request',
      };
    case 'failed-to-validate':
      return { ...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate' };
    case 'request-aborted':
      return { ...RequestAbortedErrorFromJSONTyped(json, true), type: 'request-aborted' };
    case 'unexpected-response':
      return { ...UnexpectedResponseErrorFromJSONTyped(json, true), type: 'unexpected-response' };
    default:
      throw new Error(`No variant of VIClipFeatures exists with 'type=${json['type']}'`);
  }
}

export function VIClipFeaturesToJSON(value?: VIClipFeatures | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value['type']) {
    case 'clip-features':
      return VIClipFeaturesResponseToJSON(value);
    case 'could-not-generate-clip-embedding':
      return CouldNotGenerateClipEmbeddingToJSON(value);
    case 'could-not-perform-request':
      return CouldNotPerformRequestToJSON(value);
    case 'failed-to-validate':
      return ValidationErrorToJSON(value);
    case 'request-aborted':
      return RequestAbortedErrorToJSON(value);
    case 'unexpected-response':
      return UnexpectedResponseErrorToJSON(value);
    default:
      throw new Error(`No variant of VIClipFeatures exists with 'type=${value['type']}'`);
  }
}
