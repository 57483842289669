/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  Asset,
  AssetFromJSON,
  AssetFromJSONTyped,
  AssetToJSON,
  ExtApiAssetListNotFound,
  ExtApiAssetListNotFoundFromJSON,
  ExtApiAssetListNotFoundFromJSONTyped,
  ExtApiAssetListNotFoundToJSON,
} from './';

/**
 *
 * @export
 * @interface ExtApiAssetList
 */
export interface ExtApiAssetList {
  /**
   *
   * @type {Array<Asset>}
   * @memberof ExtApiAssetList
   */
  assets: Array<Asset>;
  /**
   *
   * @type {Array<ExtApiAssetListNotFound>}
   * @memberof ExtApiAssetList
   */
  notFound: Array<ExtApiAssetListNotFound>;
}

export function ExtApiAssetListFromJSON(json: any): ExtApiAssetList {
  return ExtApiAssetListFromJSONTyped(json, false);
}

export function ExtApiAssetListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExtApiAssetList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assets: (json['assets'] as Array<any>).map(AssetFromJSON),
    notFound: (json['notFound'] as Array<any>).map(ExtApiAssetListNotFoundFromJSON),
  };
}

export function ExtApiAssetListToJSON(value?: ExtApiAssetList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assets: (value.assets as Array<any>).map(AssetToJSON),
    notFound: (value.notFound as Array<any>).map(ExtApiAssetListNotFoundToJSON),
  };
}
