import { WithConditionalCSSProp } from '@emotion/react/types/jsx-namespace';
import React from 'react';

interface ISectionProps extends WithConditionalCSSProp<JSX.Element> {
  size?: 'large' | 'small';
  className?: string;
  style?: React.CSSProperties;
}

export function Section(props: React.PropsWithChildren<ISectionProps>): JSX.Element {
  return (
    <div
      style={{ marginBottom: props.size === 'large' ? '40px' : '20px', ...props.style }}
      className={props.className}
    >
      {props.children}
    </div>
  );
}
