/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AkamaiPurgeCache,
  AkamaiPurgeCacheFromJSON,
  AkamaiPurgeCacheToJSON,
  BynderAssetEditJobListRequestBody,
  BynderAssetEditJobListRequestBodyFromJSON,
  BynderAssetEditJobListRequestBodyToJSON,
  BynderAssetJobRetry,
  BynderAssetJobRetryFromJSON,
  BynderAssetJobRetryToJSON,
  BynderAssetJobRetryRequestBody,
  BynderAssetJobRetryRequestBodyFromJSON,
  BynderAssetJobRetryRequestBodyToJSON,
  BynderAssetUploadJobListRequestBody,
  BynderAssetUploadJobListRequestBodyFromJSON,
  BynderAssetUploadJobListRequestBodyToJSON,
  BynderMeta,
  BynderMetaFromJSON,
  BynderMetaToJSON,
  CreateBynderAssetJob,
  CreateBynderAssetJobFromJSON,
  CreateBynderAssetJobToJSON,
  CreateBynderJobRequestBody,
  CreateBynderJobRequestBodyFromJSON,
  CreateBynderJobRequestBodyToJSON,
  CreateDownloadPackage,
  CreateDownloadPackageFromJSON,
  CreateDownloadPackageToJSON,
  CreatePackageBody,
  CreatePackageBodyFromJSON,
  CreatePackageBodyToJSON,
  ElasticSearchMapping,
  ElasticSearchMappingFromJSON,
  ElasticSearchMappingToJSON,
  ElasticSearchSimilarRequestBody,
  ElasticSearchSimilarRequestBodyFromJSON,
  ElasticSearchSimilarRequestBodyToJSON,
  ExtApiSearch2RequestBody,
  ExtApiSearch2RequestBodyFromJSON,
  ExtApiSearch2RequestBodyToJSON,
  ExtApiSearchRequestBody,
  ExtApiSearchRequestBodyFromJSON,
  ExtApiSearchRequestBodyToJSON,
  FSSearchByExtNameRequestBody,
  FSSearchByExtNameRequestBodyFromJSON,
  FSSearchByExtNameRequestBodyToJSON,
  FSSearchRequestBody,
  FSSearchRequestBodyFromJSON,
  FSSearchRequestBodyToJSON,
  FSSearchRequestBodyID,
  FSSearchRequestBodyIDFromJSON,
  FSSearchRequestBodyIDToJSON,
  GetBynderAssetEditJobList,
  GetBynderAssetEditJobListFromJSON,
  GetBynderAssetEditJobListToJSON,
  GetBynderAssetUploadJobList,
  GetBynderAssetUploadJobListFromJSON,
  GetBynderAssetUploadJobListToJSON,
  GetVIAssetMeta,
  GetVIAssetMetaFromJSON,
  GetVIAssetMetaToJSON,
  IDAMAsset,
  IDAMAssetFromJSON,
  IDAMAssetToJSON,
  IDAMAssetRequestBody,
  IDAMAssetRequestBodyFromJSON,
  IDAMAssetRequestBodyToJSON,
  IMCCDeliveries,
  IMCCDeliveriesFromJSON,
  IMCCDeliveriesToJSON,
  IMCCDeliveriesRequestBody,
  IMCCDeliveriesRequestBodyFromJSON,
  IMCCDeliveriesRequestBodyToJSON,
  IMCCDelivery,
  IMCCDeliveryFromJSON,
  IMCCDeliveryToJSON,
  IMCCDeliveryRequestBody,
  IMCCDeliveryRequestBodyFromJSON,
  IMCCDeliveryRequestBodyToJSON,
  IMCCImageSearchRequestBody,
  IMCCImageSearchRequestBodyFromJSON,
  IMCCImageSearchRequestBodyToJSON,
  IMCCSearchRequestBody,
  IMCCSearchRequestBodyFromJSON,
  IMCCSearchRequestBodyToJSON,
  PIABulkSearchRequestBody,
  PIABulkSearchRequestBodyFromJSON,
  PIABulkSearchRequestBodyToJSON,
  PurgeAkamaiCache,
  PurgeAkamaiCacheFromJSON,
  PurgeAkamaiCacheToJSON,
  SearchElasticClipSimilarity,
  SearchElasticClipSimilarityFromJSON,
  SearchElasticClipSimilarityToJSON,
  SearchElasticVISimilarity,
  SearchElasticVISimilarityFromJSON,
  SearchElasticVISimilarityToJSON,
  SearchExtApi,
  SearchExtApiFromJSON,
  SearchExtApiToJSON,
  SearchExtApi2,
  SearchExtApi2FromJSON,
  SearchExtApi2ToJSON,
  SearchFS,
  SearchFSFromJSON,
  SearchFSToJSON,
  SearchFSByID,
  SearchFSByIDFromJSON,
  SearchFSByIDToJSON,
  SearchFSExtName,
  SearchFSExtNameFromJSON,
  SearchFSExtNameToJSON,
  SearchIMCC,
  SearchIMCCFromJSON,
  SearchIMCCToJSON,
  SearchIMCCImage,
  SearchIMCCImageFromJSON,
  SearchIMCCImageToJSON,
  SearchPIABulk,
  SearchPIABulkFromJSON,
  SearchPIABulkToJSON,
  VIAssetMetaPayload,
  VIAssetMetaPayloadFromJSON,
  VIAssetMetaPayloadToJSON,
  VIClipFeatures,
  VIClipFeaturesFromJSON,
  VIClipFeaturesToJSON,
  VIImageFeatures,
  VIImageFeaturesFromJSON,
  VIImageFeaturesToJSON,
  VIImageFeaturesPayload,
  VIImageFeaturesPayloadFromJSON,
  VIImageFeaturesPayloadToJSON,
  VITextClipFeaturesPayload,
  VITextClipFeaturesPayloadFromJSON,
  VITextClipFeaturesPayloadToJSON,
} from '../models';

export interface UnexpectedCallError {
  isError: true;
  type: 'unexpected-call-error';
  error: unknown;
}

// responses
export type BynderAssetJobRetryResult = BynderAssetJobRetry | UnexpectedCallError;
export type BynderMetaResult = BynderMeta | UnexpectedCallError;
export type CreateBynderAssetJobResult = CreateBynderAssetJob | UnexpectedCallError;
export type CreateDownloadPackageResult = CreateDownloadPackage | UnexpectedCallError;
export type ElasticSearchMappingResult = ElasticSearchMapping | UnexpectedCallError;
export type GetBynderAssetEditJobListResult = GetBynderAssetEditJobList | UnexpectedCallError;
export type GetBynderAssetUploadJobListResult = GetBynderAssetUploadJobList | UnexpectedCallError;
export type GetVIAssetMetaResult = GetVIAssetMeta | UnexpectedCallError;
export type IDAMAssetResult = IDAMAsset | UnexpectedCallError;
export type IMCCDeliveriesResult = IMCCDeliveries | UnexpectedCallError;
export type IMCCDeliveryResult = IMCCDelivery | UnexpectedCallError;
export type PurgeAkamaiCacheResult = PurgeAkamaiCache | UnexpectedCallError;
export type SearchElasticClipSimilarityResult = SearchElasticClipSimilarity | UnexpectedCallError;
export type SearchElasticVISimilarityResult = SearchElasticVISimilarity | UnexpectedCallError;
export type SearchExtApiResult = SearchExtApi | UnexpectedCallError;
export type SearchExtApi2Result = SearchExtApi2 | UnexpectedCallError;
export type SearchFSResult = SearchFS | UnexpectedCallError;
export type SearchFSByIDResult = SearchFSByID | UnexpectedCallError;
export type SearchFSExtNameResult = SearchFSExtName | UnexpectedCallError;
export type SearchIMCCResult = SearchIMCC | UnexpectedCallError;
export type SearchIMCCImageResult = SearchIMCCImage | UnexpectedCallError;
export type SearchPIABulkResult = SearchPIABulk | UnexpectedCallError;
export type VIClipFeaturesResult = VIClipFeatures | UnexpectedCallError;
export type VIImageFeaturesResult = VIImageFeatures | UnexpectedCallError;

export interface BynderAssetJobRetryRequest {
  bynderAssetJobRetryRequestBody: BynderAssetJobRetryRequestBody;
}

export interface BynderMetaRequest {
  body?: any | null;
}

export interface CreateBynderAssetJobRequest {
  createBynderJobRequestBody: CreateBynderJobRequestBody;
}

export interface CreateDownloadPackageRequest {
  createPackageBody: CreatePackageBody;
}

export interface ElasticSearchMappingRequest {
  body?: any | null;
}

export interface GetBynderAssetEditJobListRequest {
  bynderAssetEditJobListRequestBody: BynderAssetEditJobListRequestBody;
}

export interface GetBynderAssetUploadJobListRequest {
  bynderAssetUploadJobListRequestBody: BynderAssetUploadJobListRequestBody;
}

export interface GetVIAssetMetaRequest {
  vIAssetMetaPayload: VIAssetMetaPayload;
}

export interface IDAMAssetRequest {
  iDAMAssetRequestBody: IDAMAssetRequestBody;
}

export interface IMCCDeliveriesRequest {
  iMCCDeliveriesRequestBody: IMCCDeliveriesRequestBody;
}

export interface IMCCDeliveryRequest {
  iMCCDeliveryRequestBody: IMCCDeliveryRequestBody;
}

export interface PurgeAkamaiCacheRequest {
  akamaiPurgeCache: AkamaiPurgeCache;
}

export interface SearchElasticClipSimilarityRequest {
  elasticSearchSimilarRequestBody: ElasticSearchSimilarRequestBody;
}

export interface SearchElasticVISimilarityRequest {
  elasticSearchSimilarRequestBody: ElasticSearchSimilarRequestBody;
}

export interface SearchExtApiRequest {
  extApiSearchRequestBody: ExtApiSearchRequestBody;
}

export interface SearchExtApi2Request {
  extApiSearch2RequestBody: ExtApiSearch2RequestBody;
}

export interface SearchFSRequest {
  fSSearchRequestBody: FSSearchRequestBody;
}

export interface SearchFSByIDRequest {
  fSSearchRequestBodyID: FSSearchRequestBodyID;
}

export interface SearchFSExtNameRequest {
  fSSearchByExtNameRequestBody: FSSearchByExtNameRequestBody;
}

export interface SearchIMCCRequest {
  iMCCSearchRequestBody: IMCCSearchRequestBody;
}

export interface SearchIMCCImageRequest {
  iMCCImageSearchRequestBody: IMCCImageSearchRequestBody;
}

export interface SearchPIABulkRequest {
  pIABulkSearchRequestBody: PIABulkSearchRequestBody;
}

export interface VIClipFeaturesRequest {
  vITextClipFeaturesPayload: VITextClipFeaturesPayload;
}

export interface VIImageFeaturesRequest {
  vIImageFeaturesPayload: VIImageFeaturesPayload;
}

/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
  /**
   */
  async bynderAssetJobRetryRaw(
    requestParameters: BynderAssetJobRetryRequest
  ): Promise<runtime.ApiResponse<BynderAssetJobRetry>> {
    if (
      requestParameters.bynderAssetJobRetryRequestBody === null ||
      requestParameters.bynderAssetJobRetryRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'bynderAssetJobRetryRequestBody',
        'Required parameter requestParameters.bynderAssetJobRetryRequestBody was null or undefined when calling bynderAssetJobRetry.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/job/bynder-asset-job/retry`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: BynderAssetJobRetryRequestBodyToJSON(requestParameters.bynderAssetJobRetryRequestBody),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BynderAssetJobRetryFromJSON(jsonValue)
    );
  }

  /**
   */
  async BynderAssetJobRetry(
    requestParameters: BynderAssetJobRetryRequest
  ): Promise<BynderAssetJobRetryResult> {
    try {
      const response = await this.bynderAssetJobRetryRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async bynderMetaRaw(
    requestParameters: BynderMetaRequest
  ): Promise<runtime.ApiResponse<BynderMeta>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/bynder-meta`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.body as any,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BynderMetaFromJSON(jsonValue));
  }

  /**
   */
  async BynderMeta(requestParameters: BynderMetaRequest): Promise<BynderMetaResult> {
    try {
      const response = await this.bynderMetaRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async createBynderAssetJobRaw(
    requestParameters: CreateBynderAssetJobRequest
  ): Promise<runtime.ApiResponse<CreateBynderAssetJob>> {
    if (
      requestParameters.createBynderJobRequestBody === null ||
      requestParameters.createBynderJobRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'createBynderJobRequestBody',
        'Required parameter requestParameters.createBynderJobRequestBody was null or undefined when calling createBynderAssetJob.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/job/bynder-asset-job/create`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateBynderJobRequestBodyToJSON(requestParameters.createBynderJobRequestBody),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateBynderAssetJobFromJSON(jsonValue)
    );
  }

  /**
   */
  async CreateBynderAssetJob(
    requestParameters: CreateBynderAssetJobRequest
  ): Promise<CreateBynderAssetJobResult> {
    try {
      const response = await this.createBynderAssetJobRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async createDownloadPackageRaw(
    requestParameters: CreateDownloadPackageRequest
  ): Promise<runtime.ApiResponse<CreateDownloadPackage>> {
    if (
      requestParameters.createPackageBody === null ||
      requestParameters.createPackageBody === undefined
    ) {
      throw new runtime.RequiredError(
        'createPackageBody',
        'Required parameter requestParameters.createPackageBody was null or undefined when calling createDownloadPackage.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/delivery/create-package`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreatePackageBodyToJSON(requestParameters.createPackageBody),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateDownloadPackageFromJSON(jsonValue)
    );
  }

  /**
   */
  async CreateDownloadPackage(
    requestParameters: CreateDownloadPackageRequest
  ): Promise<CreateDownloadPackageResult> {
    try {
      const response = await this.createDownloadPackageRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async elasticSearchMappingRaw(
    requestParameters: ElasticSearchMappingRequest
  ): Promise<runtime.ApiResponse<ElasticSearchMapping>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/search/elastic-mapping`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.body as any,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ElasticSearchMappingFromJSON(jsonValue)
    );
  }

  /**
   */
  async ElasticSearchMapping(
    requestParameters: ElasticSearchMappingRequest
  ): Promise<ElasticSearchMappingResult> {
    try {
      const response = await this.elasticSearchMappingRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async getBynderAssetEditJobListRaw(
    requestParameters: GetBynderAssetEditJobListRequest
  ): Promise<runtime.ApiResponse<GetBynderAssetEditJobList>> {
    if (
      requestParameters.bynderAssetEditJobListRequestBody === null ||
      requestParameters.bynderAssetEditJobListRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'bynderAssetEditJobListRequestBody',
        'Required parameter requestParameters.bynderAssetEditJobListRequestBody was null or undefined when calling getBynderAssetEditJobList.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/job/bynder-asset-edit-job/list`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: BynderAssetEditJobListRequestBodyToJSON(
        requestParameters.bynderAssetEditJobListRequestBody
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetBynderAssetEditJobListFromJSON(jsonValue)
    );
  }

  /**
   */
  async GetBynderAssetEditJobList(
    requestParameters: GetBynderAssetEditJobListRequest
  ): Promise<GetBynderAssetEditJobListResult> {
    try {
      const response = await this.getBynderAssetEditJobListRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async getBynderAssetUploadJobListRaw(
    requestParameters: GetBynderAssetUploadJobListRequest
  ): Promise<runtime.ApiResponse<GetBynderAssetUploadJobList>> {
    if (
      requestParameters.bynderAssetUploadJobListRequestBody === null ||
      requestParameters.bynderAssetUploadJobListRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'bynderAssetUploadJobListRequestBody',
        'Required parameter requestParameters.bynderAssetUploadJobListRequestBody was null or undefined when calling getBynderAssetUploadJobList.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/job/bynder-asset-upload-job/list`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: BynderAssetUploadJobListRequestBodyToJSON(
        requestParameters.bynderAssetUploadJobListRequestBody
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetBynderAssetUploadJobListFromJSON(jsonValue)
    );
  }

  /**
   */
  async GetBynderAssetUploadJobList(
    requestParameters: GetBynderAssetUploadJobListRequest
  ): Promise<GetBynderAssetUploadJobListResult> {
    try {
      const response = await this.getBynderAssetUploadJobListRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async getVIAssetMetaRaw(
    requestParameters: GetVIAssetMetaRequest
  ): Promise<runtime.ApiResponse<GetVIAssetMeta>> {
    if (
      requestParameters.vIAssetMetaPayload === null ||
      requestParameters.vIAssetMetaPayload === undefined
    ) {
      throw new runtime.RequiredError(
        'vIAssetMetaPayload',
        'Required parameter requestParameters.vIAssetMetaPayload was null or undefined when calling getVIAssetMeta.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/vi/vi-asset-meta`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VIAssetMetaPayloadToJSON(requestParameters.vIAssetMetaPayload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => GetVIAssetMetaFromJSON(jsonValue));
  }

  /**
   */
  async GetVIAssetMeta(requestParameters: GetVIAssetMetaRequest): Promise<GetVIAssetMetaResult> {
    try {
      const response = await this.getVIAssetMetaRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async iDAMAssetRaw(requestParameters: IDAMAssetRequest): Promise<runtime.ApiResponse<IDAMAsset>> {
    if (
      requestParameters.iDAMAssetRequestBody === null ||
      requestParameters.iDAMAssetRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'iDAMAssetRequestBody',
        'Required parameter requestParameters.iDAMAssetRequestBody was null or undefined when calling iDAMAsset.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/idam/asset`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: IDAMAssetRequestBodyToJSON(requestParameters.iDAMAssetRequestBody),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => IDAMAssetFromJSON(jsonValue));
  }

  /**
   */
  async IDAMAsset(requestParameters: IDAMAssetRequest): Promise<IDAMAssetResult> {
    try {
      const response = await this.iDAMAssetRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async iMCCDeliveriesRaw(
    requestParameters: IMCCDeliveriesRequest
  ): Promise<runtime.ApiResponse<IMCCDeliveries>> {
    if (
      requestParameters.iMCCDeliveriesRequestBody === null ||
      requestParameters.iMCCDeliveriesRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'iMCCDeliveriesRequestBody',
        'Required parameter requestParameters.iMCCDeliveriesRequestBody was null or undefined when calling iMCCDeliveries.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/delivery/`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: IMCCDeliveriesRequestBodyToJSON(requestParameters.iMCCDeliveriesRequestBody),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => IMCCDeliveriesFromJSON(jsonValue));
  }

  /**
   */
  async IMCCDeliveries(requestParameters: IMCCDeliveriesRequest): Promise<IMCCDeliveriesResult> {
    try {
      const response = await this.iMCCDeliveriesRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async iMCCDeliveryRaw(
    requestParameters: IMCCDeliveryRequest
  ): Promise<runtime.ApiResponse<IMCCDelivery>> {
    if (
      requestParameters.iMCCDeliveryRequestBody === null ||
      requestParameters.iMCCDeliveryRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'iMCCDeliveryRequestBody',
        'Required parameter requestParameters.iMCCDeliveryRequestBody was null or undefined when calling iMCCDelivery.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/delivery/id`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: IMCCDeliveryRequestBodyToJSON(requestParameters.iMCCDeliveryRequestBody),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => IMCCDeliveryFromJSON(jsonValue));
  }

  /**
   */
  async IMCCDelivery(requestParameters: IMCCDeliveryRequest): Promise<IMCCDeliveryResult> {
    try {
      const response = await this.iMCCDeliveryRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async purgeAkamaiCacheRaw(
    requestParameters: PurgeAkamaiCacheRequest
  ): Promise<runtime.ApiResponse<PurgeAkamaiCache>> {
    if (
      requestParameters.akamaiPurgeCache === null ||
      requestParameters.akamaiPurgeCache === undefined
    ) {
      throw new runtime.RequiredError(
        'akamaiPurgeCache',
        'Required parameter requestParameters.akamaiPurgeCache was null or undefined when calling purgeAkamaiCache.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/akamai/purge-cache`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AkamaiPurgeCacheToJSON(requestParameters.akamaiPurgeCache),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PurgeAkamaiCacheFromJSON(jsonValue)
    );
  }

  /**
   */
  async PurgeAkamaiCache(
    requestParameters: PurgeAkamaiCacheRequest
  ): Promise<PurgeAkamaiCacheResult> {
    try {
      const response = await this.purgeAkamaiCacheRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async searchElasticClipSimilarityRaw(
    requestParameters: SearchElasticClipSimilarityRequest
  ): Promise<runtime.ApiResponse<SearchElasticClipSimilarity>> {
    if (
      requestParameters.elasticSearchSimilarRequestBody === null ||
      requestParameters.elasticSearchSimilarRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'elasticSearchSimilarRequestBody',
        'Required parameter requestParameters.elasticSearchSimilarRequestBody was null or undefined when calling searchElasticClipSimilarity.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/search/elastic-clip-similarity`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ElasticSearchSimilarRequestBodyToJSON(
        requestParameters.elasticSearchSimilarRequestBody
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchElasticClipSimilarityFromJSON(jsonValue)
    );
  }

  /**
   */
  async SearchElasticClipSimilarity(
    requestParameters: SearchElasticClipSimilarityRequest
  ): Promise<SearchElasticClipSimilarityResult> {
    try {
      const response = await this.searchElasticClipSimilarityRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async searchElasticVISimilarityRaw(
    requestParameters: SearchElasticVISimilarityRequest
  ): Promise<runtime.ApiResponse<SearchElasticVISimilarity>> {
    if (
      requestParameters.elasticSearchSimilarRequestBody === null ||
      requestParameters.elasticSearchSimilarRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'elasticSearchSimilarRequestBody',
        'Required parameter requestParameters.elasticSearchSimilarRequestBody was null or undefined when calling searchElasticVISimilarity.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/search/elastic-vi-similarity`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ElasticSearchSimilarRequestBodyToJSON(
        requestParameters.elasticSearchSimilarRequestBody
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchElasticVISimilarityFromJSON(jsonValue)
    );
  }

  /**
   */
  async SearchElasticVISimilarity(
    requestParameters: SearchElasticVISimilarityRequest
  ): Promise<SearchElasticVISimilarityResult> {
    try {
      const response = await this.searchElasticVISimilarityRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async searchExtApiRaw(
    requestParameters: SearchExtApiRequest
  ): Promise<runtime.ApiResponse<SearchExtApi>> {
    if (
      requestParameters.extApiSearchRequestBody === null ||
      requestParameters.extApiSearchRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'extApiSearchRequestBody',
        'Required parameter requestParameters.extApiSearchRequestBody was null or undefined when calling searchExtApi.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/search/extapi`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ExtApiSearchRequestBodyToJSON(requestParameters.extApiSearchRequestBody),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => SearchExtApiFromJSON(jsonValue));
  }

  /**
   */
  async SearchExtApi(requestParameters: SearchExtApiRequest): Promise<SearchExtApiResult> {
    try {
      const response = await this.searchExtApiRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async searchExtApi2Raw(
    requestParameters: SearchExtApi2Request
  ): Promise<runtime.ApiResponse<SearchExtApi2>> {
    if (
      requestParameters.extApiSearch2RequestBody === null ||
      requestParameters.extApiSearch2RequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'extApiSearch2RequestBody',
        'Required parameter requestParameters.extApiSearch2RequestBody was null or undefined when calling searchExtApi2.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/search/extapi2`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ExtApiSearch2RequestBodyToJSON(requestParameters.extApiSearch2RequestBody),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => SearchExtApi2FromJSON(jsonValue));
  }

  /**
   */
  async SearchExtApi2(requestParameters: SearchExtApi2Request): Promise<SearchExtApi2Result> {
    try {
      const response = await this.searchExtApi2Raw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async searchFSRaw(requestParameters: SearchFSRequest): Promise<runtime.ApiResponse<SearchFS>> {
    if (
      requestParameters.fSSearchRequestBody === null ||
      requestParameters.fSSearchRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'fSSearchRequestBody',
        'Required parameter requestParameters.fSSearchRequestBody was null or undefined when calling searchFS.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/search/fs`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: FSSearchRequestBodyToJSON(requestParameters.fSSearchRequestBody),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => SearchFSFromJSON(jsonValue));
  }

  /**
   */
  async SearchFS(requestParameters: SearchFSRequest): Promise<SearchFSResult> {
    try {
      const response = await this.searchFSRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async searchFSByIDRaw(
    requestParameters: SearchFSByIDRequest
  ): Promise<runtime.ApiResponse<SearchFSByID>> {
    if (
      requestParameters.fSSearchRequestBodyID === null ||
      requestParameters.fSSearchRequestBodyID === undefined
    ) {
      throw new runtime.RequiredError(
        'fSSearchRequestBodyID',
        'Required parameter requestParameters.fSSearchRequestBodyID was null or undefined when calling searchFSByID.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/search/fsid`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: FSSearchRequestBodyIDToJSON(requestParameters.fSSearchRequestBodyID),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => SearchFSByIDFromJSON(jsonValue));
  }

  /**
   */
  async SearchFSByID(requestParameters: SearchFSByIDRequest): Promise<SearchFSByIDResult> {
    try {
      const response = await this.searchFSByIDRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async searchFSExtNameRaw(
    requestParameters: SearchFSExtNameRequest
  ): Promise<runtime.ApiResponse<SearchFSExtName>> {
    if (
      requestParameters.fSSearchByExtNameRequestBody === null ||
      requestParameters.fSSearchByExtNameRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'fSSearchByExtNameRequestBody',
        'Required parameter requestParameters.fSSearchByExtNameRequestBody was null or undefined when calling searchFSExtName.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/search/fsextname`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: FSSearchByExtNameRequestBodyToJSON(requestParameters.fSSearchByExtNameRequestBody),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => SearchFSExtNameFromJSON(jsonValue));
  }

  /**
   */
  async SearchFSExtName(requestParameters: SearchFSExtNameRequest): Promise<SearchFSExtNameResult> {
    try {
      const response = await this.searchFSExtNameRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async searchIMCCRaw(
    requestParameters: SearchIMCCRequest
  ): Promise<runtime.ApiResponse<SearchIMCC>> {
    if (
      requestParameters.iMCCSearchRequestBody === null ||
      requestParameters.iMCCSearchRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'iMCCSearchRequestBody',
        'Required parameter requestParameters.iMCCSearchRequestBody was null or undefined when calling searchIMCC.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/search/imcc`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: IMCCSearchRequestBodyToJSON(requestParameters.iMCCSearchRequestBody),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => SearchIMCCFromJSON(jsonValue));
  }

  /**
   */
  async SearchIMCC(requestParameters: SearchIMCCRequest): Promise<SearchIMCCResult> {
    try {
      const response = await this.searchIMCCRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async searchIMCCImageRaw(
    requestParameters: SearchIMCCImageRequest
  ): Promise<runtime.ApiResponse<SearchIMCCImage>> {
    if (
      requestParameters.iMCCImageSearchRequestBody === null ||
      requestParameters.iMCCImageSearchRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'iMCCImageSearchRequestBody',
        'Required parameter requestParameters.iMCCImageSearchRequestBody was null or undefined when calling searchIMCCImage.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/search/imccimage`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: IMCCImageSearchRequestBodyToJSON(requestParameters.iMCCImageSearchRequestBody),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => SearchIMCCImageFromJSON(jsonValue));
  }

  /**
   */
  async SearchIMCCImage(requestParameters: SearchIMCCImageRequest): Promise<SearchIMCCImageResult> {
    try {
      const response = await this.searchIMCCImageRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async searchPIABulkRaw(
    requestParameters: SearchPIABulkRequest
  ): Promise<runtime.ApiResponse<SearchPIABulk>> {
    if (
      requestParameters.pIABulkSearchRequestBody === null ||
      requestParameters.pIABulkSearchRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'pIABulkSearchRequestBody',
        'Required parameter requestParameters.pIABulkSearchRequestBody was null or undefined when calling searchPIABulk.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/search/pia/bulk`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: PIABulkSearchRequestBodyToJSON(requestParameters.pIABulkSearchRequestBody),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => SearchPIABulkFromJSON(jsonValue));
  }

  /**
   */
  async SearchPIABulk(requestParameters: SearchPIABulkRequest): Promise<SearchPIABulkResult> {
    try {
      const response = await this.searchPIABulkRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async vIClipFeaturesRaw(
    requestParameters: VIClipFeaturesRequest
  ): Promise<runtime.ApiResponse<VIClipFeatures>> {
    if (
      requestParameters.vITextClipFeaturesPayload === null ||
      requestParameters.vITextClipFeaturesPayload === undefined
    ) {
      throw new runtime.RequiredError(
        'vITextClipFeaturesPayload',
        'Required parameter requestParameters.vITextClipFeaturesPayload was null or undefined when calling vIClipFeatures.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/vi/clip-features`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VITextClipFeaturesPayloadToJSON(requestParameters.vITextClipFeaturesPayload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => VIClipFeaturesFromJSON(jsonValue));
  }

  /**
   */
  async VIClipFeatures(requestParameters: VIClipFeaturesRequest): Promise<VIClipFeaturesResult> {
    try {
      const response = await this.vIClipFeaturesRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }

  /**
   */
  async vIImageFeaturesRaw(
    requestParameters: VIImageFeaturesRequest
  ): Promise<runtime.ApiResponse<VIImageFeatures>> {
    if (
      requestParameters.vIImageFeaturesPayload === null ||
      requestParameters.vIImageFeaturesPayload === undefined
    ) {
      throw new runtime.RequiredError(
        'vIImageFeaturesPayload',
        'Required parameter requestParameters.vIImageFeaturesPayload was null or undefined when calling vIImageFeatures.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/vi/image-features`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VIImageFeaturesPayloadToJSON(requestParameters.vIImageFeaturesPayload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => VIImageFeaturesFromJSON(jsonValue));
  }

  /**
   */
  async VIImageFeatures(requestParameters: VIImageFeaturesRequest): Promise<VIImageFeaturesResult> {
    try {
      const response = await this.vIImageFeaturesRaw(requestParameters);
      return await response.value();
    } catch (e: unknown) {
      console.error(e);
      return {
        isError: true,
        type: 'unexpected-call-error',
        error: e,
      };
    }
  }
}
