import * as t from 'io-ts';
import { Asset } from '../generated-backend-api';
import { ProductCoordinateList } from './product-coordinates';

export function getExtProductCoordinates(a: Asset): t.TypeOf<typeof ProductCoordinateList> | false {
  if (!a.property_product_coordinates) {
    return false;
  }

  if (a.property_product_coordinates.length <= 0) {
    return false;
  }

  const v = a.property_product_coordinates[0].value;

  if (!v) {
    return false;
  }
  let c: unknown;
  try {
    c = JSON.parse(v);
  } catch (_) {
    console.log('failed to parse coords', v);
    return false;
  }

  if (ProductCoordinateList.is(c)) {
    return c;
  } else {
    return false;
  }
}
