/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  BynderAssetEditJob,
  BynderAssetEditJobFromJSON,
  BynderAssetEditJobFromJSONTyped,
  BynderAssetEditJobToJSON,
} from './';

/**
 *
 * @export
 * @interface BynderAssetEditJobListResponse
 */
export interface BynderAssetEditJobListResponse {
  /**
   *
   * @type {boolean}
   * @memberof BynderAssetEditJobListResponse
   */
  isError: BynderAssetEditJobListResponseIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof BynderAssetEditJobListResponse
   */
  type: BynderAssetEditJobListResponseTypeEnum;
  /**
   *
   * @type {Array<BynderAssetEditJob>}
   * @memberof BynderAssetEditJobListResponse
   */
  result: Array<BynderAssetEditJob>;
}

/**
 * @export
 * @enum {boolean}
 */
export type BynderAssetEditJobListResponseIsErrorEnum = false;

/**
 * @export
 * @enum {string}
 */
export type BynderAssetEditJobListResponseTypeEnum = 'bynder-asset-edit-job-list-response';

export function BynderAssetEditJobListResponseFromJSON(json: any): BynderAssetEditJobListResponse {
  return BynderAssetEditJobListResponseFromJSONTyped(json, false);
}

export function BynderAssetEditJobListResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BynderAssetEditJobListResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
    result: (json['result'] as Array<any>).map(BynderAssetEditJobFromJSON),
  };
}

export function BynderAssetEditJobListResponseToJSON(
  value?: BynderAssetEditJobListResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
    result: (value.result as Array<any>).map(BynderAssetEditJobToJSON),
  };
}
