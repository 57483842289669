import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { getPIAItemMedia } from './util';
import { PIABulkItemEntry } from '../../generated-backend-api';
import { ProductCoordinateType } from '../../util/product-coordinates';
import Checkbox from '@ingka/checkbox';

export const CropProduct = ({
  piaItem,
  isActive,
  onMouseEnter,
  onMouseLeave,
  index,
  productCoordinate,
  onTrashClick,
}: {
  piaItem?: PIABulkItemEntry;
  productCoordinate: ProductCoordinateType;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onTrashClick: () => void;
  isActive: boolean;
  index: number;
}) => {
  const mediaUrl = getPIAItemMedia(piaItem);

  const productName = piaItem?.content?.productNameLocal?.find((q) => q.productName)?.productName;

  return (
    <div
      css={css`
        padding: 10px;
        color: ${isActive ? '#000' : '#999'};
        display: flex;
        align-items: center;
        justify-content: flex-start;
      `}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span
        css={css`
          padding-right: 10px;
        `}
      >
        {index + 1}. {productCoordinate.prd}
      </span>
      <div
        css={css`
          min-width: 240px;
          display: flex;
          align-items: center;
          justify-content: space-around;
        `}
      >
        <span>{productName}</span>
        {mediaUrl ? (
          <div
            css={css`
              max-width: 50px;
              display: inline-block;
            `}
          >
            <img
              src={mediaUrl}
              css={css`
                max-width: 100%;
              `}
            />
          </div>
        ) : (
          <div>No media</div>
        )}
      </div>
      <Checkbox
        id={`is_disabled_product_${index}`}
        css={css`
          margin-left: 30px;
        `}
        label={'Disabled'}
        value={''}
        onClick={onTrashClick}
      />
    </div>
  );
};
