/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import { Asset, AssetFromJSON, AssetFromJSONTyped, AssetToJSON } from './';

/**
 *
 * @export
 * @interface SimilarityResult
 */
export interface SimilarityResult {
  /**
   *
   * @type {string}
   * @memberof SimilarityResult
   */
  _index: string;
  /**
   *
   * @type {string}
   * @memberof SimilarityResult
   */
  _id: string;
  /**
   *
   * @type {number}
   * @memberof SimilarityResult
   */
  _score: number;
  /**
   *
   * @type {Asset}
   * @memberof SimilarityResult
   */
  _source: Asset;
}

export function SimilarityResultFromJSON(json: any): SimilarityResult {
  return SimilarityResultFromJSONTyped(json, false);
}

export function SimilarityResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SimilarityResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    _index: json['_index'],
    _id: json['_id'],
    _score: json['_score'],
    _source: AssetFromJSON(json['_source']),
  };
}

export function SimilarityResultToJSON(value?: SimilarityResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    _index: value._index,
    _id: value._id,
    _score: value._score,
    _source: AssetToJSON(value._source),
  };
}
