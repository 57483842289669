import { AccountInfo } from '@azure/msal-browser';
import { useAccount } from '@azure/msal-react';
import { hasKey } from '@ikea-ingka-dam/type-util';

function isLoggedInDamUser(account: AccountInfo | null): boolean {
  if (account === null) {
    return false;
  }

  const claims = account.idTokenClaims;

  if (localStorage.getItem('dam-user-bypass') === 'I am a trusted user!') {
    return true;
  }

  if (hasKey('groups', claims) && Array.isArray(claims.groups)) {
    return claims.groups.findIndex((q) => q === '9c75f4ce-5a92-48a8-b393-3653d1bee93a') !== -1;
  }

  return false;
}

function useIsDamUser(): boolean {
  const account = useAccount();

  if (!account) {
    return false;
  }

  return isLoggedInDamUser(account);
}

export { useIsDamUser };
