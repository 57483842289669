/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  PIAItemItemMediaMediaContentLinksDownload,
  PIAItemItemMediaMediaContentLinksDownloadFromJSON,
  PIAItemItemMediaMediaContentLinksDownloadFromJSONTyped,
  PIAItemItemMediaMediaContentLinksDownloadToJSON,
} from './';

/**
 *
 * @export
 * @interface PIAItemItemMediaMediaContentLinks
 */
export interface PIAItemItemMediaMediaContentLinks {
  /**
   *
   * @type {PIAItemItemMediaMediaContentLinksDownload}
   * @memberof PIAItemItemMediaMediaContentLinks
   */
  download: PIAItemItemMediaMediaContentLinksDownload;
}

export function PIAItemItemMediaMediaContentLinksFromJSON(
  json: any
): PIAItemItemMediaMediaContentLinks {
  return PIAItemItemMediaMediaContentLinksFromJSONTyped(json, false);
}

export function PIAItemItemMediaMediaContentLinksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PIAItemItemMediaMediaContentLinks {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    download: PIAItemItemMediaMediaContentLinksDownloadFromJSON(json['download']),
  };
}

export function PIAItemItemMediaMediaContentLinksToJSON(
  value?: PIAItemItemMediaMediaContentLinks | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    download: PIAItemItemMediaMediaContentLinksDownloadToJSON(value.download),
  };
}
