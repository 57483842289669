import React, { useState } from 'react';

// /** @jsxImportSource @emotion/react */
// import { css } from '@emotion/react';
import { IBynderLoggedIn } from '../util/bynderReactUtils';
import { Select } from './select';
import { IBynderCollection } from '../util/bynder-api';
import { BynderBoardInfoShort } from './bynder-board-info-short';
import Button from '@ingka/button';
import { useToasts } from '../util/toastProvider';

interface SelectBynderBoardProps {
  onSelect: (collection: IBynderCollection) => void;
  loggedInBynder: IBynderLoggedIn;
}

export function SelectBynderBoard(props: SelectBynderBoardProps): JSX.Element {
  const [isCreatingBoard, setIsCreatingBoard] = useState(false);
  const toastApi = useToasts();

  return (
    <Select
      id="search_bynder_board"
      placeholder="Board name"
      disabled={isCreatingBoard}
      headerRight={(text) => (
        <Button
          text={`Create board "${text}"`}
          small={true}
          loading={isCreatingBoard}
          onClick={async () => {
            setIsCreatingBoard(true);
            const result = await props.loggedInBynder.bynder.createCollection({ name: text });
            setIsCreatingBoard(false);

            if (result.isError) {
              toastApi.push({
                isError: true,
                message: `Could not create board '${text}'. Error: ${result.type}`,
              });
            } else {
              toastApi.push({
                isError: false,
                message: `Created board '${text}'.`,
              });
            }
          }}
        />
      )}
      onSelect={(d) => props.onSelect(d.item)}
      getOptions={async (input: string) => {
        if (input.length === 0) {
          return [];
        }

        const data = await props.loggedInBynder.bynder.getCollections(
          { keyword: input },
          new AbortController(),
        );
        console.log(data);

        if (data.isError) {
          console.log('error', data);
          return [];
        }

        return data.result.map((d) => {
          const l = <BynderBoardInfoShort board={d} />;

          return {
            label: l,
            value: d.id,
            item: d,
          };
        });
      }}
    />
  );
}
