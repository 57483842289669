/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface ImageColorResult
 */
export interface ImageColorResult {
  /**
   *
   * @type {string}
   * @memberof ImageColorResult
   */
  color_name: string;
  /**
   *
   * @type {number}
   * @memberof ImageColorResult
   */
  score: number;
  /**
   *
   * @type {number}
   * @memberof ImageColorResult
   */
  part: number;
  /**
   *
   * @type {Array<number>}
   * @memberof ImageColorResult
   */
  rgb_code: Array<number>;
}

export function ImageColorResultFromJSON(json: any): ImageColorResult {
  return ImageColorResultFromJSONTyped(json, false);
}

export function ImageColorResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ImageColorResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    color_name: json['color_name'],
    score: json['score'],
    part: json['part'],
    rgb_code: json['rgb_code'],
  };
}

export function ImageColorResultToJSON(value?: ImageColorResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    color_name: value.color_name,
    score: value.score,
    part: value.part,
    rgb_code: value.rgb_code,
  };
}
