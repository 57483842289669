/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface VIClipFeaturesResponseResult
 */
export interface VIClipFeaturesResponseResult {
  /**
   *
   * @type {Array<number>}
   * @memberof VIClipFeaturesResponseResult
   */
  embedding: Array<number>;
}

export function VIClipFeaturesResponseResultFromJSON(json: any): VIClipFeaturesResponseResult {
  return VIClipFeaturesResponseResultFromJSONTyped(json, false);
}

export function VIClipFeaturesResponseResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VIClipFeaturesResponseResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    embedding: json['embedding'],
  };
}

export function VIClipFeaturesResponseResultToJSON(
  value?: VIClipFeaturesResponseResult | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    embedding: value.embedding,
  };
}
