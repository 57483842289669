/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface FSVIAssetMetaDataV2Stylegroup
 */
export interface FSVIAssetMetaDataV2Stylegroup {
  /**
   *
   * @type {number}
   * @memberof FSVIAssetMetaDataV2Stylegroup
   */
  score: number;
  /**
   *
   * @type {string}
   * @memberof FSVIAssetMetaDataV2Stylegroup
   */
  stylegroup: string;
}

export function FSVIAssetMetaDataV2StylegroupFromJSON(json: any): FSVIAssetMetaDataV2Stylegroup {
  return FSVIAssetMetaDataV2StylegroupFromJSONTyped(json, false);
}

export function FSVIAssetMetaDataV2StylegroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FSVIAssetMetaDataV2Stylegroup {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    score: json['score'],
    stylegroup: json['stylegroup'],
  };
}

export function FSVIAssetMetaDataV2StylegroupToJSON(
  value?: FSVIAssetMetaDataV2Stylegroup | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    score: value.score,
    stylegroup: value.stylegroup,
  };
}
