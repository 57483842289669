import React from 'react';
import Select, { Option } from '@ingka/select';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export type FieldQueryType = 'prefix' | 'exact' | 'lt' | 'gt' | 'ltgt' | 'contains' | 'fuzzy';

export const SearchQueryTypeSelect = ({
  value,
  onChange,
}: {
  value: FieldQueryType;
  onChange: (value: FieldQueryType) => void;
}) => (
  <Select
    id="field_query"
    css={css`
      max-width: 300px;
      display: inline-block;
      margin-right: 10px;
    `}
    value={value}
    label="Type"
    onChange={(e: React.FormEvent<HTMLSelectElement>) =>
      onChange(e.currentTarget.value as FieldQueryType)
    }
  >
    <Option value="prefix" label="Prefix" />
    <Option value="exact" label="Exact" />
    <Option value="lt" label="Less than" />
    <Option value="gt" label="Greater than" />
    <Option value="ltgt" label="Range" />
    <Option value="contains" label="Contains" />
    <Option value="fuzzy" label="Approximate" />
  </Select>
);
