/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import { PIAItem, PIAItemFromJSON, PIAItemFromJSONTyped, PIAItemToJSON } from './';

/**
 *
 * @export
 * @interface PIABulkItemEntry
 */
export interface PIABulkItemEntry {
  /**
   *
   * @type {string}
   * @memberof PIABulkItemEntry
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof PIABulkItemEntry
   */
  found: boolean;
  /**
   *
   * @type {PIAItem}
   * @memberof PIABulkItemEntry
   */
  content?: PIAItem;
}

export function PIABulkItemEntryFromJSON(json: any): PIABulkItemEntry {
  return PIABulkItemEntryFromJSONTyped(json, false);
}

export function PIABulkItemEntryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PIABulkItemEntry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    found: json['found'],
    content: !exists(json, 'content') ? undefined : PIAItemFromJSON(json['content']),
  };
}

export function PIABulkItemEntryToJSON(value?: PIABulkItemEntry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    found: value.found,
    content: PIAItemToJSON(value.content),
  };
}
