export type Loadable<T, ID = unknown> =
  | (ILoaded<T> & ID)
  // | (IStale<T> & ID)
  | (ILoading & ID)
  | IUninitialized;

export type Loaded<T, ID = Record<string, unknown>> = ILoaded<T> & ID;

interface ILoaded<T> {
  status: 'loaded';
  data: T;
}

// interface IStale<T> {
//   status: 'stale';
//   data: T;
// }

export interface ILoading {
  status: 'loading';
}

export interface IUninitialized {
  status: 'uninitialized';
}

export function hasKey<K extends string>(k: K, o: unknown): o is { [_ in K]: unknown } {
  return typeof o === 'object' && o !== null && k in o;
}

export function hasStringKey<K extends string>(k: K, o: unknown): o is { [_ in K]: string } {
  return typeof o === 'object' && o !== null && k in o;
}

export function hasNumberKey<K extends string>(k: K, o: unknown): o is { [_ in K]: number } {
  return typeof o === 'object' && o !== null && k in o;
}

export function hasExactNumberKeyValue<K extends string, ExactValue extends number>(
  k: K,
  o: unknown,
  value: ExactValue,
): o is { [_ in K]: ExactValue } {
  return hasNumberKey(k, o) && o[k] === value;
}

export function hasExactStringKeyValue<K extends string, ExactValue extends string>(
  k: K,
  o: unknown,
  value: ExactValue,
): o is { [_ in K]: ExactValue } {
  return hasStringKey(k, o) && o[k] === value;
}

export interface IResult<T> {
  isError: false;
  result: T;
}

export interface IError<T> {
  isError: true;
  type: T;
}

export function ok<T>(d: T): IResult<T> {
  return {
    isError: false,
    result: d,
  };
}
