/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  PIAItemItemMediaMediaContent,
  PIAItemItemMediaMediaContentFromJSON,
  PIAItemItemMediaMediaContentFromJSONTyped,
  PIAItemItemMediaMediaContentToJSON,
} from './';

/**
 *
 * @export
 * @interface PIAItemItemMedia
 */
export interface PIAItemItemMedia {
  /**
   *
   * @type {string}
   * @memberof PIAItemItemMedia
   */
  mediaRelNo?: string;
  /**
   *
   * @type {string}
   * @memberof PIAItemItemMedia
   */
  mediaNo?: string;
  /**
   *
   * @type {string}
   * @memberof PIAItemItemMedia
   */
  mediaTypeNo?: string;
  /**
   *
   * @type {string}
   * @memberof PIAItemItemMedia
   */
  mediaObjectNo?: string;
  /**
   *
   * @type {string}
   * @memberof PIAItemItemMedia
   */
  mediaTypeName?: string;
  /**
   *
   * @type {string}
   * @memberof PIAItemItemMedia
   */
  mediaName?: string;
  /**
   *
   * @type {number}
   * @memberof PIAItemItemMedia
   */
  sortNo?: number;
  /**
   *
   * @type {Array<PIAItemItemMediaMediaContent>}
   * @memberof PIAItemItemMedia
   */
  mediaContent?: Array<PIAItemItemMediaMediaContent>;
}

export function PIAItemItemMediaFromJSON(json: any): PIAItemItemMedia {
  return PIAItemItemMediaFromJSONTyped(json, false);
}

export function PIAItemItemMediaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PIAItemItemMedia {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    mediaRelNo: !exists(json, 'mediaRelNo') ? undefined : json['mediaRelNo'],
    mediaNo: !exists(json, 'mediaNo') ? undefined : json['mediaNo'],
    mediaTypeNo: !exists(json, 'mediaTypeNo') ? undefined : json['mediaTypeNo'],
    mediaObjectNo: !exists(json, 'mediaObjectNo') ? undefined : json['mediaObjectNo'],
    mediaTypeName: !exists(json, 'mediaTypeName') ? undefined : json['mediaTypeName'],
    mediaName: !exists(json, 'mediaName') ? undefined : json['mediaName'],
    sortNo: !exists(json, 'sortNo') ? undefined : json['sortNo'],
    mediaContent: !exists(json, 'mediaContent')
      ? undefined
      : (json['mediaContent'] as Array<any>).map(PIAItemItemMediaMediaContentFromJSON),
  };
}

export function PIAItemItemMediaToJSON(value?: PIAItemItemMedia | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    mediaRelNo: value.mediaRelNo,
    mediaNo: value.mediaNo,
    mediaTypeNo: value.mediaTypeNo,
    mediaObjectNo: value.mediaObjectNo,
    mediaTypeName: value.mediaTypeName,
    mediaName: value.mediaName,
    sortNo: value.sortNo,
    mediaContent:
      value.mediaContent === undefined
        ? undefined
        : (value.mediaContent as Array<any>).map(PIAItemItemMediaMediaContentToJSON),
  };
}
