/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface GetFeaturesResultRoomclassification
 */
export interface GetFeaturesResultRoomclassification {
  /**
   *
   * @type {string}
   * @memberof GetFeaturesResultRoomclassification
   */
  room_type: string;
  /**
   *
   * @type {number}
   * @memberof GetFeaturesResultRoomclassification
   */
  score: number;
}

export function GetFeaturesResultRoomclassificationFromJSON(
  json: any
): GetFeaturesResultRoomclassification {
  return GetFeaturesResultRoomclassificationFromJSONTyped(json, false);
}

export function GetFeaturesResultRoomclassificationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetFeaturesResultRoomclassification {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    room_type: json['room_type'],
    score: json['score'],
  };
}

export function GetFeaturesResultRoomclassificationToJSON(
  value?: GetFeaturesResultRoomclassification | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    room_type: value.room_type,
    score: value.score,
  };
}
