/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  CreateBynderJobResponseResult,
  CreateBynderJobResponseResultFromJSON,
  CreateBynderJobResponseResultFromJSONTyped,
  CreateBynderJobResponseResultToJSON,
} from './';

/**
 *
 * @export
 * @interface CreateBynderJobResponse
 */
export interface CreateBynderJobResponse {
  /**
   *
   * @type {string}
   * @memberof CreateBynderJobResponse
   */
  type: CreateBynderJobResponseTypeEnum;
  /**
   *
   * @type {Array<CreateBynderJobResponseResult>}
   * @memberof CreateBynderJobResponse
   */
  result: Array<CreateBynderJobResponseResult>;
}

/**
 * @export
 * @enum {string}
 */
export type CreateBynderJobResponseTypeEnum = 'create-bynder-job-response';

export function CreateBynderJobResponseFromJSON(json: any): CreateBynderJobResponse {
  return CreateBynderJobResponseFromJSONTyped(json, false);
}

export function CreateBynderJobResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateBynderJobResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    result: (json['result'] as Array<any>).map(CreateBynderJobResponseResultFromJSON),
  };
}

export function CreateBynderJobResponseToJSON(value?: CreateBynderJobResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    result: (value.result as Array<any>).map(CreateBynderJobResponseResultToJSON),
  };
}
