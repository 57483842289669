/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
// aaaa
// export enum IMCCImageAttributesCommonSeasonalActivity {
//
//
//     Back_to_college = 'Back to college',
//
//     Back_to_school = 'Back to school',
//
//     Refresh_Indoors__light_to_dark = 'Refresh Indoors (light to dark)',
//
//     Preparing_my_outdoor_space = 'Preparing my outdoor space',
//
//     Refresh_Indoors__dark_to_light = 'Refresh Indoors (dark to light)',
//
//     Enjoying_my_outdoor_space = 'Enjoying my outdoor space',
//
//     Celebrations = 'Celebrations'
//
//
// }

export type IMCCImageAttributesCommonSeasonalActivity =
  | 'Back to college'
  | 'Back to school'
  | 'Refresh Indoors (light to dark)'
  | 'Preparing my outdoor space'
  | 'Refresh Indoors (dark to light)'
  | 'Enjoying my outdoor space'
  | 'Celebrations';

export function IMCCImageAttributesCommonSeasonalActivityFromJSON(
  json: any
): IMCCImageAttributesCommonSeasonalActivity {
  return IMCCImageAttributesCommonSeasonalActivityFromJSONTyped(json, false);
}

export function IMCCImageAttributesCommonSeasonalActivityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCImageAttributesCommonSeasonalActivity {
  return json as IMCCImageAttributesCommonSeasonalActivity;
}

export function IMCCImageAttributesCommonSeasonalActivityToJSON(
  value?: IMCCImageAttributesCommonSeasonalActivity | null
): any {
  return value as any;
}
