import React, { useEffect, useState } from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import { Loadable } from './util/type';
import Button from '@ingka/button';
import Pill from '@ingka/pill';
import icon_arrow_cloud_out from '@ingka/ssr-icon/paths/arrow-cloud-out';
import icon_arrow_cloud_in from '@ingka/ssr-icon/paths/arrow-cloud-in';
import icon_copy from '@ingka/ssr-icon/paths/copy';
import icon_reload from '@ingka/ssr-icon/paths/arrow-counterclockwise';
import icon_globe from '@ingka/ssr-icon/paths/globe';
import icon_cross from '@ingka/ssr-icon/paths/cross';

import s from './Delivery.module.scss';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { CreatePackageBodyItem } from './generated-backend-api/models/CreatePackageBodyItem';
import { unreachable } from './util/common';
import { SelectableList, ISelectableItemProps } from './SelectableList';
import { useToasts } from './util/toastProvider';
import {
  FSAssetDev,
  FSAssetProd,
  IMCCDeliveryResult,
  SearchFSResult,
  UploadJobItem,
} from './generated-backend-api';
import { Section } from './components/section/section';
import { UploadsListLoadable } from './app-state';
import { UploadJobItemWithThumbnail } from './util/common-types';

type FSAssetNameMap = {
  [name: string]: FSAssetDev | FSAssetProd | undefined;
};

export interface IPackageFile {
  id: string;
  name: string;
  downloadUrl: string;
  extension: string;
  tags: string[];
  thumbnailUrl?: string;
}

export interface IDeliveryData {
  name: string;
  id: string;
  pkg?: unknown; // raw data, for debugging
  assets: IPackageFile[];
}

interface IDeliveryProps {
  deliveryId: string | undefined;
  deliveryName: string;
  deliveryFSAssets: Loadable<
    SearchFSResult,
    {
      deliveryId: string;
      phs: string[];
    }
  >;
  delivery?: Loadable<IMCCDeliveryResult, { id: string }>;
  uploadJobs: UploadsListLoadable;
  loadDelivery: (id: string, skipCache: boolean) => void;
  downloadPackage: (downloadables: CreatePackageBodyItem[]) => void;
  loadDeliveryFSAssets: (names: string[], deliveryId: string, skipCache: boolean) => void;
  prepareForUpload: (jobs: UploadJobItemWithThumbnail[]) => void;
}

interface ITagFilter {
  type: 'tag';
  name: string;
}

interface INotInDAMFilter {
  type: 'not-in-dam';
}

interface IInDAMFilter {
  type: 'in-dam';
}

interface IExtensionFilter {
  type: 'extension-filter';
  extension: string;
}

type Filter = ITagFilter | INotInDAMFilter | IInDAMFilter | IExtensionFilter;

const isActive = (packageFile: IPackageFile, assetNameMap: FSAssetNameMap, filters: Filter[]) => {
  for (const f of filters) {
    switch (f.type) {
      case 'tag':
        if (!packageFile.tags.includes(f.name)) {
          return false;
        }
        break;
      case 'in-dam': {
        const a = assetNameMap[packageFile.name];
        const inDam = Boolean(a);
        if (!inDam) {
          return false;
        }
        break;
      }
      case 'not-in-dam': {
        const a = assetNameMap[packageFile.name];
        const inDam = Boolean(a);
        if (inDam) {
          return false;
        }
        break;
      }
      case 'extension-filter': {
        if (packageFile.extension !== f.extension) {
          return false;
        }

        break;
      }

      default:
        unreachable(f);
    }
  }

  return true;
};

export const Delivery: React.FC<IDeliveryProps> = (props: IDeliveryProps) => {
  const {
    deliveryId,
    delivery,
    loadDelivery,
    downloadPackage,
    loadDeliveryFSAssets,
    deliveryFSAssets,
    prepareForUpload,
  } = props;
  const [activeFilters, setActiveFilters] = useState<Filter[]>([]);
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [disabledTags, setDisabledTags] = useState<Set<string>>(new Set());

  const [showDebug, setShowDebug] = useState(false);
  const toastApi = useToasts();

  useEffect(() => {
    if (typeof deliveryId !== 'string') {
      return;
    }
    if (!delivery || delivery.status === 'uninitialized' || delivery.id !== deliveryId) {
      loadDelivery(deliveryId, true);
    }
  });

  useEffect(() => {
    if (!delivery || delivery.status !== 'loaded') {
      return;
    }

    if (delivery.data.type !== 'delivery-response') {
      return;
    }

    if (
      deliveryFSAssets.status === 'uninitialized' ||
      deliveryFSAssets.deliveryId !== delivery.id
    ) {
      loadDeliveryFSAssets(
        delivery.data.assets.map((d) => d.name),
        delivery.id,
        false,
      );
    }
  });

  if (!delivery || delivery.status === 'uninitialized') {
    return null;
  }

  if (delivery.status === 'loading') {
    return (
      <Loading text={`Loading delivery ${props.deliveryName}.`}>
        <LoadingBall />
      </Loading>
    );
  }

  if (delivery.data.type !== 'delivery-response') {
    return <div>Could not load delivery: {delivery.data.type}</div>;
  }

  const deliveryIMCCAssets = delivery.data.assets;

  const filteredDeliveryAssets: IPackageFile[] = [];

  const inDAMTagName = 'In DAM';
  const notInDAMTagName = 'Not in DAM';

  const tagAggMap: Map<string, IPackageFile[]> = new Map<string, IPackageFile[]>();
  const tagAggMapAll: Map<string, IPackageFile[]> = new Map<string, IPackageFile[]>();

  const DAMStatus = {
    [inDAMTagName]: [] as IPackageFile[],
    [notInDAMTagName]: [] as IPackageFile[],
  };

  const assetNameMap: FSAssetNameMap = {};

  if (
    deliveryFSAssets.status === 'loaded' &&
    (deliveryFSAssets.data.type === 'fs-search-result-prod-response' ||
      deliveryFSAssets.data.type === 'fs-search-result-dev-response')
  ) {
    for (const a of deliveryFSAssets.data.results) {
      assetNameMap[a.name] = a;
    }
  }

  const extensions: Map<string, IPackageFile[]> = new Map<string, IPackageFile[]>();

  for (const a of deliveryIMCCAssets) {
    const active = isActive(a, assetNameMap, activeFilters);

    const asset = assetNameMap[a.name];

    let e = extensions.get(a.extension);

    if (!e) {
      e = [];
      extensions.set(a.extension, e);
    }

    if (active) {
      filteredDeliveryAssets.push(a);

      if (asset) {
        DAMStatus[inDAMTagName].push(a);
      } else {
        DAMStatus[notInDAMTagName].push(a);
      }

      e.push(a);
    }

    for (const tag of a.tags) {
      let e = tagAggMap.get(tag);

      if (!e) {
        e = [];
        tagAggMap.set(tag, e);
      }

      if (active) {
        e.push(a);
      }

      let q = tagAggMapAll.get(tag);
      if (!q) {
        q = [];
        tagAggMapAll.set(tag, q);
      }
      q.push(a);
    }
  }

  // toggles filters, isCurrent tests if a filter is the one beeing toggled and create is an instance of the new filter if not found.
  const toggleFilter = (isCurrent: (f: Filter) => boolean, create: Filter) => {
    const update: Filter[] = [];

    let exists = false;
    for (const f of activeFilters) {
      if (!isCurrent(f)) {
        update.push(f);
      } else {
        exists = true;
      }
    }

    if (!exists) {
      update.push(create);
    }
    setActiveFilters(update);
    setSelected(new Set());
  };

  // sort by unfiltered number of items in category
  const sortByTotalOrder = (a: [string, IPackageFile[]], b: [string, IPackageFile[]]) => {
    const b0 = tagAggMapAll.get(b[0]) ?? [];
    const a0 = tagAggMapAll.get(a[0]) ?? [];

    return b0.length - a0.length;
  };

  const activeTags = new Set();
  for (const f of activeFilters) {
    if (f.type === 'tag') {
      activeTags.add(f.name);
    }
  }

  const disabledTagsList = Array.from(tagAggMapAll.entries())
    .sort(sortByTotalOrder)
    .map(([tag, assets]) => {
      const lbl = (
        <>
          {tag} <span className={s['delivery-tag-label-count']}>{assets.length}</span>{' '}
        </>
      );

      return (
        <Pill
          small={true}
          label={lbl}
          key={tag}
          className={s['delivery-filter']}
          selected={disabledTags.has(tag)}
          onClick={() => {
            const current = Array.from(disabledTags);

            if (disabledTags.has(tag)) {
              setDisabledTags(new Set(current.filter((t) => t !== tag)));
            } else {
              setDisabledTags(new Set([...current, tag]));
            }
          }}
        />
      );
    });

  const tagAgg = Array.from(tagAggMap.entries())
    .filter(([tag, _]) => !disabledTags.has(tag))
    .sort(sortByTotalOrder)
    .map(([tag, assets]) => {
      const lbl = (
        <>
          {tag} <span className={s['delivery-tag-label-count']}>{assets.length}</span>
        </>
      );

      return (
        <Pill
          small={true}
          label={lbl}
          key={tag}
          className={s['delivery-filter']}
          disabled={assets.length === 0}
          selected={activeTags.has(tag)}
          onClick={() =>
            toggleFilter((t) => t.type === 'tag' && t.name === tag, { type: 'tag', name: tag })
          }
        />
      );
    });

  const extensionFilters = Array.from(extensions.entries())
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([ext, assets]) => {
      const lbl = (
        <>
          {ext === '' ? 'None' : ext}{' '}
          <span className={s['delivery-tag-label-count']}>{assets.length}</span>
        </>
      );

      return (
        <Pill
          small={true}
          label={lbl}
          key={ext}
          disabled={assets.length === 0}
          className={s['delivery-filter']}
          selected={
            activeFilters.findIndex((f) => f.type === 'extension-filter' && f.extension === ext) !==
            -1
          }
          onClick={() =>
            toggleFilter((t) => t.type === 'extension-filter' && t.extension === ext, {
              type: 'extension-filter',
              extension: ext,
            })
          }
        />
      );
    });

  const InDAMFiltersLbl = (
    <>
      {inDAMTagName}{' '}
      <span className={s['delivery-tag-label-count']}>{DAMStatus[inDAMTagName].length}</span>
    </>
  );

  const NotInDAMFiltersLbl = (
    <>
      {notInDAMTagName}{' '}
      <span className={s['delivery-tag-label-count']}>{DAMStatus[notInDAMTagName].length}</span>
    </>
  );

  const InDAMFilters = (
    <div>
      <Pill
        small={true}
        label={InDAMFiltersLbl}
        className={s['delivery-filter']}
        disabled={DAMStatus[inDAMTagName].length === 0}
        selected={activeFilters.findIndex((f) => f.type === 'in-dam') !== -1}
        onClick={() => toggleFilter((f) => f.type === 'in-dam', { type: 'in-dam' })}
      />
      <Pill
        small={true}
        label={NotInDAMFiltersLbl}
        className={s['delivery-filter']}
        disabled={DAMStatus[notInDAMTagName].length === 0}
        selected={activeFilters.findIndex((f) => f.type === 'not-in-dam') !== -1}
        onClick={() => toggleFilter((f) => f.type === 'not-in-dam', { type: 'not-in-dam' })}
      />
    </div>
  );

  const toggleAsset = (id: string) => {
    if (selected.has(id)) {
      setSelected(new Set([...selected].filter((i) => i !== id)));
    } else {
      setSelected(new Set([...selected, id]));
    }
  };

  const assets: ISelectableItemProps[] = filteredDeliveryAssets.map((a) => {
    const tags = a.tags
      .filter((at) => !disabledTags.has(at))
      .map((t, index) => {
        return (
          <Pill
            small={true}
            label={t}
            key={`${t}_${index}`}
            selected={activeTags.has(t)}
            autoFocus={false}
            onClick={() =>
              toggleFilter((q) => q.type === 'tag' && q.name === t, { type: 'tag', name: t })
            }
          />
        );
      });

    let publishStatus;
    const data = assetNameMap[a.name];

    if (data && data.isNetstorePublic === 1 && data.isPublic === 1) {
      publishStatus = (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '10px',
          }}
        >
          <Button
            iconOnly={true}
            ssrIcon={icon_globe}
            type={'tertiary'}
            small={true}
            disabled={typeof data.original !== 'undefined'}
            href={data.original}
            newWindow={true}
            title={'Bynder original link'}
          />
        </div>
      );
    }

    const label = a.extension !== '' ? `${a.name}.${a.extension}` : a.name;

    const item = <div className={s['delivery-tags']}>{tags}</div>;

    return {
      id: a.id,
      label,
      name: a.name,
      onChange: () => toggleAsset(a.id),
      selected: selected.has(a.id),
      item,
      afterLabel: publishStatus,
      imageUrl: a.thumbnailUrl,
    };
  });

  const allSelected = filteredDeliveryAssets.every((a) => selected.has(a.id));
  const onSelectAllToggle = () => {
    if (allSelected) {
      setSelected(new Set());
    } else {
      const selectedList = [...filteredDeliveryAssets.map((a) => a.id)];

      setSelected(new Set(selectedList));
    }
  };

  const downloadSelected = () => {
    const selectedDownloadables: CreatePackageBodyItem[] = deliveryIMCCAssets
      .filter((f) => selected.has(f.id))
      .map((s) => ({
        id: s.id,
        name: s.extension !== '' ? `${s.name}.${s.extension}` : s.name,
        url: s.downloadUrl,
      }));

    downloadPackage(selectedDownloadables);
  };

  const onPrepareForUpload = () => {
    const selectedDownloadables: (UploadJobItem & { thumbnailUrl?: string })[] = deliveryIMCCAssets
      .filter((f) => selected.has(f.id))
      .map((s) => ({
        type: 'upload-job',
        name: s.name,
        extension: s.extension,
        url: s.downloadUrl,
        thumbnailUrl: String(s.thumbnailUrl),
      }));

    prepareForUpload(selectedDownloadables);
  };

  const onCopyPHNumbers = () => {
    const fileNames = assets.filter(({ selected }) => selected).map(({ name }) => name);
    void navigator.clipboard.writeText(fileNames.join('\r\n'));
    const isError = false;
    const message = `Copied ${fileNames.length} file names to clipboard`;
    toastApi.push({ isError, message });
  };

  return (
    <>
      <Section>
        <h2>
          {delivery.data.name} ({delivery.data.assets.length} assets)
        </h2>
      </Section>

      <Button
        type="secondary"
        iconPosition="leading"
        ssrIcon={icon_reload}
        loading={deliveryFSAssets.status === 'loading'}
        onClick={() =>
          loadDeliveryFSAssets(
            deliveryIMCCAssets.map((d) => d.name),
            delivery.id,
            true,
          )
        }
        text="Refresh dam asset data"
      />

      <div className={s['delivery-filtering-section-wrap']}>
        <h3>Disable Tags </h3>
        <p>Selected tags will be removed from the filtering options below.</p>
        <div className={s['delivery-filter-section']}>{disabledTagsList}</div>

        <h3>DAM</h3>
        <p>Filter assets by existance in DAM.</p>
        <div className={s['delivery-filter-section']}>{InDAMFilters}</div>

        <h3>Extension</h3>
        <p>Filter assets by file extension.</p>
        <div className={s['delivery-filter-section']}>{extensionFilters}</div>

        <h3>Tags </h3>
        <p>Filter assets by tags.</p>
        <div className={s['delivery-filter-section']}>{tagAgg}</div>

        <h3>Active Filters </h3>
        <p>Currently active filters.</p>
        <div className={s['delivery-filter-section']}>
          {activeFilters.map((f) => {
            let key;
            let name;
            if (f.type === 'tag') {
              key = `${f.type}-${f.name}`;
              name = f.name;
            } else if (f.type === 'extension-filter') {
              key = `${f.type}-${f.extension}`;
              name = f.extension;

              // beeing explicit below
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            } else if (f.type === 'in-dam' || f.type === 'not-in-dam') {
              key = `${f.type}`;
              name = key;
            } else {
              unreachable(f);
            }
            return <Pill small={true} label={name} key={key} className={s['delivery-filter']} />;
          })}
          <div>
            <Button
              small={true}
              ssrIcon={icon_cross}
              text={`Clear all filters (${activeFilters.length})`}
              disabled={activeFilters.length === 0}
              onClick={() => {
                setActiveFilters([]);
                setSelected(new Set());
              }}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          margin-bottom: 2rem;
        `}
      >
        <Button
          type="primary"
          ssrIcon={icon_arrow_cloud_out}
          iconPosition={'leading'}
          disabled={selected.size === 0}
          onClick={downloadSelected}
          css={css`
            margin-right: 10px;
          `}
        >
          Download {selected.size} assets.
        </Button>

        <Button
          type="primary"
          ssrIcon={icon_arrow_cloud_in}
          iconPosition={'leading'}
          disabled={selected.size === 0}
          onClick={onPrepareForUpload}
          css={css`
            margin-right: 10px;
          `}
        >
          Prepare {selected.size} assets for upload.
        </Button>
        <Button
          type="primary"
          ssrIcon={icon_copy}
          iconPosition={'leading'}
          disabled={selected.size === 0}
          text={`Copy PH numbers to clipboard.`}
          onClick={onCopyPHNumbers}
        />
      </div>

      <SelectableList
        items={assets}
        onSelectAll={onSelectAllToggle}
        localStorageKey={'Delivery_selectableList'}
      />

      <Button onClick={() => setShowDebug(!showDebug)}>Show full response</Button>
      <pre style={{ display: showDebug ? 'block' : 'none' }}>
        {JSON.stringify(delivery, null, 4)}
      </pre>
    </>
  );
};
