/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface CreatePackageBodyItem
 */
export interface CreatePackageBodyItem {
  /**
   *
   * @type {string}
   * @memberof CreatePackageBodyItem
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreatePackageBodyItem
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CreatePackageBodyItem
   */
  url: string;
}

export function CreatePackageBodyItemFromJSON(json: any): CreatePackageBodyItem {
  return CreatePackageBodyItemFromJSONTyped(json, false);
}

export function CreatePackageBodyItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreatePackageBodyItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    id: json['id'],
    url: json['url'],
  };
}

export function CreatePackageBodyItemToJSON(value?: CreatePackageBodyItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    id: value.id,
    url: value.url,
  };
}
