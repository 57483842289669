/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IMCCImageAttributes,
  IMCCImageAttributesFromJSON,
  IMCCImageAttributesFromJSONTyped,
  IMCCImageAttributesToJSON,
  IMCCImageResponseDataLinks,
  IMCCImageResponseDataLinksFromJSON,
  IMCCImageResponseDataLinksFromJSONTyped,
  IMCCImageResponseDataLinksToJSON,
} from './';

/**
 *
 * @export
 * @interface IMCCImageResponseData
 */
export interface IMCCImageResponseData {
  /**
   *
   * @type {string}
   * @memberof IMCCImageResponseData
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof IMCCImageResponseData
   */
  id: string;
  /**
   *
   * @type {IMCCImageAttributes}
   * @memberof IMCCImageResponseData
   */
  attributes: IMCCImageAttributes;
  /**
   *
   * @type {IMCCImageResponseDataLinks}
   * @memberof IMCCImageResponseData
   */
  links: IMCCImageResponseDataLinks;
}

export function IMCCImageResponseDataFromJSON(json: any): IMCCImageResponseData {
  return IMCCImageResponseDataFromJSONTyped(json, false);
}

export function IMCCImageResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCImageResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    id: json['id'],
    attributes: IMCCImageAttributesFromJSON(json['attributes']),
    links: IMCCImageResponseDataLinksFromJSON(json['links']),
  };
}

export function IMCCImageResponseDataToJSON(value?: IMCCImageResponseData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    id: value.id,
    attributes: IMCCImageAttributesToJSON(value.attributes),
    links: IMCCImageResponseDataLinksToJSON(value.links),
  };
}
