import React, { useState } from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import {
  BynderAssetUploadJob,
  BynderAssetJobRetryResult,
  MetaProperty,
  BynderAssetEditJob,
} from '../generated-backend-api';
import { constructBynderAssetUrl } from '../util/bynder-api';
import { formatDate } from '../util/common';
import WarningTriangleIcon from '@ingka/ssr-icon/paths/warning-triangle';
import Icon from '@ingka/icon';
import { Loadable } from '../util/type';
import Button from '@ingka/button';
import { JobItemDetails } from './job-item-details';

interface JobListItemProps {
  job: BynderAssetUploadJob | BynderAssetEditJob;
  bynderBaseUrl: string;
  propMap: Map<string, MetaProperty>;
  retryJob?: (job: BynderAssetUploadJob) => void;
  retryResult?: Loadable<BynderAssetJobRetryResult>;
}

function getLastMessage(j: BynderAssetUploadJob | BynderAssetEditJob) {
  return j.messages.sort((m1, m2) => m1.timestamp - m2.timestamp)[j.messages.length - 1];
}

function isUploadJob(job: BynderAssetEditJob | BynderAssetUploadJob): job is BynderAssetUploadJob {
  return job.item.type === 'upload-job';
}

export function JobListItem(props: JobListItemProps): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);

  const { job, bynderBaseUrl, retryJob, propMap } = props;

  const lastMessage = getLastMessage(job);

  const isError = lastMessage.type === 'job-failed';

  let messageList;
  if (isExpanded) {
    const messages = job.messages.map((m) => {
      const { timestamp, type, ...rest } = m;

      return (
        <div key={`${timestamp}-${type}`} style={{ marginBottom: '20px', cursor: 'pointer' }}>
          {formatDate(timestamp)} {type}
          <pre>{JSON.stringify(rest, null, 4)}</pre>
        </div>
      );
    });

    const details = <JobItemDetails job={job} propMap={propMap} />;

    messageList = (
      <div style={{ marginLeft: '50px' }}>
        <h6
          css={css`
            margin-bottom: 10px;
          `}
        >
          Details
        </h6>
        {details}
        <hr />
        <h6
          css={css`
            margin-bottom: 10px;
          `}
        >
          Messages
        </h6>
        {messages}
      </div>
    );
  }

  const saveInBynderMessage = job.messages.find((m) => m.type === 'job-save-in-bynder-completed');
  const completedMessage = job.messages.find((m) => m.type === 'job-completed');

  let bynderLink;
  if (completedMessage && saveInBynderMessage?.type === 'job-save-in-bynder-completed') {
    bynderLink = (
      <span>
        <a
          href={constructBynderAssetUrl(bynderBaseUrl, saveInBynderMessage.bynderId)}
          target="_blank"
          rel="noreferrer"
        >
          link
        </a>
      </span>
    );
  } else if (job.item.type === 'edit-job') {
    bynderLink = (
      <span>
        <a
          href={constructBynderAssetUrl(bynderBaseUrl, job.item.id)}
          target="_blank"
          rel="noreferrer"
        >
          link
        </a>
      </span>
    );
  }

  let retry;
  if (
    ((job.attempts === job.maxAttempts && isError) ||
      (new Date().getTime() - job.lastUpdate > 1000 * 60 * 30 && !completedMessage)) &&
    retryJob &&
    isUploadJob(job)
  ) {
    retry = (
      <span>
        <Button
          small={true}
          text="Retry"
          loading={props.retryResult?.status === 'loading'}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            retryJob(job);
          }}
          type={'secondary'}
        />
      </span>
    );
  }

  return (
    <div>
      <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
        <span onClick={() => setIsExpanded(!isExpanded)} style={{ cursor: 'pointer' }}>
          <span style={{ visibility: isError ? 'visible' : 'hidden' }}>
            <Icon paths={WarningTriangleIcon} />
          </span>
          <span
            css={css`
              display: inline-block;
              margin-right: 15px;
            `}
          >
            {formatDate(job.created)}
          </span>
          <span
            css={css`
              display: inline-block;
              margin-right: 15px;
              min-width: 200px;
            `}
          >
            {job.item.name}
          </span>
          <span
            css={css`
              display: inline-block;
              margin-right: 15px;
              min-width: 200px;
            `}
          >
            {job.user.email}
          </span>
          <span
            css={css`
              display: inline-block;
              margin-right: 15px;
            `}
          >
            {lastMessage.type}
          </span>
          {retry}
        </span>
        {bynderLink}
      </div>
      {messageList}
    </div>
  );
}
