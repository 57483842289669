/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
// aaaa
// export enum IMCCImageAttributesOrientation {
//
//
//     portrait = 'portrait',
//
//     landscape = 'landscape',
//
//     square = 'square'
//
//
// }

export type IMCCImageAttributesOrientation = 'portrait' | 'landscape' | 'square';

export function IMCCImageAttributesOrientationFromJSON(json: any): IMCCImageAttributesOrientation {
  return IMCCImageAttributesOrientationFromJSONTyped(json, false);
}

export function IMCCImageAttributesOrientationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCImageAttributesOrientation {
  return json as IMCCImageAttributesOrientation;
}

export function IMCCImageAttributesOrientationToJSON(
  value?: IMCCImageAttributesOrientation | null
): any {
  return value as any;
}
