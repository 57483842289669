/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  AssetActiveOriginalFocusPoint,
  AssetActiveOriginalFocusPointFromJSON,
  AssetActiveOriginalFocusPointFromJSONTyped,
  AssetActiveOriginalFocusPointToJSON,
  AssetProductInformation,
  AssetProductInformationFromJSON,
  AssetProductInformationFromJSONTyped,
  AssetProductInformationToJSON,
  AssetPropertyBriefName,
  AssetPropertyBriefNameFromJSON,
  AssetPropertyBriefNameFromJSONTyped,
  AssetPropertyBriefNameToJSON,
  AssetPropertyCampaignsDeliveries,
  AssetPropertyCampaignsDeliveriesFromJSON,
  AssetPropertyCampaignsDeliveriesFromJSONTyped,
  AssetPropertyCampaignsDeliveriesToJSON,
  AssetPropertyChannel,
  AssetPropertyChannelFromJSON,
  AssetPropertyChannelFromJSONTyped,
  AssetPropertyChannelToJSON,
  AssetPropertyContentType,
  AssetPropertyContentTypeFromJSON,
  AssetPropertyContentTypeFromJSONTyped,
  AssetPropertyContentTypeToJSON,
  AssetPropertyCopyright,
  AssetPropertyCopyrightFromJSON,
  AssetPropertyCopyrightFromJSONTyped,
  AssetPropertyCopyrightToJSON,
  AssetPropertyCreatingMarket,
  AssetPropertyCreatingMarketFromJSON,
  AssetPropertyCreatingMarketFromJSONTyped,
  AssetPropertyCreatingMarketToJSON,
  AssetPropertyCropInfo,
  AssetPropertyCropInfoFromJSON,
  AssetPropertyCropInfoFromJSONTyped,
  AssetPropertyCropInfoToJSON,
  AssetPropertyDisclaimer,
  AssetPropertyDisclaimerFromJSON,
  AssetPropertyDisclaimerFromJSONTyped,
  AssetPropertyDisclaimerToJSON,
  AssetPropertyDominatingColour,
  AssetPropertyDominatingColourFromJSON,
  AssetPropertyDominatingColourFromJSONTyped,
  AssetPropertyDominatingColourToJSON,
  AssetPropertyIcomProjectName,
  AssetPropertyIcomProjectNameFromJSON,
  AssetPropertyIcomProjectNameFromJSONTyped,
  AssetPropertyIcomProjectNameToJSON,
  AssetPropertyInputMaterials,
  AssetPropertyInputMaterialsFromJSON,
  AssetPropertyInputMaterialsFromJSONTyped,
  AssetPropertyInputMaterialsToJSON,
  AssetPropertyLanguage,
  AssetPropertyLanguageFromJSON,
  AssetPropertyLanguageFromJSONTyped,
  AssetPropertyLanguageToJSON,
  AssetPropertyLaunch,
  AssetPropertyLaunchFromJSON,
  AssetPropertyLaunchFromJSONTyped,
  AssetPropertyLaunchToJSON,
  AssetPropertyLayoutID,
  AssetPropertyLayoutIDFromJSON,
  AssetPropertyLayoutIDFromJSONTyped,
  AssetPropertyLayoutIDToJSON,
  AssetPropertyMarkets,
  AssetPropertyMarketsFromJSON,
  AssetPropertyMarketsFromJSONTyped,
  AssetPropertyMarketsToJSON,
  AssetPropertyNegotiatedUsageRights,
  AssetPropertyNegotiatedUsageRightsFromJSON,
  AssetPropertyNegotiatedUsageRightsFromJSONTyped,
  AssetPropertyNegotiatedUsageRightsToJSON,
  AssetPropertyPlanningInput,
  AssetPropertyPlanningInputFromJSON,
  AssetPropertyPlanningInputFromJSONTyped,
  AssetPropertyPlanningInputToJSON,
  AssetPropertyProductCoordinates,
  AssetPropertyProductCoordinatesFromJSON,
  AssetPropertyProductCoordinatesFromJSONTyped,
  AssetPropertyProductCoordinatesToJSON,
  AssetPropertyRoom,
  AssetPropertyRoomFromJSON,
  AssetPropertyRoomFromJSONTyped,
  AssetPropertyRoomToJSON,
  AssetPropertySectionofChannel,
  AssetPropertySectionofChannelFromJSON,
  AssetPropertySectionofChannelFromJSONTyped,
  AssetPropertySectionofChannelToJSON,
  AssetPropertyStyle,
  AssetPropertyStyleFromJSON,
  AssetPropertyStyleFromJSONTyped,
  AssetPropertyStyleToJSON,
  AssetPropertyTheme,
  AssetPropertyThemeFromJSON,
  AssetPropertyThemeFromJSONTyped,
  AssetPropertyThemeToJSON,
  AssetThumbnails,
  AssetThumbnailsFromJSON,
  AssetThumbnailsFromJSONTyped,
  AssetThumbnailsToJSON,
  IMAsset,
  IMAssetFromJSON,
  IMAssetFromJSONTyped,
  IMAssetToJSON,
  RangePrioritization,
  RangePrioritizationFromJSON,
  RangePrioritizationFromJSONTyped,
  RangePrioritizationToJSON,
  VIAssetMetaData,
  VIAssetMetaDataFromJSON,
  VIAssetMetaDataFromJSONTyped,
  VIAssetMetaDataToJSON,
} from './';

/**
 *
 * @export
 * @interface Asset
 */
export interface Asset {
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  copyright: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  idHash: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  brandId: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  orientation: string;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  watermarked: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  isPublic: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  isShoppable: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  downloads: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  views: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  width: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  limited: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  height: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  archive: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  fileSize: number;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  datePublished: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  dateCreated: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  dateModified: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Asset
   */
  extension: Array<string>;
  /**
   *
   * @type {AssetThumbnails}
   * @memberof Asset
   */
  thumbnails: AssetThumbnails;
  /**
   *
   * @type {AssetActiveOriginalFocusPoint}
   * @memberof Asset
   */
  activeOriginalFocusPoint: AssetActiveOriginalFocusPoint;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  aspectRatio?: string;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  exactAspectRatio?: number;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  original?: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  property_EmbargoEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  property_ReleaseDate?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Asset
   */
  tags?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  property_ALTText?: string;
  /**
   *
   * @type {Array<AssetPropertyInputMaterials>}
   * @memberof Asset
   */
  property_InputMaterials?: Array<AssetPropertyInputMaterials>;
  /**
   *
   * @type {Array<AssetPropertyProductCoordinates>}
   * @memberof Asset
   */
  property_product_coordinates?: Array<AssetPropertyProductCoordinates>;
  /**
   *
   * @type {Array<AssetPropertySectionofChannel>}
   * @memberof Asset
   */
  property_SectionofChannel?: Array<AssetPropertySectionofChannel>;
  /**
   *
   * @type {Array<AssetPropertyStyle>}
   * @memberof Asset
   */
  property_Style?: Array<AssetPropertyStyle>;
  /**
   *
   * @type {Array<AssetPropertyDominatingColour>}
   * @memberof Asset
   */
  property_DominatingColour?: Array<AssetPropertyDominatingColour>;
  /**
   *
   * @type {Array<AssetPropertyChannel>}
   * @memberof Asset
   */
  property_Channel?: Array<AssetPropertyChannel>;
  /**
   *
   * @type {Array<AssetPropertyPlanningInput>}
   * @memberof Asset
   */
  property_PlanningInput?: Array<AssetPropertyPlanningInput>;
  /**
   *
   * @type {Array<AssetPropertyCropInfo>}
   * @memberof Asset
   */
  property_crop_info?: Array<AssetPropertyCropInfo>;
  /**
   *
   * @type {Array<AssetPropertyCopyright>}
   * @memberof Asset
   */
  property_Copyright?: Array<AssetPropertyCopyright>;
  /**
   *
   * @type {Array<AssetPropertyDisclaimer>}
   * @memberof Asset
   */
  property_Disclaimer?: Array<AssetPropertyDisclaimer>;
  /**
   *
   * @type {Array<AssetPropertyCreatingMarket>}
   * @memberof Asset
   */
  property_Creating_Market?: Array<AssetPropertyCreatingMarket>;
  /**
   *
   * @type {Array<AssetPropertyLanguage>}
   * @memberof Asset
   */
  property_Language?: Array<AssetPropertyLanguage>;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  launch?: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  FY?: string;
  /**
   *
   * @type {Array<AssetPropertyLaunch>}
   * @memberof Asset
   */
  property_Launch?: Array<AssetPropertyLaunch>;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  property_EndDateNUR?: string;
  /**
   *
   * @type {Array<AssetPropertyRoom>}
   * @memberof Asset
   */
  property_room?: Array<AssetPropertyRoom>;
  /**
   *
   * @type {Array<AssetPropertyMarkets>}
   * @memberof Asset
   */
  property_Markets?: Array<AssetPropertyMarkets>;
  /**
   *
   * @type {Array<AssetPropertyCampaignsDeliveries>}
   * @memberof Asset
   */
  property_CampaignsDeliveries?: Array<AssetPropertyCampaignsDeliveries>;
  /**
   *
   * @type {Array<AssetPropertyNegotiatedUsageRights>}
   * @memberof Asset
   */
  property_NegotiatedUsageRights?: Array<AssetPropertyNegotiatedUsageRights>;
  /**
   *
   * @type {Array<AssetPropertyTheme>}
   * @memberof Asset
   */
  property_Theme?: Array<AssetPropertyTheme>;
  /**
   *
   * @type {Array<AssetPropertyLayoutID>}
   * @memberof Asset
   */
  property_LayoutID?: Array<AssetPropertyLayoutID>;
  /**
   *
   * @type {Array<AssetPropertyBriefName>}
   * @memberof Asset
   */
  property_BriefName?: Array<AssetPropertyBriefName>;
  /**
   *
   * @type {Array<AssetPropertyContentType>}
   * @memberof Asset
   */
  property_ContentType?: Array<AssetPropertyContentType>;
  /**
   *
   * @type {Array<AssetPropertyIcomProjectName>}
   * @memberof Asset
   */
  property_icom_project_name?: Array<AssetPropertyIcomProjectName>;
  /**
   *
   * @type {Array<AssetProductInformation>}
   * @memberof Asset
   */
  productInformation?: Array<AssetProductInformation>;
  /**
   *
   * @type {IMAsset}
   * @memberof Asset
   */
  imAsset?: IMAsset;
  /**
   *
   * @type {RangePrioritization}
   * @memberof Asset
   */
  rangePrioritization?: RangePrioritization;
  /**
   *
   * @type {VIAssetMetaData}
   * @memberof Asset
   */
  visualSearch?: VIAssetMetaData;
  /**
   *
   * @type {Array<string>}
   * @memberof Asset
   */
  relatedAssets?: Array<string>;
}

export function AssetFromJSON(json: any): Asset {
  return AssetFromJSONTyped(json, false);
}

export function AssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Asset {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    copyright: json['copyright'],
    type: json['type'],
    idHash: json['idHash'],
    brandId: json['brandId'],
    description: json['description'],
    orientation: json['orientation'],
    watermarked: json['watermarked'],
    isPublic: json['isPublic'],
    isShoppable: json['isShoppable'],
    downloads: json['downloads'],
    views: json['views'],
    width: json['width'],
    limited: json['limited'],
    height: json['height'],
    archive: json['archive'],
    fileSize: json['fileSize'],
    datePublished: json['datePublished'],
    dateCreated: json['dateCreated'],
    dateModified: json['dateModified'],
    extension: json['extension'],
    thumbnails: AssetThumbnailsFromJSON(json['thumbnails']),
    activeOriginalFocusPoint: AssetActiveOriginalFocusPointFromJSON(
      json['activeOriginalFocusPoint']
    ),
    aspectRatio: !exists(json, 'aspectRatio') ? undefined : json['aspectRatio'],
    exactAspectRatio: !exists(json, 'exactAspectRatio') ? undefined : json['exactAspectRatio'],
    original: !exists(json, 'original') ? undefined : json['original'],
    property_EmbargoEndDate: !exists(json, 'property_EmbargoEndDate')
      ? undefined
      : json['property_EmbargoEndDate'],
    property_ReleaseDate: !exists(json, 'property_ReleaseDate')
      ? undefined
      : json['property_ReleaseDate'],
    tags: !exists(json, 'tags') ? undefined : json['tags'],
    property_ALTText: !exists(json, 'property_ALTText') ? undefined : json['property_ALTText'],
    property_InputMaterials: !exists(json, 'property_InputMaterials')
      ? undefined
      : (json['property_InputMaterials'] as Array<any>).map(AssetPropertyInputMaterialsFromJSON),
    property_product_coordinates: !exists(json, 'property_product_coordinates')
      ? undefined
      : (json['property_product_coordinates'] as Array<any>).map(
          AssetPropertyProductCoordinatesFromJSON
        ),
    property_SectionofChannel: !exists(json, 'property_SectionofChannel')
      ? undefined
      : (json['property_SectionofChannel'] as Array<any>).map(
          AssetPropertySectionofChannelFromJSON
        ),
    property_Style: !exists(json, 'property_Style')
      ? undefined
      : (json['property_Style'] as Array<any>).map(AssetPropertyStyleFromJSON),
    property_DominatingColour: !exists(json, 'property_DominatingColour')
      ? undefined
      : (json['property_DominatingColour'] as Array<any>).map(
          AssetPropertyDominatingColourFromJSON
        ),
    property_Channel: !exists(json, 'property_Channel')
      ? undefined
      : (json['property_Channel'] as Array<any>).map(AssetPropertyChannelFromJSON),
    property_PlanningInput: !exists(json, 'property_PlanningInput')
      ? undefined
      : (json['property_PlanningInput'] as Array<any>).map(AssetPropertyPlanningInputFromJSON),
    property_crop_info: !exists(json, 'property_crop_info')
      ? undefined
      : (json['property_crop_info'] as Array<any>).map(AssetPropertyCropInfoFromJSON),
    property_Copyright: !exists(json, 'property_Copyright')
      ? undefined
      : (json['property_Copyright'] as Array<any>).map(AssetPropertyCopyrightFromJSON),
    property_Disclaimer: !exists(json, 'property_Disclaimer')
      ? undefined
      : (json['property_Disclaimer'] as Array<any>).map(AssetPropertyDisclaimerFromJSON),
    property_Creating_Market: !exists(json, 'property_Creating_Market')
      ? undefined
      : (json['property_Creating_Market'] as Array<any>).map(AssetPropertyCreatingMarketFromJSON),
    property_Language: !exists(json, 'property_Language')
      ? undefined
      : (json['property_Language'] as Array<any>).map(AssetPropertyLanguageFromJSON),
    launch: !exists(json, 'launch') ? undefined : json['launch'],
    FY: !exists(json, 'FY') ? undefined : json['FY'],
    property_Launch: !exists(json, 'property_Launch')
      ? undefined
      : (json['property_Launch'] as Array<any>).map(AssetPropertyLaunchFromJSON),
    property_EndDateNUR: !exists(json, 'property_EndDateNUR')
      ? undefined
      : json['property_EndDateNUR'],
    property_room: !exists(json, 'property_room')
      ? undefined
      : (json['property_room'] as Array<any>).map(AssetPropertyRoomFromJSON),
    property_Markets: !exists(json, 'property_Markets')
      ? undefined
      : (json['property_Markets'] as Array<any>).map(AssetPropertyMarketsFromJSON),
    property_CampaignsDeliveries: !exists(json, 'property_CampaignsDeliveries')
      ? undefined
      : (json['property_CampaignsDeliveries'] as Array<any>).map(
          AssetPropertyCampaignsDeliveriesFromJSON
        ),
    property_NegotiatedUsageRights: !exists(json, 'property_NegotiatedUsageRights')
      ? undefined
      : (json['property_NegotiatedUsageRights'] as Array<any>).map(
          AssetPropertyNegotiatedUsageRightsFromJSON
        ),
    property_Theme: !exists(json, 'property_Theme')
      ? undefined
      : (json['property_Theme'] as Array<any>).map(AssetPropertyThemeFromJSON),
    property_LayoutID: !exists(json, 'property_LayoutID')
      ? undefined
      : (json['property_LayoutID'] as Array<any>).map(AssetPropertyLayoutIDFromJSON),
    property_BriefName: !exists(json, 'property_BriefName')
      ? undefined
      : (json['property_BriefName'] as Array<any>).map(AssetPropertyBriefNameFromJSON),
    property_ContentType: !exists(json, 'property_ContentType')
      ? undefined
      : (json['property_ContentType'] as Array<any>).map(AssetPropertyContentTypeFromJSON),
    property_icom_project_name: !exists(json, 'property_icom_project_name')
      ? undefined
      : (json['property_icom_project_name'] as Array<any>).map(
          AssetPropertyIcomProjectNameFromJSON
        ),
    productInformation: !exists(json, 'productInformation')
      ? undefined
      : (json['productInformation'] as Array<any>).map(AssetProductInformationFromJSON),
    imAsset: !exists(json, 'imAsset') ? undefined : IMAssetFromJSON(json['imAsset']),
    rangePrioritization: !exists(json, 'rangePrioritization')
      ? undefined
      : RangePrioritizationFromJSON(json['rangePrioritization']),
    visualSearch: !exists(json, 'visualSearch')
      ? undefined
      : VIAssetMetaDataFromJSON(json['visualSearch']),
    relatedAssets: !exists(json, 'relatedAssets') ? undefined : json['relatedAssets'],
  };
}

export function AssetToJSON(value?: Asset | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    copyright: value.copyright,
    type: value.type,
    idHash: value.idHash,
    brandId: value.brandId,
    description: value.description,
    orientation: value.orientation,
    watermarked: value.watermarked,
    isPublic: value.isPublic,
    isShoppable: value.isShoppable,
    downloads: value.downloads,
    views: value.views,
    width: value.width,
    limited: value.limited,
    height: value.height,
    archive: value.archive,
    fileSize: value.fileSize,
    datePublished: value.datePublished,
    dateCreated: value.dateCreated,
    dateModified: value.dateModified,
    extension: value.extension,
    thumbnails: AssetThumbnailsToJSON(value.thumbnails),
    activeOriginalFocusPoint: AssetActiveOriginalFocusPointToJSON(value.activeOriginalFocusPoint),
    aspectRatio: value.aspectRatio,
    exactAspectRatio: value.exactAspectRatio,
    original: value.original,
    property_EmbargoEndDate: value.property_EmbargoEndDate,
    property_ReleaseDate: value.property_ReleaseDate,
    tags: value.tags,
    property_ALTText: value.property_ALTText,
    property_InputMaterials:
      value.property_InputMaterials === undefined
        ? undefined
        : (value.property_InputMaterials as Array<any>).map(AssetPropertyInputMaterialsToJSON),
    property_product_coordinates:
      value.property_product_coordinates === undefined
        ? undefined
        : (value.property_product_coordinates as Array<any>).map(
            AssetPropertyProductCoordinatesToJSON
          ),
    property_SectionofChannel:
      value.property_SectionofChannel === undefined
        ? undefined
        : (value.property_SectionofChannel as Array<any>).map(AssetPropertySectionofChannelToJSON),
    property_Style:
      value.property_Style === undefined
        ? undefined
        : (value.property_Style as Array<any>).map(AssetPropertyStyleToJSON),
    property_DominatingColour:
      value.property_DominatingColour === undefined
        ? undefined
        : (value.property_DominatingColour as Array<any>).map(AssetPropertyDominatingColourToJSON),
    property_Channel:
      value.property_Channel === undefined
        ? undefined
        : (value.property_Channel as Array<any>).map(AssetPropertyChannelToJSON),
    property_PlanningInput:
      value.property_PlanningInput === undefined
        ? undefined
        : (value.property_PlanningInput as Array<any>).map(AssetPropertyPlanningInputToJSON),
    property_crop_info:
      value.property_crop_info === undefined
        ? undefined
        : (value.property_crop_info as Array<any>).map(AssetPropertyCropInfoToJSON),
    property_Copyright:
      value.property_Copyright === undefined
        ? undefined
        : (value.property_Copyright as Array<any>).map(AssetPropertyCopyrightToJSON),
    property_Disclaimer:
      value.property_Disclaimer === undefined
        ? undefined
        : (value.property_Disclaimer as Array<any>).map(AssetPropertyDisclaimerToJSON),
    property_Creating_Market:
      value.property_Creating_Market === undefined
        ? undefined
        : (value.property_Creating_Market as Array<any>).map(AssetPropertyCreatingMarketToJSON),
    property_Language:
      value.property_Language === undefined
        ? undefined
        : (value.property_Language as Array<any>).map(AssetPropertyLanguageToJSON),
    launch: value.launch,
    FY: value.FY,
    property_Launch:
      value.property_Launch === undefined
        ? undefined
        : (value.property_Launch as Array<any>).map(AssetPropertyLaunchToJSON),
    property_EndDateNUR: value.property_EndDateNUR,
    property_room:
      value.property_room === undefined
        ? undefined
        : (value.property_room as Array<any>).map(AssetPropertyRoomToJSON),
    property_Markets:
      value.property_Markets === undefined
        ? undefined
        : (value.property_Markets as Array<any>).map(AssetPropertyMarketsToJSON),
    property_CampaignsDeliveries:
      value.property_CampaignsDeliveries === undefined
        ? undefined
        : (value.property_CampaignsDeliveries as Array<any>).map(
            AssetPropertyCampaignsDeliveriesToJSON
          ),
    property_NegotiatedUsageRights:
      value.property_NegotiatedUsageRights === undefined
        ? undefined
        : (value.property_NegotiatedUsageRights as Array<any>).map(
            AssetPropertyNegotiatedUsageRightsToJSON
          ),
    property_Theme:
      value.property_Theme === undefined
        ? undefined
        : (value.property_Theme as Array<any>).map(AssetPropertyThemeToJSON),
    property_LayoutID:
      value.property_LayoutID === undefined
        ? undefined
        : (value.property_LayoutID as Array<any>).map(AssetPropertyLayoutIDToJSON),
    property_BriefName:
      value.property_BriefName === undefined
        ? undefined
        : (value.property_BriefName as Array<any>).map(AssetPropertyBriefNameToJSON),
    property_ContentType:
      value.property_ContentType === undefined
        ? undefined
        : (value.property_ContentType as Array<any>).map(AssetPropertyContentTypeToJSON),
    property_icom_project_name:
      value.property_icom_project_name === undefined
        ? undefined
        : (value.property_icom_project_name as Array<any>).map(AssetPropertyIcomProjectNameToJSON),
    productInformation:
      value.productInformation === undefined
        ? undefined
        : (value.productInformation as Array<any>).map(AssetProductInformationToJSON),
    imAsset: IMAssetToJSON(value.imAsset),
    rangePrioritization: RangePrioritizationToJSON(value.rangePrioritization),
    visualSearch: VIAssetMetaDataToJSON(value.visualSearch),
    relatedAssets: value.relatedAssets,
  };
}
