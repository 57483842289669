import Button from '@ingka/button';
import Loading, { LoadingBall } from '@ingka/loading';
import React, { useEffect } from 'react';
import {
  BynderAssetUploadJob,
  BynderAssetJobRetryResult,
  BynderMetaResult,
  MetaProperty,
  BynderAssetEditJob,
} from '../generated-backend-api';
import { Loadable } from '../util/type';
import { Section } from './section/section';
import ReloadIcon from '@ingka/ssr-icon/paths/arrow-counterclockwise';

import { JobListItem } from './job-list-item';
import { EditListLoadable, UploadsListLoadable } from '../app-state';

interface JobList {
  // size?: 'large' | 'small';
  list: UploadsListLoadable | EditListLoadable;
  bynderBaseUrl: string;
  fetchJobsList: (startedBefore?: number) => void;

  retryJob?: (job: BynderAssetUploadJob) => void;
  retryResults?: Record<string, Loadable<BynderAssetJobRetryResult> | undefined>;

  bynderMeta: Loadable<BynderMetaResult>;
  loadBynderMeta: () => void;
}

export function JobList(props: JobList): JSX.Element | null {
  const { fetchJobsList, list, bynderBaseUrl, bynderMeta, loadBynderMeta } = props;
  useEffect(() => {
    if (list.status === 'uninitialized') {
      fetchJobsList();
    }

    if (bynderMeta.status === 'uninitialized') {
      loadBynderMeta();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const propMap: Map<string, MetaProperty> = new Map<string, MetaProperty>();

  if (bynderMeta.status === 'loaded' && bynderMeta.data.type === 'bynder-meta-response') {
    for (const v of Object.values(bynderMeta.data.result)) {
      propMap.set(v.id, v);
    }
  }

  const renderList = (jobs: (BynderAssetUploadJob | BynderAssetEditJob)[]) => {
    jobs.sort((a, b) => b.created - a.created);

    return jobs.map((job) => {
      return (
        <JobListItem
          key={`${job.id}`}
          job={job}
          bynderBaseUrl={bynderBaseUrl}
          retryJob={props.retryJob}
          retryResult={props.retryResults ? props.retryResults[job.id] : undefined}
          propMap={propMap}
        />
      );
    });
  };

  let l;

  if (list.status === 'loading' || list.status === 'uninitialized') {
    if (list.prev) {
      l = renderList(list.prev);
    } else {
      l = (
        <Loading text={list.status === 'uninitialized' ? 'Initializing...' : 'Loading...'}>
          <LoadingBall />
        </Loading>
      );
    }
  } else {
    if (list.data.isError) {
      l = <div>Failed to fetch uploads list. {list.data.type}</div>;
    } else {
      l = renderList(list.data.result);
    }
  }

  let loadMoreButton;

  if (
    (list.lastResult &&
      list.lastResult.type === 'bynder-asset-upload-job-list-response' &&
      list.lastResult.result.length === 200) ||
    list.status === 'loading'
  ) {
    let results: BynderAssetUploadJob[] = [];

    if (list.status === 'loaded' && list.data.type === 'composite-uploads-list-result') {
      results = list.data.result;
    }

    loadMoreButton = (
      <Button
        type="secondary"
        ssrIcon={ReloadIcon}
        text={'Load more jobs'}
        disabled={list.status !== 'loaded'}
        loading={list.status === 'loading'}
        onClick={() => {
          if (results.length === 0) {
            return;
          }

          const oldestAge = results.reduce((agg, job) => {
            return Math.min(job.created, agg);
          }, new Date().getTime());

          console.log(new Date(oldestAge));

          fetchJobsList(oldestAge);
        }}
      />
    );
  }

  return (
    <div>
      <Section>
        <Button
          type="secondary"
          ssrIcon={ReloadIcon}
          loading={list.status !== 'loaded'}
          disabled={list.status !== 'loaded'}
          text={'Refresh jobs list'}
          onClick={() => {
            fetchJobsList();
          }}
        />
      </Section>

      {l}
      {loadMoreButton}
    </div>
  );
}
