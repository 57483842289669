/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface IDAMCoordinates
 */
export interface IDAMCoordinates {
  /**
   *
   * @type {number}
   * @memberof IDAMCoordinates
   */
  left?: number;
  /**
   *
   * @type {number}
   * @memberof IDAMCoordinates
   */
  top?: number;
  /**
   *
   * @type {number}
   * @memberof IDAMCoordinates
   */
  right?: number;
  /**
   *
   * @type {number}
   * @memberof IDAMCoordinates
   */
  bottom?: number;
}

export function IDAMCoordinatesFromJSON(json: any): IDAMCoordinates {
  return IDAMCoordinatesFromJSONTyped(json, false);
}

export function IDAMCoordinatesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IDAMCoordinates {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    left: !exists(json, 'left') ? undefined : json['left'],
    top: !exists(json, 'top') ? undefined : json['top'],
    right: !exists(json, 'right') ? undefined : json['right'],
    bottom: !exists(json, 'bottom') ? undefined : json['bottom'],
  };
}

export function IDAMCoordinatesToJSON(value?: IDAMCoordinates | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    left: value.left,
    top: value.top,
    right: value.right,
    bottom: value.bottom,
  };
}
