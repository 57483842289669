/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  VIClipFeaturesResponseResult,
  VIClipFeaturesResponseResultFromJSON,
  VIClipFeaturesResponseResultFromJSONTyped,
  VIClipFeaturesResponseResultToJSON,
} from './';

/**
 *
 * @export
 * @interface VIClipFeaturesResponse
 */
export interface VIClipFeaturesResponse {
  /**
   *
   * @type {boolean}
   * @memberof VIClipFeaturesResponse
   */
  isError: VIClipFeaturesResponseIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof VIClipFeaturesResponse
   */
  type: VIClipFeaturesResponseTypeEnum;
  /**
   *
   * @type {VIClipFeaturesResponseResult}
   * @memberof VIClipFeaturesResponse
   */
  result: VIClipFeaturesResponseResult;
}

/**
 * @export
 * @enum {boolean}
 */
export type VIClipFeaturesResponseIsErrorEnum = false;

/**
 * @export
 * @enum {string}
 */
export type VIClipFeaturesResponseTypeEnum = 'clip-features';

export function VIClipFeaturesResponseFromJSON(json: any): VIClipFeaturesResponse {
  return VIClipFeaturesResponseFromJSONTyped(json, false);
}

export function VIClipFeaturesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VIClipFeaturesResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
    result: VIClipFeaturesResponseResultFromJSON(json['result']),
  };
}

export function VIClipFeaturesResponseToJSON(value?: VIClipFeaturesResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
    result: VIClipFeaturesResponseResultToJSON(value.result),
  };
}
