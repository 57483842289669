/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface IMCCSearchTotalHits
 */
export interface IMCCSearchTotalHits {
  /**
   *
   * @type {string}
   * @memberof IMCCSearchTotalHits
   */
  relation: string;
  /**
   *
   * @type {number}
   * @memberof IMCCSearchTotalHits
   */
  value: number;
}

export function IMCCSearchTotalHitsFromJSON(json: any): IMCCSearchTotalHits {
  return IMCCSearchTotalHitsFromJSONTyped(json, false);
}

export function IMCCSearchTotalHitsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCSearchTotalHits {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    relation: json['relation'],
    value: json['value'],
  };
}

export function IMCCSearchTotalHitsToJSON(value?: IMCCSearchTotalHits | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    relation: value.relation,
    value: value.value,
  };
}
