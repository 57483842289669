/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import { IDAMContext, IDAMContextFromJSON, IDAMContextFromJSONTyped, IDAMContextToJSON } from './';

/**
 *
 * @export
 * @interface IDAMImage
 */
export interface IDAMImage {
  /**
   *
   * @type {string}
   * @memberof IDAMImage
   */
  area?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMImage
   */
  category?: string;
  /**
   *
   * @type {IDAMContext}
   * @memberof IDAMImage
   */
  context?: IDAMContext;
  /**
   *
   * @type {string}
   * @memberof IDAMImage
   */
  section?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof IDAMImage
   */
  features?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof IDAMImage
   */
  styleGroup?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMImage
   */
  priceLevel?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMImage
   */
  roomType?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMImage
   */
  roomSize?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMImage
   */
  cameraShot?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMImage
   */
  mainHomeFurnishingActivity?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMImage
   */
  season?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMImage
   */
  dominatingColor?: string;
}

export function IDAMImageFromJSON(json: any): IDAMImage {
  return IDAMImageFromJSONTyped(json, false);
}

export function IDAMImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDAMImage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    area: !exists(json, 'area') ? undefined : json['area'],
    category: !exists(json, 'category') ? undefined : json['category'],
    context: !exists(json, 'context') ? undefined : IDAMContextFromJSON(json['context']),
    section: !exists(json, 'section') ? undefined : json['section'],
    features: !exists(json, 'features') ? undefined : json['features'],
    styleGroup: !exists(json, 'styleGroup') ? undefined : json['styleGroup'],
    priceLevel: !exists(json, 'priceLevel') ? undefined : json['priceLevel'],
    roomType: !exists(json, 'roomType') ? undefined : json['roomType'],
    roomSize: !exists(json, 'roomSize') ? undefined : json['roomSize'],
    cameraShot: !exists(json, 'cameraShot') ? undefined : json['cameraShot'],
    mainHomeFurnishingActivity: !exists(json, 'mainHomeFurnishingActivity')
      ? undefined
      : json['mainHomeFurnishingActivity'],
    season: !exists(json, 'season') ? undefined : json['season'],
    dominatingColor: !exists(json, 'dominatingColor') ? undefined : json['dominatingColor'],
  };
}

export function IDAMImageToJSON(value?: IDAMImage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    area: value.area,
    category: value.category,
    context: IDAMContextToJSON(value.context),
    section: value.section,
    features: value.features,
    styleGroup: value.styleGroup,
    priceLevel: value.priceLevel,
    roomType: value.roomType,
    roomSize: value.roomSize,
    cameraShot: value.cameraShot,
    mainHomeFurnishingActivity: value.mainHomeFurnishingActivity,
    season: value.season,
    dominatingColor: value.dominatingColor,
  };
}
