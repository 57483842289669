/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface IDAMUsageRights
 */
export interface IDAMUsageRights {
  /**
   *
   * @type {string}
   * @memberof IDAMUsageRights
   */
  releaseDate?: string;
}

export function IDAMUsageRightsFromJSON(json: any): IDAMUsageRights {
  return IDAMUsageRightsFromJSONTyped(json, false);
}

export function IDAMUsageRightsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IDAMUsageRights {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    releaseDate: !exists(json, 'releaseDate') ? undefined : json['releaseDate'],
  };
}

export function IDAMUsageRightsToJSON(value?: IDAMUsageRights | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    releaseDate: value.releaseDate,
  };
}
