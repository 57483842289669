/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface JobCompleted
 */
export interface JobCompleted {
  /**
   *
   * @type {string}
   * @memberof JobCompleted
   */
  type: JobCompletedTypeEnum;
  /**
   *
   * @type {number}
   * @memberof JobCompleted
   */
  attempt: number;
  /**
   *
   * @type {number}
   * @memberof JobCompleted
   */
  timestamp: number;
}

/**
 * @export
 * @enum {string}
 */
export type JobCompletedTypeEnum = 'job-completed';

export function JobCompletedFromJSON(json: any): JobCompleted {
  return JobCompletedFromJSONTyped(json, false);
}

export function JobCompletedFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobCompleted {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    attempt: json['attempt'],
    timestamp: json['timestamp'],
  };
}

export function JobCompletedToJSON(value?: JobCompleted | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    attempt: value.attempt,
    timestamp: value.timestamp,
  };
}
