/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface ImageUrl
 */
export interface ImageUrl {
  /**
   *
   * @type {string}
   * @memberof ImageUrl
   */
  type: ImageUrlTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ImageUrl
   */
  imageUrl: string;
}

/**
 * @export
 * @enum {string}
 */
export type ImageUrlTypeEnum = 'imageUrl';

export function ImageUrlFromJSON(json: any): ImageUrl {
  return ImageUrlFromJSONTyped(json, false);
}

export function ImageUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageUrl {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    imageUrl: json['imageUrl'],
  };
}

export function ImageUrlToJSON(value?: ImageUrl | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    imageUrl: value.imageUrl,
  };
}
