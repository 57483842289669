/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
  BynderAssetUploadJobListResponse,
  CouldNotFetchFromFSError,
  ValidationError,
  BynderAssetUploadJobListResponseFromJSONTyped,
  BynderAssetUploadJobListResponseToJSON,
  CouldNotFetchFromFSErrorFromJSONTyped,
  CouldNotFetchFromFSErrorToJSON,
  ValidationErrorFromJSONTyped,
  ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type GetBynderAssetUploadJobList
 *
 * @export
 */
export type GetBynderAssetUploadJobList =
  | BynderAssetUploadJobListResponse
  | CouldNotFetchFromFSError
  | ValidationError;

export function GetBynderAssetUploadJobListFromJSON(json: any): GetBynderAssetUploadJobList {
  return GetBynderAssetUploadJobListFromJSONTyped(json, false);
}

export function GetBynderAssetUploadJobListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBynderAssetUploadJobList {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json['type']) {
    case 'bynder-asset-upload-job-list-response':
      return {
        ...BynderAssetUploadJobListResponseFromJSONTyped(json, true),
        type: 'bynder-asset-upload-job-list-response',
      };
    case 'could-not-fetch-from-fs':
      return {
        ...CouldNotFetchFromFSErrorFromJSONTyped(json, true),
        type: 'could-not-fetch-from-fs',
      };
    case 'failed-to-validate':
      return { ...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate' };
    default:
      throw new Error(
        `No variant of GetBynderAssetUploadJobList exists with 'type=${json['type']}'`
      );
  }
}

export function GetBynderAssetUploadJobListToJSON(value?: GetBynderAssetUploadJobList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value['type']) {
    case 'bynder-asset-upload-job-list-response':
      return BynderAssetUploadJobListResponseToJSON(value);
    case 'could-not-fetch-from-fs':
      return CouldNotFetchFromFSErrorToJSON(value);
    case 'failed-to-validate':
      return ValidationErrorToJSON(value);
    default:
      throw new Error(
        `No variant of GetBynderAssetUploadJobList exists with 'type=${value['type']}'`
      );
  }
}
