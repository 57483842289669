/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IMCCDeliveriesListResponseResult,
  IMCCDeliveriesListResponseResultFromJSON,
  IMCCDeliveriesListResponseResultFromJSONTyped,
  IMCCDeliveriesListResponseResultToJSON,
} from './';

/**
 *
 * @export
 * @interface IMCCDeliveriesListResponse
 */
export interface IMCCDeliveriesListResponse {
  /**
   *
   * @type {boolean}
   * @memberof IMCCDeliveriesListResponse
   */
  isError: IMCCDeliveriesListResponseIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof IMCCDeliveriesListResponse
   */
  type: IMCCDeliveriesListResponseTypeEnum;
  /**
   *
   * @type {IMCCDeliveriesListResponseResult}
   * @memberof IMCCDeliveriesListResponse
   */
  result: IMCCDeliveriesListResponseResult;
}

/**
 * @export
 * @enum {boolean}
 */
export type IMCCDeliveriesListResponseIsErrorEnum = false;

/**
 * @export
 * @enum {string}
 */
export type IMCCDeliveriesListResponseTypeEnum = 'deliveries-list-response';

export function IMCCDeliveriesListResponseFromJSON(json: any): IMCCDeliveriesListResponse {
  return IMCCDeliveriesListResponseFromJSONTyped(json, false);
}

export function IMCCDeliveriesListResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCDeliveriesListResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
    result: IMCCDeliveriesListResponseResultFromJSON(json['result']),
  };
}

export function IMCCDeliveriesListResponseToJSON(value?: IMCCDeliveriesListResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
    result: IMCCDeliveriesListResponseResultToJSON(value.result),
  };
}
