/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface SearchSimilarResultHitsTotal
 */
export interface SearchSimilarResultHitsTotal {
  /**
   *
   * @type {number}
   * @memberof SearchSimilarResultHitsTotal
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof SearchSimilarResultHitsTotal
   */
  relation: SearchSimilarResultHitsTotalRelationEnum;
}

/**
 * @export
 * @enum {string}
 */
export type SearchSimilarResultHitsTotalRelationEnum = 'eq';

export function SearchSimilarResultHitsTotalFromJSON(json: any): SearchSimilarResultHitsTotal {
  return SearchSimilarResultHitsTotalFromJSONTyped(json, false);
}

export function SearchSimilarResultHitsTotalFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SearchSimilarResultHitsTotal {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    value: json['value'],
    relation: json['relation'],
  };
}

export function SearchSimilarResultHitsTotalToJSON(
  value?: SearchSimilarResultHitsTotal | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    value: value.value,
    relation: value.relation,
  };
}
