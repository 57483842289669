/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
  CouldNotFetchFromFSError,
  ElasticSearchSimilarResponse,
  ValidationError,
  CouldNotFetchFromFSErrorFromJSONTyped,
  CouldNotFetchFromFSErrorToJSON,
  ElasticSearchSimilarResponseFromJSONTyped,
  ElasticSearchSimilarResponseToJSON,
  ValidationErrorFromJSONTyped,
  ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type SearchElasticClipSimilarity
 *
 * @export
 */
export type SearchElasticClipSimilarity =
  | CouldNotFetchFromFSError
  | ElasticSearchSimilarResponse
  | ValidationError;

export function SearchElasticClipSimilarityFromJSON(json: any): SearchElasticClipSimilarity {
  return SearchElasticClipSimilarityFromJSONTyped(json, false);
}

export function SearchElasticClipSimilarityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SearchElasticClipSimilarity {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json['type']) {
    case 'could-not-fetch-from-fs':
      return {
        ...CouldNotFetchFromFSErrorFromJSONTyped(json, true),
        type: 'could-not-fetch-from-fs',
      };
    case 'elastic-search-similar-response':
      return {
        ...ElasticSearchSimilarResponseFromJSONTyped(json, true),
        type: 'elastic-search-similar-response',
      };
    case 'failed-to-validate':
      return { ...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate' };
    default:
      throw new Error(
        `No variant of SearchElasticClipSimilarity exists with 'type=${json['type']}'`
      );
  }
}

export function SearchElasticClipSimilarityToJSON(value?: SearchElasticClipSimilarity | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value['type']) {
    case 'could-not-fetch-from-fs':
      return CouldNotFetchFromFSErrorToJSON(value);
    case 'elastic-search-similar-response':
      return ElasticSearchSimilarResponseToJSON(value);
    case 'failed-to-validate':
      return ValidationErrorToJSON(value);
    default:
      throw new Error(
        `No variant of SearchElasticClipSimilarity exists with 'type=${value['type']}'`
      );
  }
}
