/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface BynderAssetEditJobUser
 */
export interface BynderAssetEditJobUser {
  /**
   *
   * @type {string}
   * @memberof BynderAssetEditJobUser
   */
  email: string;
}

export function BynderAssetEditJobUserFromJSON(json: any): BynderAssetEditJobUser {
  return BynderAssetEditJobUserFromJSONTyped(json, false);
}

export function BynderAssetEditJobUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BynderAssetEditJobUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
  };
}

export function BynderAssetEditJobUserToJSON(value?: BynderAssetEditJobUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
  };
}
