/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  ContainsQuery,
  ContainsQueryFromJSON,
  ContainsQueryFromJSONTyped,
  ContainsQueryToJSON,
  ExactQuery,
  ExactQueryFromJSON,
  ExactQueryFromJSONTyped,
  ExactQueryToJSON,
  FuzzyQuery,
  FuzzyQueryFromJSON,
  FuzzyQueryFromJSONTyped,
  FuzzyQueryToJSON,
  PrefixQuery,
  PrefixQueryFromJSON,
  PrefixQueryFromJSONTyped,
  PrefixQueryToJSON,
  RangeGTQuery,
  RangeGTQueryFromJSON,
  RangeGTQueryFromJSONTyped,
  RangeGTQueryToJSON,
  RangeLTQuery,
  RangeLTQueryFromJSON,
  RangeLTQueryFromJSONTyped,
  RangeLTQueryToJSON,
  RangeQuery,
  RangeQueryFromJSON,
  RangeQueryFromJSONTyped,
  RangeQueryToJSON,
  SearchRequestBodySearchAfter,
  SearchRequestBodySearchAfterFromJSON,
  SearchRequestBodySearchAfterFromJSONTyped,
  SearchRequestBodySearchAfterToJSON,
  SortDirection,
  SortDirectionFromJSON,
  SortDirectionFromJSONTyped,
  SortDirectionToJSON,
} from './';

/**
 *
 * @export
 * @interface SearchRequestBody
 */
export interface SearchRequestBody {
  /**
   *
   * @type {{ [key: string]: PrefixQuery | RangeLTQuery | RangeGTQuery | RangeQuery | ContainsQuery | ExactQuery | FuzzyQuery; }}
   * @memberof SearchRequestBody
   */
  query: {
    [key: string]:
      | PrefixQuery
      | RangeLTQuery
      | RangeGTQuery
      | RangeQuery
      | ContainsQuery
      | ExactQuery
      | FuzzyQuery;
  };
  /**
   *
   * @type {Array<string>}
   * @memberof SearchRequestBody
   */
  usageRights: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SearchRequestBody
   */
  requestingMarket: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchRequestBody
   */
  fields?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SearchRequestBody
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof SearchRequestBody
   */
  offset?: number;
  /**
   *
   * @type {Array<{ [key: string]: SortDirection; }>}
   * @memberof SearchRequestBody
   */
  sort?: Array<{ [key: string]: SortDirection }>;
  /**
   *
   * @type {Array<SearchRequestBodySearchAfter>}
   * @memberof SearchRequestBody
   */
  searchAfter?: Array<SearchRequestBodySearchAfter>;
}

export function SearchRequestBodyFromJSON(json: any): SearchRequestBody {
  return SearchRequestBodyFromJSONTyped(json, false);
}

export function SearchRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SearchRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    query: mapValues(json['query'], (q) => q),
    usageRights: json['usageRights'],
    requestingMarket: json['requestingMarket'],
    fields: !exists(json, 'fields') ? undefined : json['fields'],
    limit: !exists(json, 'limit') ? undefined : json['limit'],
    offset: !exists(json, 'offset') ? undefined : json['offset'],
    sort: !exists(json, 'sort') ? undefined : json['sort'],
    searchAfter: !exists(json, 'searchAfter')
      ? undefined
      : (json['searchAfter'] as Array<any>).map(SearchRequestBodySearchAfterFromJSON),
  };
}

export function SearchRequestBodyToJSON(value?: SearchRequestBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    query: mapValues(value.query, (q) => q),
    usageRights: value.usageRights,
    requestingMarket: value.requestingMarket,
    fields: value.fields,
    limit: value.limit,
    offset: value.offset,
    sort: value.sort,
    searchAfter:
      value.searchAfter === undefined
        ? undefined
        : (value.searchAfter as Array<any>).map(SearchRequestBodySearchAfterToJSON),
  };
}
