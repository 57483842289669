/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
  CreatePackageResponse,
  UnknownFSError,
  ValidationError,
  CreatePackageResponseFromJSONTyped,
  CreatePackageResponseToJSON,
  UnknownFSErrorFromJSONTyped,
  UnknownFSErrorToJSON,
  ValidationErrorFromJSONTyped,
  ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type CreateDownloadPackage
 *
 * @export
 */
export type CreateDownloadPackage = CreatePackageResponse | ValidationError | UnknownFSError;

export function CreateDownloadPackageFromJSON(json: any): CreateDownloadPackage {
  return CreateDownloadPackageFromJSONTyped(json, false);
}

export function CreateDownloadPackageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateDownloadPackage {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json['type']) {
    case 'create-package-response':
      return { ...CreatePackageResponseFromJSONTyped(json, true), type: 'create-package-response' };
    case 'failed-to-validate':
      return { ...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate' };
    case 'unknown-fs-error':
      return { ...UnknownFSErrorFromJSONTyped(json, true), type: 'unknown-fs-error' };
    default:
      throw new Error(`No variant of CreateDownloadPackage exists with 'type=${json['type']}'`);
  }
}

export function CreateDownloadPackageToJSON(value?: CreateDownloadPackage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value['type']) {
    case 'create-package-response':
      return CreatePackageResponseToJSON(value);
    case 'failed-to-validate':
      return ValidationErrorToJSON(value);
    case 'unknown-fs-error':
      return UnknownFSErrorToJSON(value);
    default:
      throw new Error(`No variant of CreateDownloadPackage exists with 'type=${value['type']}'`);
  }
}
