/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface IMCCImageNotFound
 */
export interface IMCCImageNotFound {
  /**
   *
   * @type {boolean}
   * @memberof IMCCImageNotFound
   */
  isError: IMCCImageNotFoundIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof IMCCImageNotFound
   */
  type: IMCCImageNotFoundTypeEnum;
}

/**
 * @export
 * @enum {boolean}
 */
export type IMCCImageNotFoundIsErrorEnum = true;

/**
 * @export
 * @enum {string}
 */
export type IMCCImageNotFoundTypeEnum = 'imcc-image-not-found';

export function IMCCImageNotFoundFromJSON(json: any): IMCCImageNotFound {
  return IMCCImageNotFoundFromJSONTyped(json, false);
}

export function IMCCImageNotFoundFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCImageNotFound {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
  };
}

export function IMCCImageNotFoundToJSON(value?: IMCCImageNotFound | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
  };
}
