import { MetaProperty } from '../generated-backend-api';

export type BynderDefaultProperty = MetaProperty;

export const bynderNameProp: MetaProperty = {
  type: 'text',
  label: 'Name',
  id: 'name',
  name: 'Name',
  zindex: 0,
  isRequired: 0,
  isApiField: 0,
  isDisplayField: 0,
  isDrilldown: 0,
  isEditable: 1,
  isFilterable: 0,
  isMainfilter: 0,
  isMultifilter: 0,
  isMultiselect: 0,
  isSearchable: 0,
  showInDuplicateView: 0,
  showInGridView: 0,
  showInListView: 0,
  useDependencies: 0,
  options: [],
};

export const bynderDescriptionProp: MetaProperty = {
  type: 'textarea',
  label: 'Description',
  id: 'description',
  name: 'Description',
  zindex: 0,
  isRequired: 0,
  isApiField: 0,
  isDisplayField: 0,
  isDrilldown: 0,
  isEditable: 1,
  isFilterable: 0,
  isMainfilter: 0,
  isMultifilter: 0,
  isMultiselect: 1,
  isSearchable: 0,
  showInDuplicateView: 0,
  showInGridView: 0,
  showInListView: 0,
  useDependencies: 0,
  options: [],
};

export const bynderTagsProp: MetaProperty = {
  type: 'autocomplete',
  label: 'Tags',
  id: 'tags',
  name: 'Tags',
  zindex: 0,
  isRequired: 0,
  isApiField: 0,
  isDisplayField: 0,
  isDrilldown: 0,
  isEditable: 1,
  isFilterable: 0,
  isMainfilter: 0,
  isMultifilter: 0,
  isMultiselect: 1,
  isSearchable: 0,
  showInDuplicateView: 0,
  showInGridView: 0,
  showInListView: 0,
  useDependencies: 0,
  options: [],
};

export const bynderLimitedProp: MetaProperty = {
  type: 'checkbox',
  label: 'Limited',
  id: 'limited',
  name: 'Limited',
  zindex: 0,
  isRequired: 0,
  isApiField: 0,
  isDisplayField: 0,
  isDrilldown: 0,
  isEditable: 1,
  isFilterable: 0,
  isMainfilter: 0,
  isMultifilter: 0,
  isMultiselect: 0,
  isSearchable: 0,
  showInDuplicateView: 0,
  showInGridView: 0,
  showInListView: 0,
  useDependencies: 0,
  options: [],
};

export const bynderLimitedDateProp: MetaProperty = {
  type: 'date',
  label: 'Limited date',
  id: 'limited_date',
  name: 'Limited date',
  zindex: 0,
  isRequired: 0,
  isApiField: 0,
  isDisplayField: 0,
  isDrilldown: 0,
  isEditable: 1,
  isFilterable: 0,
  isMainfilter: 0,
  isMultifilter: 0,
  isMultiselect: 0,
  isSearchable: 0,
  showInDuplicateView: 0,
  showInGridView: 0,
  showInListView: 0,
  useDependencies: 0,
  options: [],
};

export const bynderIdProp: MetaProperty = {
  type: 'text',
  label: 'ID',
  id: 'fake_bynder_id',
  name: 'ID',
  zindex: 0,
  isRequired: 0,
  isApiField: 0,
  isDisplayField: 0,
  isDrilldown: 0,
  isEditable: 0,
  isFilterable: 0,
  isMainfilter: 0,
  isMultifilter: 0,
  isMultiselect: 0,
  isSearchable: 0,
  showInDuplicateView: 0,
  showInGridView: 0,
  showInListView: 0,
  useDependencies: 0,
  options: [],
};

export const defaultEditableBynderProps: MetaProperty[] = [
  bynderNameProp,
  bynderDescriptionProp,
  bynderTagsProp,
  bynderLimitedProp,
  bynderLimitedDateProp,
];

export const allBynderProps: MetaProperty[] = [
  bynderIdProp,
  bynderNameProp,
  bynderDescriptionProp,
  bynderTagsProp,
  bynderLimitedProp,
  bynderLimitedDateProp,
];
