/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  MetaProperty,
  MetaPropertyFromJSON,
  MetaPropertyFromJSONTyped,
  MetaPropertyToJSON,
} from './';

/**
 *
 * @export
 * @interface BynderMetaResponse
 */
export interface BynderMetaResponse {
  /**
   *
   * @type {boolean}
   * @memberof BynderMetaResponse
   */
  isError: BynderMetaResponseIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof BynderMetaResponse
   */
  type: BynderMetaResponseTypeEnum;
  /**
   *
   * @type {{ [key: string]: MetaProperty; }}
   * @memberof BynderMetaResponse
   */
  result: { [key: string]: MetaProperty };
}

/**
 * @export
 * @enum {boolean}
 */
export type BynderMetaResponseIsErrorEnum = false;

/**
 * @export
 * @enum {string}
 */
export type BynderMetaResponseTypeEnum = 'bynder-meta-response';

export function BynderMetaResponseFromJSON(json: any): BynderMetaResponse {
  return BynderMetaResponseFromJSONTyped(json, false);
}

export function BynderMetaResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BynderMetaResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
    result: mapValues(json['result'], (q) => q),
  };
}

export function BynderMetaResponseToJSON(value?: BynderMetaResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
    result: mapValues(value.result, (q) => q),
  };
}
