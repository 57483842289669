import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { Asset } from '../generated-backend-api';
import AspectRatioImage from '@ingka/aspect-ratio-image';

export const SearchResultAssetCard = ({ asset, uri }: { asset: Asset; uri: string }) => {
  return (
    <a
      href={uri}
      css={css`
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
      `}
    >
      <AspectRatioImage src={asset.thumbnails.webimage} alt={asset.name} />
      <AssetCardTitle asset={asset} />
    </a>
  );
};

const AssetCardTitle = ({ asset }: { asset: Asset }) => (
  <div
    css={css`
      position: absolute;
      bottom: 0px;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.5);
    `}
  >
    <span
      css={css`
        padding: 10px;
        display: inline-block;
      `}
    >
      {asset.name}
    </span>
  </div>
);
