/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface IMCCDeliveryRequestBody
 */
export interface IMCCDeliveryRequestBody {
  /**
   *
   * @type {string}
   * @memberof IMCCDeliveryRequestBody
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof IMCCDeliveryRequestBody
   */
  skipCache?: boolean;
}

export function IMCCDeliveryRequestBodyFromJSON(json: any): IMCCDeliveryRequestBody {
  return IMCCDeliveryRequestBodyFromJSONTyped(json, false);
}

export function IMCCDeliveryRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCDeliveryRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    skipCache: !exists(json, 'skipCache') ? undefined : json['skipCache'],
  };
}

export function IMCCDeliveryRequestBodyToJSON(value?: IMCCDeliveryRequestBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    skipCache: value.skipCache,
  };
}
