/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IMCCDeliveriesListResponseItem,
  IMCCDeliveriesListResponseItemFromJSON,
  IMCCDeliveriesListResponseItemFromJSONTyped,
  IMCCDeliveriesListResponseItemToJSON,
} from './';

/**
 *
 * @export
 * @interface IMCCDeliveriesListResponseResult
 */
export interface IMCCDeliveriesListResponseResult {
  /**
   *
   * @type {Array<IMCCDeliveriesListResponseItem>}
   * @memberof IMCCDeliveriesListResponseResult
   */
  items: Array<IMCCDeliveriesListResponseItem>;
  /**
   *
   * @type {number}
   * @memberof IMCCDeliveriesListResponseResult
   */
  nextOffset: number;
}

export function IMCCDeliveriesListResponseResultFromJSON(
  json: any
): IMCCDeliveriesListResponseResult {
  return IMCCDeliveriesListResponseResultFromJSONTyped(json, false);
}

export function IMCCDeliveriesListResponseResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCDeliveriesListResponseResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(IMCCDeliveriesListResponseItemFromJSON),
    nextOffset: json['nextOffset'],
  };
}

export function IMCCDeliveriesListResponseResultToJSON(
  value?: IMCCDeliveriesListResponseResult | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(IMCCDeliveriesListResponseItemToJSON),
    nextOffset: value.nextOffset,
  };
}
