/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  JobUpdatedAssetPayload,
  JobUpdatedAssetPayloadFromJSON,
  JobUpdatedAssetPayloadFromJSONTyped,
  JobUpdatedAssetPayloadToJSON,
} from './';

/**
 *
 * @export
 * @interface JobUpdatedAsset
 */
export interface JobUpdatedAsset {
  /**
   *
   * @type {string}
   * @memberof JobUpdatedAsset
   */
  type: JobUpdatedAssetTypeEnum;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof JobUpdatedAsset
   */
  payload: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof JobUpdatedAsset
   */
  attempt: number;
  /**
   *
   * @type {number}
   * @memberof JobUpdatedAsset
   */
  timestamp: number;
}

/**
 * @export
 * @enum {string}
 */
export type JobUpdatedAssetTypeEnum = 'job-updated-asset';

export function JobUpdatedAssetFromJSON(json: any): JobUpdatedAsset {
  return JobUpdatedAssetFromJSONTyped(json, false);
}

export function JobUpdatedAssetFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): JobUpdatedAsset {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    payload: json['payload'],
    attempt: json['attempt'],
    timestamp: json['timestamp'],
  };
}

export function JobUpdatedAssetToJSON(value?: JobUpdatedAsset | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    payload: value.payload,
    attempt: value.attempt,
    timestamp: value.timestamp,
  };
}
