/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

type Position = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export const CornerResizer = ({
  position,
  onDrag,
  onDragStart,
  onDragEnd,
}: {
  position: Position;
  onDrag: (e: React.MouseEvent, position: Position) => void;
  onDragStart?: (e: React.MouseEvent, position: Position) => void;
  onDragEnd?: (e: React.MouseEvent, position: Position) => void;
}) => {
  const debouncedCropCornerDrag = useDebouncedCallback((e: React.MouseEvent) =>
    onDrag(e, position),
  );

  let positionStyle;
  const borderStyle = '3px solid white';

  switch (position) {
    case 'top-left':
      positionStyle = {
        top: '-3px',
        left: '-3px',
        cursor: 'nwse-resize',
        borderTop: borderStyle,
        borderLeft: borderStyle,
      };
      break;
    case 'top-right':
      positionStyle = {
        top: '-3px',
        right: '-3px',
        cursor: 'nesw-resize',
        borderTop: borderStyle,
        borderRight: borderStyle,
      };
      break;
    case 'bottom-left':
      positionStyle = {
        bottom: '-3px',
        left: '-3px',
        cursor: 'nesw-resize',
        borderBottom: borderStyle,
        borderLeft: borderStyle,
      };
      break;
    case 'bottom-right':
      positionStyle = {
        bottom: '-3px',
        right: '-3px',
        borderBottom: borderStyle,
        borderRight: borderStyle,
        cursor: 'nwse-resize',
      };
      break;
  }

  return (
    <div
      css={css`
        width: 20px;
        height: 20px;
        position: absolute;
        z-index: 10000;
      `}
      style={positionStyle}
      draggable={true}
      onDragStart={(e) => {
        e.dataTransfer.effectAllowed = 'none';
        e.dataTransfer.dropEffect = 'none';
        let canvas = document.getElementById('drag-helper');

        if (!canvas) {
          canvas = document.createElement('canvas');
          canvas.id = 'drag-helper';
        }

        e.dataTransfer.setDragImage(canvas, 0, 0);
        document.body.appendChild(canvas);
        if (onDragStart) {
          onDragStart(e, position);
        }
      }}
      onDrag={debouncedCropCornerDrag}
      onDragEnd={(e) => {
        debouncedCropCornerDrag(e);
        const canvas = document.getElementById('drag-helper');
        if (canvas) {
          canvas.remove();
        }

        if (onDragEnd) {
          onDragEnd(e, position);
        }
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    />
  );
};
