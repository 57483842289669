/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  BynderUploadMetaValue,
  BynderUploadMetaValueFromJSON,
  BynderUploadMetaValueFromJSONTyped,
  BynderUploadMetaValueToJSON,
} from './';

/**
 *
 * @export
 * @interface EditJobItem
 */
export interface EditJobItem {
  /**
   *
   * @type {string}
   * @memberof EditJobItem
   */
  type: EditJobItemTypeEnum;
  /**
   *
   * @type {string}
   * @memberof EditJobItem
   */
  id: string;
  /**
   *
   * @type {{ [key: string]: BynderUploadMetaValue; }}
   * @memberof EditJobItem
   */
  bynderUploadMeta?: { [key: string]: BynderUploadMetaValue };
  /**
   *
   * @type {Array<string>}
   * @memberof EditJobItem
   */
  boards?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof EditJobItem
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof EditJobItem
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EditJobItem
   */
  tags?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof EditJobItem
   */
  limited?: boolean;
  /**
   *
   * @type {string}
   * @memberof EditJobItem
   */
  limitedDate?: string;
}

/**
 * @export
 * @enum {string}
 */
export type EditJobItemTypeEnum = 'edit-job';

export function EditJobItemFromJSON(json: any): EditJobItem {
  return EditJobItemFromJSONTyped(json, false);
}

export function EditJobItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditJobItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    id: json['id'],
    bynderUploadMeta: !exists(json, 'bynderUploadMeta')
      ? undefined
      : mapValues(json['bynderUploadMeta'], (q) => q),
    boards: !exists(json, 'boards') ? undefined : json['boards'],
    description: !exists(json, 'description') ? undefined : json['description'],
    name: !exists(json, 'name') ? undefined : json['name'],
    tags: !exists(json, 'tags') ? undefined : json['tags'],
    limited: !exists(json, 'limited') ? undefined : json['limited'],
    limitedDate: !exists(json, 'limitedDate') ? undefined : json['limitedDate'],
  };
}

export function EditJobItemToJSON(value?: EditJobItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    id: value.id,
    bynderUploadMeta:
      value.bynderUploadMeta === undefined
        ? undefined
        : mapValues(value.bynderUploadMeta, (q) => q),
    boards: value.boards,
    description: value.description,
    name: value.name,
    tags: value.tags,
    limited: value.limited,
    limitedDate: value.limitedDate,
  };
}
