/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  SearchSimilarResultHitsMaxScore,
  SearchSimilarResultHitsMaxScoreFromJSON,
  SearchSimilarResultHitsMaxScoreFromJSONTyped,
  SearchSimilarResultHitsMaxScoreToJSON,
  SearchSimilarResultHitsTotal,
  SearchSimilarResultHitsTotalFromJSON,
  SearchSimilarResultHitsTotalFromJSONTyped,
  SearchSimilarResultHitsTotalToJSON,
  SimilarityResult,
  SimilarityResultFromJSON,
  SimilarityResultFromJSONTyped,
  SimilarityResultToJSON,
} from './';

/**
 *
 * @export
 * @interface SearchSimilarResultHits
 */
export interface SearchSimilarResultHits {
  /**
   *
   * @type {SearchSimilarResultHitsTotal}
   * @memberof SearchSimilarResultHits
   */
  total: SearchSimilarResultHitsTotal;
  /**
   *
   * @type {SearchSimilarResultHitsMaxScore}
   * @memberof SearchSimilarResultHits
   */
  max_score: SearchSimilarResultHitsMaxScore | null;
  /**
   *
   * @type {Array<SimilarityResult>}
   * @memberof SearchSimilarResultHits
   */
  hits: Array<SimilarityResult>;
}

export function SearchSimilarResultHitsFromJSON(json: any): SearchSimilarResultHits {
  return SearchSimilarResultHitsFromJSONTyped(json, false);
}

export function SearchSimilarResultHitsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SearchSimilarResultHits {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: SearchSimilarResultHitsTotalFromJSON(json['total']),
    max_score: SearchSimilarResultHitsMaxScoreFromJSON(json['max_score']),
    hits: (json['hits'] as Array<any>).map(SimilarityResultFromJSON),
  };
}

export function SearchSimilarResultHitsToJSON(value?: SearchSimilarResultHits | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: SearchSimilarResultHitsTotalToJSON(value.total),
    max_score: SearchSimilarResultHitsMaxScoreToJSON(value.max_score),
    hits: (value.hits as Array<any>).map(SimilarityResultToJSON),
  };
}
