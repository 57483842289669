/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  PIAItemProductNameLocalCountryCode,
  PIAItemProductNameLocalCountryCodeFromJSON,
  PIAItemProductNameLocalCountryCodeFromJSONTyped,
  PIAItemProductNameLocalCountryCodeToJSON,
  PIAItemProductNameLocalLanguageCode,
  PIAItemProductNameLocalLanguageCodeFromJSON,
  PIAItemProductNameLocalLanguageCodeFromJSONTyped,
  PIAItemProductNameLocalLanguageCodeToJSON,
  PIAItemProductNameLocalProductName,
  PIAItemProductNameLocalProductNameFromJSON,
  PIAItemProductNameLocalProductNameFromJSONTyped,
  PIAItemProductNameLocalProductNameToJSON,
  PIAItemProductNameLocalProductNameNo,
  PIAItemProductNameLocalProductNameNoFromJSON,
  PIAItemProductNameLocalProductNameNoFromJSONTyped,
  PIAItemProductNameLocalProductNameNoToJSON,
  PIAItemProductNameLocalProductNameTrFull,
  PIAItemProductNameLocalProductNameTrFullFromJSON,
  PIAItemProductNameLocalProductNameTrFullFromJSONTyped,
  PIAItemProductNameLocalProductNameTrFullToJSON,
} from './';

/**
 *
 * @export
 * @interface PIAItemProductNameLocal
 */
export interface PIAItemProductNameLocal {
  /**
   *
   * @type {PIAItemProductNameLocalCountryCode}
   * @memberof PIAItemProductNameLocal
   */
  countryCode: PIAItemProductNameLocalCountryCode | null;
  /**
   *
   * @type {PIAItemProductNameLocalLanguageCode}
   * @memberof PIAItemProductNameLocal
   */
  languageCode: PIAItemProductNameLocalLanguageCode | null;
  /**
   *
   * @type {PIAItemProductNameLocalProductNameNo}
   * @memberof PIAItemProductNameLocal
   */
  productNameNo: PIAItemProductNameLocalProductNameNo | null;
  /**
   *
   * @type {number}
   * @memberof PIAItemProductNameLocal
   */
  sortNo: number;
  /**
   *
   * @type {PIAItemProductNameLocalProductName}
   * @memberof PIAItemProductNameLocal
   */
  productName: PIAItemProductNameLocalProductName | null;
  /**
   *
   * @type {PIAItemProductNameLocalProductNameTrFull}
   * @memberof PIAItemProductNameLocal
   */
  productNameTrFull: PIAItemProductNameLocalProductNameTrFull | null;
}

export function PIAItemProductNameLocalFromJSON(json: any): PIAItemProductNameLocal {
  return PIAItemProductNameLocalFromJSONTyped(json, false);
}

export function PIAItemProductNameLocalFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PIAItemProductNameLocal {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    countryCode: PIAItemProductNameLocalCountryCodeFromJSON(json['countryCode']),
    languageCode: PIAItemProductNameLocalLanguageCodeFromJSON(json['languageCode']),
    productNameNo: PIAItemProductNameLocalProductNameNoFromJSON(json['productNameNo']),
    sortNo: json['sortNo'],
    productName: PIAItemProductNameLocalProductNameFromJSON(json['productName']),
    productNameTrFull: PIAItemProductNameLocalProductNameTrFullFromJSON(json['productNameTrFull']),
  };
}

export function PIAItemProductNameLocalToJSON(value?: PIAItemProductNameLocal | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    countryCode: PIAItemProductNameLocalCountryCodeToJSON(value.countryCode),
    languageCode: PIAItemProductNameLocalLanguageCodeToJSON(value.languageCode),
    productNameNo: PIAItemProductNameLocalProductNameNoToJSON(value.productNameNo),
    sortNo: value.sortNo,
    productName: PIAItemProductNameLocalProductNameToJSON(value.productName),
    productNameTrFull: PIAItemProductNameLocalProductNameTrFullToJSON(value.productNameTrFull),
  };
}
