import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Button from '@ingka/button';

export const Pagination = ({
  offset,
  pageSize,
  selectedAssets,
  totalAssets,
  onChangePage,
  disabled,
}: {
  pageSize: number;
  selectedAssets: number;
  totalAssets: number;
  onChangePage: (offset: number) => void;
  disabled?: boolean;
  offset?: number;
}) => {
  const currentOffset = typeof offset === 'number' ? offset : 0;

  const lastOffset = Math.max(0, totalAssets - pageSize);

  const nextOffset = Math.min(currentOffset + pageSize, lastOffset);
  const prevOffset = Math.max(currentOffset - pageSize, 0);

  return (
    <div>
      <hr />
      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        {selectedAssets > 0 ? ` Selected: ${selectedAssets}, ` : ''}
        Total: {totalAssets}
      </div>

      <div>
        <span
          css={css`
            margin-right: 20px;
            line-height: 40px;
          `}
        >
          Current offset: {currentOffset}
        </span>
        <Button
          small={true}
          onClick={() => onChangePage(0)}
          disabled={currentOffset === 0 || disabled}
          css={css`
            margin-right: 10px;
          `}
        >
          First page
        </Button>
        <Button
          small={true}
          onClick={() => onChangePage(prevOffset)}
          disabled={currentOffset === prevOffset || disabled}
          css={css`
            margin-right: 10px;
          `}
        >
          Prev page ({prevOffset})
        </Button>
        <Button
          small={true}
          onClick={() => onChangePage(nextOffset)}
          disabled={currentOffset === nextOffset || disabled}
          css={css`
            margin-right: 10px;
          `}
        >
          Next page ({nextOffset})
        </Button>
        <Button
          small={true}
          onClick={() => onChangePage(lastOffset)}
          disabled={currentOffset === lastOffset || disabled}
          css={css`
            margin-right: 10px;
          `}
        >
          Last page ({lastOffset})
        </Button>
      </div>
    </div>
  );
};
