/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * oneof interface
 * @type IDAMContextProducedFor
 *
 * @export
 */
export type IDAMContextProducedFor = string | null;

export function IDAMContextProducedForFromJSON(json: any): IDAMContextProducedFor {
  return IDAMContextProducedForFromJSONTyped(json, false);
}

export function IDAMContextProducedForFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IDAMContextProducedFor {
  if (json === undefined || json === null) {
    return json;
  }

  const validTypes = ['string'];

  if (validTypes.includes(typeof json)) {
    return json;
  }

  throw new Error(
    `Invalid input type '${typeof json}' for IDAMContextProducedFor, expected one of ${validTypes.join(
      ', '
    )}`
  );

  // return { ...stringFromJSONTyped(json, true) };
}

export function IDAMContextProducedForToJSON(value?: IDAMContextProducedFor | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }

  const validTypes = ['string'];

  if (validTypes.includes(typeof value)) {
    return value;
  }

  throw new Error(
    `Invalid input type '${typeof value}' for IDAMContextProducedFor, expected one of ${validTypes.join(
      ', '
    )}`
  );

  // return { ...stringToJSON(value) };
}
