/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
  CouldNotAuthIMCC,
  CouldNotPerformRequest,
  IMCCImageNotFound,
  IMCCImageSearchResultResponse,
  RequestAbortedError,
  UnexpectedResponseError,
  ValidationError,
  CouldNotAuthIMCCFromJSONTyped,
  CouldNotAuthIMCCToJSON,
  CouldNotPerformRequestFromJSONTyped,
  CouldNotPerformRequestToJSON,
  IMCCImageNotFoundFromJSONTyped,
  IMCCImageNotFoundToJSON,
  IMCCImageSearchResultResponseFromJSONTyped,
  IMCCImageSearchResultResponseToJSON,
  RequestAbortedErrorFromJSONTyped,
  RequestAbortedErrorToJSON,
  UnexpectedResponseErrorFromJSONTyped,
  UnexpectedResponseErrorToJSON,
  ValidationErrorFromJSONTyped,
  ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type SearchIMCCImage
 *
 * @export
 */
export type SearchIMCCImage =
  | CouldNotAuthIMCC
  | CouldNotPerformRequest
  | ValidationError
  | IMCCImageNotFound
  | IMCCImageSearchResultResponse
  | RequestAbortedError
  | UnexpectedResponseError;

export function SearchIMCCImageFromJSON(json: any): SearchIMCCImage {
  return SearchIMCCImageFromJSONTyped(json, false);
}

export function SearchIMCCImageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SearchIMCCImage {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json['type']) {
    case 'could-not-auth-imcc':
      return { ...CouldNotAuthIMCCFromJSONTyped(json, true), type: 'could-not-auth-imcc' };
    case 'could-not-perform-request':
      return {
        ...CouldNotPerformRequestFromJSONTyped(json, true),
        type: 'could-not-perform-request',
      };
    case 'failed-to-validate':
      return { ...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate' };
    case 'imcc-image-not-found':
      return { ...IMCCImageNotFoundFromJSONTyped(json, true), type: 'imcc-image-not-found' };
    case 'imcc-image-search-result-response':
      return {
        ...IMCCImageSearchResultResponseFromJSONTyped(json, true),
        type: 'imcc-image-search-result-response',
      };
    case 'request-aborted':
      return { ...RequestAbortedErrorFromJSONTyped(json, true), type: 'request-aborted' };
    case 'unexpected-response':
      return { ...UnexpectedResponseErrorFromJSONTyped(json, true), type: 'unexpected-response' };
    default:
      throw new Error(`No variant of SearchIMCCImage exists with 'type=${json['type']}'`);
  }
}

export function SearchIMCCImageToJSON(value?: SearchIMCCImage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value['type']) {
    case 'could-not-auth-imcc':
      return CouldNotAuthIMCCToJSON(value);
    case 'could-not-perform-request':
      return CouldNotPerformRequestToJSON(value);
    case 'failed-to-validate':
      return ValidationErrorToJSON(value);
    case 'imcc-image-not-found':
      return IMCCImageNotFoundToJSON(value);
    case 'imcc-image-search-result-response':
      return IMCCImageSearchResultResponseToJSON(value);
    case 'request-aborted':
      return RequestAbortedErrorToJSON(value);
    case 'unexpected-response':
      return UnexpectedResponseErrorToJSON(value);
    default:
      throw new Error(`No variant of SearchIMCCImage exists with 'type=${value['type']}'`);
  }
}
