import * as t from 'io-ts';

import { hasNumberKey, hasStringKey } from '../../util/type';
import { DefaultApi, SearchRequestBody } from '../../generated-backend-api';
import { FrontendSearchState } from './Search';

export const prioritizedSearchableFields = [
  'name',
  'isShoppable',
  'FY',
  'archive',
  'isPublic',
  'id',
  'tags',
  'type',
  'extension',
  'launch',
  'idHash',
  'productInformation.itemNo',
  'productInformation.productName',
  'property_ContentType.value',
].sort();

const FieldQuery = t.union(
  [
    // regular prefix
    t.type(
      {
        prefix: t.string,
      },
      'PrefixQuery',
    ),

    // numeric queries
    t.type(
      {
        lt: t.number,
      },
      'RangeLTQuery',
    ),
    t.type(
      {
        gt: t.number,
      },
      'RangeGTQuery',
    ),
    t.type(
      {
        lt: t.number,
        gt: t.number,
      },
      'RangeQuery',
    ),
    t.type(
      {
        contains: t.string,
      },
      'ContainsQuery',
    ),

    // exact matches
    t.type(
      {
        exact: t.union([t.number, t.string]),
      },
      'ExactQuery',
    ),

    // not only exact matches
    t.type(
      {
        fuzzy: t.string,
      },
      'FuzzyQuery',
    ),

    /*
   could add
    field exists
    field does not exist
    vector search / similarity
  */
  ],
  'FieldQuery',
);

const SearchQuery = t.record(t.string, FieldQuery, 'SearchQuery');

export const ExtSearchRequestBody = t.intersection(
  [
    t.type({
      query: SearchQuery,
      usageRights: t.array(t.string),
      requestingMarket: t.string,
    }),
    t.partial({
      fields: t.array(t.string),
      limit: t.number,
      offset: t.number,
    }),
  ],
  'SearchRequestBody',
);

export const ASSETS_PER_PAGE = 35;

//  Input query (string representation in frontend fields) to search query (what's sent to backend)
export function frontendSearchStateToSearchRequestBody(
  frontEndQuery: FrontendSearchState,
  usageRights: string[],
  requestingMarket: string,
  offset?: number,
): SearchRequestBody {
  const out: SearchRequestBody = {
    query: {},
    usageRights,
    requestingMarket,
    limit: frontEndQuery.limit,
    offset: offset ?? frontEndQuery.offset,
    sort: frontEndQuery.sort,
  };

  for (const [k, v] of Object.entries(frontEndQuery.query)) {
    if (hasStringKey('gt', v) && hasStringKey('lt', v)) {
      out.query[k] = { gt: Number(v.gt), lt: Number(v.lt) };
    } else if (hasStringKey('gt', v)) {
      out.query[k] = { gt: Number(v.gt) };
    } else if (hasStringKey('lt', v)) {
      out.query[k] = { lt: Number(v.lt) };
    } else {
      out.query[k] = v;
    }
  }

  return out;
}

// Search query (what's sent to backend) to input query (string representation in frontend fields)
export function searchRequestBodyToFrontendSearchState(q: SearchRequestBody) {
  const out: FrontendSearchState = {
    query: {},
    limit: ASSETS_PER_PAGE,
    offset: typeof q.offset === 'number' ? q.offset : 0,
    sort: q.sort,
  };

  for (const [k, v] of Object.entries(q.query)) {
    if (hasNumberKey('gt', v) && hasNumberKey('lt', v)) {
      out.query[k] = { gt: String(v.gt), lt: String(v.lt) };
    } else if (hasNumberKey('gt', v)) {
      out.query[k] = { gt: String(v.gt) };
    } else if (hasNumberKey('lt', v)) {
      out.query[k] = { lt: String(v.lt) };
    } else {
      out.query[k] = v;
    }
  }

  return out;
}

export async function getAllIds(apiClient: DefaultApi, requestBody: SearchRequestBody) {
  const fallbackSort: DirectionalSort = { id: 'asc' };
  const options: SearchRequestBody = {
    ...requestBody,
    limit: 100,
    offset: 0,
    // Supplying a sort param enables the 'infinite' searchAfter pagination
    sort: requestBody.sort?.length ? requestBody.sort : [fallbackSort],
  };
  let ids: string[] = [];
  let assets;
  do {
    const res = await apiClient.SearchExtApi2({
      extApiSearch2RequestBody: { options },
    });
    if (res.isError) {
      throw new Error(JSON.stringify(res));
    }
    assets = res.results.assets;
    ids = ids.concat(assets.map(({ id }) => id));
    options.searchAfter = res.results.searchAfter;
  } while (assets.length);
  return ids;
}

export type DirectionalSort = {
  [key: string]: 'asc' | 'desc';
};
