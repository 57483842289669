/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IDAMContextProducedFor,
  IDAMContextProducedForFromJSON,
  IDAMContextProducedForFromJSONTyped,
  IDAMContextProducedForToJSON,
  IDAMUsageRights,
  IDAMUsageRightsFromJSON,
  IDAMUsageRightsFromJSONTyped,
  IDAMUsageRightsToJSON,
} from './';

/**
 *
 * @export
 * @interface IDAMContext
 */
export interface IDAMContext {
  /**
   *
   * @type {string}
   * @memberof IDAMContext
   */
  agency?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMContext
   */
  editionYear?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMContext
   */
  marketingObjective?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMContext
   */
  fiscalYear?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMContext
   */
  launch?: string;
  /**
   *
   * @type {IDAMContextProducedFor}
   * @memberof IDAMContext
   */
  producedFor?: IDAMContextProducedFor | null;
  /**
   *
   * @type {IDAMUsageRights}
   * @memberof IDAMContext
   */
  usageRights?: IDAMUsageRights;
}

export function IDAMContextFromJSON(json: any): IDAMContext {
  return IDAMContextFromJSONTyped(json, false);
}

export function IDAMContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDAMContext {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    agency: !exists(json, 'agency') ? undefined : json['agency'],
    editionYear: !exists(json, 'editionYear') ? undefined : json['editionYear'],
    marketingObjective: !exists(json, 'marketingObjective')
      ? undefined
      : json['marketingObjective'],
    fiscalYear: !exists(json, 'fiscalYear') ? undefined : json['fiscalYear'],
    launch: !exists(json, 'launch') ? undefined : json['launch'],
    producedFor: !exists(json, 'producedFor')
      ? undefined
      : IDAMContextProducedForFromJSON(json['producedFor']),
    usageRights: !exists(json, 'usageRights')
      ? undefined
      : IDAMUsageRightsFromJSON(json['usageRights']),
  };
}

export function IDAMContextToJSON(value?: IDAMContext | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    agency: value.agency,
    editionYear: value.editionYear,
    marketingObjective: value.marketingObjective,
    fiscalYear: value.fiscalYear,
    launch: value.launch,
    producedFor: IDAMContextProducedForToJSON(value.producedFor),
    usageRights: IDAMUsageRightsToJSON(value.usageRights),
  };
}
