/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
  BynderAssetEditJobListResponse,
  CouldNotFetchFromFSError,
  ValidationError,
  BynderAssetEditJobListResponseFromJSONTyped,
  BynderAssetEditJobListResponseToJSON,
  CouldNotFetchFromFSErrorFromJSONTyped,
  CouldNotFetchFromFSErrorToJSON,
  ValidationErrorFromJSONTyped,
  ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type GetBynderAssetEditJobList
 *
 * @export
 */
export type GetBynderAssetEditJobList =
  | BynderAssetEditJobListResponse
  | CouldNotFetchFromFSError
  | ValidationError;

export function GetBynderAssetEditJobListFromJSON(json: any): GetBynderAssetEditJobList {
  return GetBynderAssetEditJobListFromJSONTyped(json, false);
}

export function GetBynderAssetEditJobListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBynderAssetEditJobList {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json['type']) {
    case 'bynder-asset-edit-job-list-response':
      return {
        ...BynderAssetEditJobListResponseFromJSONTyped(json, true),
        type: 'bynder-asset-edit-job-list-response',
      };
    case 'could-not-fetch-from-fs':
      return {
        ...CouldNotFetchFromFSErrorFromJSONTyped(json, true),
        type: 'could-not-fetch-from-fs',
      };
    case 'failed-to-validate':
      return { ...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate' };
    default:
      throw new Error(`No variant of GetBynderAssetEditJobList exists with 'type=${json['type']}'`);
  }
}

export function GetBynderAssetEditJobListToJSON(value?: GetBynderAssetEditJobList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value['type']) {
    case 'bynder-asset-edit-job-list-response':
      return BynderAssetEditJobListResponseToJSON(value);
    case 'could-not-fetch-from-fs':
      return CouldNotFetchFromFSErrorToJSON(value);
    case 'failed-to-validate':
      return ValidationErrorToJSON(value);
    default:
      throw new Error(
        `No variant of GetBynderAssetEditJobList exists with 'type=${value['type']}'`
      );
  }
}
