import React, { useEffect, useRef, useState } from 'react';
import { SectionWrap } from './SectionWrap';
import Button from '@ingka/button';
import { Loadable } from './util/type';
import {
  FSAssetProd,
  FSAssetDev,
  SearchExtApiResult,
  SearchFSResult,
  SearchIMCCImageResult,
  VIImageFeaturesResult,
  GetVIAssetMetaResult,
  ExtRequestParams,
  PurgeAkamaiCacheResult,
  IDAMAssetResponse,
} from './generated-backend-api';
import Search from '@ingka/search';
import { useBynder } from './util/bynderReactUtils';
import { Section } from './components/section/section';
import ReactJson from 'react-json-view';
import { BynderGetAssetResponse, constructBynderAssetUrl } from './util/bynder-api';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import { useNavigate, useParams } from 'react-router-dom';

import { checkAssetPublishable } from '@ikea-ingka-dam/publishing';
import { resolvedEnv } from './config';

// import ShoppableImage from '@ingka/shoppable-image';
// import Loading, { LoadingBall } from '@ingka/loading';
import WarningIcon from '@ingka/ssr-icon/paths/warning-triangle';
import Table, { TableHeader, TableBody } from '@ingka/table';
import { LoadingBall } from '@ingka/loading';
import * as t from 'io-ts';
import { BynderLoginButton } from './components/bynder-login-button';
import { ExtAssetSummary } from './components/ext-asset-summary';
import { FindSimilarPayload, FindSimilarResponse } from './util/find-similar';
import { useIdamAsset } from './hooks/idam';

interface IAssetStatusProps {
  searchFs: (phs: string[]) => Promise<string[]>;
  searchFsResults: Loadable<SearchFSResult, { phs: string[]; raw?: unknown }>;
  searchImcc: (phs: string[]) => Promise<void>;
  imccImageResults: Loadable<SearchIMCCImageResult, { phs: string[]; raw?: unknown }>;
  searchBynder: (ids: string[]) => Promise<void>;
  searchBynderResults: Loadable<BynderGetAssetResponse[], { ids: string[] }>;
  searchExtApi: (options: ExtRequestParams) => Promise<void>;
  searchExtApiResults: Loadable<SearchExtApiResult, { options: ExtRequestParams; raw?: unknown }>;
  getVIMeta: (id: string) => Promise<GetVIAssetMetaResult>;
  getImageFeatures: (url: string) => Promise<VIImageFeaturesResult>;
  purgeAkamaiCache: (idHash: string) => Promise<PurgeAkamaiCacheResult>;
  bynderUrl: string;
  findSimilar: (payload: FindSimilarPayload) => Promise<FindSimilarResponse>;
  usageRights: string[];
  requestingMarket: string | undefined;
}

function hasKey<K extends string>(k: K, o: unknown): o is { [_ in K]: unknown } {
  return typeof o === 'object' && o !== null && k in o;
}

export function isUnknownObject(x: unknown): x is { [key in PropertyKey]: unknown } {
  return x !== null && typeof x === 'object';
}

const localstorageSaveKey = 'asset-status-search';

export const AssetStatus = (props: React.PropsWithChildren<IAssetStatusProps>): JSX.Element => {
  const {
    searchFsResults,
    imccImageResults,
    searchBynderResults,
    searchExtApiResults,
    bynderUrl,
    getImageFeatures,
    getVIMeta,
    findSimilar,
  } = props;
  const params = useParams<{ ph?: string }>();
  const ph = params.ph;
  const navigate = useNavigate();
  const [lastSearch, setLastSearch] = useState({ text: '', lastBynderSearchText: '' });

  const [text, setText] = useState(() => {
    if (ph) {
      return ph;
    }
    const s = localStorage.getItem(localstorageSaveKey);
    if (s === null) {
      return '';
    }

    return s;
  });
  const bynderWrap = useBynder();

  const {
    searchBynder,
    searchImcc,
    searchExtApi,
    searchFs,
    usageRights,
    requestingMarket,
    purgeAkamaiCache,
  } = props;

  const bynderUserStatus = bynderWrap.user.status;
  const isBynderLoggedIn = bynderWrap.isLoggedIn;

  useEffect(() => {
    if (typeof ph !== 'string' || ph.length === 0) {
      console.log('ph not valid string');
      return;
    }

    const isSame = lastSearch.text === ph;

    if (isSame) {
      // console.log('skipping search same ph');
      return;
    }

    console.log('searching', ph);
    setLastSearch({ ...lastSearch, text: ph });
    if (lastSearch.text !== ph) {
      void searchImcc([ph]);
      void searchExtApi({
        name: ph,
        usageRights,
        requestingMarket: typeof requestingMarket === 'undefined' ? '' : requestingMarket,
      });
      void searchFs([ph]);
    }
  }, [
    ph,
    searchBynder,
    searchExtApi,
    searchFs,
    searchImcc,
    bynderUserStatus,
    lastSearch,
    isBynderLoggedIn,
    usageRights,
    requestingMarket,
  ]);

  useEffect(() => {
    if (typeof ph !== 'string' || ph.length === 0) {
      console.log('ph not valid string');
      return;
    }

    if (
      ph !== lastSearch.lastBynderSearchText &&
      isBynderLoggedIn &&
      searchFsResults.status === 'loaded' &&
      searchFsResults.phs[0] === ph &&
      (searchFsResults.data.type === 'fs-search-result-dev-response' ||
        searchFsResults.data.type === 'fs-search-result-prod-response')
    ) {
      console.log('search bynder trigger', searchFsResults);
      void searchBynder(searchFsResults.data.results.map((a: FSAssetProd | FSAssetDev) => a.id));
      setLastSearch({ ...lastSearch, lastBynderSearchText: ph ? ph : '' });
    }
  }, [isBynderLoggedIn, searchFsResults, searchBynder, lastSearch, setLastSearch, ph]);

  const handleSearchClick = () => {
    const ph = text.trim();
    console.log('search', ph);
    localStorage.setItem(localstorageSaveKey, ph);

    setLastSearch({ text: '', lastBynderSearchText: '' });

    navigate(`/assetstatus/${ph}`, { replace: true });
  };

  // if (!bynderWrap.isLoggedIn) {
  //   return (
  //     <SectionWrap>
  //       <h1>Asset status</h1>
  //       <BynderLoginButton />
  //     </SectionWrap>
  //   );
  // }

  const smallLoader = <LoadingBall size="small"></LoadingBall>;

  const tabs = [];
  const tabPanels = [];

  ////////////////////////////////////////////////////////////////////////////////
  // EXT API
  ////////////////////////////////////////////////////////////////////////////////

  const extHits =
    searchExtApiResults.status === 'loaded' && searchExtApiResults.data.isError === false
      ? `(${searchExtApiResults.data.results.assets.length})`
      : '';

  tabs.push(
    <Tab
      ssrIcon={
        searchExtApiResults.status === 'loaded' && searchExtApiResults.data.isError === true
          ? WarningIcon
          : undefined
      }
      key="tab-api"
      text={searchExtApiResults.status === 'loading' ? smallLoader : `DAM API ${extHits}`}
      tabPanelId="tab_api"
    />,
  );
  tabPanels.push(
    <TabPanel key="tab-api" tabPanelId="tab_api">
      <AssetStatusJson
        summary={buildExtApiSummary(
          searchExtApiResults,
          bynderUrl,
          getImageFeatures,
          getVIMeta,
          findSimilar,
          purgeAkamaiCache,
        )}
        json={() =>
          searchExtApiResults.status === 'loaded' && searchExtApiResults.data.isError === false
            ? searchExtApiResults.raw
            : searchExtApiResults
        }
      />
    </TabPanel>,
  );

  ////////////////////////////////////////////////////////////////////////////////
  // FS
  ////////////////////////////////////////////////////////////////////////////////

  const fsHitCount =
    searchFsResults.status === 'loaded' && searchFsResults.data.isError === false
      ? searchFsResults.data.results.length
      : 0;

  const fsHits =
    searchFsResults.status === 'loaded' && searchFsResults.data.isError === false
      ? `(${searchFsResults.data.results.length})`
      : '';

  tabs.push(
    <Tab
      ssrIcon={
        searchFsResults.status === 'loaded' && searchFsResults.data.isError === true
          ? WarningIcon
          : undefined
      }
      key="tab-firestore"
      text={searchFsResults.status === 'loading' ? smallLoader : `Firestore ${fsHits}`}
      tabPanelId="tab_firestore"
    />,
  );
  tabPanels.push(
    <TabPanel key="tab-firestore" tabPanelId="tab_firestore">
      <AssetStatusJson
        summary={buildFsSummary(searchFsResults, bynderUrl)}
        json={() =>
          searchFsResults.status === 'loaded' && searchFsResults.data.isError === false
            ? searchFsResults.raw
            : searchFsResults
        }
      />
    </TabPanel>,
  );

  ////////////////////////////////////////////////////////////////////////////////
  // BYNDER
  ////////////////////////////////////////////////////////////////////////////////

  let bynderHits = '';

  if (fsHitCount === 0) {
    bynderHits = `(-)`;
  } else if (searchBynderResults.status === 'loaded') {
    bynderHits = `(${searchBynderResults.data.filter((d) => d.isError === false).length})`;
  }

  const anyBynderError = Boolean(
    searchBynderResults.status === 'loaded' &&
      searchBynderResults.data.find((d) => d.isError === true),
  );

  tabs.push(
    <Tab
      ssrIcon={anyBynderError ? WarningIcon : undefined}
      key="tab-bynder"
      text={
        searchFsResults.status === 'loading' || searchBynderResults.status === 'loading'
          ? smallLoader
          : `Bynder ${bynderHits}`
      }
      tabPanelId="tab_bynder"
    />,
  );
  tabPanels.push(
    <TabPanel key="tab-bynder" tabPanelId="tab_bynder">
      <AssetStatusJson
        summary={buildBynderSummary(searchBynderResults, bynderUrl)}
        json={() => {
          if (searchBynderResults.status !== 'loaded' || anyBynderError) {
            return searchBynderResults;
          }

          const out = [];

          for (const r of searchBynderResults.data) {
            if (r.isError) {
              return searchBynderResults;
            }

            out.push(r.result);
          }

          return out;
        }}
      />
    </TabPanel>,
  );

  ////////////////////////////////////////////////////////////////////////////////
  // IMCC
  ////////////////////////////////////////////////////////////////////////////////

  let imccHits =
    imccImageResults.status === 'loaded' && imccImageResults.data.isError === false
      ? `(${imccImageResults.data.results.data.length})`
      : '';

  if (
    imccImageResults.status === 'loaded' &&
    imccImageResults.data.type === 'imcc-image-not-found'
  ) {
    imccHits = `(0)`;
  }

  tabs.push(
    <Tab
      ssrIcon={
        imccImageResults.status === 'loaded' &&
        imccImageResults.data.isError === true &&
        imccImageResults.data.type !== 'imcc-image-not-found'
          ? WarningIcon
          : undefined
      }
      key="tab-imcc"
      text={imccImageResults.status === 'loading' ? smallLoader : `IMCC ${imccHits}`}
      tabPanelId="tab_imcc"
    />,
  );
  tabPanels.push(
    <TabPanel key="tab-imcc" tabPanelId="tab_imcc">
      <AssetStatusJson
        summary={buildImccSummary(imccImageResults)}
        json={() =>
          imccImageResults.status === 'loaded' && imccImageResults.data.isError === false
            ? imccImageResults.raw
            : imccImageResults
        }
      />
    </TabPanel>,
  );

  ////////////////////////////////////////////////////////////////////////////////
  // IDAM
  ////////////////////////////////////////////////////////////////////////////////

  const { data: idamData, isFetched: idamDataFetched } = useIdamAsset(ph as string);

  const getIDAMJson = () => {
    if (!idamDataFetched) return {};

    if (idamData?.isError) {
      return idamData;
    } else {
      return (idamData as IDAMAssetResponse).result;
    }
  };

  tabs.push(
    <Tab
      ssrIcon={idamDataFetched && idamData?.isError ? WarningIcon : undefined}
      key="tab-idam"
      text={!idamDataFetched ? smallLoader : `IDAM`}
      tabPanelId="tab_idam"
    />,
  );
  tabPanels.push(
    <TabPanel key="tab-idam" tabPanelId="tab_idam">
      <AssetStatusJson summary={<h2>IDAM data</h2>} json={() => getIDAMJson()} />
    </TabPanel>,
  );

  //END

  let tabsSection = null;
  if (searchFsResults.status !== 'uninitialized') {
    tabsSection = (
      <Section>
        <Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab="tab_api" />
      </Section>
    );
  }

  return (
    <SectionWrap>
      <Section size="large">
        <h1>Asset status</h1>
      </Section>

      <Section>
        <div style={{ minWidth: '300px', maxWidth: '500px' }}>
          <Search
            type="string"
            id="asset_status_search"
            placeholder="PH123456"
            value={text}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setText(e.currentTarget.value)}
            onSearch={handleSearchClick}
            onClear={() => setText('')}
            disabled={
              searchBynderResults.status === 'loading' ||
              searchExtApiResults.status === 'loading' ||
              searchFsResults.status === 'loading' ||
              imccImageResults.status === 'loading'
            }
          />
        </div>
      </Section>
      <Section>
        <BynderLoginButton />
      </Section>

      {tabsSection}
    </SectionWrap>
  );
};

const Box = t.type({
  l: t.string,
  t: t.string,
  w: t.string,
  h: t.string,
});

const Dot = t.type({
  x: t.string,
  y: t.string,
});

const ProductCoordinate = t.type({
  box: Box,
  dot: Dot,
  prd: t.string,
  t: t.string,
});

const ProductCoordinateList = t.array(ProductCoordinate);

function getBynderProductCoordinates(a: unknown): t.TypeOf<typeof ProductCoordinateList> | false {
  if (!hasKey('property_product_coordinates', a)) {
    return false;
  }

  if (Array.isArray(a.property_product_coordinates) && a.property_product_coordinates.length <= 0) {
    return false;
  }

  if (
    !(
      Array.isArray(a.property_product_coordinates) &&
      hasKey('value', a.property_product_coordinates[0]) &&
      typeof a.property_product_coordinates[0].value === 'string'
    )
  ) {
    return false;
  } else {
    const v = a.property_product_coordinates[0].value;
    let c: unknown;
    try {
      c = JSON.parse(v);
    } catch (_) {
      return false;
    }

    if (ProductCoordinateList.is(c)) {
      return c;
    } else {
      return false;
    }
  }
}

function buildExtApiSummary(
  json: Loadable<SearchExtApiResult, unknown>,
  bynderUrl: string,
  getImageFeatures: (url: string) => Promise<VIImageFeaturesResult>,
  getVIMeta: (id: string) => Promise<GetVIAssetMetaResult>,
  findSimilar: (payload: FindSimilarPayload) => Promise<FindSimilarResponse>,
  purgeAkamaiCache: (idHash: string) => Promise<PurgeAkamaiCacheResult>,
): JSX.Element {
  if (json.status !== 'loaded') {
    return <h3>No summary available</h3>;
  }

  if (json.data.isError === true) {
    return <div>Loaded with error: {json.data.type}.</div>;
  }

  const rows = json.data.results.assets.map((a) => {
    return (
      <ExtAssetSummary
        key={a.id}
        asset={a}
        bynderUrl={bynderUrl}
        getImageFeatures={getImageFeatures}
        findSimilar={findSimilar}
        getVIMeta={getVIMeta}
        purgeAkamaiCache={purgeAkamaiCache}
      />
    );
  });

  return <div>{rows}</div>;
}

function buildBynderSummary(
  json: Loadable<BynderGetAssetResponse[], unknown>,
  bynderUrl: string,
): JSX.Element {
  let summary = <h3>No summary available</h3>;

  if (json.status !== 'loaded') {
    return summary;
  }

  let i = 0;
  summary = (
    <Table>
      <TableHeader sticky={true}>
        <tr>
          <th>#</th>
          <th>Image</th>
          <th>Name</th>
          <th>ID</th>
          <th>Shoppable</th>
          <th>Main product</th>
          <th>Bynder public</th>
          <th>Public</th>
          <th>Archived</th>
          <th>Disclaimer text</th>
          <th>Release date</th>
        </tr>
      </TableHeader>
      <TableBody>
        {json.data.map((res, index) => {
          if (res.isError) {
            return (
              <tr key={`${res.type}_${index}`}>
                <td>Failed: {res.type}</td>
              </tr>
            );
          }

          const row = res.result;

          const coords = getBynderProductCoordinates(row);
          let mainProduct: unknown = undefined;
          if (
            hasKey('property_product_coordinates', row) &&
            typeof row.property_product_coordinates === 'string'
          ) {
            try {
              const j: unknown = JSON.parse(row.property_product_coordinates);
              if (Array.isArray(j) && hasKey('prd', j[0])) {
                mainProduct = j[0].prd;
              } else {
                mainProduct = 'Bad coordinates';
              }
            } catch (e) {
              mainProduct = 'Bad JSON format';
            }
          }

          // Yeah. So it is property_disclaimertext in dev but property_DisclaimerText in prod
          const disclaimer: string = hasKey('property_disclaimertext', row)
            ? Array.isArray(row.property_disclaimertext) &&
              typeof row.property_disclaimertext[0] === 'string'
              ? row.property_disclaimertext[0]
              : 'N/A'
            : hasKey('property_DisclaimerText', row) &&
                Array.isArray(row.property_DisclaimerText) &&
                typeof row.property_DisclaimerText[0] === 'string'
              ? row.property_DisclaimerText[0]
              : 'N/A';

          return (
            <tr key={`${i}-${JSON.stringify(row)}`}>
              <td>{i++}</td>
              <td>
                {hasKey('thumbnails', row) &&
                hasKey('webimage', row.thumbnails) &&
                typeof row.thumbnails.webimage === 'string' ? (
                  <img src={row.thumbnails.webimage}></img>
                ) : (
                  'N/A'
                )}
              </td>
              <td>{hasKey('name', row) && typeof row.name === 'string' ? row.name : 'N/A'}</td>
              <td>
                {hasKey('id', row) && typeof row.id === 'string' ? (
                  <a
                    href={constructBynderAssetUrl(bynderUrl, row.id)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {row.id}
                  </a>
                ) : (
                  'N/A'
                )}
              </td>
              <td>
                {hasKey('property_product_coordinates', row) &&
                typeof row.property_product_coordinates === 'string' &&
                row.property_product_coordinates.length > 0
                  ? 'Yes'
                  : 'No'}
              </td>
              <td>{coords && coords[0] && coords[0].prd ? coords[0].prd : 'N/A'}</td>
              <td>{mainProduct && typeof mainProduct === 'string' ? mainProduct : 'N/A'}</td>
              <td>
                {hasKey('isPublic', row) && typeof row.isPublic === 'number' && row.isPublic !== 0
                  ? 'Yes'
                  : 'No'}
              </td>
              <td>
                {hasKey('archive', row) && typeof row.archive === 'number' && row.archive !== 0
                  ? 'Yes'
                  : 'No'}
              </td>
              <td>{disclaimer}</td>
              <td>
                {hasKey('property_ReleaseDate', row) && typeof row.property_ReleaseDate === 'string'
                  ? row.property_ReleaseDate
                  : 'N/A'}
              </td>
            </tr>
          );
        })}
      </TableBody>
    </Table>
  );
  return summary;
}

function buildImccSummary(json: Loadable<SearchIMCCImageResult, unknown>): JSX.Element {
  let summary = <h3>No summary available</h3>;

  if (json.status !== 'loaded') {
    return summary;
  }

  if (json.data.isError === true) {
    if (json.data.type === 'imcc-image-not-found') {
      return <div>Not found.</div>;
    }

    return <div>Failed to fetch IMCC response. Error: {json.data.type}</div>;
  }

  let i = 0;

  summary = (
    <Table>
      <TableHeader sticky={true}>
        <tr>
          <th>#</th>
          <th>Image</th>
          <th>Name</th>
          <th>IMCC ID</th>
          <th>Release date</th>
          <th>Main product</th>
        </tr>
      </TableHeader>
      <TableBody>
        {json.data.results.data.map((row) => {
          return (
            <tr key={`${i}-${JSON.stringify(row)}`}>
              <td>{i++}</td>
              <td>
                <a href={row.attributes.imageUrl}>Full image here</a>
              </td>
              <td>{row.attributes.name}</td>
              <td>{row.id}</td>
              <td>{row.attributes.releasedAt}</td>
              <td>
                {row.attributes.mainProduct.articleNumber
                  ? row.attributes.mainProduct.articleNumber
                  : 'N/A'}
              </td>
            </tr>
          );
        })}
      </TableBody>
    </Table>
  );
  return summary;
}

function getFSProductCoordinates(
  a: FSAssetDev | FSAssetProd,
): t.TypeOf<typeof ProductCoordinateList> | false {
  if (!a.property_product_coordinates) {
    return false;
  }

  let c: unknown;
  try {
    c = JSON.parse(a.property_product_coordinates);
  } catch (_) {
    return false;
  }

  if (ProductCoordinateList.is(c)) {
    return c;
  } else {
    return false;
  }
}

interface IShoppableProps {
  url: string;
  products: t.TypeOf<typeof ProductCoordinateList>;
}

function useOnScreen(ref: React.RefObject<Element>) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

interface IShoppableDotProps {
  product: t.TypeOf<typeof ProductCoordinate>;
}

const ShoppableDot = (props: IShoppableDotProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const p = props.product;

  return (
    <div
      className={`shoppable-image__dot focus-visible${
        isHovered ? ' shoppable-image__dot--active' : ''
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        top: `${Number(p.dot.y) * 100}%`,
        left: `${Number(p.dot.x) * 100}%`,
        position: 'absolute',
        cursor: 'pointer',
      }}
    ></div>
  );
};

const Shoppable = (props: IShoppableProps) => {
  const targetRef = useRef<HTMLImageElement>(null);
  const isVisible = useOnScreen(targetRef);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!targetRef.current) {
      return;
    }

    const r = targetRef.current.getBoundingClientRect();
    if (dimensions.width !== r.width || dimensions.height !== r.height) {
      setDimensions({
        width: r.width,
        height: r.height,
      });
    }
  }, [dimensions.width, dimensions.height, isVisible]);

  const dots = props.products.map((p) => {
    return <ShoppableDot key={p.prd} product={p} />;
  });

  return (
    <div ref={targetRef} style={{ position: 'relative' }} className="shoppable-image--visible-dots">
      {dimensions.width > 100 ? dots : ''}
      <img src={props.url} />
    </div>
  );
};

function extractDisclaimerText(asset: FSAssetProd | FSAssetDev, type: string): string {
  if (type === 'fs-search-result-dev-response') {
    const row: FSAssetDev = asset;
    return row.property_disclaimertext &&
      hasKey('global', row.property_disclaimertext) &&
      isUnknownObject(row.property_disclaimertext.global)
      ? Object.keys(row.property_disclaimertext.global).join(',')
      : 'N/A';
  } else {
    const row: FSAssetProd = asset;
    return row.property_DisclaimerText &&
      hasKey('global', row.property_DisclaimerText) &&
      isUnknownObject(row.property_DisclaimerText.global)
      ? Object.keys(row.property_DisclaimerText.global).join(',')
      : 'N/A';
  }
  return 'ERROR';
}

function extractReleaseDate(asset: FSAssetProd | FSAssetDev): string {
  if (!asset.property_ReleaseDate) {
    return 'N/A';
  }
  try {
    if (
      hasKey('_seconds', asset.property_ReleaseDate) &&
      hasKey('_nanoseconds', asset.property_ReleaseDate)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return new Date((asset.property_ReleaseDate._seconds as number) * 1000).toDateString();
    }
    return 'N/A';
  } catch (e) {
    return 'ERROR';
  }
}

function buildFsSummary(json: Loadable<SearchFSResult, unknown>, _bynderUrl: string): JSX.Element {
  let summary = <h3>No summary available</h3>;

  if (json.status !== 'loaded') {
    return summary;
  }

  if (json.data.isError === true) {
    return <div>Could not load fs results. Error: {json.data.type}</div>;
  }

  let i = 0;

  summary = (
    <Table>
      <TableHeader sticky={true}>
        <tr>
          <th>#</th>
          <th>Image</th>
          <th>Name</th>
          <th>ID</th>
          <th>Shoppable</th>
          <th>Main product</th>
          <th>Public</th>
          <th>Bynder public</th>
          <th>Disclaimer text</th>
          <th>Release date</th>
          <th>Publishable</th>
        </tr>
      </TableHeader>
      <TableBody>
        {json.data.results.map((row: FSAssetProd | FSAssetDev) => {
          let img: React.ReactNode = 'N/A';

          const coords = getFSProductCoordinates(row);
          if (
            hasKey('thumbnails', row) &&
            hasKey('webimage', row.thumbnails) &&
            typeof row.thumbnails.webimage === 'string'
          ) {
            if (row.isShoppable && coords) {
              img = <Shoppable url={row.thumbnails.webimage} products={coords} />;
            } else {
              img = <img src={row.thumbnails.webimage} />;
            }
          }
          const disclaimerText = extractDisclaimerText(row, json.data.type);
          const fsReleaseDate = extractReleaseDate(row);

          const publishableResult = checkAssetPublishable(
            row as unknown as Record<string, unknown>,
            resolvedEnv === 'dev' ? 'STD_DEV' : 'STD_PROD',
          );

          return (
            <tr key={`${i}-${JSON.stringify(row)}`}>
              <td>{i++}</td>
              <td>{img}</td>
              <td>{row.name}</td>
              <td>{row.id}</td>
              <td>{row.isShoppable !== 0 ? 'Yes' : 'No'}</td>
              <td>{coords && coords[0] && coords[0].prd ? coords[0].prd : 'N/A'}</td>
              <td>{row.isNetstorePublic !== 0 ? 'Yes' : 'No'}</td>
              <td>{row.isPublic !== 0 ? 'Yes' : 'No'}</td>
              <td>{disclaimerText}</td>
              <td>{fsReleaseDate}</td>
              <td>{publishableResult.publishable ? 'Yes' : publishableResult.reason}</td>
            </tr>
          );
        })}
      </TableBody>
    </Table>
  );

  return summary;
}

interface AssetStatusJsonProps {
  summary?: JSX.Element;
  json: () => unknown;
}

const AssetStatusJson = (props: AssetStatusJsonProps) => {
  const { summary, json } = props;
  const [expanded, setExpanded] = useState(true);

  const data = json();

  return (
    <div>
      <Section>
        {summary && <Section size="large">{summary}</Section>}

        <Section>
          <div>
            <b>Full data</b>

            <Button
              style={{ marginLeft: '30px' }}
              small={true}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? 'Collapse' : 'Expand all'}
            </Button>
          </div>
        </Section>
        <ReactJson
          src={
            typeof data === 'object'
              ? data === null
                ? { Error: 'Null data' }
                : data
              : { Error: 'Non object data' }
          }
          sortKeys={true}
          name={false}
          collapsed={!expanded}
          collapseStringsAfterLength={165}
        />
      </Section>
    </div>
  );
};
