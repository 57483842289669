/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  JobFailedError,
  JobFailedErrorFromJSON,
  JobFailedErrorFromJSONTyped,
  JobFailedErrorToJSON,
} from './';

/**
 *
 * @export
 * @interface JobFailed
 */
export interface JobFailed {
  /**
   *
   * @type {string}
   * @memberof JobFailed
   */
  type: JobFailedTypeEnum;
  /**
   *
   * @type {number}
   * @memberof JobFailed
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof JobFailed
   */
  attempt: number;
  /**
   *
   * @type {JobFailedError}
   * @memberof JobFailed
   */
  error: JobFailedError;
}

/**
 * @export
 * @enum {string}
 */
export type JobFailedTypeEnum = 'job-failed';

export function JobFailedFromJSON(json: any): JobFailed {
  return JobFailedFromJSONTyped(json, false);
}

export function JobFailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobFailed {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    timestamp: json['timestamp'],
    attempt: json['attempt'],
    error: JobFailedErrorFromJSON(json['error']),
  };
}

export function JobFailedToJSON(value?: JobFailed | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    timestamp: value.timestamp,
    attempt: value.attempt,
    error: JobFailedErrorToJSON(value.error),
  };
}
