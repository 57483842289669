/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  FSAssetDevThumbnails,
  FSAssetDevThumbnailsFromJSON,
  FSAssetDevThumbnailsFromJSONTyped,
  FSAssetDevThumbnailsToJSON,
  FSAssetDevVisualSearch,
  FSAssetDevVisualSearchFromJSON,
  FSAssetDevVisualSearchFromJSONTyped,
  FSAssetDevVisualSearchToJSON,
} from './';

/**
 *
 * @export
 * @interface FSAssetDev
 */
export interface FSAssetDev {
  /**
   *
   * @type {FSAssetDevVisualSearch}
   * @memberof FSAssetDev
   */
  visualSearch?: FSAssetDevVisualSearch;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  activeOriginalFocusPoint?: object;
  /**
   *
   * @type {number}
   * @memberof FSAssetDev
   */
  archive?: number;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  aspectRatio?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  brandId?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  copyright?: string;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  customerSystem?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  dateCreated?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  dateModified?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  datePublished?: object;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof FSAssetDev
   */
  downloads?: number;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  extension?: object;
  /**
   *
   * @type {number}
   * @memberof FSAssetDev
   */
  fileSize?: number;
  /**
   *
   * @type {number}
   * @memberof FSAssetDev
   */
  height?: number;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  idHash?: string;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  imAsset?: object;
  /**
   *
   * @type {number}
   * @memberof FSAssetDev
   */
  isNetstorePublic?: number;
  /**
   *
   * @type {number}
   * @memberof FSAssetDev
   */
  isPublic?: number;
  /**
   *
   * @type {number}
   * @memberof FSAssetDev
   */
  isShoppable?: number;
  /**
   *
   * @type {number}
   * @memberof FSAssetDev
   */
  limited?: number;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  mediaItems?: object;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  nameLowerCase?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  orientation?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  original?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  originalName?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  originalNameLowerCase?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  phash?: string;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  productInformation?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_AvailableAsOf?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_Copyright?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_Creating_Market?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_EmbargoEndDate?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_IntendedTiming?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_NegotiatedUsageRights?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_ReleaseDate?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_Season?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_SubDelivery?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_TestDate?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_briefname?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_bynder_export?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_campaignsdeliveries?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_channel?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_contenttype?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_crop_info?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_disclaimer?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_disclaimertext?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_icom_project_name?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_inputmaterials?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_language?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_launch?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_layoutID?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_markets?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_objective?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_owner?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_planninginput?: object;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  property_product_coordinates?: string;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_product_list?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_room?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_sectionofchannel?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_solutionID?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_style?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  property_theme?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetDev
   */
  relatedAssets?: object;
  /**
   *
   * @type {{ [key: string]: boolean; }}
   * @memberof FSAssetDev
   */
  tags?: { [key: string]: boolean };
  /**
   *
   * @type {FSAssetDevThumbnails}
   * @memberof FSAssetDev
   */
  thumbnails?: FSAssetDevThumbnails;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof FSAssetDev
   */
  views?: number;
  /**
   *
   * @type {number}
   * @memberof FSAssetDev
   */
  watermarked?: number;
  /**
   *
   * @type {number}
   * @memberof FSAssetDev
   */
  width?: number;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetDev
   */
  id: string;
}

export function FSAssetDevFromJSON(json: any): FSAssetDev {
  return FSAssetDevFromJSONTyped(json, false);
}

export function FSAssetDevFromJSONTyped(json: any, ignoreDiscriminator: boolean): FSAssetDev {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    visualSearch: !exists(json, 'visualSearch')
      ? undefined
      : FSAssetDevVisualSearchFromJSON(json['visualSearch']),
    activeOriginalFocusPoint: !exists(json, 'activeOriginalFocusPoint')
      ? undefined
      : json['activeOriginalFocusPoint'],
    archive: !exists(json, 'archive') ? undefined : json['archive'],
    aspectRatio: !exists(json, 'aspectRatio') ? undefined : json['aspectRatio'],
    brandId: !exists(json, 'brandId') ? undefined : json['brandId'],
    copyright: !exists(json, 'copyright') ? undefined : json['copyright'],
    customerSystem: !exists(json, 'customerSystem') ? undefined : json['customerSystem'],
    dateCreated: !exists(json, 'dateCreated') ? undefined : json['dateCreated'],
    dateModified: !exists(json, 'dateModified') ? undefined : json['dateModified'],
    datePublished: !exists(json, 'datePublished') ? undefined : json['datePublished'],
    description: !exists(json, 'description') ? undefined : json['description'],
    downloads: !exists(json, 'downloads') ? undefined : json['downloads'],
    extension: !exists(json, 'extension') ? undefined : json['extension'],
    fileSize: !exists(json, 'fileSize') ? undefined : json['fileSize'],
    height: !exists(json, 'height') ? undefined : json['height'],
    idHash: !exists(json, 'idHash') ? undefined : json['idHash'],
    imAsset: !exists(json, 'imAsset') ? undefined : json['imAsset'],
    isNetstorePublic: !exists(json, 'isNetstorePublic') ? undefined : json['isNetstorePublic'],
    isPublic: !exists(json, 'isPublic') ? undefined : json['isPublic'],
    isShoppable: !exists(json, 'isShoppable') ? undefined : json['isShoppable'],
    limited: !exists(json, 'limited') ? undefined : json['limited'],
    mediaItems: !exists(json, 'mediaItems') ? undefined : json['mediaItems'],
    nameLowerCase: !exists(json, 'nameLowerCase') ? undefined : json['nameLowerCase'],
    orientation: !exists(json, 'orientation') ? undefined : json['orientation'],
    original: !exists(json, 'original') ? undefined : json['original'],
    originalName: !exists(json, 'originalName') ? undefined : json['originalName'],
    originalNameLowerCase: !exists(json, 'originalNameLowerCase')
      ? undefined
      : json['originalNameLowerCase'],
    phash: !exists(json, 'phash') ? undefined : json['phash'],
    productInformation: !exists(json, 'productInformation')
      ? undefined
      : json['productInformation'],
    property_AvailableAsOf: !exists(json, 'property_AvailableAsOf')
      ? undefined
      : json['property_AvailableAsOf'],
    property_Copyright: !exists(json, 'property_Copyright')
      ? undefined
      : json['property_Copyright'],
    property_Creating_Market: !exists(json, 'property_Creating_Market')
      ? undefined
      : json['property_Creating_Market'],
    property_EmbargoEndDate: !exists(json, 'property_EmbargoEndDate')
      ? undefined
      : json['property_EmbargoEndDate'],
    property_IntendedTiming: !exists(json, 'property_IntendedTiming')
      ? undefined
      : json['property_IntendedTiming'],
    property_NegotiatedUsageRights: !exists(json, 'property_NegotiatedUsageRights')
      ? undefined
      : json['property_NegotiatedUsageRights'],
    property_ReleaseDate: !exists(json, 'property_ReleaseDate')
      ? undefined
      : json['property_ReleaseDate'],
    property_Season: !exists(json, 'property_Season') ? undefined : json['property_Season'],
    property_SubDelivery: !exists(json, 'property_SubDelivery')
      ? undefined
      : json['property_SubDelivery'],
    property_TestDate: !exists(json, 'property_TestDate') ? undefined : json['property_TestDate'],
    property_briefname: !exists(json, 'property_briefname')
      ? undefined
      : json['property_briefname'],
    property_bynder_export: !exists(json, 'property_bynder_export')
      ? undefined
      : json['property_bynder_export'],
    property_campaignsdeliveries: !exists(json, 'property_campaignsdeliveries')
      ? undefined
      : json['property_campaignsdeliveries'],
    property_channel: !exists(json, 'property_channel') ? undefined : json['property_channel'],
    property_contenttype: !exists(json, 'property_contenttype')
      ? undefined
      : json['property_contenttype'],
    property_crop_info: !exists(json, 'property_crop_info')
      ? undefined
      : json['property_crop_info'],
    property_disclaimer: !exists(json, 'property_disclaimer')
      ? undefined
      : json['property_disclaimer'],
    property_disclaimertext: !exists(json, 'property_disclaimertext')
      ? undefined
      : json['property_disclaimertext'],
    property_icom_project_name: !exists(json, 'property_icom_project_name')
      ? undefined
      : json['property_icom_project_name'],
    property_inputmaterials: !exists(json, 'property_inputmaterials')
      ? undefined
      : json['property_inputmaterials'],
    property_language: !exists(json, 'property_language') ? undefined : json['property_language'],
    property_launch: !exists(json, 'property_launch') ? undefined : json['property_launch'],
    property_layoutID: !exists(json, 'property_layoutID') ? undefined : json['property_layoutID'],
    property_markets: !exists(json, 'property_markets') ? undefined : json['property_markets'],
    property_objective: !exists(json, 'property_objective')
      ? undefined
      : json['property_objective'],
    property_owner: !exists(json, 'property_owner') ? undefined : json['property_owner'],
    property_planninginput: !exists(json, 'property_planninginput')
      ? undefined
      : json['property_planninginput'],
    property_product_coordinates: !exists(json, 'property_product_coordinates')
      ? undefined
      : json['property_product_coordinates'],
    property_product_list: !exists(json, 'property_product_list')
      ? undefined
      : json['property_product_list'],
    property_room: !exists(json, 'property_room') ? undefined : json['property_room'],
    property_sectionofchannel: !exists(json, 'property_sectionofchannel')
      ? undefined
      : json['property_sectionofchannel'],
    property_solutionID: !exists(json, 'property_solutionID')
      ? undefined
      : json['property_solutionID'],
    property_style: !exists(json, 'property_style') ? undefined : json['property_style'],
    property_theme: !exists(json, 'property_theme') ? undefined : json['property_theme'],
    relatedAssets: !exists(json, 'relatedAssets') ? undefined : json['relatedAssets'],
    tags: !exists(json, 'tags') ? undefined : json['tags'],
    thumbnails: !exists(json, 'thumbnails')
      ? undefined
      : FSAssetDevThumbnailsFromJSON(json['thumbnails']),
    type: !exists(json, 'type') ? undefined : json['type'],
    views: !exists(json, 'views') ? undefined : json['views'],
    watermarked: !exists(json, 'watermarked') ? undefined : json['watermarked'],
    width: !exists(json, 'width') ? undefined : json['width'],
    name: json['name'],
    id: json['id'],
  };
}

export function FSAssetDevToJSON(value?: FSAssetDev | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    visualSearch: FSAssetDevVisualSearchToJSON(value.visualSearch),
    activeOriginalFocusPoint: value.activeOriginalFocusPoint,
    archive: value.archive,
    aspectRatio: value.aspectRatio,
    brandId: value.brandId,
    copyright: value.copyright,
    customerSystem: value.customerSystem,
    dateCreated: value.dateCreated,
    dateModified: value.dateModified,
    datePublished: value.datePublished,
    description: value.description,
    downloads: value.downloads,
    extension: value.extension,
    fileSize: value.fileSize,
    height: value.height,
    idHash: value.idHash,
    imAsset: value.imAsset,
    isNetstorePublic: value.isNetstorePublic,
    isPublic: value.isPublic,
    isShoppable: value.isShoppable,
    limited: value.limited,
    mediaItems: value.mediaItems,
    nameLowerCase: value.nameLowerCase,
    orientation: value.orientation,
    original: value.original,
    originalName: value.originalName,
    originalNameLowerCase: value.originalNameLowerCase,
    phash: value.phash,
    productInformation: value.productInformation,
    property_AvailableAsOf: value.property_AvailableAsOf,
    property_Copyright: value.property_Copyright,
    property_Creating_Market: value.property_Creating_Market,
    property_EmbargoEndDate: value.property_EmbargoEndDate,
    property_IntendedTiming: value.property_IntendedTiming,
    property_NegotiatedUsageRights: value.property_NegotiatedUsageRights,
    property_ReleaseDate: value.property_ReleaseDate,
    property_Season: value.property_Season,
    property_SubDelivery: value.property_SubDelivery,
    property_TestDate: value.property_TestDate,
    property_briefname: value.property_briefname,
    property_bynder_export: value.property_bynder_export,
    property_campaignsdeliveries: value.property_campaignsdeliveries,
    property_channel: value.property_channel,
    property_contenttype: value.property_contenttype,
    property_crop_info: value.property_crop_info,
    property_disclaimer: value.property_disclaimer,
    property_disclaimertext: value.property_disclaimertext,
    property_icom_project_name: value.property_icom_project_name,
    property_inputmaterials: value.property_inputmaterials,
    property_language: value.property_language,
    property_launch: value.property_launch,
    property_layoutID: value.property_layoutID,
    property_markets: value.property_markets,
    property_objective: value.property_objective,
    property_owner: value.property_owner,
    property_planninginput: value.property_planninginput,
    property_product_coordinates: value.property_product_coordinates,
    property_product_list: value.property_product_list,
    property_room: value.property_room,
    property_sectionofchannel: value.property_sectionofchannel,
    property_solutionID: value.property_solutionID,
    property_style: value.property_style,
    property_theme: value.property_theme,
    relatedAssets: value.relatedAssets,
    tags: value.tags,
    thumbnails: FSAssetDevThumbnailsToJSON(value.thumbnails),
    type: value.type,
    views: value.views,
    watermarked: value.watermarked,
    width: value.width,
    name: value.name,
    id: value.id,
  };
}
