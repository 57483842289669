import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const Stack = ({
  children,
  vertical,
  gap,
}: {
  children: React.ReactNode;
  vertical?: boolean;
  gap?: number | string;
}) => {
  let gapValue = '10px';

  if (typeof gap === 'number') {
    gapValue = `${gap}px`;
  } else if (typeof gap === 'string') {
    gapValue = gap;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${vertical ? 'column' : 'row'};
        gap: ${gapValue};
        align-items: flex-start;
      `}
    >
      {children}
    </div>
  );
};
