/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import { IDAMSelf, IDAMSelfFromJSON, IDAMSelfFromJSONTyped, IDAMSelfToJSON } from './';

/**
 *
 * @export
 * @interface IDAMLinks
 */
export interface IDAMLinks {
  /**
   *
   * @type {IDAMSelf}
   * @memberof IDAMLinks
   */
  self?: IDAMSelf;
}

export function IDAMLinksFromJSON(json: any): IDAMLinks {
  return IDAMLinksFromJSONTyped(json, false);
}

export function IDAMLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDAMLinks {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    self: !exists(json, 'self') ? undefined : IDAMSelfFromJSON(json['self']),
  };
}

export function IDAMLinksToJSON(value?: IDAMLinks | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    self: IDAMSelfToJSON(value.self),
  };
}
