/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface AkamaiPurgeCache
 */
export interface AkamaiPurgeCache {
  /**
   *
   * @type {Array<string>}
   * @memberof AkamaiPurgeCache
   */
  idHashList: Array<string>;
}

export function AkamaiPurgeCacheFromJSON(json: any): AkamaiPurgeCache {
  return AkamaiPurgeCacheFromJSONTyped(json, false);
}

export function AkamaiPurgeCacheFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AkamaiPurgeCache {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    idHashList: json['idHashList'],
  };
}

export function AkamaiPurgeCacheToJSON(value?: AkamaiPurgeCache | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    idHashList: value.idHashList,
  };
}
