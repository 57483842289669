/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IDAMData,
  IDAMDataFromJSON,
  IDAMDataFromJSONTyped,
  IDAMDataToJSON,
  IDAMJsonapi,
  IDAMJsonapiFromJSON,
  IDAMJsonapiFromJSONTyped,
  IDAMJsonapiToJSON,
  IDAMLinks,
  IDAMLinksFromJSON,
  IDAMLinksFromJSONTyped,
  IDAMLinksToJSON,
} from './';

/**
 *
 * @export
 * @interface IDAMAssetInterface
 */
export interface IDAMAssetInterface {
  /**
   *
   * @type {IDAMJsonapi}
   * @memberof IDAMAssetInterface
   */
  jsonapi: IDAMJsonapi;
  /**
   *
   * @type {IDAMLinks}
   * @memberof IDAMAssetInterface
   */
  links: IDAMLinks;
  /**
   *
   * @type {IDAMData}
   * @memberof IDAMAssetInterface
   */
  data: IDAMData;
}

export function IDAMAssetInterfaceFromJSON(json: any): IDAMAssetInterface {
  return IDAMAssetInterfaceFromJSONTyped(json, false);
}

export function IDAMAssetInterfaceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IDAMAssetInterface {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    jsonapi: IDAMJsonapiFromJSON(json['jsonapi']),
    links: IDAMLinksFromJSON(json['links']),
    data: IDAMDataFromJSON(json['data']),
  };
}

export function IDAMAssetInterfaceToJSON(value?: IDAMAssetInterface | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    jsonapi: IDAMJsonapiToJSON(value.jsonapi),
    links: IDAMLinksToJSON(value.links),
    data: IDAMDataToJSON(value.data),
  };
}
