import * as t from 'io-ts';

export const Box = t.type({
  l: t.string,
  t: t.string,
  w: t.string,
  h: t.string,
});

export const Dot = t.type({
  x: t.string,
  y: t.string,
});

export const ProductCoordinate = t.intersection([
  t.type({
    box: Box,
    dot: Dot,
    prd: t.string,
  }),
  t.partial({
    t: t.string,
  }),
]);

export type ProductCoordinateType = t.TypeOf<typeof ProductCoordinate>;

export const ProductCoordinateList = t.array(ProductCoordinate);

export type ProductCoordinateListType = t.TypeOf<typeof ProductCoordinateList>;
