/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface AssetPropertyInputMaterials
 */
export interface AssetPropertyInputMaterials {
  /**
   *
   * @type {string}
   * @memberof AssetPropertyInputMaterials
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof AssetPropertyInputMaterials
   */
  value?: string;
}

export function AssetPropertyInputMaterialsFromJSON(json: any): AssetPropertyInputMaterials {
  return AssetPropertyInputMaterialsFromJSONTyped(json, false);
}

export function AssetPropertyInputMaterialsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssetPropertyInputMaterials {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    language: !exists(json, 'language') ? undefined : json['language'],
    value: !exists(json, 'value') ? undefined : json['value'],
  };
}

export function AssetPropertyInputMaterialsToJSON(value?: AssetPropertyInputMaterials | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    language: value.language,
    value: value.value,
  };
}
