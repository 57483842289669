import React, { useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';
import { BynderAssetCardById } from '../components/bynder-asset-card-by-id';
import { Grid } from '../components/grid';
import { WithLoadedLoadable } from '../components/with-loaded-loadable';
import { SectionWrap } from '../SectionWrap';
import { INotLoggedInError } from '../util/bynder-api';
import { useBynder } from '../util/bynderReactUtils';
import { ICouldNotPerformRequest, IRequestAbortedError } from '../util/common';
import { IResult, Loadable } from '../util/type';

export const CropBoardPage = () => {
  const [collectionAssetsLoadable, setCollectionAssetsLoadable] = React.useState<
    Loadable<IResult<string[]> | INotLoggedInError | ICouldNotPerformRequest | IRequestAbortedError>
  >({
    status: 'uninitialized',
  });

  const { id } = useParams();
  const bynder = useBynder();

  useEffect(() => {
    if (!bynder.isLoggedIn || !id || collectionAssetsLoadable.status !== 'uninitialized') {
      return;
    }

    setCollectionAssetsLoadable({ status: 'loading' });

    void (async () => {
      const collectionAssets = await bynder.bynder.getCollectionAssets(id, new AbortController());

      setCollectionAssetsLoadable({ status: 'loaded', data: collectionAssets });
    })();
  }, [bynder, id, collectionAssetsLoadable]);

  if (!id) {
    return <div>Missing id</div>;
  }

  if (!bynder.isLoggedIn) {
    return <div>Bynder not loaded</div>;
  }

  if (collectionAssetsLoadable.status === 'loading') {
    return <div>Loading</div>;
  }

  return (
    <SectionWrap>
      <div>
        <Link to="/crop/boards">Back to boards.</Link>
      </div>
      <WithLoadedLoadable loadable={collectionAssetsLoadable} fallback={<div>Loading...</div>}>
        {(collectionAssets) => {
          if (collectionAssets.isError) {
            return <div>Could not load collection</div>;
          }

          return (
            <Grid colGap={20} columns={5} rowGap={20}>
              {collectionAssets.result.map((assetId) => {
                return <BynderAssetCardById key={assetId} id={assetId} />;
              })}
            </Grid>
          );
        }}
      </WithLoadedLoadable>
    </SectionWrap>
  );
};
