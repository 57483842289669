import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Select, { Option } from '@ingka/select';
import { OptionDivider } from './option-divider';

export const SearchQueryFieldsSelect = ({
  value,
  isFieldEnabled,
  onChange,
  searchableFields,
  label,
  id,
  prioritizedFields,
}: {
  label: string;
  id: string;
  prioritizedFields?: string[];
  value?: string;
  isFieldEnabled?: (v: string) => boolean;
  onChange?: (value: string) => void;
  searchableFields: string[];
}) => {
  const fields = searchableFields.filter((f) => !prioritizedFields?.includes(f));

  return (
    <Select
      css={css`
        max-width: 300px;
      `}
      label={label}
      id={id}
      value={value}
      onChange={(e: React.FormEvent<HTMLSelectElement>) => {
        if (onChange) {
          onChange(e.currentTarget.value);
        }
      }}
    >
      {prioritizedFields?.map((f) => (
        <Option
          key={f}
          value={f}
          label={f}
          disabled={isFieldEnabled ? !isFieldEnabled(f) : false}
        />
      ))}
      {prioritizedFields && <OptionDivider />}
      {fields.map((f) => {
        return (
          <Option
            key={f}
            value={f}
            label={f}
            disabled={isFieldEnabled ? !isFieldEnabled(f) : false}
          />
        );
      })}
    </Select>
  );
};
