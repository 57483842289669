import { baseUrl, cacheLocation } from '../config';
import { Configuration } from '@azure/msal-browser';

export const config: Configuration = {
  auth: {
    clientId: '36365be5-94f4-41f2-b7c2-330f0d06f8af',
    redirectUri: baseUrl,
    authority: 'https://login.microsoftonline.com/720b637a-655a-40cf-816a-f22f40755c2c/',
    postLogoutRedirectUri: baseUrl,
  },
  cache: {
    cacheLocation,
  },
  system: {
    tokenRenewalOffsetSeconds: 5 * 60,
  },
};

export const ladanBackendScope = 'api://36365be5-94f4-41f2-b7c2-330f0d06f8af/verktygsladan';
