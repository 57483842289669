import React, { useState } from 'react';
import { ProductCoordinateType } from '../util/product-coordinates';

interface IShoppableDotProps {
  product: ProductCoordinateType;
  onClick?: (art: string) => void;
  isActive?: boolean;
}

export function ShoppableDot(props: IShoppableDotProps): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);

  const p = props.product;

  return (
    <div
      className={`shoppable-image__dot focus-visible${
        isHovered ? ' shoppable-image__dot--active' : ''
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (props.onClick) {
          props.onClick(props.product.prd);
        }
      }}
      style={{
        border: props.isActive ? '2px solid #bebebe' : 'inherit',
        top: `${Number(p.dot.y) * 100}%`,
        left: `${Number(p.dot.x) * 100}%`,
        position: 'absolute',
        cursor: 'pointer',
        zIndex: 100,
      }}
    ></div>
  );
}
