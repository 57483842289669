import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { BynderAssetEditJob, BynderAssetUploadJob, MetaProperty } from '../generated-backend-api';
import { formatDate } from '../util/common';
import { TemplateSummary } from './template-summary';

interface JobItemDetailsProps {
  job: BynderAssetUploadJob | BynderAssetEditJob;
  propMap: Map<string, MetaProperty>;
}
export function JobItemDetails(props: JobItemDetailsProps): JSX.Element {
  const { job, propMap } = props;

  let bynderProps;

  if (job.item.bynderUploadMeta) {
    const o: { [key: string]: string[] } = {};

    for (const [k, v] of Object.entries(job.item.bynderUploadMeta)) {
      o[k.replace('metaproperty.', '')] = String(v).split(',');
    }

    bynderProps = (
      <TemplateSummary
        values={o}
        propMap={propMap}
        description={job.item.description}
        limited={job.item.limited}
        limitedDate={job.item.limitedDate}
        name={job.item.name}
        originalName={job.item.type === 'upload-job' ? job.item.originalName : undefined}
        tags={job.item.tags}
      />
    );
  }

  return (
    <div css={css``}>
      <div>id: {job.id}</div>
      <div>job type: {job.item.type}</div>
      <div>last update: {formatDate(job.lastUpdate)}</div>
      <div>Props:</div>
      <div
        css={css`
          max-width: 500px;
          padding: 10px;
        `}
      >
        {bynderProps}
      </div>
    </div>
  );
}
