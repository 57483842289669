import React from 'react';
import {
  BynderAssetJobRetryResult,
  BynderAssetUploadJob,
  BynderMetaResult,
  CreateBynderAssetJobResult,
  SearchFSResult,
  UploadJobItem,
} from './generated-backend-api';

import { Loadable } from './util/type';
import { JobList } from './components/job-list';
import { SectionWrap } from './SectionWrap';
import { Section } from './components/section/section';
import { UploadForm } from './components/upload-form';
import { IDAMAssetResultWrapper, UploadsListLoadable } from './app-state';
import { FindSimilarPayload, FindSimilarResponse } from './util/find-similar';

interface IUploadProps {
  bynderMeta: Loadable<BynderMetaResult>;
  loadBynderMeta: () => void;
  assetsToUpload: UploadJobItem[];
  uploadToBynder: (items: UploadJobItem[]) => Promise<CreateBynderAssetJobResult | false>;
  bynderBaseUrl: string;
  loadUploadsList: (startBefore?: number) => Promise<unknown> | undefined;
  uploadsList: UploadsListLoadable;
  searchFS: (phs: string[]) => void;
  searchFSResult: Loadable<SearchFSResult, { phs: string[] }>;
  setUploadJobItems: (jobs: UploadJobItem[]) => void;
  searchIDAMImage: (phs: string[]) => void;
  searchIDAMImageResult: Loadable<IDAMAssetResultWrapper, { phs: string[] }>;
  retryJob: (job: BynderAssetUploadJob) => void;
  retryResults: Record<string, Loadable<BynderAssetJobRetryResult> | undefined>;
  findSimilarImages: (
    payloads: FindSimilarPayload[],
    onProgress?: (percentage: number) => void,
  ) => Promise<FindSimilarResponse[]>;
}

export function Upload(props: IUploadProps): JSX.Element {
  let upload;

  if (props.assetsToUpload.length > 0) {
    upload = (
      <>
        <Section>
          <h2>Create upload jobs</h2>
        </Section>
        <UploadForm
          assetsToUpload={props.assetsToUpload}
          bynderBaseUrl={props.bynderBaseUrl}
          bynderMeta={props.bynderMeta}
          loadBynderMeta={props.loadBynderMeta}
          loadUploadsList={props.loadUploadsList}
          searchFS={props.searchFS}
          searchFSResult={props.searchFSResult}
          setUploadJobItems={props.setUploadJobItems}
          uploadToBynder={props.uploadToBynder}
          uploadsList={props.uploadsList}
          searchIDAMImage={props.searchIDAMImage}
          searchIDAMImageResult={props.searchIDAMImageResult}
          findSimilarImages={props.findSimilarImages}
        />
      </>
    );
  } else {
    // upload = <Section>No assets selected for upload.</Section>;
    upload = null;
  }

  return (
    <>
      <SectionWrap>
        {upload}
        <Section>
          <Section>
            <h2>Upload jobs</h2>
          </Section>
          <JobList
            fetchJobsList={props.loadUploadsList}
            loadBynderMeta={props.loadBynderMeta}
            bynderMeta={props.bynderMeta}
            list={props.uploadsList}
            bynderBaseUrl={props.bynderBaseUrl}
            retryJob={props.retryJob}
            retryResults={props.retryResults}
          />
        </Section>
      </SectionWrap>
    </>
  );
}
