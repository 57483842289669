/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface CouldNotFetchMeta
 */
export interface CouldNotFetchMeta {
  /**
   *
   * @type {string}
   * @memberof CouldNotFetchMeta
   */
  detail: string;
  /**
   *
   * @type {string}
   * @memberof CouldNotFetchMeta
   */
  type: CouldNotFetchMetaTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export type CouldNotFetchMetaTypeEnum = 'could-not-fetch-meta';

export function CouldNotFetchMetaFromJSON(json: any): CouldNotFetchMeta {
  return CouldNotFetchMetaFromJSONTyped(json, false);
}

export function CouldNotFetchMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CouldNotFetchMeta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    detail: json['detail'],
    type: json['type'],
  };
}

export function CouldNotFetchMetaToJSON(value?: CouldNotFetchMeta | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    detail: value.detail,
    type: value.type,
  };
}
