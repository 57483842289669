/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface JobUploadingToAws
 */
export interface JobUploadingToAws {
  /**
   *
   * @type {string}
   * @memberof JobUploadingToAws
   */
  type: JobUploadingToAwsTypeEnum;
  /**
   *
   * @type {number}
   * @memberof JobUploadingToAws
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof JobUploadingToAws
   */
  bytesUploaded: number;
  /**
   *
   * @type {number}
   * @memberof JobUploadingToAws
   */
  attempt: number;
}

/**
 * @export
 * @enum {string}
 */
export type JobUploadingToAwsTypeEnum = 'job-uploading-to-aws';

export function JobUploadingToAwsFromJSON(json: any): JobUploadingToAws {
  return JobUploadingToAwsFromJSONTyped(json, false);
}

export function JobUploadingToAwsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): JobUploadingToAws {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    timestamp: json['timestamp'],
    bytesUploaded: json['bytesUploaded'],
    attempt: json['attempt'],
  };
}

export function JobUploadingToAwsToJSON(value?: JobUploadingToAws | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    timestamp: value.timestamp,
    bytesUploaded: value.bytesUploaded,
    attempt: value.attempt,
  };
}
