import React, { useEffect, useRef } from 'react';
import { BynderGetAssetResponse } from '../util/bynder-api';
import { useBynder } from '../util/bynderReactUtils';
import { Loadable } from '../util/type';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import AspectRatioBox from '@ingka/aspect-ratio-box';
import Skeleton from '@ingka/skeleton';
import { useIsVisible } from '../hooks/useIsVisible';

export const BynderAssetCardById = ({ id }: { id: string }) => {
  const bynder = useBynder();
  const ref = useRef(null);
  const isVisible = useIsVisible(ref);

  const [assetLoadable, setAssetLoadable] = React.useState<Loadable<BynderGetAssetResponse>>({
    status: 'uninitialized',
  });

  useEffect(() => {
    if (!bynder.isLoggedIn || !id || assetLoadable.status !== 'uninitialized' || !isVisible) {
      return;
    }

    setAssetLoadable({ status: 'loading' });

    void (async () => {
      const asset = await bynder.bynder.getAsset(id);
      setAssetLoadable({ status: 'loaded', data: asset });
    })();
  }, [bynder, id, assetLoadable, isVisible]);

  if (assetLoadable.status !== 'loaded') {
    return (
      <div ref={ref}>
        <AspectRatioBox>
          <Skeleton className="image" />
        </AspectRatioBox>
      </div>
    );
  }

  if (assetLoadable.data.isError) {
    return <div>Could not load asset</div>;
  }

  const asset = assetLoadable.data.result;

  return (
    <div
      css={css`
        padding: 10px;
        &:hover {
          background-color: #f5f5f5;
        }
      `}
    >
      <Link to={`/crop/${id}`}>
        <img
          css={css`
            max-width: 100%;
          `}
          src={asset.thumbnails?.webimage}
          alt={asset.name}
        />
        <br />
        Name: {asset.name}
      </Link>
    </div>
  );
};
