import React, { useEffect, useState } from 'react';
import Search from '@ingka/search';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useDebouncedCallback } from 'use-debounce';
import { Loadable } from '../util/type';
// import Loading, { LoadingBall } from '@ingka/loading';

interface SelectOption {
  label: React.ReactNode;
  value: string;
}

interface SelectProps<T extends SelectOption> {
  getOptions: (input: string) => Promise<T[]>;
  onSelect: (opt: T) => void;
  id: string;
  placeholder?: string;
  headerRight?: (text: string) => React.ReactNode;
  disabled?: boolean;
}

export function Select<T extends SelectOption>(props: SelectProps<T>): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);
  const [options, setOptions] = useState<Loadable<T[], { text: string; lastOptions?: T[] }>>({
    status: 'uninitialized',
  });
  // const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState('');

  const getOptions = useDebouncedCallback(async (text: string) => {
    setOptions({
      status: 'loading',
      text: text,
      lastOptions: options.status === 'loaded' ? options.data : [],
    });
    const opts = await props.getOptions(text);
    setOptions({ status: 'loaded', data: opts, text: text });
  }, 300);

  useEffect(() => {
    if (isFocused !== true) {
      return;
    }
    void getOptions(text);
  }, [getOptions, isFocused, text]);

  let optionsToRender;

  if (options.status === 'loaded') {
    optionsToRender = options.data;
  } else if (options.status === 'loading' && options.lastOptions) {
    optionsToRender = options.lastOptions;
  }

  let optionRows;
  let headerOption;

  if (optionsToRender) {
    const headerCSS = css`
      padding: 10px;
      display: flex;
      justify-content: space-between;
    `;

    if (options.status === 'loaded') {
      headerOption = (
        <div key={`${text}_Loaded`} css={headerCSS}>
          <span>
            Showing {optionsToRender.length} results for &quot;{options.text}&quot;
          </span>
          {props.headerRight ? <span>{props.headerRight(text)}</span> : null}
        </div>
      );
    } else {
      headerOption = (
        <div key={`${text}_Loading`} css={headerCSS}>
          Loading...
        </div>
      );
    }

    optionRows = optionsToRender.map((o) => {
      return (
        <div
          key={o.value}
          css={css`
            cursor: pointer;
            padding: 10px;

            &:hover {
              text-decoration: underline;
            }
          `}
          onClick={() => {
            setIsFocused(false);
            props.onSelect(o);
          }}
        >
          {o.label}
        </div>
      );
    });
  }

  optionRows = [headerOption, ...(optionRows ? optionRows : [])];

  let overlay;

  if (isFocused) {
    overlay = (
      <div
        onClick={() => {
          setIsFocused(false);
        }}
        css={css`
          outline: none;
          margin: 0;
          padding: 0;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          height: 100vh;
          cursor: pointer;
          background: #111;
          transition: opacity 0.2s ease-in-out;
          opacity: 0.15;
          z-index: 100;
        `}
      />
    );
  }

  return (
    <div>
      <div
        css={css`
          z-index: 200;
          position: relative;
          background-color: #fff;
          padding: 10px;
          border-radius: 0.5rem;
        `}
      >
        <Search
          id={props.id}
          placeholder={props.placeholder}
          value={text}
          disabled={props.disabled}
          onClear={() => setText('')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setText(e.currentTarget.value)}
          onFocus={() => setIsFocused(true)}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Enter') {
              void getOptions(text);
            }
          }}
        />
        <div
          css={css`
            background-color: #fff;
            position: absolute;
            width: 100%;
            padding: 10px;
            margin-left: -10px;
            border-radius: 0.5rem;
          `}
          style={{ display: isFocused ? 'block' : 'none' }}
        >
          {optionRows}
        </div>
      </div>

      {overlay}
    </div>
  );
}
