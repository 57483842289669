/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IMCCSearchHit,
  IMCCSearchHitFromJSON,
  IMCCSearchHitFromJSONTyped,
  IMCCSearchHitToJSON,
  IMCCSearchInfo,
  IMCCSearchInfoFromJSON,
  IMCCSearchInfoFromJSONTyped,
  IMCCSearchInfoToJSON,
} from './';

/**
 *
 * @export
 * @interface IMCCSearchResult
 */
export interface IMCCSearchResult {
  /**
   *
   * @type {Array<IMCCSearchHit>}
   * @memberof IMCCSearchResult
   */
  hits: Array<IMCCSearchHit>;
  /**
   *
   * @type {IMCCSearchInfo}
   * @memberof IMCCSearchResult
   */
  info: IMCCSearchInfo;
}

export function IMCCSearchResultFromJSON(json: any): IMCCSearchResult {
  return IMCCSearchResultFromJSONTyped(json, false);
}

export function IMCCSearchResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCSearchResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    hits: (json['hits'] as Array<any>).map(IMCCSearchHitFromJSON),
    info: IMCCSearchInfoFromJSON(json['info']),
  };
}

export function IMCCSearchResultToJSON(value?: IMCCSearchResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    hits: (value.hits as Array<any>).map(IMCCSearchHitToJSON),
    info: IMCCSearchInfoToJSON(value.info),
  };
}
