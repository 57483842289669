/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface BynderAssetJobRetryResponse
 */
export interface BynderAssetJobRetryResponse {
  /**
   *
   * @type {boolean}
   * @memberof BynderAssetJobRetryResponse
   */
  isError: BynderAssetJobRetryResponseIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof BynderAssetJobRetryResponse
   */
  type: BynderAssetJobRetryResponseTypeEnum;
}

/**
 * @export
 * @enum {boolean}
 */
export type BynderAssetJobRetryResponseIsErrorEnum = false;

/**
 * @export
 * @enum {string}
 */
export type BynderAssetJobRetryResponseTypeEnum = 'bynder-asset-job-retry-response';

export function BynderAssetJobRetryResponseFromJSON(json: any): BynderAssetJobRetryResponse {
  return BynderAssetJobRetryResponseFromJSONTyped(json, false);
}

export function BynderAssetJobRetryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BynderAssetJobRetryResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
  };
}

export function BynderAssetJobRetryResponseToJSON(value?: BynderAssetJobRetryResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
  };
}
