/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface RequestAbortedError
 */
export interface RequestAbortedError {
  /**
   *
   * @type {boolean}
   * @memberof RequestAbortedError
   */
  isError: RequestAbortedErrorIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof RequestAbortedError
   */
  type: RequestAbortedErrorTypeEnum;
  /**
   *
   * @type {object}
   * @memberof RequestAbortedError
   */
  error: object;
}

/**
 * @export
 * @enum {boolean}
 */
export type RequestAbortedErrorIsErrorEnum = true;

/**
 * @export
 * @enum {string}
 */
export type RequestAbortedErrorTypeEnum = 'request-aborted';

export function RequestAbortedErrorFromJSON(json: any): RequestAbortedError {
  return RequestAbortedErrorFromJSONTyped(json, false);
}

export function RequestAbortedErrorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RequestAbortedError {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
    error: json['error'],
  };
}

export function RequestAbortedErrorToJSON(value?: RequestAbortedError | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
    error: value.error,
  };
}
