/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  MappingResult,
  MappingResultFromJSON,
  MappingResultFromJSONTyped,
  MappingResultToJSON,
} from './';

/**
 *
 * @export
 * @interface ElasticSearchMappingResponse
 */
export interface ElasticSearchMappingResponse {
  /**
   *
   * @type {boolean}
   * @memberof ElasticSearchMappingResponse
   */
  isError: ElasticSearchMappingResponseIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof ElasticSearchMappingResponse
   */
  type: ElasticSearchMappingResponseTypeEnum;
  /**
   *
   * @type {MappingResult}
   * @memberof ElasticSearchMappingResponse
   */
  result: MappingResult;
}

/**
 * @export
 * @enum {boolean}
 */
export type ElasticSearchMappingResponseIsErrorEnum = false;

/**
 * @export
 * @enum {string}
 */
export type ElasticSearchMappingResponseTypeEnum = 'elastic-search-mapping-response';

export function ElasticSearchMappingResponseFromJSON(json: any): ElasticSearchMappingResponse {
  return ElasticSearchMappingResponseFromJSONTyped(json, false);
}

export function ElasticSearchMappingResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ElasticSearchMappingResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
    result: MappingResultFromJSON(json['result']),
  };
}

export function ElasticSearchMappingResponseToJSON(
  value?: ElasticSearchMappingResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
    result: MappingResultToJSON(value.result),
  };
}
