/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import { FSAssetDev, FSAssetDevFromJSON, FSAssetDevFromJSONTyped, FSAssetDevToJSON } from './';

/**
 *
 * @export
 * @interface FSSearchResultResponseDev
 */
export interface FSSearchResultResponseDev {
  /**
   *
   * @type {boolean}
   * @memberof FSSearchResultResponseDev
   */
  isError: FSSearchResultResponseDevIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof FSSearchResultResponseDev
   */
  type: FSSearchResultResponseDevTypeEnum;
  /**
   *
   * @type {Array<FSAssetDev>}
   * @memberof FSSearchResultResponseDev
   */
  results: Array<FSAssetDev>;
}

/**
 * @export
 * @enum {boolean}
 */
export type FSSearchResultResponseDevIsErrorEnum = false;

/**
 * @export
 * @enum {string}
 */
export type FSSearchResultResponseDevTypeEnum = 'fs-search-result-dev-response';

export function FSSearchResultResponseDevFromJSON(json: any): FSSearchResultResponseDev {
  return FSSearchResultResponseDevFromJSONTyped(json, false);
}

export function FSSearchResultResponseDevFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FSSearchResultResponseDev {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
    results: (json['results'] as Array<any>).map(FSAssetDevFromJSON),
  };
}

export function FSSearchResultResponseDevToJSON(value?: FSSearchResultResponseDev | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
    results: (value.results as Array<any>).map(FSAssetDevToJSON),
  };
}
