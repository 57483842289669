/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  BynderAssetUploadJobMessages,
  BynderAssetUploadJobMessagesFromJSON,
  BynderAssetUploadJobMessagesFromJSONTyped,
  BynderAssetUploadJobMessagesToJSON,
  BynderAssetUploadJobUser,
  BynderAssetUploadJobUserFromJSON,
  BynderAssetUploadJobUserFromJSONTyped,
  BynderAssetUploadJobUserToJSON,
  UploadJobItem,
  UploadJobItemFromJSON,
  UploadJobItemFromJSONTyped,
  UploadJobItemToJSON,
} from './';

/**
 *
 * @export
 * @interface BynderAssetUploadJob
 */
export interface BynderAssetUploadJob {
  /**
   *
   * @type {number}
   * @memberof BynderAssetUploadJob
   */
  version: BynderAssetUploadJobVersionEnum;
  /**
   *
   * @type {string}
   * @memberof BynderAssetUploadJob
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BynderAssetUploadJob
   */
  secrets: string;
  /**
   *
   * @type {UploadJobItem}
   * @memberof BynderAssetUploadJob
   */
  item: UploadJobItem;
  /**
   *
   * @type {BynderAssetUploadJobUser}
   * @memberof BynderAssetUploadJob
   */
  user: BynderAssetUploadJobUser;
  /**
   *
   * @type {number}
   * @memberof BynderAssetUploadJob
   */
  attempts: number;
  /**
   *
   * @type {number}
   * @memberof BynderAssetUploadJob
   */
  created: number;
  /**
   *
   * @type {number}
   * @memberof BynderAssetUploadJob
   */
  lastUpdate: number;
  /**
   *
   * @type {Array<BynderAssetUploadJobMessages>}
   * @memberof BynderAssetUploadJob
   */
  messages: Array<BynderAssetUploadJobMessages>;
  /**
   *
   * @type {number}
   * @memberof BynderAssetUploadJob
   */
  maxAttempts: number;
}

/**
 * @export
 * @enum {string}
 */
export type BynderAssetUploadJobVersionEnum = 2;

export function BynderAssetUploadJobFromJSON(json: any): BynderAssetUploadJob {
  return BynderAssetUploadJobFromJSONTyped(json, false);
}

export function BynderAssetUploadJobFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BynderAssetUploadJob {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    version: json['version'],
    id: json['id'],
    secrets: json['secrets'],
    item: UploadJobItemFromJSON(json['item']),
    user: BynderAssetUploadJobUserFromJSON(json['user']),
    attempts: json['attempts'],
    created: json['created'],
    lastUpdate: json['lastUpdate'],
    messages: (json['messages'] as Array<any>).map(BynderAssetUploadJobMessagesFromJSON),
    maxAttempts: json['maxAttempts'],
  };
}

export function BynderAssetUploadJobToJSON(value?: BynderAssetUploadJob | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    version: value.version,
    id: value.id,
    secrets: value.secrets,
    item: UploadJobItemToJSON(value.item),
    user: BynderAssetUploadJobUserToJSON(value.user),
    attempts: value.attempts,
    created: value.created,
    lastUpdate: value.lastUpdate,
    messages: (value.messages as Array<any>).map(BynderAssetUploadJobMessagesToJSON),
    maxAttempts: value.maxAttempts,
  };
}
