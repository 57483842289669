import { DefaultApi, SimilarityResult, ExtApiAssetList } from '../generated-backend-api';
import { FindSimilarFilter } from './find-similar';

export type ClipSearchPayload = {
  type: 'text';
  text: string;
  count?: number;
  filter: FindSimilarFilter;
};

export type ClipSearchResponse =
  | false
  | {
      searchResult: ExtApiAssetList;
      similar: { id: string; distance: number }[];
    };

/**
 * Finds approximately similar images to the given text.
 */
export async function clipSearch(
  payload: ClipSearchPayload,
  client: DefaultApi,
): Promise<ClipSearchResponse> {
  const featureResult = await client.VIClipFeatures({
    vITextClipFeaturesPayload: {
      text: payload.text,
    },
  });

  if (featureResult.type !== 'clip-features') {
    return false;
  }

  const features = featureResult.result.embedding;

  if (features.length !== 512) {
    console.error('Unexpected feature length', featureResult);
    return false;
  }

  const { count, filter } = payload;
  const searchResult = await client.SearchElasticClipSimilarity({
    elasticSearchSimilarRequestBody: {
      vector: features,
      ...(count ? { count } : {}),
      ...(filter.valid ? { filter } : {}),
    },
  });

  if (searchResult.isError === true) {
    return false;
  }

  const h = searchResult.result.hits.hits;
  const z = h.map((q: SimilarityResult) => q._source);

  return {
    searchResult: { assets: z, notFound: [] },
    similar: h.map((q) => {
      return {
        id: q._id,
        distance: q._score,
      };
    }),
  };
}
