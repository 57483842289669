/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface VITextClipFeaturesPayload
 */
export interface VITextClipFeaturesPayload {
  /**
   *
   * @type {string}
   * @memberof VITextClipFeaturesPayload
   */
  text: string;
}

export function VITextClipFeaturesPayloadFromJSON(json: any): VITextClipFeaturesPayload {
  return VITextClipFeaturesPayloadFromJSONTyped(json, false);
}

export function VITextClipFeaturesPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VITextClipFeaturesPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    text: json['text'],
  };
}

export function VITextClipFeaturesPayloadToJSON(value?: VITextClipFeaturesPayload | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    text: value.text,
  };
}
