import { IDAMData, UploadJobItem } from '../generated-backend-api';

type UniqueKeyData =
  | {
      type: 'upload-job-item';
      data: UploadJobItem;
    }
  | {
      type: 'idam-data';
      data: IDAMData;
    };

export const getUniqueKey = ({ type, data }: UniqueKeyData): string => {
  let name, extension;

  if (type === 'upload-job-item') {
    name = data.name;
    extension = data.extension;
  } else {
    name = data.name ?? '';
    extension = data.files?.renditions?.[0]?.extension ?? '';
  }

  // strip dot from extension and make lowercase, since format form imcc and idam differs
  return name + extension.replace(/\./g, '').toLowerCase();
};
