/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IDAMUsageRights,
  IDAMUsageRightsFromJSON,
  IDAMUsageRightsFromJSONTyped,
  IDAMUsageRightsToJSON,
} from './';

/**
 *
 * @export
 * @interface IDAMStoreCommunication
 */
export interface IDAMStoreCommunication {
  /**
   *
   * @type {string}
   * @memberof IDAMStoreCommunication
   */
  carrierType?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMStoreCommunication
   */
  hfb?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMStoreCommunication
   */
  productArea?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMStoreCommunication
   */
  editionYear?: string;
  /**
   *
   * @type {IDAMUsageRights}
   * @memberof IDAMStoreCommunication
   */
  usageRights?: IDAMUsageRights;
}

export function IDAMStoreCommunicationFromJSON(json: any): IDAMStoreCommunication {
  return IDAMStoreCommunicationFromJSONTyped(json, false);
}

export function IDAMStoreCommunicationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IDAMStoreCommunication {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    carrierType: !exists(json, 'carrierType') ? undefined : json['carrierType'],
    hfb: !exists(json, 'hfb') ? undefined : json['hfb'],
    productArea: !exists(json, 'productArea') ? undefined : json['productArea'],
    editionYear: !exists(json, 'editionYear') ? undefined : json['editionYear'],
    usageRights: !exists(json, 'usageRights')
      ? undefined
      : IDAMUsageRightsFromJSON(json['usageRights']),
  };
}

export function IDAMStoreCommunicationToJSON(value?: IDAMStoreCommunication | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    carrierType: value.carrierType,
    hfb: value.hfb,
    productArea: value.productArea,
    editionYear: value.editionYear,
    usageRights: IDAMUsageRightsToJSON(value.usageRights),
  };
}
