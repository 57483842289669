import React from 'react';

import InputField from '@ingka/input-field';
import { useSearchableFields } from '../hooks/searchableFields';
import { FindSimilarFilter } from '../util/find-similar';
import { prioritizedSearchableFields } from './Search/helpers';
import { SearchQueryFieldsSelect } from './search-query-field-select';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const mapValue = (v: string) => {
  const intValue = parseInt(v, 10);
  return intValue.toString() === v ? intValue : v === 'true' || (v === 'false' ? false : v);
};

export const SimilarityFilter = ({
  filter,
  onChange,
}: {
  filter: FindSimilarFilter;
  onChange: (filter: FindSimilarFilter) => void;
}) => {
  const validateChange = ({ key, value }: FindSimilarFilter) => {
    onChange({
      key,
      value,
      valid: !!(key && key !== 'Choose an option' && (value || value === 0 || value === false)),
    });
  };

  const { data: searchableFields } = useSearchableFields();
  return (
    <div
      css={css`
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
      `}
    >
      <SearchQueryFieldsSelect
        onChange={(key) => validateChange({ ...filter, key })}
        searchableFields={searchableFields}
        prioritizedFields={prioritizedSearchableFields}
        label="Exclude assets with property"
        id="filter-key"
      />
      <InputField
        type="text"
        id="filter-value"
        css={css`
          margin-left: 10px;
        `}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          validateChange({ ...filter, value: mapValue(e.currentTarget.value) })
        }
      />
    </div>
  );
};
