import React from 'react';
import Modal, { Sheets, ModalBody, ModalHeader, ModalFooter } from '@ingka/modal';
import Button from '@ingka/button';
import { AppState } from '../app-state';
import InputField from '@ingka/input-field';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Section } from './section/section';
import FormField from '@ingka/form-field';

interface ProfileModalProps {
  onClose: () => void;
  userSettings: AppState['userSettings'];
  onUpdateUserSettings: (settings: AppState['userSettings']) => void;
}

export function ProfileModal(props: ProfileModalProps): JSX.Element {
  const { userSettings } = props;

  const handleClose = () => {
    props.onClose();
  };

  const profileBody = () => {
    return (
      <div>
        <h3
          css={css`
            margin-bottom: 20px;
          `}
        >
          Profile
        </h3>
        <Section>
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <FormField
              shouldValidate={false}
              fieldHelper={{
                msg: 'Global and/or INGKA and/or any 2 letter country code. Example: "Global,INGKA,se" or "Global"',
              }}
            >
              <InputField
                type="number"
                id={`profile_usagerights`}
                placeholder="Global,INGKA,..."
                label={'Usage rights'}
                value={userSettings.usageRights.join(',')}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  props.onUpdateUserSettings({
                    ...userSettings,
                    usageRights: e.currentTarget.value.split(',').map((q) => q.trim()),
                  });
                }}
              />
            </FormField>
          </div>

          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <FormField
              shouldValidate={false}
              fieldHelper={{
                msg: 'Any 2 letter country code. Example: "se"',
              }}
            >
              <InputField
                type="number"
                id={`profile_requestingmarket`}
                placeholder="se"
                label={'Requesting market'}
                value={
                  typeof userSettings.requestingMarket === 'undefined'
                    ? ''
                    : userSettings.requestingMarket
                }
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  props.onUpdateUserSettings({
                    ...userSettings,
                    requestingMarket: e.currentTarget.value.trim(),
                  });
                }}
              />
            </FormField>
          </div>
        </Section>
      </div>
    );
  };

  return (
    <Modal
      visible={true} // Opens / Closes modal
      escapable={false} // the modal view can't be dismissed without interacting with modal content
      handleCloseBtn={props.onClose} // on Internal close request
    >
      <Sheets
        title="Share board with users" // Prompt's heading
        header={<ModalHeader></ModalHeader>}
        footer={
          <ModalFooter>
            <Button text="Close" type="primary" onClick={() => handleClose()} />
          </ModalFooter>
        }
      >
        <ModalBody>{profileBody()}</ModalBody>
      </Sheets>
    </Modal>
  );
}
