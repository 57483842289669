/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface UploadJobItemCrop
 */
export interface UploadJobItemCrop {
  /**
   *
   * @type {number}
   * @memberof UploadJobItemCrop
   */
  x: number;
  /**
   *
   * @type {number}
   * @memberof UploadJobItemCrop
   */
  y: number;
  /**
   *
   * @type {number}
   * @memberof UploadJobItemCrop
   */
  width: number;
  /**
   *
   * @type {number}
   * @memberof UploadJobItemCrop
   */
  height: number;
}

export function UploadJobItemCropFromJSON(json: any): UploadJobItemCrop {
  return UploadJobItemCropFromJSONTyped(json, false);
}

export function UploadJobItemCropFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UploadJobItemCrop {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    x: json['x'],
    y: json['y'],
    width: json['width'],
    height: json['height'],
  };
}

export function UploadJobItemCropToJSON(value?: UploadJobItemCrop | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    x: value.x,
    y: value.y,
    width: value.width,
    height: value.height,
  };
}
