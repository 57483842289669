/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface PIAItemCountrySpecificParent
 */
export interface PIAItemCountrySpecificParent {
  /**
   *
   * @type {string}
   * @memberof PIAItemCountrySpecificParent
   */
  parentItemType: string;
  /**
   *
   * @type {string}
   * @memberof PIAItemCountrySpecificParent
   */
  parentItemNo: string;
}

export function PIAItemCountrySpecificParentFromJSON(json: any): PIAItemCountrySpecificParent {
  return PIAItemCountrySpecificParentFromJSONTyped(json, false);
}

export function PIAItemCountrySpecificParentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PIAItemCountrySpecificParent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    parentItemType: json['parentItemType'],
    parentItemNo: json['parentItemNo'],
  };
}

export function PIAItemCountrySpecificParentToJSON(
  value?: PIAItemCountrySpecificParent | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    parentItemType: value.parentItemType,
    parentItemNo: value.parentItemNo,
  };
}
