/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface ProductColorResult
 */
export interface ProductColorResult {
  /**
   *
   * @type {string}
   * @memberof ProductColorResult
   */
  color_name: string;
  /**
   *
   * @type {number}
   * @memberof ProductColorResult
   */
  part: number;
  /**
   *
   * @type {number}
   * @memberof ProductColorResult
   */
  score: number;
}

export function ProductColorResultFromJSON(json: any): ProductColorResult {
  return ProductColorResultFromJSONTyped(json, false);
}

export function ProductColorResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductColorResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    color_name: json['color_name'],
    part: json['part'],
    score: json['score'],
  };
}

export function ProductColorResultToJSON(value?: ProductColorResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    color_name: value.color_name,
    part: value.part,
    score: value.score,
  };
}
