import * as t from 'io-ts';
import { Matrix } from 'react-spreadsheet';

const LOCALSTORAGE_DATA_KEY = 'edit-asset-table-data';

type StoredTableType = t.TypeOf<typeof StoredTable>;

const StoredTable = t.type({
  headers: t.array(t.string),
  data: t.array(
    t.array(
      t.type({
        value: t.string,
      }),
    ),
  ),
});

interface IFetchStoredTable {
  headers: string[];
  data: {
    value: string;
  }[][];
}
const fetchStoredTable = (): null | IFetchStoredTable => {
  const data = localStorage.getItem(LOCALSTORAGE_DATA_KEY);

  if (data === null) {
    return null;
  }

  try {
    const d: unknown = JSON.parse(data);

    if (StoredTable.is(d)) {
      return d;
    }

    console.warn('Could not validate stored table', d);

    return null;
  } catch (e) {
    console.error(`Could not parse stored data`, e);

    return null;
  }
};

const storeTableData = (data: Matrix<{ value: string }>, headers: string[]): void => {
  localStorage.setItem(
    LOCALSTORAGE_DATA_KEY,
    JSON.stringify({
      headers,
      data,
    }),
  );
};

function getDefaults(): StoredTableType {
  const tableData = fetchStoredTable();

  if (tableData === null) {
    const data: { value: string }[][] = [[{ value: '' }, { value: '' }]];

    return {
      data,
      headers: data[0].map((_) => 'empty'),
    };
  }

  return tableData;
}

export { getDefaults, fetchStoredTable, storeTableData };
