/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface IMCCDeliveriesRequestBody
 */
export interface IMCCDeliveriesRequestBody {
  /**
   *
   * @type {boolean}
   * @memberof IMCCDeliveriesRequestBody
   */
  skipCache?: boolean;
  /**
   *
   * @type {number}
   * @memberof IMCCDeliveriesRequestBody
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof IMCCDeliveriesRequestBody
   */
  limit?: number;
}

export function IMCCDeliveriesRequestBodyFromJSON(json: any): IMCCDeliveriesRequestBody {
  return IMCCDeliveriesRequestBodyFromJSONTyped(json, false);
}

export function IMCCDeliveriesRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCDeliveriesRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    skipCache: !exists(json, 'skipCache') ? undefined : json['skipCache'],
    offset: !exists(json, 'offset') ? undefined : json['offset'],
    limit: !exists(json, 'limit') ? undefined : json['limit'],
  };
}

export function IMCCDeliveriesRequestBodyToJSON(value?: IMCCDeliveriesRequestBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    skipCache: value.skipCache,
    offset: value.offset,
    limit: value.limit,
  };
}
