import React from 'react';

import { SortDirection } from '../generated-backend-api';
import { SearchQueryFieldsSelect } from './search-query-field-select';
import Select, { Option } from '@ingka/select';
import { Stack } from './stack';

export const SearchSortPicker = ({
  field,
  order,
  allFields,
  onChange,
  prioritizedFields,
  isFieldEnabled,
}: {
  field?: string;
  allFields: string[];
  order?: SortDirection;
  onChange: (field: string, order: SortDirection) => void;
  prioritizedFields?: string[];
  isFieldEnabled?: (field: string) => boolean;
}) => {
  return (
    <Stack>
      <SearchQueryFieldsSelect
        searchableFields={allFields}
        prioritizedFields={prioritizedFields}
        isFieldEnabled={isFieldEnabled}
        id="select-sort-fields"
        label="Sort by"
        value={field}
        onChange={(q) => {
          onChange(q, order || 'asc');
        }}
      />
      <Select
        id="select-sort-order"
        label="Order"
        value={order ?? 'asc'}
        hintText={''}
        onChange={(v: React.FormEvent<HTMLSelectElement>) => {
          onChange(field || allFields[0], v.currentTarget.value as SortDirection);
        }}
      >
        <Option value="asc" label="Ascending" />
        <Option value="desc" label="Descending" />
      </Select>
    </Stack>
  );
};
