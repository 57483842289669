import { hasStringKey, IError, IResult, ok } from './type';

export function unreachable(_data: never): never {
  throw Error('supposed to be unreachable');
}

export interface ICouldNotPerformRequest extends IError<'could-not-perform-request'> {
  error: unknown;
}

export interface IRequestAbortedError extends IError<'request-aborted'> {
  error: unknown;
}

export function formatDate(timestamp: number): string {
  const d = new Date(timestamp);

  return (
    [
      d.getFullYear(),
      (d.getMonth() + 1).toString().padStart(2, '0'),
      d.getDate().toString().padStart(2, '0'),
    ].join('-') +
    ' ' +
    [
      d.getHours().toString().padStart(2, '0'),
      d.getMinutes().toString().padStart(2, '0'),
      d.getSeconds().toString().padStart(2, '0'),
    ].join(':')
  );
}

export async function controlledFetch(
  input: RequestInfo,
  init?: RequestInit,
): Promise<IResult<Response> | IRequestAbortedError | ICouldNotPerformRequest> {
  try {
    const res = await fetch(input, init);

    return ok(res);
  } catch (e: unknown) {
    if (hasStringKey('name', e) && e.name === 'AbortError') {
      return { isError: true, type: 'request-aborted', error: e };
    }

    return { isError: true, type: 'could-not-perform-request', error: e };
  }
}

/**
 * Extracts json from a Response object and returns fallback if extraction fails. Safe as in does not throw.
 */
export async function safeExtractResponseJson(
  response: Response,
  fallback = null,
): Promise<unknown> {
  try {
    return (await response.json()) as unknown;
  } catch (e) {
    console.warn('Could not extract response.', e);
  }

  return fallback;
}
