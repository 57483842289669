import { DefaultApi, SimilarityResult, ExtApiAssetList } from '../generated-backend-api';

export type FindSimilarFilter = {
  key: string;
  value: string | number | boolean;
  valid: boolean;
};

export type FindSimilarPayload =
  | { imageUrl: string; type: 'url'; count?: number }
  | { base64: string; type: 'base64'; count?: number };

export type FindSimilarResponse =
  | false
  | {
      searchResult: ExtApiAssetList;
      similar: { id: string; distance: number }[];
    };

/**
 * Finds approximately similar images to the given image. The image can be given as a URL or as a base64 encoded string.
 */
export async function findSimilar(
  payload: FindSimilarPayload,
  client: DefaultApi,
): Promise<FindSimilarResponse> {
  const featureResult = await client.VIImageFeatures({
    vIImageFeaturesPayload: {
      ...(payload.type === 'url'
        ? { image: { imageUrl: payload.imageUrl, type: 'imageUrl' } }
        : { image: { base64: payload.base64, type: 'base64' } }),
    },
  });

  if (featureResult.type !== 'vi-image-features') {
    return false;
  }

  const features = featureResult.result.results[0].extract;

  if (features?.length !== 192) {
    console.error('Unexpected feature length', featureResult);
    return false;
  }

  const { count } = payload;
  const similarImages = await client.SearchElasticVISimilarity({
    elasticSearchSimilarRequestBody: {
      vector: features,
      ...(count ? { count } : {}),
    },
  });

  if (similarImages.isError === true) {
    return false;
  }

  const h = similarImages.result.hits.hits;
  const z = h.map((q: SimilarityResult) => q._source);

  return {
    searchResult: { assets: z, notFound: [] },
    similar: h.map((q) => {
      return {
        id: q._id,
        distance: q._score,
      };
    }),
  };
}
