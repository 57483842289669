/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface RangePrioritization
 */
export interface RangePrioritization {
  /**
   *
   * @type {object}
   * @memberof RangePrioritization
   */
  itemMarkets: object;
  /**
   *
   * @type {Array<string>}
   * @memberof RangePrioritization
   */
  markets: Array<string>;
}

export function RangePrioritizationFromJSON(json: any): RangePrioritization {
  return RangePrioritizationFromJSONTyped(json, false);
}

export function RangePrioritizationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RangePrioritization {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    itemMarkets: json['itemMarkets'],
    markets: json['markets'],
  };
}

export function RangePrioritizationToJSON(value?: RangePrioritization | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    itemMarkets: value.itemMarkets,
    markets: value.markets,
  };
}
