/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
// aaaa
// export enum IMCCImageAttributesCameraShot {
//
//
//     Full_shot = 'Full shot',
//
//     Medium_shot = 'Medium shot',
//
//     Close_up = 'Close-up',
//
//     Extreme_close_up = 'Extreme close-up'
//
//
// }

export type IMCCImageAttributesCameraShot =
  | 'Full shot'
  | 'Medium shot'
  | 'Close-up'
  | 'Extreme close-up';

export function IMCCImageAttributesCameraShotFromJSON(json: any): IMCCImageAttributesCameraShot {
  return IMCCImageAttributesCameraShotFromJSONTyped(json, false);
}

export function IMCCImageAttributesCameraShotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCImageAttributesCameraShot {
  return json as IMCCImageAttributesCameraShot;
}

export function IMCCImageAttributesCameraShotToJSON(
  value?: IMCCImageAttributesCameraShot | null
): any {
  return value as any;
}
