/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface StreamError
 */
export interface StreamError {
  /**
   *
   * @type {boolean}
   * @memberof StreamError
   */
  isError: StreamErrorIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof StreamError
   */
  type: StreamErrorTypeEnum;
  /**
   *
   * @type {object}
   * @memberof StreamError
   */
  error: object;
}

/**
 * @export
 * @enum {boolean}
 */
export type StreamErrorIsErrorEnum = true;

/**
 * @export
 * @enum {string}
 */
export type StreamErrorTypeEnum = 'stream-error';

export function StreamErrorFromJSON(json: any): StreamError {
  return StreamErrorFromJSONTyped(json, false);
}

export function StreamErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreamError {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
    error: json['error'],
  };
}

export function StreamErrorToJSON(value?: StreamError | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
    error: value.error,
  };
}
