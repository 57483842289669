/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IDAMCoordinates,
  IDAMCoordinatesFromJSON,
  IDAMCoordinatesFromJSONTyped,
  IDAMCoordinatesToJSON,
} from './';

/**
 *
 * @export
 * @interface IDAMPropping
 */
export interface IDAMPropping {
  /**
   *
   * @type {string}
   * @memberof IDAMPropping
   */
  itemType?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMPropping
   */
  itemNo?: string;
  /**
   *
   * @type {IDAMCoordinates}
   * @memberof IDAMPropping
   */
  coordinates?: IDAMCoordinates;
}

export function IDAMProppingFromJSON(json: any): IDAMPropping {
  return IDAMProppingFromJSONTyped(json, false);
}

export function IDAMProppingFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDAMPropping {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    itemType: !exists(json, 'itemType') ? undefined : json['itemType'],
    itemNo: !exists(json, 'itemNo') ? undefined : json['itemNo'],
    coordinates: !exists(json, 'coordinates')
      ? undefined
      : IDAMCoordinatesFromJSON(json['coordinates']),
  };
}

export function IDAMProppingToJSON(value?: IDAMPropping | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    itemType: value.itemType,
    itemNo: value.itemNo,
    coordinates: IDAMCoordinatesToJSON(value.coordinates),
  };
}
