/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
// aaaa
// export enum IMCCImageAttributesSeason {
//
//
//     Spring = 'Spring',
//
//     Summer = 'Summer',
//
//     Winter = 'Winter',
//
//     Autumn = 'Autumn'
//
//
// }

export type IMCCImageAttributesSeason = 'Spring' | 'Summer' | 'Winter' | 'Autumn';

export function IMCCImageAttributesSeasonFromJSON(json: any): IMCCImageAttributesSeason {
  return IMCCImageAttributesSeasonFromJSONTyped(json, false);
}

export function IMCCImageAttributesSeasonFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCImageAttributesSeason {
  return json as IMCCImageAttributesSeason;
}

export function IMCCImageAttributesSeasonToJSON(value?: IMCCImageAttributesSeason | null): any {
  return value as any;
}
