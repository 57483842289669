/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IMCCProductResponseArticleNumber,
  IMCCProductResponseArticleNumberFromJSON,
  IMCCProductResponseArticleNumberFromJSONTyped,
  IMCCProductResponseArticleNumberToJSON,
  IMCCProductResponseType,
  IMCCProductResponseTypeFromJSON,
  IMCCProductResponseTypeFromJSONTyped,
  IMCCProductResponseTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface IMCCProductResponse
 */
export interface IMCCProductResponse {
  /**
   *
   * @type {IMCCProductResponseArticleNumber}
   * @memberof IMCCProductResponse
   */
  articleNumber?: IMCCProductResponseArticleNumber | null;
  /**
   *
   * @type {string}
   * @memberof IMCCProductResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof IMCCProductResponse
   */
  description?: string;
  /**
   *
   * @type {IMCCProductResponseType}
   * @memberof IMCCProductResponse
   */
  type?: IMCCProductResponseType | null;
}

export function IMCCProductResponseFromJSON(json: any): IMCCProductResponse {
  return IMCCProductResponseFromJSONTyped(json, false);
}

export function IMCCProductResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCProductResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    articleNumber: !exists(json, 'articleNumber')
      ? undefined
      : IMCCProductResponseArticleNumberFromJSON(json['articleNumber']),
    name: !exists(json, 'name') ? undefined : json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    type: !exists(json, 'type') ? undefined : IMCCProductResponseTypeFromJSON(json['type']),
  };
}

export function IMCCProductResponseToJSON(value?: IMCCProductResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    articleNumber: IMCCProductResponseArticleNumberToJSON(value.articleNumber),
    name: value.name,
    description: value.description,
    type: IMCCProductResponseTypeToJSON(value.type),
  };
}
