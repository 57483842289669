/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface BynderAssetUploadJobListRequestBody
 */
export interface BynderAssetUploadJobListRequestBody {
  /**
   *
   * @type {number}
   * @memberof BynderAssetUploadJobListRequestBody
   */
  startAfter?: number;
  /**
   *
   * @type {number}
   * @memberof BynderAssetUploadJobListRequestBody
   */
  startBefore?: number;
  /**
   *
   * @type {string}
   * @memberof BynderAssetUploadJobListRequestBody
   */
  name?: string;
}

export function BynderAssetUploadJobListRequestBodyFromJSON(
  json: any
): BynderAssetUploadJobListRequestBody {
  return BynderAssetUploadJobListRequestBodyFromJSONTyped(json, false);
}

export function BynderAssetUploadJobListRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BynderAssetUploadJobListRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    startAfter: !exists(json, 'startAfter') ? undefined : json['startAfter'],
    startBefore: !exists(json, 'startBefore') ? undefined : json['startBefore'],
    name: !exists(json, 'name') ? undefined : json['name'],
  };
}

export function BynderAssetUploadJobListRequestBodyToJSON(
  value?: BynderAssetUploadJobListRequestBody | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    startAfter: value.startAfter,
    startBefore: value.startBefore,
    name: value.name,
  };
}
