/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
  ElasticSearchMappingResponse,
  ValidationError,
  ElasticSearchMappingResponseFromJSONTyped,
  ElasticSearchMappingResponseToJSON,
  ValidationErrorFromJSONTyped,
  ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type ElasticSearchMapping
 *
 * @export
 */
export type ElasticSearchMapping = ElasticSearchMappingResponse | ValidationError;

export function ElasticSearchMappingFromJSON(json: any): ElasticSearchMapping {
  return ElasticSearchMappingFromJSONTyped(json, false);
}

export function ElasticSearchMappingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ElasticSearchMapping {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json['type']) {
    case 'elastic-search-mapping-response':
      return {
        ...ElasticSearchMappingResponseFromJSONTyped(json, true),
        type: 'elastic-search-mapping-response',
      };
    case 'failed-to-validate':
      return { ...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate' };
    default:
      throw new Error(`No variant of ElasticSearchMapping exists with 'type=${json['type']}'`);
  }
}

export function ElasticSearchMappingToJSON(value?: ElasticSearchMapping | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value['type']) {
    case 'elastic-search-mapping-response':
      return ElasticSearchMappingResponseToJSON(value);
    case 'failed-to-validate':
      return ValidationErrorToJSON(value);
    default:
      throw new Error(`No variant of ElasticSearchMapping exists with 'type=${value['type']}'`);
  }
}
