/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  FSVIAssetMetaV2,
  FSVIAssetMetaV2FromJSON,
  FSVIAssetMetaV2FromJSONTyped,
  FSVIAssetMetaV2ToJSON,
} from './';

/**
 *
 * @export
 * @interface VIAssetMetaResponse
 */
export interface VIAssetMetaResponse {
  /**
   *
   * @type {boolean}
   * @memberof VIAssetMetaResponse
   */
  isError: VIAssetMetaResponseIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof VIAssetMetaResponse
   */
  type: VIAssetMetaResponseTypeEnum;
  /**
   *
   * @type {FSVIAssetMetaV2}
   * @memberof VIAssetMetaResponse
   */
  result: FSVIAssetMetaV2;
}

/**
 * @export
 * @enum {boolean}
 */
export type VIAssetMetaResponseIsErrorEnum = false;

/**
 * @export
 * @enum {string}
 */
export type VIAssetMetaResponseTypeEnum = 'vi-asset-meta';

export function VIAssetMetaResponseFromJSON(json: any): VIAssetMetaResponse {
  return VIAssetMetaResponseFromJSONTyped(json, false);
}

export function VIAssetMetaResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VIAssetMetaResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
    result: FSVIAssetMetaV2FromJSON(json['result']),
  };
}

export function VIAssetMetaResponseToJSON(value?: VIAssetMetaResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
    result: FSVIAssetMetaV2ToJSON(value.result),
  };
}
