/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  PIAItemIdentifierType,
  PIAItemIdentifierTypeFromJSON,
  PIAItemIdentifierTypeFromJSONTyped,
  PIAItemIdentifierTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface PIAItemIdentifier
 */
export interface PIAItemIdentifier {
  /**
   *
   * @type {string}
   * @memberof PIAItemIdentifier
   */
  artNo: string;
  /**
   *
   * @type {PIAItemIdentifierType}
   * @memberof PIAItemIdentifier
   */
  type: PIAItemIdentifierType;
}

export function PIAItemIdentifierFromJSON(json: any): PIAItemIdentifier {
  return PIAItemIdentifierFromJSONTyped(json, false);
}

export function PIAItemIdentifierFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PIAItemIdentifier {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    artNo: json['artNo'],
    type: PIAItemIdentifierTypeFromJSON(json['type']),
  };
}

export function PIAItemIdentifierToJSON(value?: PIAItemIdentifier | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    artNo: value.artNo,
    type: PIAItemIdentifierTypeToJSON(value.type),
  };
}
