import React from 'react';

interface GridProps {
  children: React.ReactNode;
  columns: number;
  rowGap: number | string;
  colGap: number | string;
}

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

function numberToPx(n: number | string): string {
  if (typeof n === 'string') {
    return n;
  }

  return `${n}px`;
}

export const Grid: React.FC<GridProps> = ({ children, columns, rowGap, colGap }: GridProps) => {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(${columns}, 1fr);
        row-gap: ${numberToPx(rowGap)};
        column-gap: ${numberToPx(colGap)};
      `}
    >
      {children}
    </div>
  );
};
