/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface BynderAssetEditJobListRequestBody
 */
export interface BynderAssetEditJobListRequestBody {
  /**
   *
   * @type {number}
   * @memberof BynderAssetEditJobListRequestBody
   */
  startAfter?: number;
  /**
   *
   * @type {number}
   * @memberof BynderAssetEditJobListRequestBody
   */
  startBefore?: number;
}

export function BynderAssetEditJobListRequestBodyFromJSON(
  json: any
): BynderAssetEditJobListRequestBody {
  return BynderAssetEditJobListRequestBodyFromJSONTyped(json, false);
}

export function BynderAssetEditJobListRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BynderAssetEditJobListRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    startAfter: !exists(json, 'startAfter') ? undefined : json['startAfter'],
    startBefore: !exists(json, 'startBefore') ? undefined : json['startBefore'],
  };
}

export function BynderAssetEditJobListRequestBodyToJSON(
  value?: BynderAssetEditJobListRequestBody | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    startAfter: value.startAfter,
    startBefore: value.startBefore,
  };
}
