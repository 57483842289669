/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IMCCImageResponseData,
  IMCCImageResponseDataFromJSON,
  IMCCImageResponseDataFromJSONTyped,
  IMCCImageResponseDataToJSON,
  IMCCImageSearchResultResponseResultsJsonapi,
  IMCCImageSearchResultResponseResultsJsonapiFromJSON,
  IMCCImageSearchResultResponseResultsJsonapiFromJSONTyped,
  IMCCImageSearchResultResponseResultsJsonapiToJSON,
} from './';

/**
 *
 * @export
 * @interface IMCCImageSearchResultResponseResults
 */
export interface IMCCImageSearchResultResponseResults {
  /**
   *
   * @type {Array<IMCCImageResponseData>}
   * @memberof IMCCImageSearchResultResponseResults
   */
  data: Array<IMCCImageResponseData>;
  /**
   *
   * @type {IMCCImageSearchResultResponseResultsJsonapi}
   * @memberof IMCCImageSearchResultResponseResults
   */
  jsonapi: IMCCImageSearchResultResponseResultsJsonapi;
}

export function IMCCImageSearchResultResponseResultsFromJSON(
  json: any
): IMCCImageSearchResultResponseResults {
  return IMCCImageSearchResultResponseResultsFromJSONTyped(json, false);
}

export function IMCCImageSearchResultResponseResultsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCImageSearchResultResponseResults {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(IMCCImageResponseDataFromJSON),
    jsonapi: IMCCImageSearchResultResponseResultsJsonapiFromJSON(json['jsonapi']),
  };
}

export function IMCCImageSearchResultResponseResultsToJSON(
  value?: IMCCImageSearchResultResponseResults | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(IMCCImageResponseDataToJSON),
    jsonapi: IMCCImageSearchResultResponseResultsJsonapiToJSON(value.jsonapi),
  };
}
