import React, { useEffect, useState } from 'react';
import {
  Asset,
  GetVIAssetMetaResult,
  VIImageFeaturesResult,
  FSVIAssetMetaV2,
  PurgeAkamaiCacheResult,
} from '../generated-backend-api';
import { constructBynderAssetUrl } from '../util/bynder-api';
import { getExtProductCoordinates } from '../util/ext-asset-util';
import { hasKey, Loadable } from '../util/type';
import { Shoppable } from './shoppable';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Button from '@ingka/button';
import { SimilarList } from './similar-list';
import { Section } from './section/section';
import { FindSimilarPayload, FindSimilarResponse } from '../util/find-similar';
import { Stack } from './stack';
import { useToasts } from '../util/toastProvider';
import { useIsDamUser } from '../hooks/useIsDamUser';

interface ExtAssetSummaryProps {
  asset: Asset;
  bynderUrl: string;
  getImageFeatures: (url: string) => Promise<VIImageFeaturesResult>;
  getVIMeta: (id: string) => Promise<GetVIAssetMetaResult>;
  findSimilar: (payload: FindSimilarPayload) => Promise<FindSimilarResponse>;
  purgeAkamaiCache: (idHash: string) => Promise<PurgeAkamaiCacheResult>;
}

interface ImageColorProps {
  colors: NonNullable<FSVIAssetMetaV2['visualSearch']['imagecolor']>;
}

function ImageColor(props: ImageColorProps) {
  const { colors } = props;

  const samples = colors.map((c, index) => {
    return (
      <div
        key={`${index}_${c.rgb_code.join(',')}`}
        css={css`
          padding: 10px;
        `}
      >
        <div>
          Sample:{' '}
          <div
            style={{
              backgroundColor: `rgba(${c.rgb_code[0]}, ${c.rgb_code[1]}, ${c.rgb_code[2]}, 1)`,
              width: '100px',
              height: '100px',
            }}
          ></div>
        </div>
        <div>RGB: {`${c.rgb_code[0]}, ${c.rgb_code[1]}, ${c.rgb_code[2]}`}</div>
        <div>Part: {c.part.toPrecision(4)}</div>
        <div>Name: {c.color_name}</div>
        <div>Score: {c.score}</div>
      </div>
    );
  });

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      {samples}
    </div>
  );
}

interface ProductColorProps {
  colors: NonNullable<FSVIAssetMetaV2['visualSearch']['productcolor']>[string];
}

function ProductColor(props: ProductColorProps) {
  const { colors } = props;

  const samples = colors
    .filter((c) => c.part > 0.05)
    .map((c, index) => {
      return (
        <div
          key={`${index}_${c.color_name}`}
          css={css`
            padding: 10px;
          `}
        >
          <div>Name: {c.color_name}</div>
          <div>Score: {c.score}</div>
          <div>Part: {c.part.toPrecision(4)}</div>
        </div>
      );
    });

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      {samples}
    </div>
  );
}

interface StyleGroupProps {
  stylegroup: NonNullable<FSVIAssetMetaV2['visualSearch']['stylegroup']>;
}

function StyleGroup(props: StyleGroupProps) {
  const { stylegroup } = props;

  const samples = stylegroup.map((s, index) => {
    return (
      <div
        key={`${index}_${s.stylegroup}`}
        css={css`
          padding: 10px;
        `}
      >
        <div>
          <div>Style: {s.stylegroup}</div>
          <div>Score: {s.score}</div>
        </div>
      </div>
    );
  });

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      {samples}
    </div>
  );
}

interface RoomClassificationProps {
  roomclassification: NonNullable<FSVIAssetMetaV2['visualSearch']['roomclassification']>;
}

function RoomClassification(props: RoomClassificationProps) {
  const { roomclassification } = props;

  const samples = roomclassification.map((s, index) => {
    return (
      <div
        key={`${index}_${s.room_type}`}
        css={css`
          padding: 10px;
        `}
      >
        <div>Room type: {s.room_type}</div>
        <div>Score: {s.score.toFixed(2)}</div>
      </div>
    );
  });

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      {samples}
    </div>
  );
}

export function ExtAssetSummary(props: ExtAssetSummaryProps): JSX.Element {
  const [similar, setSimilar] = useState<Loadable<FindSimilarResponse>>({
    status: 'uninitialized',
  });

  const [assetVIMeta, setAssetVIMeta] = useState<Loadable<GetVIAssetMetaResult>>({
    status: 'uninitialized',
  });

  const [isPurging, setIsPurging] = useState(false);
  const isDamUser = useIsDamUser();

  const [activeDots, setActiveDots] = useState<Set<string>>(new Set());

  const toastApi = useToasts();

  const { asset, bynderUrl, getVIMeta } = props;

  useEffect(() => {
    if (assetVIMeta.status !== 'uninitialized') {
      return;
    }

    setAssetVIMeta({ status: 'loading' });
    getVIMeta(asset.id)
      .then((d) => {
        setAssetVIMeta({ status: 'loaded', data: d });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [asset, getVIMeta, assetVIMeta.status]);

  const onFindSimilar = async () => {
    console.log('find similar');

    if (!props.asset.thumbnails.webimage) {
      console.log('no image');
      return;
    }

    setSimilar({ status: 'loading' });

    const f = await props.findSimilar({ type: 'url', imageUrl: props.asset.thumbnails.webimage });

    setSimilar({ status: 'loaded', data: f });
  };

  const coords = getExtProductCoordinates(asset);

  let img;
  if (typeof asset.thumbnails.webimage === 'string') {
    if (asset.isShoppable && coords) {
      img = (
        <Shoppable
          url={asset.thumbnails.webimage}
          products={coords}
          highlightProductBoxes={[...activeDots]}
          onDotClick={(id) => {
            if (activeDots.has(id)) {
              const dots = [...activeDots].filter((d) => d !== id);
              setActiveDots(new Set([...dots]));
            } else {
              setActiveDots(new Set([...activeDots, id]));
            }
          }}
        />
      );
    } else {
      img = (
        <img
          src={asset.thumbnails.webimage}
          css={css`
            max-width: 100%;
          `}
        />
      );
    }
  } else {
    img = 'N/a';
  }

  // This mess is bacause DisclaimerText isn't exposed in API yet, and thus not in the type.
  // Once it it it will be visible in production though, and then we can fix this once we have
  // the updated api spec in place.
  const disclaimerText =
    hasKey('property_DisclaimerText', asset) && Array.isArray(asset.property_DisclaimerText)
      ? asset.property_DisclaimerText
          .map((a) => {
            if (hasKey('value', a)) {
              return a.value;
            }
            return 'N/A';
          })
          .join(',')
      : 'N/A';

  let featureRendering;

  let similarRendering;
  if (similar.status === 'loaded' && similar.data) {
    similarRendering = (
      <Section>
        <SimilarList similar={similar} bynderBaseUrl={props.bynderUrl} />
      </Section>
    );
  }

  let viMeta;
  if (assetVIMeta.status === 'loaded') {
    if (assetVIMeta.data.type !== 'vi-asset-meta') {
      viMeta = (
        <div>
          Error fetching VI asset meta<pre>{JSON.stringify(assetVIMeta.data, null, 4)}</pre>
        </div>
      );
    } else {
      const { imagecolor, productcolor, stylegroup, roomclassification } =
        assetVIMeta.data.result.visualSearch;

      let p;
      if (productcolor) {
        const artRows = Object.keys(productcolor)
          .sort()
          .map((k) => {
            const v = productcolor[k];

            return (
              <div
                key={k}
                css={css`
                  margin-bottom: 5px;
                `}
              >
                <h4 style={{ textDecoration: activeDots.has(k) ? 'underline' : 'none' }}>{k}</h4>
                <ProductColor colors={v} />
              </div>
            );
          });
        p = <div>{artRows}</div>;
      }

      const L = ({ text }: { text: string }) => {
        return (
          <span
            css={css`
              width: 100px;
              display: inline-block;
            `}
          >
            {text}
          </span>
        );
      };

      viMeta = (
        <div>
          {imagecolor ? (
            <Section>
              <h3>Image colors</h3>
              <div
                css={css`
                  padding: 10px;
                  margin-bottom: 10px;
                `}
              >
                <div>
                  <L text="Sample:" />
                  Sample of the color.
                </div>
                <div>
                  <L text="RGB:" />
                  Red, green, blue channel values.
                </div>
                <div>
                  <L text="Part:" />
                  Percentage of image identified by this color.
                </div>
                <div>
                  <L text="Name:" />
                  Name of the closest IKEA color.
                </div>
                <div>
                  <L text="Score:" />
                  How good match is it against the IKEA color.
                </div>
              </div>

              <ImageColor colors={imagecolor} />
            </Section>
          ) : null}
          <Section>
            <h3>Product colors</h3>
            <div
              css={css`
                padding: 10px;
                margin-bottom: 10px;
              `}
            >
              <div>
                <L text="Name:" />
                Name of the closest IKEA color.
              </div>
              <div>
                <L text="Score:" />
                How good match is it against the IKEA color.
              </div>
              <div>
                <L text="Part:" />
                Percentage of the product in the product box in the image identified by this color.
              </div>
            </div>
            {p}
          </Section>
          {stylegroup ? (
            <Section>
              <h3>Style group</h3>
              <div
                css={css`
                  padding: 10px;
                  margin-bottom: 10px;
                `}
              >
                <div>
                  <L text="Style:" />
                  Name of the style.
                </div>
                <div>
                  <L text="Score:" />
                  How well does the image match this style.
                </div>
              </div>
              <StyleGroup stylegroup={stylegroup} />
            </Section>
          ) : null}
          {roomclassification ? (
            <Section>
              <h3>Room classification</h3>
              <div
                css={css`
                  padding: 10px;
                  margin-bottom: 10px;
                `}
              >
                <div>
                  <L text="Room type:" />
                  Name of the room type.
                </div>
                <div>
                  <L text="Score:" />
                  How well does the image match this room type.
                </div>
              </div>
              <RoomClassification roomclassification={roomclassification} />
            </Section>
          ) : null}
        </div>
      );
    }
  } else if (assetVIMeta.status === 'loading') {
    viMeta = 'Loading VI meta...';
  }

  return (
    <div>
      <Section>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex-basis: 100%;
              flex: 1;
            `}
          >
            {img}
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex-basis: 100%;
              flex: 2;
              padding: 10px 10px 10px 30px;
              box-sizing: border-box;
            `}
          >
            <Section>
              <div>
                Id:{' '}
                <a
                  href={constructBynderAssetUrl(bynderUrl, asset.id)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {asset.id}
                </a>
              </div>
              <div>Name: {asset.name}</div>
              <div>Is shoppable: {asset.isShoppable === 1 ? 'Yes' : 'No'}</div>
              <div>
                Main product: {coords && coords[0] && coords[0].prd ? coords[0].prd : 'N/A'}
              </div>
              <div>
                Is public:{' '}
                {asset.isPublic === 1 && asset.imAsset ? (
                  <a href={asset.imAsset.url} target="_blank" rel="noreferrer">
                    {asset.imAsset.url}
                  </a>
                ) : (
                  'No'
                )}
              </div>
              <div>Disclaimer text: {disclaimerText}</div>
              <div>
                Release date: {asset.property_ReleaseDate ? asset.property_ReleaseDate : 'N/A'}
              </div>
            </Section>

            <Section>
              <Stack>
                <Button
                  text={'Find similar images'}
                  loading={similar.status === 'loading'}
                  onClick={onFindSimilar}
                />
                {isDamUser && (
                  <Button
                    text={'Purge cache'}
                    disabled={isPurging}
                    onClick={async () => {
                      setIsPurging(true);
                      const response = await props.purgeAkamaiCache(asset.idHash);
                      setIsPurging(false);

                      if (response.isError) {
                        console.error('Purge error', response);
                        toastApi.push({
                          isError: true,
                          message: response.type,
                        });
                      } else {
                        toastApi.push({
                          isError: false,
                          message: 'Purge successful',
                        });
                      }
                    }}
                  />
                )}
              </Stack>
            </Section>
            {featureRendering}
            {viMeta}
          </div>
        </div>
      </Section>
      {similarRendering}
    </div>
  );
}
