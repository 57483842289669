import Button from '@ingka/button';
import React from 'react';
import { useBynder } from '../util/bynderReactUtils';

export function BynderLoginButton(): JSX.Element {
  const bynderWrap = useBynder();

  const onBynder = () => {
    if (bynderWrap.isLoggedIn) {
      console.log('logout');
      bynderWrap.logout();
    } else {
      console.log('login');
      bynderWrap.login();
    }
  };

  if (!bynderWrap.isLoggedIn) {
    return (
      <Button
        title="Bynder"
        onClick={onBynder}
        loading={bynderWrap.user.status === 'loading'}
        text={`Bynder login`}
      />
    );
  } else {
    return (
      <Button
        title="Bynder"
        onClick={onBynder}
        text={`Bynder ${`logout (${bynderWrap.user.data.email})`}`}
      />
    );
  }
}
