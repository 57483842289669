import * as t from 'io-ts';
import { set, get } from 'idb-keyval';

const TEMPLATES_KEY = 'presets_data';

export const Template = t.type({
  version: t.literal('1'),
  columns: t.array(t.string),
  values: t.record(t.string, t.array(t.string)),
  timestamp: t.number,
  name: t.string,
});

export const TemplateList = t.array(Template);

export type TemplateType = t.TypeOf<typeof Template>;
export type TemplateListType = t.TypeOf<typeof TemplateList>;

export async function getIDBTemplates(): Promise<TemplateListType> {
  const data: unknown = await get(TEMPLATES_KEY);

  if (TemplateList.is(data)) {
    return data;
  } else {
    return [];
  }
}

export async function setIDBTemplates(templates: TemplateListType): Promise<void> {
  return set(TEMPLATES_KEY, templates);
}
