/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  FSAssetProdThumbnails,
  FSAssetProdThumbnailsFromJSON,
  FSAssetProdThumbnailsFromJSONTyped,
  FSAssetProdThumbnailsToJSON,
  FSAssetProdVisualSearch,
  FSAssetProdVisualSearchFromJSON,
  FSAssetProdVisualSearchFromJSONTyped,
  FSAssetProdVisualSearchToJSON,
} from './';

/**
 *
 * @export
 * @interface FSAssetProd
 */
export interface FSAssetProd {
  /**
   *
   * @type {FSAssetProdVisualSearch}
   * @memberof FSAssetProd
   */
  visualSearch?: FSAssetProdVisualSearch;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  activeOriginalFocusPoint?: object;
  /**
   *
   * @type {number}
   * @memberof FSAssetProd
   */
  archive?: number;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  aspectRatio?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  brandId?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  copyright?: string;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  customerSystem?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  dateCreated?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  dateModified?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  datePublished?: object;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof FSAssetProd
   */
  downloads?: number;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  extension?: object;
  /**
   *
   * @type {number}
   * @memberof FSAssetProd
   */
  fileSize?: number;
  /**
   *
   * @type {number}
   * @memberof FSAssetProd
   */
  height?: number;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  idHash?: string;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  imAsset?: object;
  /**
   *
   * @type {number}
   * @memberof FSAssetProd
   */
  isNetstorePublic?: number;
  /**
   *
   * @type {number}
   * @memberof FSAssetProd
   */
  isPublic?: number;
  /**
   *
   * @type {number}
   * @memberof FSAssetProd
   */
  isShoppable?: number;
  /**
   *
   * @type {number}
   * @memberof FSAssetProd
   */
  limited?: number;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  mediaItems?: object;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  nameLowerCase?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  orientation?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  original?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  originalName?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  originalNameLowerCase?: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  phash?: string;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  productInformation?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_AvailableAsOf?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_BriefName?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_CampaignsDeliveries?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_Channel?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_ConceptApproachID?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_ContentType?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_Copyright?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_Creating_Market?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_Disclaimer?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_DisclaimerText?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_EmbargoEndDate?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_InputMaterials?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_Language?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_Launch?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_LayoutID?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_Markets?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_NegotiatedUsageRights?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_Objective?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_Owner?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_PlanningInput?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_ProjectNumber?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_ReleaseDate?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_Season?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_SectionofChannel?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_Style?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_SubDelivery?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_SubSectionofChannel?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_Theme?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_WebLink?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_WorkflowJob?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_bynder_export?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_crop_info?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_icom_project_name?: object;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  property_product_coordinates?: string;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_product_list?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_room?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  property_solutionID?: object;
  /**
   *
   * @type {object}
   * @memberof FSAssetProd
   */
  relatedAssets?: object;
  /**
   *
   * @type {{ [key: string]: boolean; }}
   * @memberof FSAssetProd
   */
  tags?: { [key: string]: boolean };
  /**
   *
   * @type {FSAssetProdThumbnails}
   * @memberof FSAssetProd
   */
  thumbnails?: FSAssetProdThumbnails;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof FSAssetProd
   */
  views?: number;
  /**
   *
   * @type {number}
   * @memberof FSAssetProd
   */
  watermarked?: number;
  /**
   *
   * @type {number}
   * @memberof FSAssetProd
   */
  width?: number;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FSAssetProd
   */
  id: string;
}

export function FSAssetProdFromJSON(json: any): FSAssetProd {
  return FSAssetProdFromJSONTyped(json, false);
}

export function FSAssetProdFromJSONTyped(json: any, ignoreDiscriminator: boolean): FSAssetProd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    visualSearch: !exists(json, 'visualSearch')
      ? undefined
      : FSAssetProdVisualSearchFromJSON(json['visualSearch']),
    activeOriginalFocusPoint: !exists(json, 'activeOriginalFocusPoint')
      ? undefined
      : json['activeOriginalFocusPoint'],
    archive: !exists(json, 'archive') ? undefined : json['archive'],
    aspectRatio: !exists(json, 'aspectRatio') ? undefined : json['aspectRatio'],
    brandId: !exists(json, 'brandId') ? undefined : json['brandId'],
    copyright: !exists(json, 'copyright') ? undefined : json['copyright'],
    customerSystem: !exists(json, 'customerSystem') ? undefined : json['customerSystem'],
    dateCreated: !exists(json, 'dateCreated') ? undefined : json['dateCreated'],
    dateModified: !exists(json, 'dateModified') ? undefined : json['dateModified'],
    datePublished: !exists(json, 'datePublished') ? undefined : json['datePublished'],
    description: !exists(json, 'description') ? undefined : json['description'],
    downloads: !exists(json, 'downloads') ? undefined : json['downloads'],
    extension: !exists(json, 'extension') ? undefined : json['extension'],
    fileSize: !exists(json, 'fileSize') ? undefined : json['fileSize'],
    height: !exists(json, 'height') ? undefined : json['height'],
    idHash: !exists(json, 'idHash') ? undefined : json['idHash'],
    imAsset: !exists(json, 'imAsset') ? undefined : json['imAsset'],
    isNetstorePublic: !exists(json, 'isNetstorePublic') ? undefined : json['isNetstorePublic'],
    isPublic: !exists(json, 'isPublic') ? undefined : json['isPublic'],
    isShoppable: !exists(json, 'isShoppable') ? undefined : json['isShoppable'],
    limited: !exists(json, 'limited') ? undefined : json['limited'],
    mediaItems: !exists(json, 'mediaItems') ? undefined : json['mediaItems'],
    nameLowerCase: !exists(json, 'nameLowerCase') ? undefined : json['nameLowerCase'],
    orientation: !exists(json, 'orientation') ? undefined : json['orientation'],
    original: !exists(json, 'original') ? undefined : json['original'],
    originalName: !exists(json, 'originalName') ? undefined : json['originalName'],
    originalNameLowerCase: !exists(json, 'originalNameLowerCase')
      ? undefined
      : json['originalNameLowerCase'],
    phash: !exists(json, 'phash') ? undefined : json['phash'],
    productInformation: !exists(json, 'productInformation')
      ? undefined
      : json['productInformation'],
    property_AvailableAsOf: !exists(json, 'property_AvailableAsOf')
      ? undefined
      : json['property_AvailableAsOf'],
    property_BriefName: !exists(json, 'property_BriefName')
      ? undefined
      : json['property_BriefName'],
    property_CampaignsDeliveries: !exists(json, 'property_CampaignsDeliveries')
      ? undefined
      : json['property_CampaignsDeliveries'],
    property_Channel: !exists(json, 'property_Channel') ? undefined : json['property_Channel'],
    property_ConceptApproachID: !exists(json, 'property_ConceptApproachID')
      ? undefined
      : json['property_ConceptApproachID'],
    property_ContentType: !exists(json, 'property_ContentType')
      ? undefined
      : json['property_ContentType'],
    property_Copyright: !exists(json, 'property_Copyright')
      ? undefined
      : json['property_Copyright'],
    property_Creating_Market: !exists(json, 'property_Creating_Market')
      ? undefined
      : json['property_Creating_Market'],
    property_Disclaimer: !exists(json, 'property_Disclaimer')
      ? undefined
      : json['property_Disclaimer'],
    property_DisclaimerText: !exists(json, 'property_DisclaimerText')
      ? undefined
      : json['property_DisclaimerText'],
    property_EmbargoEndDate: !exists(json, 'property_EmbargoEndDate')
      ? undefined
      : json['property_EmbargoEndDate'],
    property_InputMaterials: !exists(json, 'property_InputMaterials')
      ? undefined
      : json['property_InputMaterials'],
    property_Language: !exists(json, 'property_Language') ? undefined : json['property_Language'],
    property_Launch: !exists(json, 'property_Launch') ? undefined : json['property_Launch'],
    property_LayoutID: !exists(json, 'property_LayoutID') ? undefined : json['property_LayoutID'],
    property_Markets: !exists(json, 'property_Markets') ? undefined : json['property_Markets'],
    property_NegotiatedUsageRights: !exists(json, 'property_NegotiatedUsageRights')
      ? undefined
      : json['property_NegotiatedUsageRights'],
    property_Objective: !exists(json, 'property_Objective')
      ? undefined
      : json['property_Objective'],
    property_Owner: !exists(json, 'property_Owner') ? undefined : json['property_Owner'],
    property_PlanningInput: !exists(json, 'property_PlanningInput')
      ? undefined
      : json['property_PlanningInput'],
    property_ProjectNumber: !exists(json, 'property_ProjectNumber')
      ? undefined
      : json['property_ProjectNumber'],
    property_ReleaseDate: !exists(json, 'property_ReleaseDate')
      ? undefined
      : json['property_ReleaseDate'],
    property_Season: !exists(json, 'property_Season') ? undefined : json['property_Season'],
    property_SectionofChannel: !exists(json, 'property_SectionofChannel')
      ? undefined
      : json['property_SectionofChannel'],
    property_Style: !exists(json, 'property_Style') ? undefined : json['property_Style'],
    property_SubDelivery: !exists(json, 'property_SubDelivery')
      ? undefined
      : json['property_SubDelivery'],
    property_SubSectionofChannel: !exists(json, 'property_SubSectionofChannel')
      ? undefined
      : json['property_SubSectionofChannel'],
    property_Theme: !exists(json, 'property_Theme') ? undefined : json['property_Theme'],
    property_WebLink: !exists(json, 'property_WebLink') ? undefined : json['property_WebLink'],
    property_WorkflowJob: !exists(json, 'property_WorkflowJob')
      ? undefined
      : json['property_WorkflowJob'],
    property_bynder_export: !exists(json, 'property_bynder_export')
      ? undefined
      : json['property_bynder_export'],
    property_crop_info: !exists(json, 'property_crop_info')
      ? undefined
      : json['property_crop_info'],
    property_icom_project_name: !exists(json, 'property_icom_project_name')
      ? undefined
      : json['property_icom_project_name'],
    property_product_coordinates: !exists(json, 'property_product_coordinates')
      ? undefined
      : json['property_product_coordinates'],
    property_product_list: !exists(json, 'property_product_list')
      ? undefined
      : json['property_product_list'],
    property_room: !exists(json, 'property_room') ? undefined : json['property_room'],
    property_solutionID: !exists(json, 'property_solutionID')
      ? undefined
      : json['property_solutionID'],
    relatedAssets: !exists(json, 'relatedAssets') ? undefined : json['relatedAssets'],
    tags: !exists(json, 'tags') ? undefined : json['tags'],
    thumbnails: !exists(json, 'thumbnails')
      ? undefined
      : FSAssetProdThumbnailsFromJSON(json['thumbnails']),
    type: !exists(json, 'type') ? undefined : json['type'],
    views: !exists(json, 'views') ? undefined : json['views'],
    watermarked: !exists(json, 'watermarked') ? undefined : json['watermarked'],
    width: !exists(json, 'width') ? undefined : json['width'],
    name: json['name'],
    id: json['id'],
  };
}

export function FSAssetProdToJSON(value?: FSAssetProd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    visualSearch: FSAssetProdVisualSearchToJSON(value.visualSearch),
    activeOriginalFocusPoint: value.activeOriginalFocusPoint,
    archive: value.archive,
    aspectRatio: value.aspectRatio,
    brandId: value.brandId,
    copyright: value.copyright,
    customerSystem: value.customerSystem,
    dateCreated: value.dateCreated,
    dateModified: value.dateModified,
    datePublished: value.datePublished,
    description: value.description,
    downloads: value.downloads,
    extension: value.extension,
    fileSize: value.fileSize,
    height: value.height,
    idHash: value.idHash,
    imAsset: value.imAsset,
    isNetstorePublic: value.isNetstorePublic,
    isPublic: value.isPublic,
    isShoppable: value.isShoppable,
    limited: value.limited,
    mediaItems: value.mediaItems,
    nameLowerCase: value.nameLowerCase,
    orientation: value.orientation,
    original: value.original,
    originalName: value.originalName,
    originalNameLowerCase: value.originalNameLowerCase,
    phash: value.phash,
    productInformation: value.productInformation,
    property_AvailableAsOf: value.property_AvailableAsOf,
    property_BriefName: value.property_BriefName,
    property_CampaignsDeliveries: value.property_CampaignsDeliveries,
    property_Channel: value.property_Channel,
    property_ConceptApproachID: value.property_ConceptApproachID,
    property_ContentType: value.property_ContentType,
    property_Copyright: value.property_Copyright,
    property_Creating_Market: value.property_Creating_Market,
    property_Disclaimer: value.property_Disclaimer,
    property_DisclaimerText: value.property_DisclaimerText,
    property_EmbargoEndDate: value.property_EmbargoEndDate,
    property_InputMaterials: value.property_InputMaterials,
    property_Language: value.property_Language,
    property_Launch: value.property_Launch,
    property_LayoutID: value.property_LayoutID,
    property_Markets: value.property_Markets,
    property_NegotiatedUsageRights: value.property_NegotiatedUsageRights,
    property_Objective: value.property_Objective,
    property_Owner: value.property_Owner,
    property_PlanningInput: value.property_PlanningInput,
    property_ProjectNumber: value.property_ProjectNumber,
    property_ReleaseDate: value.property_ReleaseDate,
    property_Season: value.property_Season,
    property_SectionofChannel: value.property_SectionofChannel,
    property_Style: value.property_Style,
    property_SubDelivery: value.property_SubDelivery,
    property_SubSectionofChannel: value.property_SubSectionofChannel,
    property_Theme: value.property_Theme,
    property_WebLink: value.property_WebLink,
    property_WorkflowJob: value.property_WorkflowJob,
    property_bynder_export: value.property_bynder_export,
    property_crop_info: value.property_crop_info,
    property_icom_project_name: value.property_icom_project_name,
    property_product_coordinates: value.property_product_coordinates,
    property_product_list: value.property_product_list,
    property_room: value.property_room,
    property_solutionID: value.property_solutionID,
    relatedAssets: value.relatedAssets,
    tags: value.tags,
    thumbnails: FSAssetProdThumbnailsToJSON(value.thumbnails),
    type: value.type,
    views: value.views,
    watermarked: value.watermarked,
    width: value.width,
    name: value.name,
    id: value.id,
  };
}
