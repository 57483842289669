/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
  EditJobItem,
  UploadJobItem,
  EditJobItemFromJSONTyped,
  EditJobItemToJSON,
  UploadJobItemFromJSONTyped,
  UploadJobItemToJSON,
} from './';

/**
 * oneof interface
 * @type CreateBynderJobRequestBodyItems
 *
 * @export
 */
export type CreateBynderJobRequestBodyItems = EditJobItem | UploadJobItem;

export function CreateBynderJobRequestBodyItemsFromJSON(
  json: any
): CreateBynderJobRequestBodyItems {
  return CreateBynderJobRequestBodyItemsFromJSONTyped(json, false);
}

export function CreateBynderJobRequestBodyItemsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateBynderJobRequestBodyItems {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json['type']) {
    case 'edit-job':
      return { ...EditJobItemFromJSONTyped(json, true), type: 'edit-job' };
    case 'upload-job':
      return { ...UploadJobItemFromJSONTyped(json, true), type: 'upload-job' };
    default:
      throw new Error(
        `No variant of CreateBynderJobRequestBodyItems exists with 'type=${json['type']}'`
      );
  }
}

export function CreateBynderJobRequestBodyItemsToJSON(
  value?: CreateBynderJobRequestBodyItems | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value['type']) {
    case 'edit-job':
      return EditJobItemToJSON(value);
    case 'upload-job':
      return UploadJobItemToJSON(value);
    default:
      throw new Error(
        `No variant of CreateBynderJobRequestBodyItems exists with 'type=${value['type']}'`
      );
  }
}
