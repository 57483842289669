/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
  VIAssetMetaNotFound,
  VIAssetMetaResponse,
  ValidationError,
  VIAssetMetaNotFoundFromJSONTyped,
  VIAssetMetaNotFoundToJSON,
  VIAssetMetaResponseFromJSONTyped,
  VIAssetMetaResponseToJSON,
  ValidationErrorFromJSONTyped,
  ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type GetVIAssetMeta
 *
 * @export
 */
export type GetVIAssetMeta = ValidationError | VIAssetMetaResponse | VIAssetMetaNotFound;

export function GetVIAssetMetaFromJSON(json: any): GetVIAssetMeta {
  return GetVIAssetMetaFromJSONTyped(json, false);
}

export function GetVIAssetMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetVIAssetMeta {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json['type']) {
    case 'failed-to-validate':
      return { ...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate' };
    case 'vi-asset-meta':
      return { ...VIAssetMetaResponseFromJSONTyped(json, true), type: 'vi-asset-meta' };
    case 'vi-asset-meta-not-found':
      return { ...VIAssetMetaNotFoundFromJSONTyped(json, true), type: 'vi-asset-meta-not-found' };
    default:
      throw new Error(`No variant of GetVIAssetMeta exists with 'type=${json['type']}'`);
  }
}

export function GetVIAssetMetaToJSON(value?: GetVIAssetMeta | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value['type']) {
    case 'failed-to-validate':
      return ValidationErrorToJSON(value);
    case 'vi-asset-meta':
      return VIAssetMetaResponseToJSON(value);
    case 'vi-asset-meta-not-found':
      return VIAssetMetaNotFoundToJSON(value);
    default:
      throw new Error(`No variant of GetVIAssetMeta exists with 'type=${value['type']}'`);
  }
}
