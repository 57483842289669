/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
// aaaa
// export enum IMCCImageAttributesMainHomeFurnishingActivityPrimary {
//
//
//     Come__go = 'Come & go',
//
//     Cook = 'Cook',
//
//     Eat = 'Eat',
//
//     Hobby = 'Hobby',
//
//     Personal_care = 'Personal care',
//
//     Play__create__learn = 'Play & create & learn',
//
//     Sleep = 'Sleep',
//
//     Socialise = 'Socialise',
//
//     Stay_healthy = 'Stay healthy',
//
//     Store__organise = 'Store & organise',
//
//     Wash__clean = 'Wash & clean',
//
//     Work = 'Work'
//
//
// }

export type IMCCImageAttributesMainHomeFurnishingActivityPrimary =
  | 'Come & go'
  | 'Cook'
  | 'Eat'
  | 'Hobby'
  | 'Personal care'
  | 'Play & create & learn'
  | 'Sleep'
  | 'Socialise'
  | 'Stay healthy'
  | 'Store & organise'
  | 'Wash & clean'
  | 'Work';

export function IMCCImageAttributesMainHomeFurnishingActivityPrimaryFromJSON(
  json: any
): IMCCImageAttributesMainHomeFurnishingActivityPrimary {
  return IMCCImageAttributesMainHomeFurnishingActivityPrimaryFromJSONTyped(json, false);
}

export function IMCCImageAttributesMainHomeFurnishingActivityPrimaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCImageAttributesMainHomeFurnishingActivityPrimary {
  return json as IMCCImageAttributesMainHomeFurnishingActivityPrimary;
}

export function IMCCImageAttributesMainHomeFurnishingActivityPrimaryToJSON(
  value?: IMCCImageAttributesMainHomeFurnishingActivityPrimary | null
): any {
  return value as any;
}
