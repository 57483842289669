/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
  CouldNotAuthIMCC,
  CouldNotPerformRequest,
  ExtSearch2ResultResponse,
  RequestAbortedError,
  UnexpectedResponseError,
  ValidationError,
  CouldNotAuthIMCCFromJSONTyped,
  CouldNotAuthIMCCToJSON,
  CouldNotPerformRequestFromJSONTyped,
  CouldNotPerformRequestToJSON,
  ExtSearch2ResultResponseFromJSONTyped,
  ExtSearch2ResultResponseToJSON,
  RequestAbortedErrorFromJSONTyped,
  RequestAbortedErrorToJSON,
  UnexpectedResponseErrorFromJSONTyped,
  UnexpectedResponseErrorToJSON,
  ValidationErrorFromJSONTyped,
  ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type SearchExtApi2
 *
 * @export
 */
export type SearchExtApi2 =
  | CouldNotAuthIMCC
  | CouldNotPerformRequest
  | ExtSearch2ResultResponse
  | ValidationError
  | RequestAbortedError
  | UnexpectedResponseError;

export function SearchExtApi2FromJSON(json: any): SearchExtApi2 {
  return SearchExtApi2FromJSONTyped(json, false);
}

export function SearchExtApi2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchExtApi2 {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json['type']) {
    case 'could-not-auth-imcc':
      return { ...CouldNotAuthIMCCFromJSONTyped(json, true), type: 'could-not-auth-imcc' };
    case 'could-not-perform-request':
      return {
        ...CouldNotPerformRequestFromJSONTyped(json, true),
        type: 'could-not-perform-request',
      };
    case 'ext-search2-result-response':
      return {
        ...ExtSearch2ResultResponseFromJSONTyped(json, true),
        type: 'ext-search2-result-response',
      };
    case 'failed-to-validate':
      return { ...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate' };
    case 'request-aborted':
      return { ...RequestAbortedErrorFromJSONTyped(json, true), type: 'request-aborted' };
    case 'unexpected-response':
      return { ...UnexpectedResponseErrorFromJSONTyped(json, true), type: 'unexpected-response' };
    default:
      throw new Error(`No variant of SearchExtApi2 exists with 'type=${json['type']}'`);
  }
}

export function SearchExtApi2ToJSON(value?: SearchExtApi2 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value['type']) {
    case 'could-not-auth-imcc':
      return CouldNotAuthIMCCToJSON(value);
    case 'could-not-perform-request':
      return CouldNotPerformRequestToJSON(value);
    case 'ext-search2-result-response':
      return ExtSearch2ResultResponseToJSON(value);
    case 'failed-to-validate':
      return ValidationErrorToJSON(value);
    case 'request-aborted':
      return RequestAbortedErrorToJSON(value);
    case 'unexpected-response':
      return UnexpectedResponseErrorToJSON(value);
    default:
      throw new Error(`No variant of SearchExtApi2 exists with 'type=${value['type']}'`);
  }
}
