/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface VIAssetMetaNotFound
 */
export interface VIAssetMetaNotFound {
  /**
   *
   * @type {boolean}
   * @memberof VIAssetMetaNotFound
   */
  isError: VIAssetMetaNotFoundIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof VIAssetMetaNotFound
   */
  type: VIAssetMetaNotFoundTypeEnum;
}

/**
 * @export
 * @enum {boolean}
 */
export type VIAssetMetaNotFoundIsErrorEnum = true;

/**
 * @export
 * @enum {string}
 */
export type VIAssetMetaNotFoundTypeEnum = 'vi-asset-meta-not-found';

export function VIAssetMetaNotFoundFromJSON(json: any): VIAssetMetaNotFound {
  return VIAssetMetaNotFoundFromJSONTyped(json, false);
}

export function VIAssetMetaNotFoundFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VIAssetMetaNotFound {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
  };
}

export function VIAssetMetaNotFoundToJSON(value?: VIAssetMetaNotFound | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
  };
}
