/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  AudioMetaDuration,
  AudioMetaDurationFromJSON,
  AudioMetaDurationFromJSONTyped,
  AudioMetaDurationToJSON,
} from './';

/**
 *
 * @export
 * @interface AudioMeta
 */
export interface AudioMeta {
  /**
   *
   * @type {number}
   * @memberof AudioMeta
   */
  size: number;
  /**
   *
   * @type {string}
   * @memberof AudioMeta
   */
  type: AudioMetaTypeEnum;
  /**
   *
   * @type {AudioMetaDuration}
   * @memberof AudioMeta
   */
  duration?: AudioMetaDuration;
}

/**
 * @export
 * @enum {string}
 */
export type AudioMetaTypeEnum = 'audio-meta';

export function AudioMetaFromJSON(json: any): AudioMeta {
  return AudioMetaFromJSONTyped(json, false);
}

export function AudioMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AudioMeta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    size: json['size'],
    type: json['type'],
    duration: !exists(json, 'duration') ? undefined : AudioMetaDurationFromJSON(json['duration']),
  };
}

export function AudioMetaToJSON(value?: AudioMeta | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    size: value.size,
    type: value.type,
    duration: AudioMetaDurationToJSON(value.duration),
  };
}
