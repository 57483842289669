import AspectRatioImage from '@ingka/aspect-ratio-image';
import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IBynderCollection } from '../util/bynder-api';

interface BynderBoardInfoShortProps {
  board: IBynderCollection;
}

export function BynderBoardInfoShort({ board }: BynderBoardInfoShortProps): JSX.Element {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <div
        css={css`
          width: 64px;
          height: 64px;
        `}
      >
        <AspectRatioImage
          src={board.cover.thumbnail ? board.cover.thumbnail : ''}
          ratio={'square'}
        />
      </div>
      <span
        css={css`
          margin-left: 10px;
          display: inline-block;
        `}
      >
        {board.name}
        <br />
        User: {board.user ? board.user.name : 'Unknown'}
        <br />
        Asset count: {board.collectionCount}
      </span>
    </div>
  );
}
