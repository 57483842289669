/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IMCCImageSearchResultResponseResults,
  IMCCImageSearchResultResponseResultsFromJSON,
  IMCCImageSearchResultResponseResultsFromJSONTyped,
  IMCCImageSearchResultResponseResultsToJSON,
} from './';

/**
 *
 * @export
 * @interface IMCCImageSearchResultResponse
 */
export interface IMCCImageSearchResultResponse {
  /**
   *
   * @type {boolean}
   * @memberof IMCCImageSearchResultResponse
   */
  isError: IMCCImageSearchResultResponseIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof IMCCImageSearchResultResponse
   */
  type: IMCCImageSearchResultResponseTypeEnum;
  /**
   *
   * @type {IMCCImageSearchResultResponseResults}
   * @memberof IMCCImageSearchResultResponse
   */
  results: IMCCImageSearchResultResponseResults;
}

/**
 * @export
 * @enum {boolean}
 */
export type IMCCImageSearchResultResponseIsErrorEnum = false;

/**
 * @export
 * @enum {string}
 */
export type IMCCImageSearchResultResponseTypeEnum = 'imcc-image-search-result-response';

export function IMCCImageSearchResultResponseFromJSON(json: any): IMCCImageSearchResultResponse {
  return IMCCImageSearchResultResponseFromJSONTyped(json, false);
}

export function IMCCImageSearchResultResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCImageSearchResultResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
    results: IMCCImageSearchResultResponseResultsFromJSON(json['results']),
  };
}

export function IMCCImageSearchResultResponseToJSON(
  value?: IMCCImageSearchResultResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
    results: IMCCImageSearchResultResponseResultsToJSON(value.results),
  };
}
