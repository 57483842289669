/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface Base64
 */
export interface Base64 {
  /**
   *
   * @type {string}
   * @memberof Base64
   */
  type: Base64TypeEnum;
  /**
   *
   * @type {string}
   * @memberof Base64
   */
  base64: string;
}

/**
 * @export
 * @enum {string}
 */
export type Base64TypeEnum = 'base64';

export function Base64FromJSON(json: any): Base64 {
  return Base64FromJSONTyped(json, false);
}

export function Base64FromJSONTyped(json: any, ignoreDiscriminator: boolean): Base64 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    base64: json['base64'],
  };
}

export function Base64ToJSON(value?: Base64 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    base64: value.base64,
  };
}
