/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  CreatePackageBodyItem,
  CreatePackageBodyItemFromJSON,
  CreatePackageBodyItemFromJSONTyped,
  CreatePackageBodyItemToJSON,
} from './';

/**
 *
 * @export
 * @interface CreatePackageBody
 */
export interface CreatePackageBody {
  /**
   *
   * @type {Array<CreatePackageBodyItem>}
   * @memberof CreatePackageBody
   */
  assetsToDownload: Array<CreatePackageBodyItem>;
}

export function CreatePackageBodyFromJSON(json: any): CreatePackageBody {
  return CreatePackageBodyFromJSONTyped(json, false);
}

export function CreatePackageBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreatePackageBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assetsToDownload: (json['assetsToDownload'] as Array<any>).map(CreatePackageBodyItemFromJSON),
  };
}

export function CreatePackageBodyToJSON(value?: CreatePackageBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assetsToDownload: (value.assetsToDownload as Array<any>).map(CreatePackageBodyItemToJSON),
  };
}
