/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface IDAMAssetType
 */
export interface IDAMAssetType {
  /**
   *
   * @type {string}
   * @memberof IDAMAssetType
   */
  communicationType?: string;
  /**
   *
   * @type {string}
   * @memberof IDAMAssetType
   */
  kind?: string;
}

export function IDAMAssetTypeFromJSON(json: any): IDAMAssetType {
  return IDAMAssetTypeFromJSONTyped(json, false);
}

export function IDAMAssetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDAMAssetType {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    communicationType: !exists(json, 'communicationType') ? undefined : json['communicationType'],
    kind: !exists(json, 'kind') ? undefined : json['kind'],
  };
}

export function IDAMAssetTypeToJSON(value?: IDAMAssetType | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    communicationType: value.communicationType,
    kind: value.kind,
  };
}
