/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  ExtApiSearch2Response,
  ExtApiSearch2ResponseFromJSON,
  ExtApiSearch2ResponseFromJSONTyped,
  ExtApiSearch2ResponseToJSON,
} from './';

/**
 *
 * @export
 * @interface ExtSearch2ResultResponse
 */
export interface ExtSearch2ResultResponse {
  /**
   *
   * @type {boolean}
   * @memberof ExtSearch2ResultResponse
   */
  isError: ExtSearch2ResultResponseIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof ExtSearch2ResultResponse
   */
  type: ExtSearch2ResultResponseTypeEnum;
  /**
   *
   * @type {ExtApiSearch2Response}
   * @memberof ExtSearch2ResultResponse
   */
  results: ExtApiSearch2Response;
}

/**
 * @export
 * @enum {boolean}
 */
export type ExtSearch2ResultResponseIsErrorEnum = false;

/**
 * @export
 * @enum {string}
 */
export type ExtSearch2ResultResponseTypeEnum = 'ext-search2-result-response';

export function ExtSearch2ResultResponseFromJSON(json: any): ExtSearch2ResultResponse {
  return ExtSearch2ResultResponseFromJSONTyped(json, false);
}

export function ExtSearch2ResultResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExtSearch2ResultResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
    results: ExtApiSearch2ResponseFromJSON(json['results']),
  };
}

export function ExtSearch2ResultResponseToJSON(value?: ExtSearch2ResultResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
    results: ExtApiSearch2ResponseToJSON(value.results),
  };
}
