/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  SimilarityFilterValue,
  SimilarityFilterValueFromJSON,
  SimilarityFilterValueFromJSONTyped,
  SimilarityFilterValueToJSON,
} from './';

/**
 *
 * @export
 * @interface SimilarityFilter
 */
export interface SimilarityFilter {
  /**
   *
   * @type {string}
   * @memberof SimilarityFilter
   */
  key: string;
  /**
   *
   * @type {SimilarityFilterValue}
   * @memberof SimilarityFilter
   */
  value: SimilarityFilterValue;
}

export function SimilarityFilterFromJSON(json: any): SimilarityFilter {
  return SimilarityFilterFromJSONTyped(json, false);
}

export function SimilarityFilterFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SimilarityFilter {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    key: json['key'],
    value: SimilarityFilterValueFromJSON(json['value']),
  };
}

export function SimilarityFilterToJSON(value?: SimilarityFilter | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    key: value.key,
    value: SimilarityFilterValueToJSON(value.value),
  };
}
