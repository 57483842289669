/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
// aaaa
// export enum IMCCImageAttributesRoomType {
//
//
//     Bedroom = 'Bedroom',
//
//     Living_room = 'Living room',
//
//     Kitchen = 'Kitchen',
//
//     Dining = 'Dining',
//
//     Baby_and_Childrens_room = 'Baby and Children\'s room',
//
//     Bathroom = 'Bathroom',
//
//     Workspace = 'Workspace',
//
//     Hallway = 'Hallway',
//
//     Outdoor = 'Outdoor'
//
//
// }

export type IMCCImageAttributesRoomType =
  | 'Bedroom'
  | 'Living room'
  | 'Kitchen'
  | 'Dining'
  | "Baby and Children's room"
  | 'Bathroom'
  | 'Workspace'
  | 'Hallway'
  | 'Outdoor';

export function IMCCImageAttributesRoomTypeFromJSON(json: any): IMCCImageAttributesRoomType {
  return IMCCImageAttributesRoomTypeFromJSONTyped(json, false);
}

export function IMCCImageAttributesRoomTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IMCCImageAttributesRoomType {
  return json as IMCCImageAttributesRoomType;
}

export function IMCCImageAttributesRoomTypeToJSON(value?: IMCCImageAttributesRoomType | null): any {
  return value as any;
}
