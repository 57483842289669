import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { SearchQueryFieldsSelect } from './search-query-field-select';
import { SearchFieldQuery } from './search-query-types';

export const SearchQueryFieldAdder = ({
  searchableFields,
  query,
  onChange,
  prioritizedFields,
}: {
  searchableFields: string[];
  query: SearchFieldQuery;
  prioritizedFields?: string[];
  onChange: (query: SearchFieldQuery) => void;
}) => {
  const usedFields = new Set(Object.keys(query));

  const add = (value: string) => {
    const searchTyp = value === 'productInformation.productName' ? { fuzzy: '' } : { prefix: '' };
    onChange({ ...query, ...{ [value]: searchTyp } });
  };

  return (
    <div
      css={css`
        margin-bottom: 10px;
      `}
    >
      <SearchQueryFieldsSelect
        isFieldEnabled={(f) => !usedFields.has(f)}
        onChange={(v) => add(v)}
        searchableFields={searchableFields}
        prioritizedFields={prioritizedFields}
        label="Add field"
        id="add-search-field"
      />
    </div>
  );
};
