/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  SimilarityFilter,
  SimilarityFilterFromJSON,
  SimilarityFilterFromJSONTyped,
  SimilarityFilterToJSON,
} from './';

/**
 *
 * @export
 * @interface ElasticSearchSimilarRequestBody
 */
export interface ElasticSearchSimilarRequestBody {
  /**
   *
   * @type {Array<number>}
   * @memberof ElasticSearchSimilarRequestBody
   */
  vector: Array<number>;
  /**
   *
   * @type {number}
   * @memberof ElasticSearchSimilarRequestBody
   */
  count?: number;
  /**
   *
   * @type {SimilarityFilter}
   * @memberof ElasticSearchSimilarRequestBody
   */
  filter?: SimilarityFilter;
}

export function ElasticSearchSimilarRequestBodyFromJSON(
  json: any
): ElasticSearchSimilarRequestBody {
  return ElasticSearchSimilarRequestBodyFromJSONTyped(json, false);
}

export function ElasticSearchSimilarRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ElasticSearchSimilarRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vector: json['vector'],
    count: !exists(json, 'count') ? undefined : json['count'],
    filter: !exists(json, 'filter') ? undefined : SimilarityFilterFromJSON(json['filter']),
  };
}

export function ElasticSearchSimilarRequestBodyToJSON(
  value?: ElasticSearchSimilarRequestBody | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vector: value.vector,
    count: value.count,
    filter: SimilarityFilterToJSON(value.filter),
  };
}
