/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface JobSavingInBynder
 */
export interface JobSavingInBynder {
  /**
   *
   * @type {string}
   * @memberof JobSavingInBynder
   */
  type: JobSavingInBynderTypeEnum;
  /**
   *
   * @type {number}
   * @memberof JobSavingInBynder
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof JobSavingInBynder
   */
  attempt: number;
}

/**
 * @export
 * @enum {string}
 */
export type JobSavingInBynderTypeEnum = 'job-saving-in-bynder';

export function JobSavingInBynderFromJSON(json: any): JobSavingInBynder {
  return JobSavingInBynderFromJSONTyped(json, false);
}

export function JobSavingInBynderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): JobSavingInBynder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    timestamp: json['timestamp'],
    attempt: json['attempt'],
  };
}

export function JobSavingInBynderToJSON(value?: JobSavingInBynder | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    timestamp: value.timestamp,
    attempt: value.attempt,
  };
}
