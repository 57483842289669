/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  IDAMRenditions,
  IDAMRenditionsFromJSON,
  IDAMRenditionsFromJSONTyped,
  IDAMRenditionsToJSON,
} from './';

/**
 *
 * @export
 * @interface IDAMFiles
 */
export interface IDAMFiles {
  /**
   *
   * @type {Array<IDAMRenditions>}
   * @memberof IDAMFiles
   */
  renditions?: Array<IDAMRenditions>;
}

export function IDAMFilesFromJSON(json: any): IDAMFiles {
  return IDAMFilesFromJSONTyped(json, false);
}

export function IDAMFilesFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDAMFiles {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    renditions: !exists(json, 'renditions')
      ? undefined
      : (json['renditions'] as Array<any>).map(IDAMRenditionsFromJSON),
  };
}

export function IDAMFilesToJSON(value?: IDAMFiles | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    renditions:
      value.renditions === undefined
        ? undefined
        : (value.renditions as Array<any>).map(IDAMRenditionsToJSON),
  };
}
