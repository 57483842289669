/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  SearchRequestBody,
  SearchRequestBodyFromJSON,
  SearchRequestBodyFromJSONTyped,
  SearchRequestBodyToJSON,
} from './';

/**
 *
 * @export
 * @interface ExtApiSearch2RequestBody
 */
export interface ExtApiSearch2RequestBody {
  /**
   *
   * @type {SearchRequestBody}
   * @memberof ExtApiSearch2RequestBody
   */
  options: SearchRequestBody;
}

export function ExtApiSearch2RequestBodyFromJSON(json: any): ExtApiSearch2RequestBody {
  return ExtApiSearch2RequestBodyFromJSONTyped(json, false);
}

export function ExtApiSearch2RequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExtApiSearch2RequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    options: SearchRequestBodyFromJSON(json['options']),
  };
}

export function ExtApiSearch2RequestBodyToJSON(value?: ExtApiSearch2RequestBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    options: SearchRequestBodyToJSON(value.options),
  };
}
