/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import { PIAItemList, PIAItemListFromJSON, PIAItemListFromJSONTyped, PIAItemListToJSON } from './';

/**
 *
 * @export
 * @interface PIASearchResponse
 */
export interface PIASearchResponse {
  /**
   *
   * @type {boolean}
   * @memberof PIASearchResponse
   */
  isError: PIASearchResponseIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof PIASearchResponse
   */
  type: PIASearchResponseTypeEnum;
  /**
   *
   * @type {PIAItemList}
   * @memberof PIASearchResponse
   */
  response: PIAItemList;
}

/**
 * @export
 * @enum {boolean}
 */
export type PIASearchResponseIsErrorEnum = false;

/**
 * @export
 * @enum {string}
 */
export type PIASearchResponseTypeEnum = 'pia-bulk-search-response';

export function PIASearchResponseFromJSON(json: any): PIASearchResponse {
  return PIASearchResponseFromJSONTyped(json, false);
}

export function PIASearchResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PIASearchResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
    response: PIAItemListFromJSON(json['response']),
  };
}

export function PIASearchResponseToJSON(value?: PIASearchResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
    response: PIAItemListToJSON(value.response),
  };
}
