import React from 'react';
import { BynderBoardViewer } from '../components/bynder-boards-viewer';
import { SectionWrap } from '../SectionWrap';

export const CropBoardsPage = () => {
  return (
    <SectionWrap>
      <BynderBoardViewer />
    </SectionWrap>
  );
};
