/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface FailedToInvalidateCacheError
 */
export interface FailedToInvalidateCacheError {
  /**
   *
   * @type {boolean}
   * @memberof FailedToInvalidateCacheError
   */
  isError: FailedToInvalidateCacheErrorIsErrorEnum;
  /**
   *
   * @type {string}
   * @memberof FailedToInvalidateCacheError
   */
  type: FailedToInvalidateCacheErrorTypeEnum;
  /**
   *
   * @type {object}
   * @memberof FailedToInvalidateCacheError
   */
  body: object;
  /**
   *
   * @type {number}
   * @memberof FailedToInvalidateCacheError
   */
  status: number;
}

/**
 * @export
 * @enum {boolean}
 */
export type FailedToInvalidateCacheErrorIsErrorEnum = true;

/**
 * @export
 * @enum {string}
 */
export type FailedToInvalidateCacheErrorTypeEnum = 'failed-to-invalidate-cache';

export function FailedToInvalidateCacheErrorFromJSON(json: any): FailedToInvalidateCacheError {
  return FailedToInvalidateCacheErrorFromJSONTyped(json, false);
}

export function FailedToInvalidateCacheErrorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FailedToInvalidateCacheError {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isError: json['isError'],
    type: json['type'],
    body: json['body'],
    status: json['status'],
  };
}

export function FailedToInvalidateCacheErrorToJSON(
  value?: FailedToInvalidateCacheError | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isError: value.isError,
    type: value.type,
    body: value.body,
    status: value.status,
  };
}
