/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
  VideoMetaDuration,
  VideoMetaDurationFromJSON,
  VideoMetaDurationFromJSONTyped,
  VideoMetaDurationToJSON,
} from './';

/**
 *
 * @export
 * @interface VideoMeta
 */
export interface VideoMeta {
  /**
   *
   * @type {number}
   * @memberof VideoMeta
   */
  size: number;
  /**
   *
   * @type {string}
   * @memberof VideoMeta
   */
  type: VideoMetaTypeEnum;
  /**
   *
   * @type {VideoMetaDuration}
   * @memberof VideoMeta
   */
  duration?: VideoMetaDuration;
  /**
   *
   * @type {number}
   * @memberof VideoMeta
   */
  height?: number;
  /**
   *
   * @type {number}
   * @memberof VideoMeta
   */
  width?: number;
}

/**
 * @export
 * @enum {string}
 */
export type VideoMetaTypeEnum = 'video-meta';

export function VideoMetaFromJSON(json: any): VideoMeta {
  return VideoMetaFromJSONTyped(json, false);
}

export function VideoMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): VideoMeta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    size: json['size'],
    type: json['type'],
    duration: !exists(json, 'duration') ? undefined : VideoMetaDurationFromJSON(json['duration']),
    height: !exists(json, 'height') ? undefined : json['height'],
    width: !exists(json, 'width') ? undefined : json['width'],
  };
}

export function VideoMetaToJSON(value?: VideoMeta | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    size: value.size,
    type: value.type,
    duration: VideoMetaDurationToJSON(value.duration),
    height: value.height,
    width: value.width,
  };
}
