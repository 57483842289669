/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface AssetThumbnails
 */
export interface AssetThumbnails {
  /**
   *
   * @type {string}
   * @memberof AssetThumbnails
   */
  mini?: string;
  /**
   *
   * @type {string}
   * @memberof AssetThumbnails
   */
  webimage?: string;
  /**
   *
   * @type {string}
   * @memberof AssetThumbnails
   */
  thul?: string;
}

export function AssetThumbnailsFromJSON(json: any): AssetThumbnails {
  return AssetThumbnailsFromJSONTyped(json, false);
}

export function AssetThumbnailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssetThumbnails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    mini: !exists(json, 'mini') ? undefined : json['mini'],
    webimage: !exists(json, 'webimage') ? undefined : json['webimage'],
    thul: !exists(json, 'thul') ? undefined : json['thul'],
  };
}

export function AssetThumbnailsToJSON(value?: AssetThumbnails | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    mini: value.mini,
    webimage: value.webimage,
    thul: value.thul,
  };
}
