/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 *
 * @export
 * @interface NotLoggedInError
 */
export interface NotLoggedInError {
  /**
   *
   * @type {string}
   * @memberof NotLoggedInError
   */
  type: NotLoggedInErrorTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof NotLoggedInError
   */
  isError: NotLoggedInErrorIsErrorEnum;
}

/**
 * @export
 * @enum {string}
 */
export type NotLoggedInErrorTypeEnum = 'not-logged-in';

/**
 * @export
 * @enum {boolean}
 */
export type NotLoggedInErrorIsErrorEnum = true;

export function NotLoggedInErrorFromJSON(json: any): NotLoggedInError {
  return NotLoggedInErrorFromJSONTyped(json, false);
}

export function NotLoggedInErrorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NotLoggedInError {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    isError: json['isError'],
  };
}

export function NotLoggedInErrorToJSON(value?: NotLoggedInError | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    isError: value.isError,
  };
}
